import { CustomTooltipProps } from "./MuiTooltip-tooltip.types";
import { ClickAwayListener, Tooltip } from "@mui/material";
import { useCallback } from "react";

export const CustomTooltip = ({
  isOpen,
  onClose,
  title,
  children,
  ...props
}: CustomTooltipProps) => {
  const handleTooltipClick = useCallback(
    (event) => {
      const target = event.target as HTMLElement;
      if (!isOpen) return;

      const isTooltip =
        target.classList.contains("MuiTooltip-tooltip") ||
        target.closest(".MuiTooltip-tooltip");

      if (!isTooltip) onClose();
    },
    [isOpen]
  );

  return (
    <ClickAwayListener onClickAway={handleTooltipClick}>
      <Tooltip open={isOpen} arrow {...props} title={title}>
        {children}
      </Tooltip>
    </ClickAwayListener>
  );
};
