import { createContext } from "react";
import { ProjectDictionary, ProjectListFilter } from "../../api/companies";
import { FormsState } from "./project.types";

interface Context {
  dictionaries: ProjectDictionary;
  formsState: FormsState;
  updateFormState: (state: FormsState) => void;
  filters: ProjectListFilter;
}

export const initialContext: Context = {
  dictionaries: {
    regions: [],
    timezones: [],
  },
  formsState: new FormsState(),
  filters: {
    regions: [],
    enabledModules: [],
    finTypes: [],
    projectTypes: [],
    statuses: [],
  },
  updateFormState: () => {},
};

export const projectContext = createContext<Context>(initialContext);
