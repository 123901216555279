import styled, { css } from "styled-components";
import { IconButton } from "@mui/material";

interface CardProps {
  $highPriority?: boolean;
}

export const StyledCard = styled.div<CardProps>`
  width: 100%;
  height: 100%;
  border-radius: 16px;
  padding: 20px;
  background: ${({ theme, $highPriority }) =>
    $highPriority ? theme.legends.orange : theme.primary.blueShades};
  display: grid;
  grid-row-gap: 12px;
  cursor: pointer;

  &:hover {
    box-shadow: 0 -6px 12px 0 #1018281a, 0 7px 8px -3px #1018281a,
      0 6px 20px 2px #10182814;
  }
`;

export const StyledHeader = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledButtonsWrapper = styled.div`
  display: flex;
  column-gap: 6px;
  align-self: start;
`;

interface IconButtonProps {
  $hasBorder?: boolean;
  $iconSize?: number;
}

export const StyledIconButton = styled(IconButton)<IconButtonProps>`
  width: 30px;
  height: 30px;
  border-radius: 6px;
  background: #fff;
  padding: 0;
  ${({ $hasBorder, theme }) =>
    $hasBorder &&
    css`
      border: 1px solid ${theme.primary.outlinedBorder};
    `};

  .icon {
    width: ${({ $iconSize }) => ($iconSize ? `${$iconSize}px` : "16px")};
    fill: ${({ theme }) => theme.primary.mainBlue};
  }
`;

export const StyledSeparator = styled.div`
  width: 100%;
  height: 1px;
  border-radius: 2px;
  background: #0000001a;
`;

export const StyledTextWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 4px;
`;

export const StyledTooltipText = styled.div`
  white-space: pre-line;
`;
