import { FC, useCallback, MouseEvent, useContext, useState } from "react";
import { UserByDivisionProps } from "./user-by-division.types";
import {
  StyledUser,
  StyledUserInfo,
  StyledAvatar,
  StyledNameWrapper,
  StyledName,
  StyledDivisionHead,
  StyledPosition,
  StyledControls,
  StyledButtonSync,
} from "./user-by-division.styles";
import { getPersonInitials } from "../../../../utils/commonUtils";
import SyncIcon from "@mui/icons-material/Sync";
import SyncDisabledIcon from "@mui/icons-material/SyncDisabled";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import { counterpartyStaffContext } from "../../context";
import { UnSyncModal } from "../un-sync-modal";
import { DivisionMenu } from "../division-menu";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { StyledDivisionButton } from "../../counterparty-staff.styles";
import { MenuItemValue } from "../division-menu/division-menu.types";
import { useUpdateAccountProfileByCompanyMutation } from "../../../../api/company-admin";
import { useTranslation } from "react-i18next";
import { StyledHeadName } from "../users-by-divisions/users-by-divisions.styles";
import { DeleteModal } from "../../../delete-modal";
import { useSnackbar } from "notistack";

export const UserByDivision: FC<UserByDivisionProps> = ({
  user,
  hasHead,
  hasDivision,
}) => {
  const lastName = user.lastName || "";
  const navigate = useNavigate();
  const location = useLocation();
  const { onUserUnSync, updateModalUnSyncID, modalUnSyncID } = useContext(
    counterpartyStaffContext
  );
  const params = useParams<{ id: string }>();
  const counterpartyID = Number(params.id);
  const { t } = useTranslation("counterparty");
  const { t: commonTranslation } = useTranslation("common");
  const { enqueueSnackbar } = useSnackbar();

  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const isInteractive = !!user.coreID || !!user.companyID;

  const [updateAccountProfileByCompany] =
    useUpdateAccountProfileByCompanyMutation();

  const userName = `${lastName} ${getPersonInitials({
    firstName: user.firstName,
    middleName: user.middleName,
    divider: ".",
  })}`;

  const handleButtonUnSyncClick = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation();

      updateModalUnSyncID(user.coreID);
    },
    [user.coreID, user.companyID]
  );

  const handleSyncConfirm = useCallback(() => {
    updateModalUnSyncID(null);
    if (!user.coreID || !user.companyID) return;

    onUserUnSync(user.companyID, user.coreID);
  }, []);

  const handleUserClick = useCallback(() => {
    navigate(`/companies/${user.companyID}/users/${user.coreID}`, {
      state: { from: location.pathname },
    });
  }, [navigate, location]);

  const handleButtonMoreClick = useCallback((event: MouseEvent) => {
    event.stopPropagation();
    const target = event.target as HTMLElement;

    setMenuAnchor(target);
  }, []);

  const updateAccountProfile = async () => {
    if (!user.companyID) return;

    try {
      await updateAccountProfileByCompany({
        companyId: user.companyID,
        userId: user.coreID,
        division: user.division,
        position: user.position,
        firstName: user.firstName,
        middleName: user.middleName,
        lastName: user.lastName,
        ...(user.company?.counterparty &&
          user.company.id && { company: { counterpartyID: user.company.id } }),
        companyName: user.companyName,
        divisionHead: true,
      });
    } catch {
      enqueueSnackbar(commonTranslation("error"), {
        variant: "error",
      });
    }
  };

  const handleMenuItemClick = useCallback(
    async (value: MenuItemValue) => {
      if (value === "head") {
        if (user.divisionHead) return;
        hasHead && !user.divisionHead
          ? setDeleteModalOpen(true)
          : await updateAccountProfile();
        return;
      }
    },
    [
      counterpartyID,
      user.companyID,
      user.coreID,
      user.division,
      user.position,
      user.firstName,
      user.middleName,
      user.lastName,
      user.companyName,
      hasHead,
    ]
  );

  const handleDeleteModalConfirm = useCallback(() => {
    setDeleteModalOpen(false);
    updateAccountProfile();
  }, []);

  return (
    <StyledUser $isInteractive={isInteractive} onClick={handleUserClick}>
      <StyledUserInfo>
        <StyledAvatar src={user.avatarPreview}>{userName}</StyledAvatar>

        <StyledNameWrapper>
          <StyledName>{userName}</StyledName>
          {user.divisionHead && (
            <StyledDivisionHead>{t("staff.head")}</StyledDivisionHead>
          )}
        </StyledNameWrapper>
        <StyledPosition>{`${user.position || ""}`}</StyledPosition>
      </StyledUserInfo>

      <StyledControls>
        <UnSyncModal
          open={modalUnSyncID === user.coreID}
          onClose={() => updateModalUnSyncID(null)}
          onConfirm={handleSyncConfirm}
        >
          <StyledButtonSync
            disabled={user.access.syncWithGlobal}
            onClick={handleButtonUnSyncClick}
          >
            {user.access.syncWithGlobal ? (
              <SyncIcon className="icon" />
            ) : (
              <SyncDisabledIcon className="icon" />
            )}
          </StyledButtonSync>
        </UnSyncModal>

        {hasDivision && !user.divisionHead && (
          <StyledDivisionButton onClick={handleButtonMoreClick}>
            <MoreVertIcon className="icon" />
          </StyledDivisionButton>
        )}
      </StyledControls>

      <DivisionMenu
        open={!!menuAnchor}
        onClose={() => setMenuAnchor(null)}
        anchor={menuAnchor}
        variant="user"
        onMenuItemClick={handleMenuItemClick}
      />

      <DeleteModal
        open={deleteModalOpen}
        title="Смена руководителя"
        description={
          <>
            Вы действительно хотите изменить руководителя подразделения? Новым
            руководителем будет назначен:
            <StyledHeadName>{userName}</StyledHeadName>
          </>
        }
        onClose={() => setDeleteModalOpen(false)}
        rightButtonColor="primary"
        onConfirm={handleDeleteModalConfirm}
      />
    </StyledUser>
  );
};
