import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const RecoveryTitle = () => {
  const { t } = useTranslation("recovery");
  const params = useParams();
  const step = params["*"];

  return <>{t(`steps.${step}`)}</>;
};
