import React, { FC, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Stack, Typography } from "@mui/material";
import { FieldForm } from "../../../../components/field-form";
import { AvatarFieldForm } from "../../../../components/avatar-field-form";
import { CompanySelect } from "../../../../components/company-select";
import { UserContext } from "../../context";
import { useAppContext } from "../../../../layouts/app-layout/context";
import { CounterpartyProfileRes } from "../../../../api/counterparty/types";
import { AutoComplete } from "../../../../components/auto-complete";

export const AccountForm: FC = () => {
  const { t } = useTranslation("user");
  const { filtersData, userValues, counterparties } = useContext(UserContext);
  const { mainCompany } = useAppContext();

  const divisionOptions = useMemo(() => {
    if (!filtersData.divisions?.length) return [];

    return filtersData.divisions.map((division) => ({
      label: division,
      value: division,
    }));
  }, [filtersData.divisions]);

  return (
    <Stack>
      <Stack direction="row" spacing={2.5} mb={1.5}>
        <AvatarFieldForm name="avatar" needCrop />
        <Stack flex={1} justifyContent="space-between">
          <FieldForm
            version="profile"
            name="lastName"
            title={t("lastName")}
            placeholder={t("placeholders.lastName")}
          />
          <FieldForm
            version="profile"
            name="firstName"
            title={t("firstName")}
            placeholder={t("placeholders.firstName")}
          />
          <FieldForm
            version="profile"
            name="middleName"
            title={t("middleName")}
            placeholder={t("placeholders.notRequired")}
          />
        </Stack>
      </Stack>
      <Stack spacing={1.5}>
        <CompanySelect
          counterparties={counterparties}
          userValues={userValues}
          mainCounterparty={mainCompany as Partial<CounterpartyProfileRes>}
        />

        <label className="field-form-label">
          <Typography pb={0.5} color="secondary" pl={2}>
            {t("divisionName")}
          </Typography>

          <AutoComplete
            options={divisionOptions}
            name="divisionName"
            placeholder={t("placeholders.divisionName")}
            isCreatable
            variant="profile"
            noOptionsMessage={t("noOptions.division")}
            disabled={!userValues?.companyName}
          />
        </label>

        <FieldForm
          version="profile"
          name="position"
          title={t("position")}
          placeholder={t("placeholders.position")}
        />
      </Stack>
    </Stack>
  );
};
