import styled from "styled-components";
import { IconButton } from "@mui/material";

export const StyledItem = styled.li`
  padding: 16px;
  border-radius: 8px;
  background: ${({ theme }) => theme.primary.blueShades};
  list-style: none;

  .icon {
    fill: ${({ theme }) => theme.secondary.text};
    width: 20px;
    margin-right: 8px;
  }

  .field-form-label {
    justify-content: start;
  }
`;

export const StyledSelectWrapper = styled.div`
  width: 100%;
  margin-left: 32px;

  .MuiInputBase-root.MuiOutlinedInput-root {
    background: #fff;
  }
`;

export const StyledDisabledOption = styled.li`
  opacity: 1 !important;
  display: flex;
  padding: 6px 16px;
  //pointer-events: auto!important;

  .warning-icon {
    width: 20px;
    margin-left: auto;
    fill: ${({ theme }) => theme.primary.warningMain};
  }
`;

export const StyledDisabledText = styled.div`
  opacity: 0.38;
`;

export const StyledWarningTooltipMessage = styled.div`
  width: 200px;
  white-space: pre-line;
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
`;

export const StyledButtonDelete = styled(IconButton)`
  margin-left: 8px;
  background: #ffb1b126;
  width: 36px;
  height: 36px;
  border-radius: 6px;

  .icon {
    width: 16px;
    fill: ${({ theme }) => theme.secondary.error};
    margin-right: 0;
  }

  &:disabled {
    background: ${({ theme }) => theme.background.gray};

    .icon {
      fill: ${({ theme }) => theme.primary.disabled};
    }
  }
`;
