import styled, { css } from "styled-components";
import { Autocomplete } from "@mui/material";

interface LabelProps {
  $variant?: "profile" | "default";
}

export const StyledLabel = styled.label<LabelProps>`
  .MuiTypography-root {
    font-size: 14px;
    color: ${({ theme }) => theme.primary.textDark};
  }

  .MuiInputBase-root.MuiOutlinedInput-root {
    ${({ $variant, theme }) =>
      $variant === "profile" &&
      css`
        background: ${theme.primary.formBackground};
        border: none;
        border-radius: 8px;
      `};
  }
`;

export const StyledAutoComplete = styled(Autocomplete)`
  .MuiInputBase-root {
    border: 1px solid ${({ theme }) => `${theme.secondary.textAdditional}4D`};
    color: ${({ theme }) => theme.primary.textDark};
  }

  .MuiInputBase-root {
    padding: 0 39px 0 16px;
  }

  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
`;

export const StyledNoOptionsMessage = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: ${({ theme }) => theme.primary.textDark};

  .icon {
    width: 20px;
    fill: ${({ theme }) => theme.primary.light};
    margin-right: 16px;
  }
`;
