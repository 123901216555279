import styled from "styled-components";

export const StyledTableWrapper = styled.div`
  width: 100%;
  height: 100%;

  .ag-row {
    &_interactive:hover {
      background: ${({ theme }) => `${theme.background.blue}14`};
    }

    &_high-priority {
      background: ${({ theme }) => theme.legends.orange};
    }
  }

  .ag-cell {
    &_vertical-padding_9 {
      padding: 9px 12px;
    }
  }
`;
