import { decode } from "js-base64";

export class FiltersState {
  group: string[] = [];
  authorID: number[] = [];
  supervisorID: number[] = [];
  upToDateFrom: string | null = null;
  upToDateTo: string | null = null;

  constructor(searchParams: URLSearchParams) {
    const groupParam = searchParams.get("group");
    const authorIDParam = searchParams.get("authorID");
    const supervisorIDParam = searchParams.get("supervisorID");
    //const upToDateFromParam = searchParams.get("upToDateFrom");
    //const upToDateToParam = searchParams.get("upToDateTo");

    if (groupParam) this.group = decode(groupParam).split(",");
    if (authorIDParam)
      this.authorID = decode(authorIDParam).split(",").map(Number);
    if (supervisorIDParam)
      this.supervisorID = decode(supervisorIDParam).split(",").map(Number);
    //if (upToDateFromParam) this.upToDateFrom = upToDateFromParam;
    //if (upToDateToParam) this.upToDateTo = upToDateToParam;
  }
}
