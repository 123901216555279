import { api } from "../api";
import {
  BindByClientIDParams,
  BindCounterparty,
  BindNewCounterpartyParams,
  BindNewCounterpartyWithAvatarParams,
  CheckInnParams,
  CheckInnRes,
  CommonParams,
  CommonParamsWithCounterparty,
  CounterpartyProfileRes,
  CounterpartyStaffResponse,
  GetListParams,
  GetListRes,
  GetRolesRes,
  UpdateCounterpartyAccessRequest,
  GetCompaniesAccessResponse,
  SetMainDivisionParams,
} from "./types";
import { Access } from "../profile";

export const counterpartyApi = api.injectEndpoints({
  endpoints: (build) => ({
    getList: build.query<GetListRes, GetListParams>({
      query: ({ companyID, ...params }) => {
        return {
          url: `/companies/${companyID}/admin/counterparty/list`,
          params,
        };
      },
      providesTags: ["Counterparty"],
    }),

    getCompaniesAccess: build.query<GetCompaniesAccessResponse, CommonParams>({
      query: ({ companyID }) => {
        return {
          url: `/companies/${companyID}/admin/counterparty/get-access`,
        };
      },
      providesTags: ["Counterparty"],
    }),

    getRoles: build.query<GetRolesRes, CommonParams>({
      query: ({ companyID }) => {
        return {
          url: `/companies/${companyID}/admin/counterparty/roles`,
          method: "GET",
        };
      },
    }),

    bindNewCounterparty: build.mutation<
      BindCounterparty,
      BindNewCounterpartyParams
    >({
      query: ({ companyID, body, variant, counterpartyID }) => {
        const urlPrefix =
          variant === "add" ? "bind-new" : `${counterpartyID}/update`;

        return {
          url: `/companies/${companyID}/admin/counterparty/${urlPrefix}`,
          method: variant === "add" ? "POST" : "PATCH",
          body,
        };
      },
      invalidatesTags: ["Counterparty", "Users"],
    }),

    bindNewCounterpartyWithAvatar: build.mutation<
      BindCounterparty,
      BindNewCounterpartyWithAvatarParams
    >({
      query: ({ companyID, body, variant, counterpartyID }) => {
        const urlPrefix =
          variant === "add"
            ? "bind-new-with-avatar"
            : `${counterpartyID}/update-with-avatar`;

        return {
          url: `/companies/${companyID}/admin/counterparty/${urlPrefix}`,
          method: variant === "add" ? "POST" : "PATCH",
          body,
        };
      },
      invalidatesTags: ["Counterparty"],
    }),

    checkInn: build.mutation<CheckInnRes, CheckInnParams>({
      query: ({ companyID, body }) => {
        return {
          url: `/companies/${companyID}/admin/counterparty/check-inn`,
          method: "POST",
          body,
        };
      },
    }),

    bindByClientID: build.mutation<BindCounterparty, BindByClientIDParams>({
      query: ({ companyID, body }) => {
        return {
          url: `/companies/${companyID}/admin/counterparty/bind-by-client-id`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["Counterparty"],
    }),

    deleteCounterparty: build.mutation<unknown, CommonParamsWithCounterparty>({
      query: ({ companyID, counterpartyID }) => {
        return {
          url: `/companies/${companyID}/admin/counterparty/${counterpartyID}/delete`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["Counterparty"],
    }),

    getCounterpartyByID: build.query<
      CounterpartyProfileRes,
      CommonParamsWithCounterparty
    >({
      query: ({ companyID, counterpartyID }) => {
        return {
          url: `/companies/${companyID}/admin/counterparty/${counterpartyID}`,
          method: "GET",
        };
      },
    }),

    getUsersByCounterparty: build.query<
      CounterpartyStaffResponse,
      CommonParamsWithCounterparty
    >({
      query: ({ companyID, counterpartyID }) => {
        return {
          url: `/companies/${companyID}/admin/counterparty/${counterpartyID}/users`,
          method: "GET",
        };
      },
      providesTags: ["Counterparty"],
    }),

    updateCounterpartyAccess: build.mutation<
      Access,
      UpdateCounterpartyAccessRequest
    >({
      query: ({ companyID, counterpartyID, body }) => {
        return {
          url: `/companies/${companyID}/admin/counterparty/${counterpartyID}/update-access`,
          body,
          method: "POST",
        };
      },
      invalidatesTags: ["Counterparty"],
    }),

    setMainDivision: build.mutation<string, SetMainDivisionParams>({
      query: ({ companyID, counterpartyID, body }) => {
        return {
          url: `/companies/${companyID}/admin/counterparty/${counterpartyID}/set-main-division`,
          body,
          method: "POST",
        };
      },
      invalidatesTags: ["Counterparty"],
    }),

    setMainDivisionForMainCompany: build.mutation<string, Omit<SetMainDivisionParams, 'counterpartyID'>>({
      query: ({ companyID, body }) => {
        return {
          url: `/companies/${companyID}/admin/set-main-division`,
          body,
          method: "POST",
        };
      },
      invalidatesTags: ["Counterparty"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetListQuery,
  useGetRolesQuery,
  useBindNewCounterpartyMutation,
  useBindNewCounterpartyWithAvatarMutation,
  useCheckInnMutation,
  useBindByClientIDMutation,
  useDeleteCounterpartyMutation,
  useGetCounterpartyByIDQuery,
  useLazyGetCounterpartyByIDQuery,
  useGetUsersByCounterpartyQuery,
  useUpdateCounterpartyAccessMutation,
  useGetCompaniesAccessQuery,
  useSetMainDivisionMutation,
  useSetMainDivisionForMainCompanyMutation,
} = counterpartyApi;
