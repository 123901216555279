import styled, { css } from "styled-components";

export const StyledPage = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: hidden;
`;

interface BodyProps {
  $isFlex?: boolean;
}

export const StyledBody = styled.div<BodyProps>`
  overflow-y: auto;
  height: calc(100% - 128px);
  ${({ $isFlex }) =>
    $isFlex &&
    css`
      display: flex;
      justify-content: center;
    `}
`;

interface BlockProps {
  $width: number;
}

export const StyledBlock = styled.div<BlockProps>`
  width: ${({ $width }) => `${$width}px`};
  padding: 0 60px 60px;
`;
