import React, { FC, useCallback, useMemo } from "react";
import { ExecutorsBlockProps } from "./executors-block.types";
import {
  StyledBlock,
  StyledTitle,
  StyledExecutorsList,
  StyledExecutorItem,
  StyledAddBlock,
  StyledLabel,
  StyledButtonDelete,
  StyledLabelWrapper,
  StyledExecutorName,
} from "./executors-block.styles";
import PeopleIcon from "@mui/icons-material/People";
import { useTranslation } from "react-i18next";
import { StyledDivider } from "../../assignment-by-division.styles";
import { ReactComponent as WorkersIcon } from "../../../../components/icons/imported-icons/workers.svg";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { AutoComplete } from "../../../../components/auto-complete";
import { getAssignmentPersonName } from "../../../../utils/commonUtils";
import { AssignmentPersonProfile } from "../../../../api/assignments/types";

export const ExecutorsBlock: FC<ExecutorsBlockProps> = ({
  executors,
  potentialExecutors,
  isUserAllowEdit,
  onExecutorsChange,
}) => {
  const { t } = useTranslation("assignments");

  const executorsNames = useMemo(
    () => executors.map((value) => value.value),
    [executors]
  );

  const executorsOptions = useMemo(() => {
    if (!potentialExecutors) return [];

    const filtered = potentialExecutors.filter(
      (item) => !executorsNames.includes(getAssignmentPersonName(item))
    );

    return filtered.map((value) => {
      const person = getAssignmentPersonName(value);

      return {
        value,
        label: person,
      };
    });
  }, [potentialExecutors, executorsNames]);

  const handleExecutorChange = useCallback(
    (label: string, value: {}, index: number) => {
      const newState = [...executors];

      newState[index] = {
        value: label,
        id: (value as AssignmentPersonProfile).coreID,
      };
      onExecutorsChange(newState);
    },
    [executors, potentialExecutors]
  );

  const handleExecutorDelete = useCallback(
    (value: string) => {
      onExecutorsChange(
        executors.filter((executor) => executor.value !== value)
      );
    },
    [executors]
  );

  return (
    <StyledBlock>
      <StyledTitle>
        <PeopleIcon className="title-icon" />
        {t("titles.executorsList")}
      </StyledTitle>

      <StyledDivider />

      <StyledExecutorsList>
        {executors.map((item, index) => (
          <StyledExecutorItem key={item.value || index}>
            <StyledLabelWrapper>
              <WorkersIcon className="executor-icon" />
              <StyledLabel>{t("executorFIO")}</StyledLabel>
            </StyledLabelWrapper>

            {isUserAllowEdit ? (
              <AutoComplete
                name="executor"
                options={executorsOptions}
                value={{ label: item.value || "", value: item.value || "" }}
                disabled={!isUserAllowEdit}
                onChange={(label, value) =>
                  handleExecutorChange(label, value, index)
                }
              />
            ) : (
              <StyledExecutorName>{item.value}</StyledExecutorName>
            )}

            {isUserAllowEdit && (
              <StyledButtonDelete
                disabled={executors.length === 1}
                onClick={() => handleExecutorDelete(item.value)}
              >
                <DeleteIcon className="icon" />
              </StyledButtonDelete>
            )}
          </StyledExecutorItem>
        ))}
      </StyledExecutorsList>

      {!!executorsOptions.length && isUserAllowEdit && (
        <StyledAddBlock
          startIcon={<AddIcon />}
          onClick={() => onExecutorsChange([...executors, { value: "" }])}
          disabled={!isUserAllowEdit}
        >
          {t("addExecutor")}
        </StyledAddBlock>
      )}
    </StyledBlock>
  );
};
