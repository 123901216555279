import styled from "styled-components";
import { Button, IconButton } from "@mui/material";

export const StyledBlock = styled.div`
  padding: 20px 0;
  display: grid;
  grid-row-gap: 16px;
`;

export const StyledTitle = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;

  .title-icon {
    fill: ${({ theme }) => theme.primary.light};
    width: 20px;
    margin-right: 10px;
  }
`;

export const StyledExecutorsList = styled.li`
  margin: 0;
  display: grid;
  grid-row-gap: 16px;
  grid-auto-rows: 68px;
`;

export const StyledExecutorItem = styled.li`
  list-style: none;
  border-radius: 8px;
  background: ${({ theme }) => theme.primary.blueShades};
  display: grid;
  grid-template-columns: max-content 1fr auto;
  grid-column-gap: 8px;
  align-items: center;
  column-gap: 8px;
  padding: 16px;

  .executor-icon {
    width: 20px;

    path {
      fill: ${({ theme }) => theme.secondary.text};
    }
  }
`;

export const StyledLabelWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
`;

export const StyledLabel = styled.div`
  color: ${({ theme }) => theme.secondary.textAdditional};
  padding-right: 10px;
  position: relative;

  &::before {
    content: "*";
    position: absolute;
    right: 0;
    color: ${({ theme }) => theme.secondary.error};
  }
`;

export const StyledAddBlock = styled(Button)`
  max-width: 230px;
  min-width: 230px;
  min-height: 36px;
  border-radius: 6px;
  background: ${({ theme }) => theme.primary.blueShades};
  justify-self: end;
  color: ${({ theme }) => theme.primary.mainBlue};
  font-size: 13px;
`;

export const StyledButtonDelete = styled(IconButton)`
  width: 36px;
  height: 36px;
  border-radius: 6px;
  background: ${({ theme }) => `${theme.charts.red}26`};
  margin-left: auto;

  .icon {
    fill: ${({ theme }) => theme.secondary.error};
  }

  &:disabled {
    background: ${({ theme }) => theme.background.gray};

    .icon {
      fill: ${({ theme }) => theme.primary.disabled};
    }
  }
`;

export const StyledExecutorName = styled.div`
  justify-self: end;
`;
