export type Browser =
  | "Microsoft Edge"
  | "Microsoft Internet Explorer"
  | "Mozilla Firefox"
  | "Opera"
  | "UC Browser"
  | "Samsung Browser"
  | "Google Chrome"
  | "Apple Safari"
  | "Other";

export const detectBrowser = (): Browser => {
  const test = function (regexp: RegExp) {
    return regexp.test(window.navigator.userAgent);
  };
  switch (true) {
    case test(/edg/i):
      return "Microsoft Edge";
    case test(/trident/i):
      return "Microsoft Internet Explorer";
    case test(/firefox|fxios/i):
      return "Mozilla Firefox";
    case test(/opr\//i):
      return "Opera";
    case test(/ucbrowser/i):
      return "UC Browser";
    case test(/samsungbrowser/i):
      return "Samsung Browser";
    case test(/chrome|chromium|crios/i):
      return "Google Chrome";
    case test(/safari/i):
      return "Apple Safari";
    default:
      return "Other";
  }
};
