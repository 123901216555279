import styled from "styled-components";

export const StyledWrapper = styled.div`
  display: grid;
  grid-row-gap: 4px;
`;

export const StyledTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
`;

export const StyledDescription = styled.div`
  font-size: 12px;
  font-weight: 400;
`;
