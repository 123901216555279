import {
  StyledCounterpartiesBlock,
  StyledRow,
  StyledKey,
  StyledKeyWrapper,
  StyledID,
  StyledCopyButton,
  StyledDescription,
  StyledButtonRefresh,
} from "./counterparties-block.styles";
import { useTranslation } from "react-i18next";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useSnackbar } from "notistack";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { CounterpartiesBlockProps } from "./counterparties-block.types";
import { FC } from "react";

export const CounterpartiesBlock: FC<CounterpartiesBlockProps> = ({
  clientID,
  onRefresh,
}) => {
  const { t } = useTranslation("administration");
  const { t: commonTranslation } = useTranslation("common");
  const { enqueueSnackbar } = useSnackbar();

  const handleCopyButtonClick = async () => {
    try {
      await navigator.clipboard.writeText(clientID);
    } catch (err) {
      enqueueSnackbar(commonTranslation("errors.copyError"), {
        variant: "error",
      });
    }
  };

  /*const handleRefreshConfirm = () => {
    setIsConfirmDialogOpen(false);
    onRefresh()
  };*/

  return (
    <StyledCounterpartiesBlock>
      <StyledRow>
        <StyledKey>{t("clientID")}</StyledKey>
        <StyledKeyWrapper>
          <StyledID>{clientID || ""}</StyledID>
          <StyledCopyButton onClick={handleCopyButtonClick}>
            <ContentCopyIcon className="icon" />
          </StyledCopyButton>
        </StyledKeyWrapper>

        <StyledButtonRefresh onClick={onRefresh}>
          <RestartAltIcon className="icon" />
        </StyledButtonRefresh>
      </StyledRow>

      <StyledDescription>{t("clientIDDescription")}</StyledDescription>

      {/*<Dialog open={isConfirmDialogOpen}>
        <ConfirmationDialog
          onCancel={() => setIsConfirmDialogOpen(false)}
          onSuccess={handleRefreshConfirm}
          title={t("clientIDRefreshModal.title")}
          description={t("clientIDRefreshModal.description")}
          theme="error"
        />
      </Dialog>*/}
    </StyledCounterpartiesBlock>
  );
};
