import { ListItem, Stack, Typography } from "@mui/material";
import React, { FC, SVGProps } from "react";
import Link from '@mui/material/Link';

interface PartnerCardProps {
  name: string;
  description: string;
  Icon: FC<SVGProps<SVGSVGElement>>;
  url: string;
}

export const PartnerCard: FC<PartnerCardProps> = ({
  name,
  description,
  Icon,
  url,
}) => {
  return (
    <Link href={url} target="_blank" color="#2B3648" underline="none">
      <ListItem
        sx={{
          padding: 0,
          borderRadius: 2,
          background: "#F6F7FB",
          position: "relative",
        }}
      >
        <Stack
          textAlign="center"
          alignItems="center"
          justifyContent="space-around"
          width="160px"
          height="220px"
          p="0 10px 10px"
        >
          <Stack flex={1}>
            <Stack m="0 auto" minHeight={100} justifyContent="center">
              <Icon />
            </Stack>
            <Typography
              sx={{
                fontWeight: 800,
              }}
              variant="body2"
              mb="6px"
              mt="6px"
            >
              {name}
            </Typography>

            <Typography
              sx={{
                fontWeight: 400,
              }}
              variant="body2"
              whiteSpace="pre-line"
            >
              {description}
            </Typography>
          </Stack>
        </Stack>
      </ListItem>
    </Link>
  );
};
