import { formatMaskPhone } from "../phone-field-form/utils";
import { Candidate, CreateCompanyUserResponse } from "../../api/company-admin";
import i18n from "i18next";

export const getLabelBindField = (
  candidates: CreateCompanyUserResponse["candidates"]
) => {
  return candidates?.[0]?.bindFields
    ?.map((field: "email" | "login" | "phone") =>
      field === "phone"
        ? formatMaskPhone(candidates[0]?.profile[field])
        : candidates[0]?.profile[field]
    )
    .join(", ");
};

export const getLabelBindFields = (bindFields: Candidate["bindFields"]) => {
  return bindFields
    ?.map((field) => i18n.t(`user:status.${field}`))
    .join(" И ")
    .toUpperCase();
};
