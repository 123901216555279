import styled from "styled-components";
import { textOneRow } from "../../common/styledMixins";

interface FailureDaysProps {
  $colorTheme: "primary" | "secondary" | "gray";
}

export const StyledText = styled.div`
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.primary.errorDark};
  width: 100%;
  text-align: center;
  ${textOneRow};
`;

export const StyledFailureDays = styled.div<FailureDaysProps>`
  min-width: 32px;
  height: 20px;
  border-radius: 6px;
  background: ${({ $colorTheme, theme }) => {
    if ($colorTheme === "gray") return theme.primary.blueShades;
    return $colorTheme === "secondary"
      ? theme.charts.red200
      : `${theme.charts.red}26`;
  }};
  padding: 3px 4px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${StyledText} {
    color: ${({ $colorTheme, theme }) => {
      if ($colorTheme === "gray") return theme.secondary.text;
      return $colorTheme === "primary"
        ? theme.secondary.error
        : theme.primary.errorDark;
    }}
`;
