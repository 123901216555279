import { Button, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteSharpIcon from "@mui/icons-material/DeleteSharp";
import {
  StyledHeader,
  StyledButtonRemove,
  StyledWrapper,
} from "./counterparty-header.styles";
import React, { useCallback, FC } from "react";
import { goBack } from "../../counterparty.service";
import { useLocation, useNavigate } from "react-router-dom";
import { CounterpartyHeaderProps, Tab } from "./counterparty-header.types";
import { useAppContext } from "../../../../layouts/app-layout/context";
import { useTranslation } from "react-i18next";
import { Tabs} from "../../../../components/tabs";

export const CounterpartyHeader: FC<CounterpartyHeaderProps> = ({
  tab,
  variant = "update",
  onTabChange,
  onButtonDeleteClick,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isAdmin } = useAppContext();
  const { t } = useTranslation("common");
  const { t: counterpartyT } = useTranslation("counterparty");

  const handleButtonGoBack = useCallback(() => {
    goBack({ location, navigate });
  }, [location, navigate]);

  const handleTabChange = (value: string) => {
    onTabChange(value as Tab);
  };

  return (
    <StyledHeader>
      <StyledWrapper>
        <Button
          onClick={handleButtonGoBack}
          sx={{
            "&": {
              color: "#2B3648",
            },
          }}
          startIcon={<ArrowBackIcon />}
        >
          <Typography variant="body2">{t("buttons.back")}</Typography>
        </Button>

        {isAdmin && (
          <Tabs
            currentValue={tab}
            values={[
              { label: counterpartyT("tabs.info"), value: "info" },
              { label: counterpartyT("tabs.users"), value: "users" },
            ]}
            onChange={handleTabChange}
          />
        )}

        {variant === "update" && isAdmin && (
          <StyledButtonRemove
            color="error"
            variant="contained"
            onClick={onButtonDeleteClick}
          >
            <DeleteSharpIcon />
          </StyledButtonRemove>
        )}
      </StyledWrapper>
    </StyledHeader>
  );
};
