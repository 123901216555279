import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Button, Stack } from "@mui/material";

type ProfileButtonsProps = {
  isSubmitDisabled: boolean;
  isDisabledContactsFields: boolean;
  handleReset: (e: React.SyntheticEvent) => void;
};

export const UserButtons: FC<ProfileButtonsProps> = ({
  isSubmitDisabled,
  isDisabledContactsFields,
  handleReset,
}) => {
  const { t } = useTranslation("user");

  return (
    <Stack mt={6} direction="row" spacing={4}>
      <Button
        fullWidth
        variant="contained"
        type="submit"
        color="success"
        disabled={isDisabledContactsFields ? false : isSubmitDisabled}
      >
        {t("common:buttons.save")}
      </Button>
      <Button fullWidth variant="contained" onClick={handleReset}>
        {t("common:buttons.cancel")}
      </Button>
    </Stack>
  );
};
