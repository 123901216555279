import { createContext } from "react";
import { Values } from "./components/assignment-form/assignment-form.types";
import {AssignmentsResponse} from "../../api/assignments/types";

interface EditAssignmentsContext {
  isFormReady: boolean;
  setIsFormReady: (value: boolean) => void;
  isAnyFieldChanged: boolean;
  setIsAnyFieldChanged: (value: boolean) => void;
  values?: Values;
  setValues: (value: Values) => void;
  currentAssignment?: AssignmentsResponse;
}

export const editAssignmentsContext = createContext<EditAssignmentsContext>({
  isFormReady: false,
  isAnyFieldChanged: false,
  setIsFormReady: () => {},
  setIsAnyFieldChanged: () => {},
  setValues: () => {},
});
