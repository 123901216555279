import styled from "styled-components";
import { MenuItem } from "@mui/material";

export const StyledListItemReset = styled(MenuItem)`
  font-weight: 500;
  padding: 8px 16px;
  background: #fff;
  max-height: 35px;
`;

export const StyledMenuItem = styled(MenuItem)`
  font-weight: 400;
  padding: 8px 16px;
  max-height: 35px;
`;

export const StyledInput = styled.input`
  margin: 8px 16px;
  width: calc(100% - 32px);
  border: 1px solid ${({ theme }) => `${theme.secondary.textAdditional}4D`};
  color: ${({ theme }) => theme.primary.textDark};
  background: #fff;
  border-radius: 8px;
  padding: 10.5px 16px;

  &::placeholder {
    color: ${({ theme }) => theme.primary.disabled};
  }

  &:focus-visible {
    outline: none;
  }
`;
