import styled from "styled-components";

export const StyledCell = styled.div`
  display: grid;
  grid-row-gap: 4px;
  align-items: center;
`;

interface TextProps {
  $colorTheme?: "primary" | "secondary";
}

export const StyledText = styled.div<TextProps>`
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: ${({ theme, $colorTheme = "primary" }) =>
    $colorTheme === "primary"
      ? theme.primary.textDark
      : theme.secondary.textAdditional};
`;
