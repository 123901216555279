import React, { FC, HTMLAttributes, useCallback, useMemo } from "react";
import { DivisionItemProps } from "./DivisionItem.types";
import {
  StyledItem,
  StyledSelectWrapper,
  StyledDisabledOption,
  StyledDisabledText,
  StyledWarningTooltipMessage,
  StyledButtonDelete,
} from "./DivisionItem.styles";
import { useTranslation } from "react-i18next";
import {
  StyledInputWrapper,
  StyledLabel,
} from "../../../assignment-form/assignment-form.styles";
import MapsHomeWorkIcon from "@mui/icons-material/MapsHomeWork";
import { AutoComplete } from "../../../../../../components/auto-complete";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { Tooltip } from "@mui/material";
import { checkOptionValue } from "./DivisionItem.service";
import DeleteIcon from "@mui/icons-material/Delete";

export const DivisionItem: FC<DivisionItemProps> = ({
  division,
  creationData,
  onButtonRemoveClick,
  isDisabled,
  onChange,
}) => {
  const { t } = useTranslation("assignments");

  const options = useMemo(() => {
    const options = creationData.map((value) => ({
      value,
      label: value.name || "",
    }));

    return [{ label: t("allDivisions"), value: "all" }, ...options];
  }, [creationData]);

  const renderOption = useCallback(
    (
      props: HTMLAttributes<HTMLLIElement>,
      option: { label: string; value: string | object }
    ) => {
      const isDisabled = checkOptionValue(option.value);

      return isDisabled ? (
        <li {...props}>{option.label}</li>
      ) : (
        <StyledDisabledOption>
          <StyledDisabledText>{option.label}</StyledDisabledText>
          <Tooltip
            slotProps={{
              tooltip: {
                sx: {
                  background: "#F6F7FB",
                  color: "#2B3648",
                },
              },
            }}
            followCursor
            title={
              <StyledWarningTooltipMessage>
                {t("divisions.warningTooltip")}
              </StyledWarningTooltipMessage>
            }
          >
            <WarningAmberIcon className="warning-icon" />
          </Tooltip>
        </StyledDisabledOption>
      );
    },
    []
  );

  return (
    <StyledItem>
      <StyledInputWrapper $direction="column" className="field-form-label">
        <MapsHomeWorkIcon className="icon" />
        <StyledLabel $isRequired>{t("division")}</StyledLabel>

        <StyledSelectWrapper>
          <AutoComplete
            options={options}
            name="division"
            variant="profile"
            placeholder={t("divisions.placeholder")}
            getOptionDisabled={(option) => !checkOptionValue(option.value)}
            renderOption={renderOption}
            onChange={onChange}
            value={{ label: division.name, value: division.name }}
          />
        </StyledSelectWrapper>

        <StyledButtonDelete disabled={isDisabled} onClick={onButtonRemoveClick}>
          <DeleteIcon className="icon" />
        </StyledButtonDelete>
      </StyledInputWrapper>
    </StyledItem>
  );
};
