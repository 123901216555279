import { StyledHeader } from "./header.styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Button } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { HeaderProps } from "./header.types";
import { encode } from "js-base64";
import { FilterSelectMultiple } from "../../../../components/filter-select-multiple";
import { Filters } from "../../assignment-by-central-office.types";
import { fromValuesToOptions } from "../../../../utils/commonUtils";
import { useAppContext } from "../../../../layouts/app-layout/context";

export const Header: FC<HeaderProps> = ({
  filters,
  searchParams,
  setSearchParams,
  statuses,
  onFiltersChange,
}) => {
  const { t: tCommon } = useTranslation("common");
  const { t } = useTranslation("assignments");
  const { currentCompanyId } = useAppContext();
  const navigate = useNavigate();

  const handleButtonBack = useCallback(() => {
    navigate(`/companies/${currentCompanyId}/assignments`);
  }, [currentCompanyId, navigate]);

  const handleChange = useCallback(
    (value: string[], key: keyof Filters) => {
      searchParams.set(key, encode(value.join(",")));
      setSearchParams(searchParams);

      onFiltersChange({
        ...filters,
        [key]: value,
      });
    },
    [filters, searchParams]
  );

  return (
    <StyledHeader>
      <Button startIcon={<ArrowBackIcon />} onClick={handleButtonBack}>
        {tCommon("buttons.back")}
      </Button>

      <div>
        <FilterSelectMultiple
          items={fromValuesToOptions(statuses)}
          value={filters.status}
          onChange={(value) => handleChange(value, "status")}
          defaultValue={t("filtersPlaceholders.status")}
        />
      </div>
    </StyledHeader>
  );
};
