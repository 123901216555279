import { Values } from "./components/assignment-form/assignment-form.types";
import { formatDateToISO } from "../../utils/commonUtils";
import { AssignmentsCreationData } from "../../api/assignments/types";
import {TFunction} from "react-i18next";

export function prepareBody(
  values: Values,
  isEdit?: boolean,
  creationData?: AssignmentsCreationData
) {
  const formData = new FormData();
  const upToDate = values.upToDate
    ? formatDateToISO(new Date(values.upToDate), true)
    : null;

  formData.append("text", values.text);
  formData.append("highPriority", values.highPriority.toString());
  if (upToDate) formData.append("upToDate", upToDate);
  if (values.group) formData.append("group", values.group);

  const supervisor = creationData?.supervisors?.find(
    (supervisor) =>
      `${supervisor.lastName} ${supervisor.firstName || ""} ${
        supervisor.middleName || ""
      }` === values.supervisor
  );
  if (typeof supervisor?.coreID === "number") {
    formData.append("supervisorID", supervisor.coreID.toString());
  }

  values.files.forEach((file) => {
    if (file.file instanceof Blob) formData.append("file", file.file);
    else {
      formData.append("existingFile", file.file.id.toString());
    }
  });

  if (values.divisions.length) {
    values.divisions.forEach((division) => {
      formData.append("division", division.name);
    });
  }

  if (isEdit) {
    formData.append("deleteOldDivisions", "true");
  }

  return formData;
}

export function getErrorMessage(message: string, t: TFunction, tCommon: TFunction) {
  if (message === "Not author or supervisor") return t("errors.notAuthorOrSupervisor");
  return tCommon("errors.error");
}
