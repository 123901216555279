import { FC } from "react";
import { AssignmentPanelProps } from "./assignment-panel.types";
import {
  StyledAssignmentPanel,
  StyledTitle,
  StyledDivider,
} from "./assignment-panel.styles";
import {
  StyledColumn,
  StyledLabel,
  StyledRow,
} from "../../../../common/assignmentsCommonStyles";
import { StyledText } from "../../../../common/assignmentsCommonStyles";
import { useTranslation } from "react-i18next";
import DateRangeIcon from "@mui/icons-material/DateRange";
import { formatDate, getPersonInitials } from "../../../../utils/commonUtils";
import { Divider } from "@mui/material";
import { ReactComponent as DirectorIcon } from "../../../../components/icons/imported-icons/director.svg";
import { ReactComponent as AuthorIcon } from "../../../../components/icons/imported-icons/author.svg";
import { AssignmentFilesList } from "../../../../components/assignment-files-list";
import { ReactComponent as FileIcon } from "../../../../components/icons/imported-icons/file.svg";

export const AssignmentPanel: FC<AssignmentPanelProps> = ({ data }) => {
  const { t } = useTranslation("assignments");

  const author = data?.author
    ? `${data.author.lastName} ${getPersonInitials({
        firstName: data.author.firstName,
        middleName: data.author.middleName,
        divider: ".",
      })}`
    : "–";
  const supervisor = data?.supervisor
    ? `${data.supervisor.lastName} ${getPersonInitials({
        firstName: data.supervisor.firstName,
        middleName: data.supervisor.middleName,
        divider: ".",
      })}`
    : "–";

  return (
    <StyledAssignmentPanel>
      <StyledTitle>{data?.text || "–"}</StyledTitle>

      <StyledDivider />

      <StyledRow>
        <StyledText $colorTheme="secondary">{`${t(
          "assignmentGroup"
        )}:`}</StyledText>
        <StyledText>{data?.group ?? "–"}</StyledText>
      </StyledRow>

      <StyledDivider />

      <StyledColumn>
        <StyledLabel>
          <DateRangeIcon className="icon" />
          <StyledText $colorTheme="secondary">{`${t(
            "departureDate"
          )}:`}</StyledText>
        </StyledLabel>
        <StyledText $isBold>
          {data?.createdAt ? formatDate(data.createdAt) : "–"}
        </StyledText>
      </StyledColumn>

      <StyledColumn>
        <StyledLabel>
          <DateRangeIcon className="icon" />
          <StyledText $colorTheme="secondary">{`${t(
            "executionPeriod"
          )}:`}</StyledText>
        </StyledLabel>
        <StyledText $isBold>
          {data?.createdAt ? formatDate(data.upToDate) : "–"}
        </StyledText>
      </StyledColumn>

      <Divider />

      <StyledColumn>
        <StyledLabel>
          <AuthorIcon className="icon" />
          <StyledText $colorTheme="secondary">{`${t("author")}:`}</StyledText>
        </StyledLabel>
        <StyledText $isBold>{author}</StyledText>
      </StyledColumn>

      <StyledColumn>
        <StyledLabel>
          <DirectorIcon className="icon" />
          <StyledText $colorTheme="secondary">{`${t(
            "supervisor.label"
          )}:`}</StyledText>
        </StyledLabel>
        <StyledText $isBold>{supervisor}</StyledText>
      </StyledColumn>

      {!!data?.files?.length && (
        <>
          <StyledDivider />

          <StyledColumn>
            <StyledLabel>
              <FileIcon className="icon" />
              <StyledText $colorTheme="secondary">{`${t(
                "attachedFiles"
              )}:`}</StyledText>
            </StyledLabel>
          </StyledColumn>

          <AssignmentFilesList files={data.files} />
        </>
      )}
    </StyledAssignmentPanel>
  );
};
