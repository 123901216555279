import {
  StyledInfoBlock,
  StyledBlock,
  StyledHeader,
  StyledAdministration,
  StyledUsersBlock,
} from "./administration.styles";
import { CounterpartiesBlock } from "./components/counterparties-block";
import { useForm } from "../../hooks/use-form";
import { validationAdministration } from "./validation";
import { FormikProvider } from "formik";
import {
  useGetCompanyAdminInfoQuery,
  useResetClientIdMutation,
  useUpdateCompanyAdminInfoWithAvatarMutation,
} from "../../api/companies";
import {
  useUpdateAccessMutation,
  useResetUserAccessByCounterpartyMutation,
  useGetUsersListOwnQuery,
} from "../../api/company-admin";
import { useAppContext } from "../../layouts/app-layout/context";
import { useCallback, useEffect, useMemo, useState } from "react";
import { InitValues } from "./administration.types";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { Progress } from "../../components/progress";
import { CounterpartyForm } from "../../components/counterparty-form";
import { Tabs } from "../../components/tabs";
import { Tab } from "./administration.types";
import { CounterpartyStaff } from "../../components/counterparty-staff";
import { Access } from "../../api/profile";
import { useQuery } from "../../hooks/useQuery";

export const Administration = () => {
  const { t: commonTranslation } = useTranslation("common");
  const { t } = useTranslation("administration");
  const { t: counterpartyT } = useTranslation("counterparty");
  const { currentCompanyId } = useAppContext();
  const { enqueueSnackbar } = useSnackbar();

  const queryHandler = useQuery((state) => {
    const tabs = state.get("tabs");
    if (!tabs) state.set("tabs", "info");
  });
  const queryTabs = queryHandler.get<Tab>("tabs");

  const [tabsState, setTabsState] = useState<Tab>(queryTabs || "info");

  const companyAdminInfo = useGetCompanyAdminInfoQuery(
    { companyID: currentCompanyId },
    { skip: !currentCompanyId }
  );
  const [
    updateCompanyAdminInfoWithAvatar,
    updateCompanyAdminInfoWithAvatarResult,
  ] = useUpdateCompanyAdminInfoWithAvatarMutation();

  const [updateAccess] = useUpdateAccessMutation();

  const [resetClientId] = useResetClientIdMutation();

  const [resetAccess] = useResetUserAccessByCounterpartyMutation();

  const users = useGetUsersListOwnQuery(
    {
      companyID: currentCompanyId,
    },
    { skip: !currentCompanyId || tabsState !== "users" }
  );

  const staffData = useMemo(() => {
    if (companyAdminInfo.data && users.data) {
      return {
        access: companyAdminInfo.data.access,
        divisions: users.data?.divisions || [],
      };
    }
  }, [users, companyAdminInfo]);

  const initialValues: InitValues = {
    name: companyAdminInfo.data?.name || "",
    inn: companyAdminInfo.data?.inn || "",
    kpp: companyAdminInfo.data?.kpp || "",
    ogrn: companyAdminInfo.data?.ogrn || "",
    fullName: companyAdminInfo.data?.fullName || "",
    legalAddress: companyAdminInfo.data?.legalAddress || "",
    avatar: companyAdminInfo.data?.avatar || "",
  };

  const handleSubmit = useCallback(
    async (values: InitValues) => {
      try {
        const formData = new FormData();
        values.avatar instanceof Blob && formData.append("file", values.avatar);
        const items = {
          name: values.name,
          inn: values.inn,
          kpp: values.kpp,
          ogrn: values.ogrn,
          fullName: values.fullName,
          legalAddress: values.legalAddress,
        };
        formData.append("request", JSON.stringify(items));
        await updateCompanyAdminInfoWithAvatar({
          companyID: currentCompanyId,
          body: formData,
        });
      } catch {
        enqueueSnackbar(commonTranslation("errors.error"), {
          variant: "error",
        });
      }
    },
    [initialValues]
  );

  const { formik, isSubmitDisabled } = useForm({
    validationSchema: validationAdministration,
    enableReinitialize: true,
    initialValues,
    onSubmit: handleSubmit,
    validateOnMount: true,
  });

  const { values, handleReset } = formik;

  useEffect(() => {
    if ([updateCompanyAdminInfoWithAvatarResult.isError].includes(true)) {
      enqueueSnackbar(commonTranslation("errors.error"), {
        variant: "error",
      });
    }
  }, [updateCompanyAdminInfoWithAvatarResult.isError]);

  const isValuesChanged = useMemo(
    () => JSON.stringify(initialValues) !== JSON.stringify(values),
    [initialValues, values]
  );

  const handleResetClientId = useCallback(async () => {
    try {
      await resetClientId({ companyID: currentCompanyId });
    } catch {
      enqueueSnackbar(commonTranslation("errors.error"), {
        variant: "error",
      });
    }
  }, [currentCompanyId]);

  const handleAccessConfirm = useCallback(
    async (access: Access) => {
      try {
        await updateAccess({
          companyID: currentCompanyId,
          body: access,
        });
        enqueueSnackbar(counterpartyT("confirm.access"), {
          variant: "success",
        });
      } catch {
        enqueueSnackbar(commonTranslation("errors.error"), {
          variant: "error",
        });
      }
    },
    [currentCompanyId, currentCompanyId, updateAccess]
  );

  const handleResetAccess = useCallback(
    async (companyID: number, coreID: number) => {
      try {
        await resetAccess({ companyID, userID: coreID });
      } catch {
        enqueueSnackbar(commonTranslation("errors.error"), {
          variant: "error",
        });
      }
    },
    []
  );

  const mainContentByTab = {
    info: (
      <FormikProvider value={formik}>
        {companyAdminInfo.isLoading ? (
          <Progress />
        ) : (
          <StyledInfoBlock>
            <StyledBlock $orientation="right">
              <CounterpartyForm
                t={t}
                formik={formik}
                isButtonsDisabled={isSubmitDisabled || !isValuesChanged}
                variant="update"
                onReset={handleReset}
              />
            </StyledBlock>
            <StyledBlock>
              <CounterpartiesBlock
                clientID={companyAdminInfo.data?.clientID || ""}
                onRefresh={handleResetClientId}
              />
            </StyledBlock>
          </StyledInfoBlock>
        )}
      </FormikProvider>
    ),

    users: (
      <StyledUsersBlock>
        <CounterpartyStaff
          companyID={currentCompanyId}
          counterpartyName={companyAdminInfo.data?.name || ""}
          onUserUnSync={handleResetAccess}
          onAccessConfirm={handleAccessConfirm}
          staffData={staffData}
          scrollPaddingBottom={60}
          isLoading={users.isLoading}
        />
      </StyledUsersBlock>
    ),
  };

  const handleTabsChange = (value: string) => {
    setTabsState(value as Tab);
    queryHandler.set("tabs", value);
  };

  return (
    <StyledAdministration>
      <StyledHeader>
        <Tabs
          currentValue={tabsState}
          values={[
            { label: counterpartyT("tabs.info"), value: "info" },
            { label: counterpartyT("tabs.users"), value: "users" },
          ]}
          onChange={handleTabsChange}
          hasPadding={false}
        />
      </StyledHeader>

      {mainContentByTab[tabsState]}
    </StyledAdministration>
  );
};
