import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, FormikProvider } from "formik";
import { useNavigate } from "react-router-dom";
import { Button, InputAdornment, Stack, Typography } from "@mui/material";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import CheckIcon from "@mui/icons-material/Check";
import PhoneIcon from "@mui/icons-material/Phone";
import { useLoginByPhoneMutation, useSendSmsMutation } from "../../../api/auth";
import { ShowByTimer, Timer } from "../../../components/show-by-timer";
import { PhoneFieldForm } from "../../../components/phone-field-form";
import { CodeFieldForm } from "../../../components/code-field-form";
import { useForm } from "../../../hooks/use-form";
import { useMutationHandlers } from "../../../hooks/use-mutation-handlers";
import { validationByPhone, validationByPhoneAndCode } from "./validation";

export const PhoneForm = () => {
  const { t } = useTranslation("auth");
  const navigate = useNavigate();

  const [isSmsWasSend, setIsSmsWasSend] = useState(false);
  const [timer, setTimer] = useState<Timer>();

  const [sendSms, sendSmsResponse] = useSendSmsMutation();
  const [loginByPhone, loginByPhoneResponse] = useLoginByPhoneMutation();

  const handleSendSms = useCallback(
    ({ phone }) => {
      sendSms({ phone });
    },
    [sendSms]
  );

  const handleSubmit = useCallback(
    ({ phone, code }) => {
      if (isSmsWasSend) {
        loginByPhone({ phone, code });
      } else {
        handleSendSms({ phone });
      }
    },
    [isSmsWasSend, loginByPhone, handleSendSms]
  );

  const { formik, isSubmitDisabled } = useForm({
    validationSchema: isSmsWasSend
      ? validationByPhoneAndCode
      : validationByPhone,
    initialValues: {
      phone: "",
      code: "",
    },
    onSubmit: handleSubmit,
  });
  const { errors, values, setFieldError, setFieldValue, setFieldTouched } =
    formik;

  useEffect(() => {
    setIsSmsWasSend(false);
  }, [values.phone]);

  useMutationHandlers(
    loginByPhoneResponse,
    () => {
      navigate("/companies");
    },
    (error) => {
      setFieldError(
        "code",
        "data" in error ? t(`common:errors.${error.data}`) : "Неверный код"
      );
    }
  );
  useMutationHandlers(
    sendSmsResponse,
    (data) => {
      setTimer({
        start: Math.floor(data?.canResendAfterMs / 1000) || 90,
      });
      setIsSmsWasSend(true);
      setFieldValue("code", "");
      setFieldTouched("code", false);
    },
    (error) => {
      setFieldError(
        "phone",
        "data" in error ? t(`common:errors.${error.data}`) : "Ошибка"
      );
    }
  );

  const isShowTextLoginButton =
    (!isSmsWasSend && isSubmitDisabled) || isSmsWasSend;

  const isShowSuccessIcon = values.code && !errors.code;

  return (
    <FormikProvider value={formik}>
      <Stack component={Form}>
        {isSmsWasSend ? (
          <Typography px={10} variant="body2" textAlign={"center"}>
            {t("phoneForm.status.sentCode", { phone: values.phone })}
          </Typography>
        ) : (
          <Typography variant="body2" textAlign={"center"}>
            {t("phoneForm.status.description")}
          </Typography>
        )}
        <PhoneFieldForm
          sx={{ marginTop: 3 }}
          name="phone"
          label={t("phoneForm.phone")}
          placeholder="+7 (900) 000-00-00"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PhoneIcon />
              </InputAdornment>
            ),
          }}
        />
        {isSmsWasSend && (
          <>
            <CodeFieldForm
              sx={{ marginTop: 3 }}
              name="code"
              label={t("phoneForm.code")}
              placeholder="- - - - - -"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PhoneAndroidIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    {isShowSuccessIcon && <CheckIcon color="success" />}
                  </InputAdornment>
                ),
              }}
            />
            <ShowByTimer
              timer={timer}
              waitingText={t("common:buttons.canGetNewCode")}
            >
              <Button
                sx={{ mt: 3 }}
                startIcon={<AutorenewIcon />}
                onClick={() => {
                  handleSendSms(values);
                }}
              >
                {t("common:buttons.getNewCode")}
              </Button>
            </ShowByTimer>
          </>
        )}

        <Button
          type="submit"
          sx={{ marginTop: 4, padding: 1.5 }}
          variant="contained"
          disabled={isSubmitDisabled}
        >
          {isShowTextLoginButton
            ? t("common:buttons.login")
            : t("common:buttons.send")}
        </Button>
      </Stack>
    </FormikProvider>
  );
};
