import React, { FC, useCallback, useEffect, useState } from "react";
import { StyledBody, StyledPage, StyledBlock } from "./counterparty.styles";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { MainForm } from "./components/main-form";
import { useAppContext } from "../../layouts/app-layout/context";
import {
  useGetRolesQuery,
  useGetCounterpartyByIDQuery,
  useGetUsersByCounterpartyQuery,
  useUpdateCounterpartyAccessMutation,
} from "../../api/counterparty";
import { useResetUserAccessByCounterpartyMutation } from "../../api/company-admin";
import { CounterpartyProps } from "./counerparty.types";
import { Tab } from "./components/counterparty-header/counterparty-header.types";
import { DeleteCounterpartyModal } from "./components/delete-counterparty-modal";
import { Progress } from "../../components/progress";
import { CounterpartyHeader } from "./components/counterparty-header";
import { CounterpartyStaff } from "../../components/counterparty-staff";
import { useSnackbar } from "notistack";
import { Access } from "../../api/profile";
import { useQuery } from "../../hooks/useQuery";

export const Counterparty: FC<CounterpartyProps> = ({ variant }) => {
  const { t } = useTranslation("counterparty");
  const { t: commonTranslation } = useTranslation("common");
  const { currentCompanyId } = useAppContext();
  const params = useParams<{ id: string }>();
  const counterpartyID = Number(params.id);
  const { enqueueSnackbar } = useSnackbar();
  const queryHandler = useQuery((state) => {
    const tabs = state.get("tabs");
    if (!tabs) state.set("tabs", "info");
  });
  const queryTabs = queryHandler.get<Tab>("tabs");

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [tabsState, setTabsState] = useState<Tab>(queryTabs || "info");

  const roles = useGetRolesQuery({ companyID: currentCompanyId });
  const { data: counterparty, isLoading } = useGetCounterpartyByIDQuery(
    { companyID: currentCompanyId, counterpartyID: counterpartyID },
    { skip: !counterpartyID || variant === "add" }
  );

  const usersByCounterparty = useGetUsersByCounterpartyQuery(
    {
      companyID: currentCompanyId,
      counterpartyID: counterpartyID,
    },
    { skip: !counterpartyID || tabsState !== "users" }
  );

  const [resetUserAccess, resetUserAccessData] =
    useResetUserAccessByCounterpartyMutation();

  const [updateCounterpartyAccess] = useUpdateCounterpartyAccessMutation();

  useEffect(() => {
    if (
      [usersByCounterparty.isError, resetUserAccessData.isError].includes(true)
    ) {
      enqueueSnackbar(commonTranslation("errors.error"), {
        variant: "error",
      });
    }
  }, [usersByCounterparty.isError, resetUserAccessData.isError]);

  const handleUserUnSync = useCallback((companyID: number, coreID: number) => {
    resetUserAccess({ companyID, userID: coreID });
  }, []);

  const handleTabChange = useCallback((value: Tab) => {
    setTabsState(value);
    queryHandler.set("tabs", value);
  }, []);

  const handleAccessConfirm = useCallback(async (access: Access) => {
    try {
      await updateCounterpartyAccess({
        companyID: currentCompanyId,
        counterpartyID: counterpartyID,
        body: access,
      });

      enqueueSnackbar(t("confirm.access"), {
        variant: "success",
      });
    } catch {
      enqueueSnackbar(commonTranslation("errors.error"), {
        variant: "error",
      });
    }
  }, []);

  const contentByTab = {
    info: (
      <StyledBody $isFlex>
        <StyledBlock $width={596}>
          <MainForm
            roles={roles.data?.roles}
            counterparty={counterparty}
            variant={variant}
          />
        </StyledBlock>
        <StyledBlock $width={540} />
      </StyledBody>
    ),
    users: (
      <StyledBody>
        <CounterpartyStaff
          staffData={usersByCounterparty.data}
          companyID={currentCompanyId}
          counterpartyName={counterparty?.name || ""}
          onUserUnSync={handleUserUnSync}
          isLoading={usersByCounterparty.isLoading}
          onAccessConfirm={handleAccessConfirm}
          scrollPaddingBottom={145}
        />
      </StyledBody>
    ),
  };

  return (
    <StyledPage>
      <CounterpartyHeader
        variant={variant}
        tab={tabsState}
        onButtonDeleteClick={() => setIsDeleteModalOpen(true)}
        onTabChange={handleTabChange}
      />

      {isLoading ? <Progress /> : contentByTab[tabsState]}

      <DeleteCounterpartyModal
        open={isDeleteModalOpen}
        title={t("deleteModal.title")}
        description={t("deleteModal.description")}
        onClose={() => setIsDeleteModalOpen(false)}
        onConfirm={() => ({})}
      />
    </StyledPage>
  );
};
