import {FC, useCallback, MouseEvent} from "react";
import { DivisionMenuProps, MenuItemValue } from "./division-menu.types";
import { Menu } from "@mui/material";
import { StyledMenuItem } from "./division-menu.styles";
import { divisionListData, userListData } from "./division-menu.constants";

const OFFSET_LEFT = 328;

export const DivisionMenu: FC<DivisionMenuProps> = ({
  open,
  anchor,
  onClose,
  variant,
  onMenuItemClick,
}) => {
  const handleClose = useCallback((event: MouseEvent) => {
    event.stopPropagation();
    onClose();
  }, [])

  const handleMenuItemClick = useCallback((event: MouseEvent, value: MenuItemValue) => {
    event.stopPropagation();

    onMenuItemClick(value);
    onClose();
  }, [])

  return (
    <Menu
      open={open}
      onClose={handleClose}
      anchorEl={anchor}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      transformOrigin={{ horizontal: OFFSET_LEFT, vertical: "top" }}
      slotProps={{
        paper: {
          sx: {
            overflow: "visible",
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 8,
              width: 10,
              height: 10,
              backgroundColor: "inherit",
              transform: "translateY(-50%) rotate(45deg)",
            },
          },
        },
      }}
    >
      {(variant === "user" ? userListData : divisionListData).map((item) => (
        <StyledMenuItem
          key={item.value}
          onClick={(event: MouseEvent) => handleMenuItemClick(event, item.value as MenuItemValue)}
        >
          <item.Icon className="icon" />
          {item.title}
        </StyledMenuItem>
      ))}
    </Menu>
  );
};
