import styled from "styled-components";

export const StyledPage = styled.div`
  width: 100%;
  height: 100%;
  padding: 10px 20px 20px;
`;

export const StyledBody = styled.div`
  width: 100%;
  height: calc(100% - 60px);
  margin-top: 10px;
`;
