import { ChangeEvent, FC, useCallback, useRef, useState } from "react";
import {
  AttachFile,
  FileAttachListProps,
} from "./assignments-file-attach-list.types";
import {
  StyledFileAttachList,
  StyledButtonAttach,
  StyledInputFile,
} from "./assignments-file-attach-list.styles";
import { useTranslation } from "react-i18next";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { Tooltip } from "@mui/material";
import { FilesList } from "./components/files-list";
import { DeleteModal } from "../delete-modal";
import { v4 } from "uuid";

const MAX_SIZE_IN_MB = 200;
const MAX_COUNT = 5;

export const AssignmentsFileAttachList: FC<FileAttachListProps> = ({
  files,
  onChange,
  disabled,
  hasFileExtension,
  onDelete,
}) => {
  const { t } = useTranslation("assignments");
  const inputRef = useRef<HTMLInputElement>(null);
  const [deletedFile, setDeletedFile] = useState<AttachFile | null>(null);

  const handelButtonAttachClick = useCallback(() => {
    if (!inputRef.current) return;

    inputRef.current.click();
  }, [inputRef.current]);

  const handleInputChange = useCallback(
    (event: ChangeEvent) => {
      const target = event.target as HTMLInputElement;

      if (!target.files) return;
      const filesList = Array.from(target.files);
      const filteredFilesList = filesList.filter(
        (file) => file.size <= MAX_SIZE_IN_MB * 1024 * 1024
      );

      const maxLength = MAX_COUNT - files.length;
      const newState = filteredFilesList
        .map((file) => ({ file, id: v4() }))
        .slice(0, maxLength);
      onChange([...files, ...newState]);

      target.value = "";
    },
    [files, inputRef.current]
  );

  const handleConfirm = useCallback(() => {
    if (deletedFile) {
      onDelete(deletedFile.file, deletedFile.id);
      setDeletedFile(null);
    }
  }, [deletedFile]);

  return (
    <StyledFileAttachList>
      <FilesList
        files={files}
        onButtonDeleteClick={setDeletedFile}
        hasFileExtension={hasFileExtension}
        disabled={disabled}
      />

      {files.length < MAX_COUNT && !disabled && (
        <Tooltip title={t("attachFiles.tooltip")}>
          <StyledButtonAttach
            startIcon={<AttachFileIcon />}
            variant="contained"
            onClick={handelButtonAttachClick}
          >
            {t("attachFiles.label")}
          </StyledButtonAttach>
        </Tooltip>
      )}

      <StyledInputFile
        ref={inputRef}
        type="file"
        multiple
        onChange={handleInputChange}
      />

      <DeleteModal
        open={!!deletedFile}
        title={t("attachDeleteModal.title")}
        description={t("attachDeleteModal.description")}
        onClose={() => setDeletedFile(null)}
        onConfirm={handleConfirm}
        rightButtonColor="primary"
      />
    </StyledFileAttachList>
  );
};
