import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
} from "@mui/material";
import AutoFixHighSharpIcon from "@mui/icons-material/AutoFixHighSharp";
import CheckIcon from "@mui/icons-material/Check";
import {
  MAX_PASSWORD_LENGTH_VALIDATION,
  MAX_WIDTH_PROFILE_BLOCK,
  MIN_WIDTH_PROFILE_BLOCK,
} from "../../../../utils/constants";
import {
  useSendConfirmationEmailMutation,
  useSendSmsMutation,
} from "../../../../api/auth";
import { PhoneFieldForm } from "../../../../components/phone-field-form";
import {
  EmailConfirmDailog,
  PhoneConfirmDialog,
} from "../../../../components/confirmation-dialogs";
import { FieldForm } from "../../../../components/field-form";

type ContactFormProps = {
  isShowConfirmPhone: boolean;
  isShowConfirmEmail: boolean;
  currentPhone: string;
  currentEmail: string;
  isPhoneConfirmed: boolean;
  isEmailConfirmed: boolean;
  handleLoginGeneration: () => void;
  handlePasswordGeneration: () => void;
  onFocusPassword: () => void;
  onBlurPassword: () => void;
};

export const ContactForm: FC<ContactFormProps> = ({
  isPhoneConfirmed,
  isEmailConfirmed,
  isShowConfirmEmail,
  isShowConfirmPhone,
  currentPhone,
  currentEmail,
  handleLoginGeneration,
  handlePasswordGeneration,
  onFocusPassword,
  onBlurPassword,
}) => {
  const { t } = useTranslation("profile");

  const [getCodeByPhone, getCodeByPhoneResponse] = useSendSmsMutation();
  const [getConfirmCodeEmail, getCodeByEmailResponse] =
    useSendConfirmationEmailMutation();

  const handleGetCodeByPhone = useCallback(() => {
    getCodeByPhone({ phone: currentPhone });
  }, [getCodeByPhone, currentPhone]);

  const handleGetCodeByEmail = useCallback(() => {
    getConfirmCodeEmail({ email: currentEmail });
  }, [getConfirmCodeEmail, currentEmail]);

  return (
    <Stack
      px={7.5}
      maxWidth={MAX_WIDTH_PROFILE_BLOCK}
      minWidth={MIN_WIDTH_PROFILE_BLOCK}
      flex={1}
      justifyContent="space-between"
      spacing={2}
      mb={2}
    >
      <Stack spacing={2}>
        <PhoneConfirmDialog
          currentPhone={currentPhone}
          handleGetCodeByPhone={handleGetCodeByPhone}
          getCodeByPhoneResponse={getCodeByPhoneResponse}
        />
        <EmailConfirmDailog
          currentEmail={currentEmail}
          handleGetCodeByEmail={handleGetCodeByEmail}
          getCodeByEmailResponse={getCodeByEmailResponse}
        />
        <label>
          <Typography color="secondary" pl={2}>
            {t("phone")}
          </Typography>
          <PhoneFieldForm
            sx={{
              "& div": {
                borderRadius: 2,
                "& .MuiFilledInput-input": {
                  padding: "10px 16px",
                },
              },
            }}
            fullWidth
            name="phone"
            variant="filled"
            size="small"
            placeholder={t("placeholders.phone")}
            InputProps={{
              disableUnderline: true,
              endAdornment: (
                <InputAdornment position="end">
                  {isShowConfirmPhone ? (
                    isPhoneConfirmed ? (
                      <CheckIcon color="primary" />
                    ) : (
                      <Button onClick={handleGetCodeByPhone}>
                        <Typography variant="caption" color="primary">
                          {t("confirm")}
                        </Typography>
                      </Button>
                    )
                  ) : undefined}
                </InputAdornment>
              ),
            }}
          />
        </label>
        <FieldForm
          version="profile"
          type="email"
          name="email"
          title={t("email")}
          placeholder={t("placeholders.email")}
          InputProps={{
            disableUnderline: true,
            endAdornment: (
              <InputAdornment position="end">
                {isShowConfirmEmail ? (
                  isEmailConfirmed ? (
                    <CheckIcon color="primary" />
                  ) : (
                    <Button onClick={handleGetCodeByEmail}>
                      <Typography variant="caption" color="primary">
                        {t("confirm")}
                      </Typography>
                    </Button>
                  )
                ) : undefined}
              </InputAdornment>
            ),
          }}
        />
      </Stack>
      <Stack spacing={2}>
        <FieldForm
          version="profile"
          name="login"
          title={t("login")}
          placeholder={t("placeholders.login")}
          InputProps={{
            disableUnderline: true,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleLoginGeneration}>
                  <AutoFixHighSharpIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <FieldForm
          onFocus={onFocusPassword}
          onBlur={onBlurPassword}
          maxLength={MAX_PASSWORD_LENGTH_VALIDATION}
          version="profile"
          name="password"
          title={t("password")}
          placeholder={t("placeholders.password")}
          InputProps={{
            disableUnderline: true,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handlePasswordGeneration}>
                  <AutoFixHighSharpIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>
    </Stack>
  );
};
