import styled from "styled-components";

export const StyledCommonInfo = styled.div`
  width: 100%;
  padding: 20px;
  border-radius: 16px;
  background: ${({ theme }) => theme.primary.blueShades};
  display: grid;
  row-gap: 12px;
  align-content: start;
`;

export const StyledHeader = styled.div`
  height: 40px;
`;
