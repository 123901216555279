import React, { FC, useCallback, useMemo } from "react";
import { FilterSelectMultiple } from "../../../../components/filter-select-multiple";
import { FiltersProps } from "./Filters.types";
import FilterAltSharpIcon from "@mui/icons-material/FilterAltSharp";
import { fromValuesToOptions } from "../../../../utils/commonUtils";
import { useTranslation } from "react-i18next";
import { FiltersKeys } from "../../projects.types";
import {
  enProjectFinancingTypes,
  enProjectStatuses,
  enProjectTypes,
} from "../../projects.constants";
import { StyledFilters } from "./Filters.styles";
import { encode } from "js-base64";
import { ModuleType } from "../../../../api/companies";

export const Filters: FC<FiltersProps> = ({
  finTypes,
  projectTypes,
  regions,
  enabledModules,
  statuses,
  state,
  searchParams,
  setSearchParams,
  onChange,
}) => {
  const { t } = useTranslation("projects");

  const finTypesOptions = useMemo(() => {
    return finTypes?.map((value) => ({
      title: enProjectFinancingTypes[value],
      value,
    }));
  }, [finTypes]);

  const projectTypesOptions = useMemo(() => {
    return projectTypes?.map((value) => ({
      title: enProjectTypes[value],
      value,
    }));
  }, [projectTypes]);

  const projectStatusesOptions = useMemo(() => {
    return statuses?.map((value) => ({
      title: enProjectStatuses[value],
      value,
    }));
  }, [statuses]);

  const enabledModulesOptions = useMemo(
    () =>
      enabledModules.map((value) => {
        let title = "";
        switch (value) {
          case ModuleType.resource_managment:
            title = "resource managment";
            break;
          case ModuleType.quality_control:
            title = "quality control";
            break;
          case ModuleType.asbuilt_doc:
            title = "as-built documentation";
            break;
          default:
            title = value;
        }

        return { value, title };
      }),
    [enabledModules]
  );

  const handleChange = useCallback(
    (value: string[], key: FiltersKeys) => {
      searchParams.set(key, encode(value.join(",")));
      setSearchParams(searchParams);

      onChange({
        ...state,
        [key]: value,
      });
    },
    [state, searchParams]
  );

  return (
    <StyledFilters>
      <FilterSelectMultiple
        startIcon={<FilterAltSharpIcon />}
        items={enabledModulesOptions}
        value={state.enabledModules}
        onChange={(value) => handleChange(value, "enabledModules")}
        defaultValue={t("filters.enabledModules.all")}
      />

      <FilterSelectMultiple
        items={finTypesOptions}
        value={state.finTypes}
        onChange={(value) => handleChange(value, "finTypes")}
        defaultValue={t("filters.finTypes.all")}
      />

      <FilterSelectMultiple
        items={projectTypesOptions}
        value={state.projectTypes}
        onChange={(value) => handleChange(value, "projectTypes")}
        defaultValue={t("filters.projectTypes.all")}
      />

      <FilterSelectMultiple
        items={fromValuesToOptions(regions)}
        value={state.regions}
        onChange={(value) => handleChange(value, "regions")}
        defaultValue={t("filters.regions.all")}
        isSearchable
      />

      <FilterSelectMultiple
        items={projectStatusesOptions}
        value={state.statuses}
        onChange={(value) => handleChange(value, "statuses")}
        defaultValue={t("filters.statuses.all")}
      />
    </StyledFilters>
  );
};
