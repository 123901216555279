import styled from "styled-components";
import { Button } from "@mui/material";

export const StyledHeader = styled.div`
  margin: 10px 0;
  display: flex;
  align-items: center;
  min-height: 60px;
`;

export const StyledButtonAdd = styled(Button)`
  border-radius: 8px;
  margin-left: auto;
  background: ${({ theme }) => theme.primary.mainBlue};
`;
