import React, { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Form, FormikProvider } from "formik";
import { Button, InputAdornment, Stack, Typography } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import {
  SendCodeToEmailRequest,
  useSendCodeToEmailMutation,
} from "../../../api/auth";
import { useMutationHandlers } from "../../../hooks/use-mutation-handlers";
import { useForm } from "../../../hooks/use-form";
import { Success } from "./components/success";
import { FieldForm } from "../../../components/field-form";
import { validationSendCodeToEmail } from "./validation";

export const CodeForm = () => {
  const { t } = useTranslation("recovery");

  const [isSentEmail, setIsSentEmail] = useState(false);

  const [sendCode, sendEmailResponse] = useSendCodeToEmailMutation();

  const handleSubmit = useCallback(
    ({ email }) => {
      sendCode({ email });
    },
    [sendCode]
  );

  const { formik, isSubmitDisabled } = useForm({
    validationSchema: validationSendCodeToEmail,
    initialValues: {
      email: "",
    },
    onSubmit: handleSubmit,
  });
  const { values, setFieldError } = formik;

  useMutationHandlers(
    sendEmailResponse,
    () => {
      setIsSentEmail(true);
    },
    (error) => {
      setFieldError("email", "data" in error ? t(`common:errors.${error.data}`) : "");
    }
  );

  const isShowButtonReestablish = !!values.email.length;

  return (
    <FormikProvider value={formik}>
      {isSentEmail ? (
        <Success
          email={values.email as SendCodeToEmailRequest["email"]}
          resend={sendCode}
        />
      ) : (
        <Stack component={Form}>
          <Typography px={7} variant="body2" textAlign={"center"}>
            {t("description")}
          </Typography>
          <Typography variant="body2" textAlign={"center"}>
            {t("signing")}
          </Typography>
          <FieldForm
            sx={{ marginTop: 3 }}
            name="email"
            label={t("E-mail")}
            placeholder={t("Info@pragma.com")}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailIcon />
                </InputAdornment>
              ),
            }}
          />
          {isShowButtonReestablish && (
            <Button
              sx={{ mt: 4, p: 1.5 }}
              variant="contained"
              type="submit"
              disabled={isSubmitDisabled}
            >
              {t("common:buttons.reestablish")}
            </Button>
          )}

          <Button
            component={Link}
            to={"/auth/email"}
            sx={{ mt: 3, p: 1.5 }}
            variant="contained"
          >
            {t("common:buttons.cancel")}
          </Button>
        </Stack>
      )}
    </FormikProvider>
  );
};
