import React, { FC, useCallback, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useAppContext } from "../../../../layouts/app-layout/context";
import { useSelector } from "react-redux";
import { projectSelector } from "../../../../services/slices/project";
import { projectContext } from "../../context";
import { useUpdateProcurementMutation } from "../../../../api/companies";
import { FormikValues } from "formik/dist/types";
import { formatDateToISO } from "../../../../utils/commonUtils";
import { useForm } from "../../../../hooks/use-form";
import {
  StyledColumn,
  StyledColumnTitle,
  StyledForm,
  StyledFormLayout,
  StyledLabel,
  StyledMain,
  StyledRow,
  StyledTitle,
} from "../../project.styles";
import { FormikProvider } from "formik";
import { EnabledSwitch } from "../enabled-switch";
import { Typography } from "@mui/material";
import { DatePicker } from "../../../../components/date-picker";
import dayjs from "dayjs";
import { ReactComponent as ProcurementIcon } from "../../../../components/icons/project/procurement.svg";

export const Procurement: FC = () => {
  const { t } = useTranslation("project");
  const { t: commonT } = useTranslation("common");
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { currentCompanyId, isAdmin } = useAppContext();
  const { info: project } = useSelector(projectSelector);

  const { updateFormState, formsState } = useContext(projectContext);
  const projectID = params.projectID ? Number(params.projectID) : null;

  const [updateProcurement] = useUpdateProcurementMutation();

  const initialValues = {
    enabled: project?.procurement?.enabled || false,
    startDate: project?.procurement?.startDate
      ? new Date(project.procurement.startDate)
      : null,
    endDate: project?.procurement?.endDate
      ? new Date(project.procurement.endDate)
      : null,
  };

  const handleSubmit = useCallback(async (values: FormikValues) => {
    try {
      const startDate = values.startDate ? formatDateToISO(new Date(values.startDate), true) : null;
      const endDate = values.endDate ? formatDateToISO(new Date(values.endDate), true) : null;

      await updateProcurement({
        companyID: currentCompanyId,
        projectID: projectID || 0,
        body: {
          enabled: values.enabled,
          ...(startDate && { startDate }),
          ...(endDate && { endDate }),
        },
      });
    } catch {
      enqueueSnackbar(commonT("errors.error"), {
        variant: "error",
      });
    }
  }, []);

  const { formik, isSubmitDisabled } = useForm({
    enableReinitialize: true,
    initialValues,
    onSubmit: handleSubmit,
  });
  const { values, dirty } = formik;

  useEffect(() => {
    updateFormState({
      ...formsState,
      procurement: {
        isChanged: dirty,
        isSubmitDisabled: projectID
          ? JSON.stringify(values) === JSON.stringify(initialValues) ||
            isSubmitDisabled
          : true,
      },
    });
  }, [values, isSubmitDisabled, dirty]);

  return (
    <StyledMain>
      <FormikProvider value={formik}>
        <StyledForm id="procurement">
          <StyledTitle>
            <ProcurementIcon className="icon" />
            {t("titles.procurement")}{" "}
            <EnabledSwitch name="enabled" disabled={!isAdmin} />
          </StyledTitle>

          <StyledFormLayout>
            <StyledColumn>
              <StyledColumnTitle>
                {t("columnTitles.deadlines")}
              </StyledColumnTitle>

              <StyledRow>
                <StyledLabel className="field-form-label field-form-label_required">
                  <Typography pb={0.5} color="secondary" pl={2}>
                    {t("inputs.startDate.title")}
                  </Typography>
                  <DatePicker
                    name="startDate"
                    placeholder={t("inputs.startDate.placeholder")}
                    maxDate={dayjs(values.endDate)}
                    disabled={!isAdmin}
                  />
                </StyledLabel>

                <StyledLabel className="field-form-label field-form-label_required">
                  <Typography pb={0.5} color="secondary" pl={2}>
                    {t("inputs.endDate.title")}
                  </Typography>
                  <DatePicker
                    name="endDate"
                    placeholder={t("inputs.endDate.placeholder")}
                    minDate={dayjs(values.startDate)}
                    disabled={!isAdmin}
                  />
                </StyledLabel>
              </StyledRow>
            </StyledColumn>

            <StyledColumn />
          </StyledFormLayout>
        </StyledForm>
      </FormikProvider>
    </StyledMain>
  );
};
