import { FC } from "react";
import { Dialog, DialogTitle, Button, Stack, Typography } from "@mui/material";

interface DeleteConfirmDialogProps {
  open: boolean;
  onClose: (isConfirmed?: boolean) => void;
  title: string;
  description: string;
}

export const DeleteConfirmDialog: FC<DeleteConfirmDialogProps> = ({
  title,
  description,
  open,
  onClose,
}) => {
  return (
    <Dialog open={open} onClose={() => onClose()} sx={{ padding: "10px" }}>
      <Stack p={4} maxWidth={400}>
        <DialogTitle
          sx={{
            textAlign: "center",
            fontSize: "20px",
            color: "#0044b4",
            padding: 0,
          }}
        >
          {title}
        </DialogTitle>
        <Typography mt={2} mb={4} fontSize={14} textAlign="center">
          {description}
        </Typography>

        <Stack flexDirection="row" justifyContent="space-between">
          <Button
            variant="contained"
            color="success"
            onClick={() => onClose(true)}
            sx={{ width: "100%", height: "48px", marginRight: "8px" }}
          >
            Да
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => onClose()}
            sx={{ width: "100%", height: "48px" }}
          >
            Нет
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
};
