import styled from "styled-components";
import { IconButton } from "@mui/material";

export const StyledModal = styled.div`
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 24px 48px 0 #10182824, 0 -8px 24px 0 #10182814;
  background: #fff;
  margin: auto;
  max-width: 400px;
  position: relative;
`;

export const StyledButtonClose = styled(IconButton)`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.secondary.text};

  .icon {
    width: 16px;
    fill: ${({ theme }) => theme.secondary.text};
  }
`;

interface TitleProps {
  $titleColor?: string;
}

export const StyledTitle = styled.div<TitleProps>`
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 12px;
  color: ${({ theme, $titleColor }) => $titleColor || theme.primary.errorDark};
`;

export const StyledDescription = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 143%;
  text-align: center;
  color: ${({ theme }) => theme.primary.textDark};
  margin: 0 0 20px;
  white-space: pre-line;
`;

export const StyledButtons = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;
`;
