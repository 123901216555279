import React, { FC, useContext, useMemo } from "react";
import {
  StyledColumn,
  StyledColumnTitle,
  StyledFormLayout,
  StyledTitle,
} from "../../../../project.styles";
import { useTranslation } from "react-i18next";
import { ProjectGeographyProps } from "./project-geography.types";
import { StyledMuiSelect } from "../../../../../../components/styled-mui-select";
import { projectContext } from "../../../../context";
import { FieldForm } from "../../../../../../components/field-form";
import { useField } from "formik";
import { InputAdornment } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { StyledProjectGeography } from "./project-geography.styles";
import { useAppContext } from "../../../../../../layouts/app-layout/context";
import { SearchableSelect } from "../../../../../../components/searchable-select";

export const ProjectGeography: FC<ProjectGeographyProps> = ({ values }) => {
  const { t } = useTranslation("project");
  const { dictionaries } = useContext(projectContext);
  const { isAdmin } = useAppContext();
  const [, , { setValue: setTimezoneValue }] = useField("timezone");

  const regionsOptions = useMemo(
    () =>
      dictionaries.regions.map((region) => ({
        label: region.name,
        value: region.timezones,
      })),
    [dictionaries.regions]
  );

  const timezonesOffsets = useMemo(() => {
    return dictionaries.timezones.reduce(
      (accumulator, current) => ({
        ...accumulator,
        [current.name]: current.mskDelta,
      }),
      {} as { [key: string]: number }
    );
  }, [dictionaries.timezones]);

  const timeZoneByRegion = useMemo(() => {
    if (!values.region) {
      setTimezoneValue("Москва (МСК +0)");
      return;
    }

    const currentRegion = dictionaries.regions.find(
      (region) => region.name === values.region
    );

    const currentTimezone = currentRegion?.timezones[0];
    currentTimezone &&
      setTimezoneValue(`Москва (МСК +${timezonesOffsets[currentTimezone]})`);

    return currentRegion;
  }, [values.region, timezonesOffsets]);

  const timezoneOptions = useMemo(
    () =>
      timeZoneByRegion?.timezones.map((timezone) => ({
        label: `Москва (МСК +${timezonesOffsets[timezone]})`,
        value: timezone,
      })) || [],
    [timeZoneByRegion?.timezones, timezonesOffsets]
  );

  const selectedTimeZonesCount = timeZoneByRegion?.timezones?.length || 0;

  return (
    <StyledProjectGeography>
      <StyledTitle>{t("titles.projectGeography")}</StyledTitle>
      <StyledFormLayout>
        <StyledColumn>
          <StyledColumnTitle>{t("columnTitles.location")}</StyledColumnTitle>

          <SearchableSelect
            labelText={t("inputs.region.title")}
            labelClassName="field-form-label_required"
            options={regionsOptions}
            name="region"
            placeholder={t("inputs.region.placeholder")}
          />

          {selectedTimeZonesCount > 1 ? (
            <StyledMuiSelect
              props={{
                name: "timezone",
                variant: "filled",
                value: values.timezone,
                disabled: !isAdmin,
              }}
              labelText={t("inputs.timezone.title")}
              options={timezoneOptions}
            />
          ) : (
            <FieldForm
              version="profile"
              name="timezone"
              title={t("inputs.timezone.title")}
              disabled
              InputProps={{
                disableUnderline: true,
              }}
            />
          )}
        </StyledColumn>

        <StyledColumn $hasPaddingTop>
          <FieldForm
            version="profile"
            name="address"
            className="field-address"
            title={t("inputs.address.title")}
            placeholder={t("inputs.address.placeholder")}
            labelClassName="field-form-label_required"
            InputProps={{
              disableUnderline: true,
              endAdornment: (
                <InputAdornment position="end">
                  <LocationOnIcon className="icon-map" />
                </InputAdornment>
              ),
            }}
            disabled={!isAdmin}
            rows={4}
            multiline
          />
        </StyledColumn>
      </StyledFormLayout>
    </StyledProjectGeography>
  );
};
