import { FC, ReactNode } from "react";
import { Navigate, useSearchParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { Outlet } from "react-router-dom";
import { Header } from "../../components/header";
import { AppLayout } from "../app-layout/app-layout";
import { isAuthSelector, setSourceCompany, setSourceModule } from '../../services/slices/auth/auth-slice'

type LayoutProps = {
  title?: ReactNode;
};

export const Layout: FC<LayoutProps> = ({ title }) => {
  const dispatch = useDispatch()
  const isAuth = useSelector(isAuthSelector);
  const [searchParams] = useSearchParams()
  const sourceModule: string | null = searchParams.get("source")
  const sourceCompany: string | null = searchParams.get("company")

  if (sourceModule) {
    dispatch(setSourceModule(sourceModule))
  }

  if (sourceCompany) {
    dispatch(setSourceCompany(sourceCompany))
  }

  if (!isAuth) {
    return <Navigate to="/auth" />;
  }

  return (
    <AppLayout>
      <Header title={title} />
      <Outlet />
    </AppLayout>
  );
};
