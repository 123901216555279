import { ChangeEvent, FC, useCallback } from "react";
import { StyledFormControlLabel } from "./enabled-switch.styles";
import { Switch } from "@mui/material";
import { EnabledSwitchProps } from "./enabled-switch.types";
import { useTranslation } from "react-i18next";
import { useField } from "formik";

export const EnabledSwitch: FC<EnabledSwitchProps> = ({ name, ...props }) => {
  const { t } = useTranslation("project");
  const [{ value }, , { setValue }] = useField({
    name,
  });

  const handleChange = useCallback((event: ChangeEvent) => {
    const target = event.target as HTMLInputElement;

    setValue(target.checked);
  }, []);

  return (
    <StyledFormControlLabel
      label={t("inputs.enabled.title")}
      control={<Switch checked={value} onChange={handleChange} />}
      labelPlacement="start"
      {...props}
    />
  );
};
