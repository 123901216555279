import { FC, useCallback, useState } from "react";
import {
  StyledPage,
  StyledHeader,
  StyledButtons,
  StyledButtonSave,
  StyledBody,
  StyledButtonDelete,
} from "./edit-assignments.styles";
import { useTranslation } from "react-i18next";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Button } from "@mui/material";
import { AssignmentForm } from "./components/assignment-form";
import { editAssignmentsContext } from "./context";
import { DeleteModal } from "../../components/delete-modal";
import {
  useCreateAssignmentMutation,
  useGetAssignmentsCreationDataQuery,
  useGetAssignmentQuery,
  useUpdateAssignmentMutation,
  useDeleteAssignmentMutation,
} from "../../api/assignments";
import { Values } from "./components/assignment-form/assignment-form.types";
import { getErrorMessage, prepareBody } from "./edit-assignments.service";
import { useAppContext } from "../../layouts/app-layout/context";
import { useSnackbar } from "notistack";
import DeleteIcon from "@mui/icons-material/Delete";
import { EditAssignmentsProps } from "./edit-assignments.types";

export const EditAssignments: FC<EditAssignmentsProps> = ({ isEdit }) => {
  const { t: tCommon } = useTranslation("common");
  const { t } = useTranslation("assignments");
  const location = useLocation();
  const navigate = useNavigate();
  const { currentCompanyId } = useAppContext();
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams();

  const [isFormReady, setIsFormReady] = useState(false);
  const [isAnyFieldChanged, setIsAnyFieldChanged] = useState(false);
  const [isConfirmModalBackOpen, setIsConfirmModalBackOpen] = useState(false);
  const [isConfirmModalDeleteOpen, setIsConfirmModalDeleteOpen] =
    useState(false);
  const [values, setValues] = useState<Values>();
  const [isSaving, setIsSaving] = useState(false);

  const assignmentID = params.id && isEdit ? Number(params.id) : null;

  const { data: currentAssignment } = useGetAssignmentQuery(
    {
      companyID: currentCompanyId,
      assignmentID: assignmentID || 0,
    },
    { skip: typeof assignmentID !== "number" }
  );
  const [createAssignment] = useCreateAssignmentMutation();
  const [updateAssignment] = useUpdateAssignmentMutation();
  const [deleteAssignment] = useDeleteAssignmentMutation();

  const creationData = useGetAssignmentsCreationDataQuery(
    { companyID: currentCompanyId },
    { skip: !currentCompanyId }
  );

  const handleButtonBackClick = useCallback(() => {
    navigate(-1);
  }, [location]);

  const handleSave = useCallback(async () => {
    if (!values) return;

    try {
      setIsSaving(true);
      const commonParams = {
        companyID: currentCompanyId,
        body: prepareBody(values, isEdit, creationData.data),
      };
      const response = isEdit
        ? await updateAssignment({
            assignmentID: assignmentID || 0,
            ...commonParams,
          })
        : await createAssignment({
            ...commonParams,
          });
      if ("error" in response && "data" in response.error) {
        enqueueSnackbar(
          getErrorMessage(response.error.data as string, t, tCommon),
          { variant: "error" }
        );
        setIsSaving(false);
        return;
      }

      const paths = location.pathname.split("/");
      paths.pop();
      navigate(paths.join("/"));
      enqueueSnackbar(t(isEdit ? "assignmentUpdated" : "assignmentAdded"), {
        variant: "success",
      });
      setIsSaving(false);
    } catch {
      enqueueSnackbar(tCommon("errors.error"), { variant: "error" });
      setIsSaving(true);
    }
  }, [
    values,
    currentCompanyId,
    creationData,
    assignmentID,
    isEdit,
    navigate,
    enqueueSnackbar,
  ]);

  const handleDeleteAssignment = useCallback(async () => {
    if (!assignmentID) return;

    try {
      await deleteAssignment({ companyID: currentCompanyId, assignmentID });
      navigate(-1);
      enqueueSnackbar(t("assignmentDeleted"), {
        variant: "success",
      });
    } catch {
      enqueueSnackbar(tCommon("errors.error"), { variant: "error" });
    }
  }, [assignmentID]);

  return (
    <StyledPage>
      <editAssignmentsContext.Provider
        value={{
          isFormReady,
          isAnyFieldChanged,
          setIsFormReady,
          setIsAnyFieldChanged,
          values,
          setValues,
          currentAssignment,
        }}
      >
        <StyledHeader>
          <Button
            startIcon={<ArrowBackIcon />}
            onClick={
              isAnyFieldChanged
                ? () => setIsConfirmModalBackOpen(true)
                : handleButtonBackClick
            }
          >
            {tCommon("buttons.back")}
          </Button>

          <StyledButtons>
            <StyledButtonSave
              variant="contained"
              disabled={!isFormReady || !isAnyFieldChanged || isSaving}
              onClick={handleSave}
            >
              {tCommon(isEdit ? "buttons.save" : "buttons.create")}
            </StyledButtonSave>

            {isEdit && (
              <StyledButtonDelete
                onClick={() => setIsConfirmModalDeleteOpen(true)}
              >
                <DeleteIcon className="icon" />
              </StyledButtonDelete>
            )}
          </StyledButtons>
        </StyledHeader>

        <StyledBody>
          <AssignmentForm
            creationData={creationData.data}
            isEdit={typeof assignmentID === "number"}
          />
        </StyledBody>
      </editAssignmentsContext.Provider>

      <DeleteModal
        open={isConfirmModalBackOpen}
        title={t("backModal.title")}
        description={t("backModal.description")}
        onClose={() => setIsConfirmModalBackOpen(false)}
        onConfirm={handleButtonBackClick}
      />

      <DeleteModal
        open={isConfirmModalDeleteOpen}
        title={t("deleteModal.title")}
        description={t("deleteModal.description")}
        onClose={() => setIsConfirmModalDeleteOpen(false)}
        onConfirm={handleDeleteAssignment}
        rightButtonColor="primary"
      />
    </StyledPage>
  );
};
