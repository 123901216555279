import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Stack, Typography } from "@mui/material";

export const Success = () => {
  const { t } = useTranslation("recovery");

  return (
    <Stack>
      <Typography px={7} variant="body2" textAlign={"center"}>
        {t("confirm.description")}
      </Typography>

      <Button
        component={Link}
        to={"/"}
        sx={{ mt: 4, p: 1.5 }}
        variant="contained"
      >
        {t("common:buttons.login")}
      </Button>
    </Stack>
  );
};
