import React, { FC, useEffect, useState } from "react";
import { Typography } from "@mui/material";
export type Timer = {
  start: number;
  end?: number;
};
type ShowByTimerProps = {
  timer?: Timer;
  waitingText?: string;
  duration?: number;
};

const defaultTimerValue = 0;

export const ShowByTimer: FC<ShowByTimerProps> = ({
  children,
  waitingText,
  timer,
  duration = 1000,
}) => {
  const [value, setValue] = useState(timer?.start || defaultTimerValue);

  useEffect(() => {
    if (timer) {
      const { start = defaultTimerValue, end = defaultTimerValue } = timer;
      setValue(start);

      const intervalId = setInterval(() => {
        setValue((state) => {
          const newValue = state - 1;
          if (newValue <= end) {
            clearInterval(intervalId);
          }
          return newValue;
        });
      }, duration);
      return () => clearInterval(intervalId);
    }
  }, [timer, duration]);

  const isTimerOn = timer && value > (timer.end || defaultTimerValue);

  if (isTimerOn) {
    return (
      <Typography mt={2} variant="body2" textAlign="center" color="secondary">
        {waitingText}
        {value}
      </Typography>
    );
  }

  return <>{children}</>;
};
