import { useAppContext } from "../../layouts/app-layout/context";
import { useParams } from "react-router-dom";
import {
  useGetAssignmentBranchByDivisionQuery,
  useSetExecutorsMutation,
  useUpdateBranchFilesMutation,
  useChangeBranchStatusMutation, useDeleteAssignmentMutation,
} from "../../api/assignments";
import {
  StyledPage,
  StyledBody,
  StyledColumn,
} from "./assignment-by-division.styles";
import { Header } from "./components/header";
import { Progress } from "../../components/progress";
import { CommonInfo } from "./components/common-info";
import { useForm } from "../../hooks/use-form";
import { State } from "./assignment-by-division.types";
import { useCallback, useEffect, useMemo, useState } from "react";
import { StatusBlock } from "./components/status-block";
import { FilesBlock } from "./components/files-block";
import { FormikProvider } from "formik";
import { ExecutorsBlock } from "./components/executors-block";
import {
  checkStateIsReady,
  prepareExecutorsBody,
  prepareFilesBody,
} from "./assignment-by-division.service";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useNavigate, useLocation } from "react-router-dom";
import { DeleteModal } from "../../components/delete-modal";

export const AssignmentByDivision = () => {
  const { currentCompanyId, profileByCompany, isAdmin } = useAppContext();
  const params = useParams<{ id: string }>();
  const assignmentID = Number(params.id);
  const { t: tCommon } = useTranslation("common");
  const { t } = useTranslation("assignments");
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const location = useLocation();

  const [state, setState] = useState(new State());
  const [isSaving, setIsSaving] = useState(false);
  const [isConfirmModalDeleteOpen, setIsConfirmModalDeleteOpen] =
    useState(false);

  const [updateBranchFiles] = useUpdateBranchFilesMutation();
  const [setExecutors] = useSetExecutorsMutation();
  const [changeBranchStatus] = useChangeBranchStatusMutation();
  const [deleteAssignment] = useDeleteAssignmentMutation();

  const { data: assignmentData, isLoading } =
    useGetAssignmentBranchByDivisionQuery(
      {
        companyID: currentCompanyId,
        assignmentID,
      },
      { skip: !currentCompanyId || !assignmentID }
    );

  const isAuthor = profileByCompany?.coreID === assignmentData?.author?.coreID;
  const isSupervisor = profileByCompany?.coreID === assignmentData?.supervisor?.coreID;
  const isResponsible = profileByCompany?.coreID === assignmentData?.division?.responsible?.coreID;
  const canDelete = isAdmin || isAuthor || isSupervisor;
  const canEditExecutors = (isAdmin || isResponsible) && !!assignmentData?.canEdit;

  const initialValues = {
    upToDate: assignmentData?.division.upToDate,
  };

  const { formik } = useForm({
    enableReinitialize: true,
    initialValues,
    onSubmit: () => {},
  });
  const { values } = formik;

  useEffect(() => {
    if (assignmentData && state.isEmpty) {
      setState(new State(assignmentData));
    }
  }, [assignmentData]);

  const { isReady, isFilesChanged, isExecutorsChanged, isStatusChanged } =
    useMemo(() => {
      return checkStateIsReady(state, assignmentData);
    }, [state, assignmentData]);

  const handleSave = useCallback(async () => {
    setIsSaving(true);
    const commonParams = { companyID: currentCompanyId, assignmentID };
    const statusBody = { status: state.status };
    const executorsBody = {
      executorID: prepareExecutorsBody(state.executors),
    };
    const filesBody = prepareFilesBody(state.files);

    try {
      await Promise.all([
        ...(isStatusChanged
          ? [changeBranchStatus({ ...commonParams, body: statusBody })]
          : []),
        ...(isExecutorsChanged
          ? [setExecutors({ ...commonParams, body: executorsBody })]
          : []),
        ...(isFilesChanged
          ? [updateBranchFiles({ ...commonParams, body: filesBody })]
          : []),
      ]);
      setIsSaving(false);
      navigate(`/companies/${currentCompanyId}/assignments`);
    } catch {
      enqueueSnackbar(tCommon("errors.error"), { variant: "error" });
      setIsSaving(false);
    }
  }, [
    state,
    currentCompanyId,
    assignmentID,
    assignmentData?.division.potentialExecutors,
    isStatusChanged,
    isExecutorsChanged,
    isFilesChanged,
    location,
    navigate,
  ]);

  const handleDeleteAssignment = useCallback(async () => {
    if (!assignmentID) return;

    try {
      await deleteAssignment({ companyID: currentCompanyId, assignmentID });
      navigate(`/companies/${currentCompanyId}/assignments`);
      enqueueSnackbar(t("assignmentDeleted"), {
        variant: "success",
      });
    } catch {
      enqueueSnackbar(tCommon("errors.error"), { variant: "error" });
    }
  }, [assignmentID]);

  return (
    <StyledPage>
      <Header
        isReady={isReady && !isSaving}
        canDelete={canDelete}
        onDeleteClick={() => setIsConfirmModalDeleteOpen(true)}
        onSave={handleSave}
        isUserAllowEdit={assignmentData?.canEdit}
      />

      {isLoading ? (
        <Progress />
      ) : (
        <FormikProvider value={formik}>
          <StyledBody>
            <StyledColumn>
              {assignmentData && (
                <CommonInfo
                  text={assignmentData?.text}
                  group={assignmentData?.group}
                  createdAt={assignmentData?.createdAt}
                  author={assignmentData?.author}
                  supervisor={assignmentData?.supervisor}
                  highPriority={assignmentData?.highPriority}
                />
              )}

              <StatusBlock
                status={state.status}
                statuses={assignmentData?.canChangeToStatus || []}
                isDateChanged={initialValues.upToDate !== values.upToDate}
                assignmentID={assignmentID}
                isUserAllowEdit={!!assignmentData?.canEdit}
                pendingDateStatus={assignmentData?.division.pendingDateStatus}
                pendingDate={assignmentData?.division.upToDatePending}
                initialUpToDate={assignmentData?.division.upToDate}
                initialStatus={assignmentData?.division.status}
                comment={assignmentData?.division.comment}
                commentDate={assignmentData?.division.commentDate}
                commentAuthor={assignmentData?.division.commentAuthor}
                onChangeStatus={(status) =>
                  setState((prev) => ({ ...prev, status }))
                }
                onChangeUpToDate={(upToDate) =>
                  setState((prev) => ({ ...prev, upToDate }))
                }
              />

              <FilesBlock
                commonFiles={assignmentData?.files || []}
                divisionFiles={state.files}
                serverDivisionFiles={assignmentData?.division.files || []}
                isUserAllowEdit={!!assignmentData?.canEdit && assignmentData?.division.status !== 'Приёмка'}
                onDivisionFilesChange={(files) =>
                  setState((prev) => ({ ...prev, files }))
                }
              />
            </StyledColumn>

            <StyledColumn>
              <ExecutorsBlock
                isUserAllowEdit={canEditExecutors}
                executors={state.executors}
                onExecutorsChange={(executors) =>
                  setState((prev) => ({ ...prev, executors }))
                }
                potentialExecutors={assignmentData?.division.potentialExecutors}
              />
            </StyledColumn>
          </StyledBody>
        </FormikProvider>
      )}

      <DeleteModal
        open={isConfirmModalDeleteOpen}
        title={t("deleteModal.title")}
        description={t("deleteModal.description")}
        onClose={() => setIsConfirmModalDeleteOpen(false)}
        onConfirm={handleDeleteAssignment}
        rightButtonColor="primary"
      />
    </StyledPage>
  );
};
