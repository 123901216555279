import { useCallback, useEffect, useRef } from "react";

export const useHorizontalScroll = () => {
  const ref = useRef<HTMLDivElement>(null);

  const scrollHorizontal = useCallback((step: number) => {
    if (ref.current) {
      ref.current.scrollLeft += step;
    }
  }, []);

  useEffect(() => {
    const element = ref?.current;

    if (element) {
      const onWheel = (e: any) => {
        if (e.deltaY === 0) return;
        if (
          !(element?.scrollLeft === 0 && e.deltaY < 0) &&
          !(
            element?.scrollWidth -
              element?.clientWidth -
              Math.round(element.scrollLeft) ===
              0 && e.deltaY > 0
          )
        ) {
          e.preventDefault();
        }
        element.scrollTo({
          left: element.scrollLeft + e.deltaY * 2.4,
          behavior: "smooth",
        });
      };
      element.addEventListener("wheel", onWheel);
      return () => element.removeEventListener("wheel", onWheel);
    }
  }, []);
  return {
    ref,
    scrollHorizontal,
  };
};
