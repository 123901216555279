import { FC, useCallback, useEffect, useState } from "react";
import { StyledPage } from "./project.styles";
import { ProjectHeader } from "./components/project-header";
import { FormsState, Tab } from "./project.types";
import {
  useGetProjectDictionaryQuery,
  useGetProjectFiltersQuery,
  useGetProjectQuery,
  useDeleteProjectMutation,
} from "../../api/companies";
import { ProjectInfo } from "./components/project-info";
import { useAppContext } from "../../layouts/app-layout/context";
import { initialContext, projectContext } from "./context";
import { Dashboard } from "./components/dashboard";
import { useNavigate, useParams } from "react-router-dom";
import { Design } from "./components/design";
import { useAppDispatch } from "../../store";
import { setProject } from "../../services/slices/project";
import { Rm } from "./components/rm";
import { Qc } from "./components/qc";
import { Estimation } from "./components/estimation";
import { Finance } from "./components/finance";
import { Procurement } from "./components/procurement";
import { DeleteModal } from "../../components/delete-modal";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { Progress } from "../../components/progress";
import { AsbuiltDoc } from "./components/asbuiltDoc";

export const Project: FC = () => {
  const { currentCompanyId } = useAppContext();
  const dispatch = useAppDispatch();
  const { t } = useTranslation("project");
  const { t: commonT } = useTranslation("common");
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [tab, setTab] = useState<Tab>("projectInfo");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [nextTab, setNextTab] = useState<Tab | null>(null);
  const [formsState, setFormsState] = useState<FormsState>(new FormsState());
  const params = useParams();

  const projectID = params.projectID ? Number(params.projectID) : null;

  const { data: dictionaries = initialContext.dictionaries } =
    useGetProjectDictionaryQuery(
      { companyID: currentCompanyId },
      { skip: !currentCompanyId }
    );

  const {
    data: project,
    isLoading,
    isFetching,
  } = useGetProjectQuery(
    { companyID: currentCompanyId, projectID: projectID || 0 },
    { skip: !projectID }
  );

  const { data: filters = initialContext.filters } = useGetProjectFiltersQuery(
    { companyID: currentCompanyId, body: {} },
    { skip: !currentCompanyId }
  );

  const [deleteProject] = useDeleteProjectMutation();

  const contentByTab = {
    projectInfo: <ProjectInfo />,
    personsAndCompanies: <></>,
    dashboard: <Dashboard />,
    design: <Design />,
    procurement: <Procurement />,
    rm: <Rm />,
    qc: <Qc />,
    estimation: <Estimation />,
    finance: <Finance />,
    asbuilt_doc: <AsbuiltDoc />,
  };

  const handleDeleteProject = useCallback(async () => {
    try {
      if (!projectID) return;

      await deleteProject({ companyID: currentCompanyId, projectID });
      navigate(`/companies/${currentCompanyId}/projects`);
    } catch {
      enqueueSnackbar(commonT("errors.error"), {
        variant: "error",
      });
    }
  }, [projectID, currentCompanyId]);

  const handleTabChange = useCallback(
    (value: Tab) => {
      const isChanged = formsState[tab].isChanged;
      if (isChanged) {
        setNextTab(value);
        return;
      }

      setTab(value);
    },
    [formsState, tab]
  );

  const handleTabChanged = useCallback(() => {
    if (!nextTab) return;

    setTab(nextTab);
    setNextTab(null);
  }, [nextTab]);

  useEffect(() => {
    if (!projectID) dispatch(setProject(null));
    else dispatch(setProject(project));
  }, [projectID, project]);

  return (
    <projectContext.Provider
      value={{
        dictionaries,
        formsState,
        filters,
        updateFormState: setFormsState,
      }}
    >
      <StyledPage>
        <ProjectHeader
          tab={tab}
          isEdit={!!projectID}
          onTabChange={handleTabChange}
          onButtonDeleteClick={() => setIsDeleteModalOpen(true)}
        />

        {isLoading || isFetching ? <Progress /> : contentByTab[tab]}
      </StyledPage>

      <DeleteModal
        open={isDeleteModalOpen}
        title={t("deleteModal.title")}
        description={t("deleteModal.description")}
        onClose={() => setIsDeleteModalOpen(false)}
        onConfirm={handleDeleteProject}
      />

      <DeleteModal
        open={!!nextTab}
        title={t("changeTabModal.title")}
        description={t("changeTabModal.description")}
        onClose={handleTabChanged}
        onConfirm={() => setNextTab(null)}
        onButtonCloseClick={() => setNextTab(null)}
        leftButtonColor="success"
        rightButtonColor="error"
        rightButtonText={commonT("buttons.no")}
      />
    </projectContext.Provider>
  );
};
