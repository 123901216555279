import React, { useEffect } from "react";
import { Navigate, useSearchParams } from "react-router-dom";
import { useLoginByEmailCodeMutation } from "../../../api/auth";
import { Stack } from "@mui/material";
import { useTranslation } from "react-i18next";

export const Confirm = () => {
  const { t } = useTranslation("common");
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email") || "";
  const code = searchParams.get("code") || "";

  const [sendCodeId, { isSuccess, isError }] = useLoginByEmailCodeMutation();

  useEffect(() => {
    sendCodeId({ email, code });
  }, [sendCodeId, email, code]);

  if (isSuccess) {
    return <Navigate to="/recovery/password" />;
  }
  if (isError) {
    return <Navigate to="/recovery/code" />;
  }

  return <Stack alignItems="center">{t("loading")}</Stack>;
};
