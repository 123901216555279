import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { IconButton, InputAdornment, Stack, Typography } from "@mui/material";
import AutoFixHighSharpIcon from "@mui/icons-material/AutoFixHighSharp";
import CheckIcon from "@mui/icons-material/Check";
import { PhoneFieldForm } from "../../../../components/phone-field-form";
import { FieldForm } from "../../../../components/field-form";
import { MAX_PASSWORD_LENGTH_VALIDATION } from "../../../../utils/constants";

type ContactFormProps = {
  isShowConfirmPhone?: boolean;
  isShowConfirmEmail?: boolean;
  isPhoneConfirmed?: boolean;
  isEmailConfirmed?: boolean;
  handleLoginGeneration: () => void;
  handlePasswordGeneration: () => void;
  isShowPassword?: boolean;
  isDisabled?: boolean;
};

export const ContactsForm: FC<ContactFormProps> = ({
  isPhoneConfirmed,
  isEmailConfirmed,
  isShowConfirmPhone,
  isShowConfirmEmail,
  handleLoginGeneration,
  handlePasswordGeneration,
  isShowPassword,
  isDisabled,
}) => {
  const { t } = useTranslation("user");

  return (
    <Stack spacing={1.5}>
      <label>
        <Typography color="secondary" pl={2} mt={1.5}>
          {t("phone")}
        </Typography>
        <PhoneFieldForm
          sx={{
            "& div": {
              borderRadius: 2,
              "& .MuiFilledInput-input": {
                padding: "10px 16px",
              },
            },
          }}
          fullWidth
          disabled={isDisabled}
          name="phone"
          variant="filled"
          size="small"
          placeholder={t("placeholders.phone")}
          InputProps={{
            disableUnderline: true,
            endAdornment: (
              <InputAdornment position="end">
                {isShowConfirmPhone ? (
                  isPhoneConfirmed ? (
                    <CheckIcon color="primary" />
                  ) : (
                    <Typography variant="caption" color="error">
                      {t("notConfirmed")}
                    </Typography>
                  )
                ) : undefined}
              </InputAdornment>
            ),
          }}
        />
      </label>
      <FieldForm
        disabled={isDisabled}
        version="profile"
        type="email"
        name="email"
        title={t("email")}
        placeholder={t("placeholders.email")}
        InputProps={{
          disableUnderline: true,
          endAdornment: (
            <InputAdornment position="end">
              {isShowConfirmEmail ? (
                isEmailConfirmed ? (
                  <CheckIcon color="primary" />
                ) : (
                  <Typography variant="caption" color="error">
                    {t("notConfirmed")}
                  </Typography>
                )
              ) : undefined}
            </InputAdornment>
          ),
        }}
      />

      <FieldForm
        disabled={isDisabled}
        version="profile"
        name="login"
        title={t("login")}
        placeholder={t("placeholders.login")}
        InputProps={{
          disableUnderline: true,
          endAdornment: (
            <InputAdornment position="end">
              <IconButton disabled={isDisabled} onClick={handleLoginGeneration}>
                <AutoFixHighSharpIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      {isShowPassword && (
        <FieldForm
          disabled={isDisabled}
          maxLength={MAX_PASSWORD_LENGTH_VALIDATION}
          version="profile"
          name="password"
          title={t("password")}
          placeholder={t("placeholders.password")}
          InputProps={{
            disableUnderline: true,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  disabled={isDisabled}
                  onClick={handlePasswordGeneration}
                >
                  <AutoFixHighSharpIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      )}
    </Stack>
  );
};
