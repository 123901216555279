import { styled } from "styled-components";

export const StyledBody = styled.div`
  padding: 10px;
  display: grid;
  grid-row-gap: 8px;
`;

export const StyledHeader = styled.div`
  height: 24px;
  display: flex;
  align-items: center;
  column-gap: 4px;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: ${({ theme }) => theme.primary.mainBlue};

  .icon {
    width: 16px;
    height: 16px;
    fill: ${({ theme }) => theme.primary.mainBlue};
  }
`;

export const StyledMessage = styled.p`
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  margin: 0;
  color: ${({ theme }) => theme.primary.textDark};
  max-height: 200px;
  overflow-y: auto;
  max-width: 264px;
  word-wrap: break-word;
`;

export const StyledFooter = styled.div`
  padding-top: 8px;
  border-top: 1px solid #0000001a;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${({ theme }) => theme.secondary.textAdditional};
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
`;
