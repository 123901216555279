import { FC } from "react";
import { AssignmentsBranchResponse } from "../../../../api/assignments/types";
import { StyledCommonInfo, StyledHeader } from "./common-info.styles";
import { StyledDivider } from "../../assignment-by-division.styles";
import {
  StyledColumn,
  StyledLabel,
  StyledRow,
  StyledText,
} from "../../../../common/assignmentsCommonStyles";
import { useTranslation } from "react-i18next";
import DateRangeIcon from "@mui/icons-material/DateRange";
import { formatDate, getPersonInitials } from "../../../../utils/commonUtils";
import { ReactComponent as AuthorIcon } from "../../../../components/icons/imported-icons/author.svg";
import { ReactComponent as DirectorIcon } from "../../../../components/icons/imported-icons/director.svg";
//import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { ReactComponent as HighPriorityIcon } from "../../../../components/icons/imported-icons/high-priority.svg";

export const CommonInfo: FC<
  Pick<
    AssignmentsBranchResponse,
    "text" | "group" | "createdAt" | "author" | "supervisor" | "highPriority"
  >
> = ({ text, group, createdAt, author, supervisor, highPriority }) => {
  const { t } = useTranslation("assignments");

  const authorName = author
    ? `${author.lastName} ${getPersonInitials({
        firstName: author.firstName,
        middleName: author.middleName,
        divider: ".",
      })}`
    : "–";

  const supervisorName = supervisor
    ? `${supervisor.lastName} ${getPersonInitials({
        firstName: supervisor.firstName,
        middleName: supervisor.middleName,
        divider: ".",
      })}`
    : "–";

  return (
    <StyledCommonInfo>
      <StyledHeader>
        <StyledText $maxRows={2} $maxWidth={400} $isBold>
          {text}
        </StyledText>
      </StyledHeader>

      <StyledDivider />

      <StyledRow>
        <StyledText $colorTheme="secondary">{`${t(
          "assignmentGroup"
        )}:`}</StyledText>
        <StyledText>{group ?? "–"}</StyledText>
      </StyledRow>

      <StyledDivider />

      {highPriority && (
        <StyledColumn>
          <StyledLabel>
            <HighPriorityIcon className="icon" />
            <StyledText $colorTheme="secondary">{`${t(
              "priority"
            )}:`}</StyledText>
          </StyledLabel>
          <StyledText $isBold $colorTheme="orange">
            {t("high")}
          </StyledText>
        </StyledColumn>
      )}

      <StyledColumn>
        <StyledLabel>
          <DateRangeIcon className="icon" />
          <StyledText $colorTheme="secondary">{`${t(
            "departureDate"
          )}:`}</StyledText>
        </StyledLabel>
        <StyledText $isBold>
          {createdAt ? formatDate(createdAt) : "–"}
        </StyledText>
      </StyledColumn>

      <StyledColumn>
        <StyledLabel>
          <AuthorIcon className="icon" />
          <StyledText $colorTheme="secondary">{`${t("author")}:`}</StyledText>
        </StyledLabel>
        <StyledText $isBold>{authorName}</StyledText>
      </StyledColumn>

      <StyledColumn>
        <StyledLabel>
          <DirectorIcon className="icon" />
          <StyledText $colorTheme="secondary">{`${t(
            "supervisor.label"
          )}:`}</StyledText>
        </StyledLabel>
        <StyledText $isBold>{supervisorName}</StyledText>
      </StyledColumn>
    </StyledCommonInfo>
  );
};
