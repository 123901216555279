import React, { useCallback, useMemo } from "react";
import { Form, FormikProvider } from "formik";
import { Stack } from "@mui/material";
import { useAppContext } from "../../layouts/app-layout/context";
import { useForm } from "../../hooks/use-form";
import { DEFAULT_DISPLAY_PASSWORD_VALUE } from "../../utils/constants";
import { generateRandomString } from "../../utils/generate-random-string";
import {
  useUpdateProfileAvatarMutation,
  useUpdateProfileByCompanyMutation,
} from "../../api/companies";
import { useUpdateProfileMutation } from "../../api/profile";
import { validationProfile } from "./validation";
import { ProfileButtons } from "./components/profile-buttons";
import { AccountForm } from "./components/account-form";
import { ContactForm } from "./components/contact-form";
import { useMutationHandlers } from "../../hooks/use-mutation-handlers";
import { useTranslation } from "react-i18next";
import { mapFieldErrorByError } from "../../utils/mapFieldErrorByError";
import { useSnackbar } from "notistack";
import { useGetCompaniesAccessQuery } from "../../api/counterparty";
import { useGetUserFiltersQuery } from "../../api/company-admin";
import { CounterpartyProfileRes } from "../../api/counterparty/types";

export const Profile = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const {
    profile,
    currentCompanyId,
    profileByCompany,
    mainCompany: appMainCompany,
  } = useAppContext();

  const {
    login,
    email,
    phoneConfirmed,
    emailConfirmed,
    phone,
    password,
    mainCompany,
  } = profile;

  const {
    companyName,
    position,
    firstName,
    lastName,
    middleName,
    avatar,
    division,
  } = profileByCompany;

  const counterparties = useGetCompaniesAccessQuery(
    {
      companyID: currentCompanyId,
    },
    { skip: !currentCompanyId }
  );
  const counterpartiesList = counterparties.data?.data || [];

  const [updateProfile, updateProfileResponse] = useUpdateProfileMutation();
  const [updateProfileByCompany] = useUpdateProfileByCompanyMutation();
  const [updateProfileAvatar] = useUpdateProfileAvatarMutation();

  const handleSubmit = useCallback(
    ({
      login,
      email,
      phone,
      password,
      avatar,
      companyName,
      divisionName,
      position,
      firstName,
      lastName,
      middleName,
      all,
    }) => {
      if (avatar instanceof Blob) {
        updateProfileAvatar({
          companyId: currentCompanyId,
          file: avatar,
          all,
        });
      }

      updateProfile({
        login,
        email,
        phone,
        password,
      });

      const counterparty = (counterparties?.data
        ? [counterparties.data.mainCompany, ...counterparties.data.data]
        : []).find(
        (counterparty) => counterparty.name === companyName
      );
      updateProfileByCompany({
        companyId: currentCompanyId,
        division: divisionName,
        position,
        firstName,
        lastName,
        middleName,
        ...(counterparty?.id && {
          company: { counterpartyID: counterparty.id },
        }),
        ...(companyName === appMainCompany?.name && {
          company: { counterpartyID: null },
        }),
        all,
      });
    },
    [
      updateProfile,
      updateProfileByCompany,
      currentCompanyId,
      updateProfileAvatar,
      counterpartiesList,
      mainCompany,
      appMainCompany,
    ]
  );

  const initialValues = useMemo(() => {
    return {
      lastName: lastName || "",
      firstName: firstName || "",
      middleName: middleName || "",
      companyName: companyName || "",
      divisionName: division || "",
      position: position || "",
      phone: phone || "",
      email: email || "",
      login: login || "",
      password: (password || DEFAULT_DISPLAY_PASSWORD_VALUE) as
        | string
        | undefined,
      all: false,
      avatar: avatar || "",
    };
  }, [
    lastName,
    firstName,
    middleName,
    companyName,
    position,
    phone,
    email,
    login,
    password,
    avatar,
  ]);

  const { formik, isSubmitDisabled } = useForm({
    validationSchema: validationProfile,
    enableReinitialize: true,
    initialValues,
    onSubmit: (values) => {
      const data = { ...values };
      if (values?.password === DEFAULT_DISPLAY_PASSWORD_VALUE) {
        delete data?.password;
      }
      handleSubmit(data);
    },
  });

  const { values, handleReset, setFieldValue, setFieldError } = formik;

  const currentCounterparty = useMemo(
    () =>
      counterpartiesList.find(
        (counterparty) => counterparty.name === values.companyName
      ),
    [counterpartiesList, values.companyName]
  );

  const { data: userFilters } = useGetUserFiltersQuery(
    {
      companyId: currentCompanyId,
      counterpartyIDs: [currentCounterparty?.id || mainCompany?.id],
    },
    { skip: !currentCounterparty?.id && !mainCompany?.id }
  );

  useMutationHandlers(
    updateProfileResponse,
    () => {},
    (error) => {
      const errorData = mapFieldErrorByError(error);
      if (errorData) {
        const { field, text } = errorData;
        setFieldError(field, t(text));
      } else {
        enqueueSnackbar(t("common:errors.request_error"), { variant: "error" });
      }
    }
  );

  const isVisibleButtons = useMemo(
    () =>
      !!values &&
      !!Object.keys(values).find(
        (key) =>
          values[key as keyof typeof initialValues] !==
          initialValues[key as keyof typeof initialValues]
      ),
    [values, initialValues]
  );

  const setLoginGeneration = useCallback(() => {
    setFieldValue("login", generateRandomString({}));
  }, [setFieldValue]);

  const setPasswordGeneration = useCallback(() => {
    setFieldValue(
      "password",
      generateRandomString({
        length: 8,
        lower: true,
        symbols: true,
        numbers: true,
        upper: true,
      })
    );
  }, [setFieldValue]);

  const resetPasswordValue = useCallback(() => {
    if (values?.password === DEFAULT_DISPLAY_PASSWORD_VALUE) {
      setFieldValue("password", "");
    }
  }, [setFieldValue, values]);

  const setPasswordInitialValue = useCallback(() => {
    if (values.password === "") {
      setFieldValue("password", DEFAULT_DISPLAY_PASSWORD_VALUE);
    }
  }, [setFieldValue, values]);

  const isShowConfirmPhone =
    values.phone === initialValues.phone && !!values.phone;
  const isShowConfirmEmail =
    values.email === initialValues.email && !!values.email;

  return (
    <FormikProvider value={formik}>
      <Stack flex={1} component={Form}>
        <Stack
          px={7}
          py={4}
          direction="row"
          flexWrap="wrap"
          justifyContent="center"
        >
          <AccountForm
            name={mainCompany?.name}
            counterparties={counterpartiesList}
            mainCounterparty={appMainCompany as Partial<CounterpartyProfileRes>}
            userValues={values}
            divisions={userFilters?.divisions || []}
          />
          <ContactForm
            currentPhone={values.phone}
            currentEmail={values.email}
            isShowConfirmPhone={isShowConfirmPhone}
            isShowConfirmEmail={isShowConfirmEmail}
            isPhoneConfirmed={phoneConfirmed}
            isEmailConfirmed={emailConfirmed}
            handlePasswordGeneration={setPasswordGeneration}
            handleLoginGeneration={setLoginGeneration}
            onFocusPassword={resetPasswordValue}
            onBlurPassword={setPasswordInitialValue}
          />
        </Stack>
        {isVisibleButtons && (
          <ProfileButtons
            isSubmitDisabled={isSubmitDisabled}
            handleReset={handleReset}
          />
        )}
      </Stack>
    </FormikProvider>
  );
};
