import React, { FC } from "react";
import { CheckFieldForm } from "../check-field-form";
import { RolesInModules } from "./components/roles-in-modules";
import { ModulesAccessProps } from "./modules-access.types";
import { useTranslation } from "react-i18next";
import { useAppContext } from "../../layouts/app-layout/context";
import { StyledAdminWrapper, StyledAdminLabel, StyledAdminTitle } from "./modules-access.styles";

export const ModulesAccess: FC<ModulesAccessProps> = ({
  changeRole,
  hasDivision,
}) => {
  const { t } = useTranslation("user");
  const { availableModules } = useAppContext();

  return (
    <>
      <StyledAdminWrapper>
        <StyledAdminTitle>{t("companyAdmin")}</StyledAdminTitle>
        <CheckFieldForm
          label={<StyledAdminLabel className="label">{t("admin")}</StyledAdminLabel>}
          name="admin"
          color="primary"
        />
      </StyledAdminWrapper>

      {hasDivision && (
        <StyledAdminWrapper>
          <StyledAdminTitle>{t("headDivision")}</StyledAdminTitle>
          <CheckFieldForm
            label={<StyledAdminLabel>{t("appointed")}</StyledAdminLabel>}
            name="divisionHead"
            color="primary"
          />
        </StyledAdminWrapper>
      )}

      {availableModules
        ?.filter((module) => module !== "analytics")
        .map((nameModule) => {
          return (
            <RolesInModules
              key={nameModule}
              name={nameModule}
              title={t(`allModules.${nameModule}`)}
              handleChangeRole={changeRole}
            />
          );
        })}
    </>
  );
};
