import React, {
  ChangeEvent,
  FC,
  SyntheticEvent,
  useCallback,
  useState,
  KeyboardEvent,
} from "react";
import { StyledAutoComplete, StyledLabel, StyledNoOptionsMessage } from "./auto-complete.styles";
import { AutoCompleteProps } from "./auto-complete.types";
import { FieldForm } from "../field-form";
import { useField } from "formik";
import { TextFieldProps, Typography } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useTranslation } from "react-i18next";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import SearchIcon from "@mui/icons-material/Search";

export const AutoComplete: FC<AutoCompleteProps> = ({
  options,
  name,
  labelText,
  labelClassName,
  placeholder,
  isCreatable,
  variant = "default",
  onChange,
  noOptionsMessage,
  renderOption,
  getOptionDisabled,
  disabled,
  value,
}) => {
  const [{ value: formikValue }, , { setValue }] = useField(name || "");
  const { t } = useTranslation("common");
  const [open, setOpen] = useState(false);

  const handleChange = useCallback(
    (
      _: SyntheticEvent,
      selectedOption: { label: string; value: string | {} } | null
    ) => {
      setValue(selectedOption?.label || "");
      onChange?.(selectedOption?.label || '', selectedOption?.value || "");
    },
    [options]
  );

  const handleInputChange = useCallback(
    (event: ChangeEvent) => {
      if (!isCreatable) return null;

      const target = event.target as HTMLInputElement;
      setValue(target.value || "");
    },
    [formikValue]
  );

  const handleKeyDown = useCallback((event: KeyboardEvent) => {
    if (event.key === "Enter") {
      event.preventDefault();

      setOpen(false);
      return;
    }
  }, []);

  return (
    <StyledLabel
      className={`field-form-label ${labelClassName}`}
      $variant={variant}
    >
      {labelText && (
        <Typography pb={0.5} color="secondary" pl={2}>
          {labelText}
        </Typography>
      )}

      <StyledAutoComplete
        disablePortal
        value={value ||formikValue}
        options={options}
        onChange={handleChange}
        disableClearable
        disabled={disabled}
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        popupIcon={
          variant === "profile" ? (
            <ArrowDropDownIcon />
          ) : (
            <KeyboardArrowDownIcon />
          )
        }
        noOptionsText={
          <StyledNoOptionsMessage>
            <SearchIcon className="icon" />
            {noOptionsMessage || t("noOptions")}
          </StyledNoOptionsMessage>
        }
        renderInput={(params: TextFieldProps) => (
          <FieldForm
            {...params}
            name={name || ''}
            onChange={handleInputChange}
            placeholder={placeholder}
            onKeyDown={handleKeyDown}
          />
        )}
        renderOption={renderOption}
        getOptionDisabled={getOptionDisabled}
      />
    </StyledLabel>
  );
};
