import React, {
  FC,
  SyntheticEvent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useForm } from "../../../../hooks/use-form";
import { useTranslation } from "react-i18next";
import { InitValues, MainFormProps } from "./main-form.types";
import { useAppContext } from "../../../../layouts/app-layout/context";
import { validation } from "./validation";
import {
  useBindNewCounterpartyMutation,
  useBindNewCounterpartyWithAvatarMutation,
  useCheckInnMutation,
} from "../../../../api/counterparty";
import { useSnackbar } from "notistack";
import { useNavigate, useLocation } from "react-router-dom";
import {
  fromFormValueToRes,
  getErrorMessage,
  getFieldError,
} from "./main-form.service";
import { ConnectCounterpartyModal } from "../../../../components/connect-counterparty-modal/connect-conterparty-modal";
import { BindCounterparty } from "../../../../api/counterparty/types";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { SerializedError } from "@reduxjs/toolkit";
import { goBack } from "../../counterparty.service";
import { CounterpartyForm } from "../../../../components/counterparty-form";

export const MainForm: FC<MainFormProps> = ({
  roles,
  counterparty,
  variant,
}) => {
  const { t } = useTranslation("counterparty");
  const { t: commonTranslation } = useTranslation("common");
  const { currentCompanyId, isAdmin } = useAppContext();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const location = useLocation();

  const [checkedInn, setCheckedInn] = useState<number | null>(null);

  const [bindNewCounterparty, bindNewCounterpartyResult] =
    useBindNewCounterpartyMutation();
  const [bindNewWithAvatarCounterparty, bindNewWithAvatarCounterpartyResult] =
    useBindNewCounterpartyWithAvatarMutation();
  const [checkInn] = useCheckInnMutation();

  const initialValues = {
    name: counterparty?.name || "",
    role: counterparty?.role || "",
    avatar: counterparty?.avatar || "",
    inn: counterparty?.inn || "",
    kpp: counterparty?.kpp || "",
    ogrn: counterparty?.ogrn || "",
    fullName: counterparty?.fullName || "",
    legalAddress: counterparty?.legalAddress || "",
  };

  const checkResponseByDuplicate = useCallback(
    (
      res:
        | { data: BindCounterparty }
        | { error: FetchBaseQueryError | SerializedError }
    ) => {
      if ("data" in res) {
        if (res.data.duplicateName) {
          formik.setFieldError("name", t("errors.duplicateName"));
          return true;
        }
        if (res.data.duplicateInn) {
          formik.setFieldError("inn", t("errors.duplicateInn"));
          return true;
        }
      }

      return false;
    },
    []
  );

  const handleSubmit = useCallback(
    async (values: InitValues) => {
      if (values.avatar instanceof Blob) {
        const formData = new FormData();
        formData.append("file", values.avatar);
        const res = await bindNewWithAvatarCounterparty({
          companyID: currentCompanyId,
          body: fromFormValueToRes({ values }),
          variant,
          counterpartyID: counterparty?.id,
        });

        if (checkResponseByDuplicate(res)) return;
        if (!("data" in res)) return;
      } else {
        const res = await bindNewCounterparty({
          companyID: currentCompanyId,
          body: {
            name: values.name,
            fullName: values.fullName,
            inn: values.inn,
            role: values.role,
            kpp: values.kpp,
            ogrn: values.ogrn,
            legalAddress: values.legalAddress,
          },
          variant,
          counterpartyID: counterparty?.id,
        });

        if (checkResponseByDuplicate(res)) return;
        if (!("data" in res)) return;
      }
    },
    [variant]
  );

  const { formik, isSubmitDisabled } = useForm({
    validationSchema: validation,
    enableReinitialize: true,
    initialValues,
    validateOnMount: true,
    onSubmit: handleSubmit,
  });
  const { values, isValid, handleReset } = formik;

  const isDisabled =
    variant === "add"
      ? isSubmitDisabled
      : !isValid || JSON.stringify(initialValues) === JSON.stringify(values);

  // error handling
  useEffect(() => {
    if (
      [
        bindNewWithAvatarCounterpartyResult.isError,
        bindNewCounterpartyResult.isError,
      ].includes(true)
    ) {
      const error = bindNewWithAvatarCounterpartyResult.isError
        ? bindNewWithAvatarCounterpartyResult.error
        : bindNewCounterpartyResult.error;
      const message = getErrorMessage({ error, t });
      if (message) return formik.setFieldError(message.key, t(message.value));

      enqueueSnackbar(commonTranslation("errors.error"), {
        variant: "error",
      });
    }
  }, [
    bindNewWithAvatarCounterpartyResult.isError,
    bindNewCounterpartyResult.isError,
  ]);

  // success handling
  useEffect(() => {
    // if success is true, it means that there was no error
    if (
      [
        !!bindNewWithAvatarCounterpartyResult.data?.success,
        !!bindNewCounterpartyResult.data?.success,
      ].includes(true)
    ) {
      enqueueSnackbar(
        t(variant === "add" ? "newCounterpartyAdded" : "counterpartyUpdated"),
        {
          variant: "success",
        }
      );

      goBack({
        location,
        navigate,
        newCounterparty: bindNewCounterpartyResult.data?.success,
      });
    }

    // if success is null, it means that there was an error
    if (
      [
        bindNewWithAvatarCounterpartyResult.data?.success === null,
        bindNewCounterpartyResult.data?.success === null,
      ].includes(true)
    ) {
      const result =
        bindNewWithAvatarCounterpartyResult.data?.success === null
          ? bindNewWithAvatarCounterpartyResult.data
          : bindNewCounterpartyResult.data;
      getFieldError({
        existingInn: result?.existingInn,
        duplicateInn: result?.duplicateInn,
        duplicateName: result?.duplicateName,
        callback: formik.setFieldError,
        t,
        commonTranslation,
        enqueueSnackbar,
      });
    }
  }, [
    bindNewWithAvatarCounterpartyResult.isSuccess,
    bindNewCounterpartyResult.isSuccess,
  ]);

  const handleInnBlur = useCallback(async (event: SyntheticEvent) => {
    const target = event.target as HTMLInputElement;

    if (target.value) {
      const res = await checkInn({
        companyID: currentCompanyId,
        body: {
          inn: target.value,
        },
      });

      const inn = Number(target.value);
      if ("data" in res && res.data.existingInn && typeof inn === "number") {
        setCheckedInn(inn);
      }
    }
  }, []);

  return (
    <>
      {
        <CounterpartyForm
          t={t}
          formik={formik}
          isFormDisabled={!isAdmin}
          isButtonsDisabled={isDisabled}
          variant={variant}
          onBlur={handleInnBlur}
          hasRole
          roles={roles}
          onReset={handleReset}
        />
      }

      {roles?.length && (
        <ConnectCounterpartyModal
          open={!!checkedInn}
          onClose={() => setCheckedInn(null)}
          onSuccess={() => navigate(-1)}
          roles={roles}
          title={t("connectModal.title")}
          description={`${t("connectModal.descriptionStart")} ${
            checkedInn ?? ""
          }\n ${t("connectModal.descriptionEnd")}`}
        />
      )}
    </>
  );
};
