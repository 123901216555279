export function fromDateToDateString(
  date: Date | null,
  options?: Intl.DateTimeFormatOptions
) {
  if (!(date instanceof Date)) return null;

  const day = date.toLocaleDateString(
    "ru-RU",
    options || {
      day: "2-digit",
      month: "long",
      year: "numeric",
    }
  );

  return `${day}`;
}
