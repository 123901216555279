import styled, { css } from "styled-components";

export const StyledList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
`;

interface ModuleProps {
  $isDisabled?: boolean;
}

export const StyledModule = styled.div<ModuleProps>`
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.primary.outlinedBorder};
  background: ${({ theme }) => `${theme.background.blue}14`};
  padding: 6px 8px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: ${({ theme }) => theme.primary.mainBlue};

  .icon {
    margin-right: 16px;
    width: 20px;
  }

  .painted-area {
    fill: ${({ theme }) => theme.primary.mainBlue};
  }

  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      background: ${({ theme }) => `#F6F7FB`};
      border-color: ${({ theme }) => theme.primary.blueShades};
      color: ${({ theme }) => theme.primary.disabled};

      .painted-area {
        fill: ${({ theme }) => theme.primary.disabled};
      }

      .cls-4,
      .cls-7 {
        stroke: ${({ theme }) => theme.primary.disabled};
      }

      .cls-2 {
        fill: ${({ theme }) => theme.primary.disabled};
      }

      .cls-3 {
        fill: none;
      }
    `};

  .icon-missed-data,
  .icon-disabled {
    width: 18px;
    margin-left: auto;
  }

  .icon-disabled {
    fill: ${({ theme }) => theme.primary.disabled};
  }

  .icon-missed-data {
    fill: ${({ theme }) => theme.primary.warningLight};
  }
`;
