import styled from "styled-components";
import { Form } from "formik";

export const StyledPage = styled.div`
  padding: 20px;
  width: 100%;
  height: 100%;
`;

export const StyledBody = styled(Form)`
  padding: 30px 0;
  display: grid;
  grid-template-columns: 440px 520px;
  grid-column-gap: 80px;
  justify-content: center;
  overflow: auto;
  width: 100%;
  height: calc(100% - 48px);
`;

export const StyledColumn = styled.div`
  display: grid;
  grid-row-gap: 12px;
  align-content: start;
`;

export const StyledDivider = styled.div`
  width: 100%;
  height: 1px;
  background: ${({ theme }) => theme.secondary.backgroundDivider};
`;
