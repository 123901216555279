import { forwardRef } from "react";
import { CustomSnackbarContentProps } from "./custom-snackbar-content.types";
import {
  StyledWrapper,
  StyledTitle,
  StyledDescription,
} from "./custom-snackbar-content.styles";
import { SnackbarContent } from "notistack";

export const CustomSnackbarContent = forwardRef<
  HTMLDivElement,
  CustomSnackbarContentProps
>(({ title, message }, ref) => {
  return (
    <SnackbarContent ref={ref}>
      <StyledWrapper>
        <StyledTitle>{title}</StyledTitle>
        <StyledDescription>{message}</StyledDescription>
      </StyledWrapper>
    </SnackbarContent>
  );
});
