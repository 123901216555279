import * as yup from "yup";
import { MAX_PASSWORD_LENGTH_VALIDATION } from "../../utils/constants";

export const validationUser = yup.object({
  lastName: yup.string().trim().required("common:errors.required"),
  firstName: yup.string().trim().required("common:errors.required"),
  middleName: yup.string(),
  companyName: yup.string().required("common:errors.required"),
  divisionName: yup.string(),
  position: yup.string().trim().required("common:errors.required"),
  phone: yup.string().trim().required("common:errors.required"),
  email: yup
    .string()
    .trim()
    .email("common:errors.email")
    .required("common:errors.required"),
  login: yup.string(),
  password: yup
    .string()
    .trim()
    .min(MAX_PASSWORD_LENGTH_VALIDATION, "common:errors.min")
    .required("common:errors.required"),
});

export const disabledContactsFieldsValidation = yup.object({
  lastName: yup.string().trim().required("common:errors.required"),
  firstName: yup.string().trim().required("common:errors.required"),
  middleName: yup.string(),
  companyName: yup.string().required("common:errors.required"),
  divisionName: yup.string(),
  position: yup.string().trim().required("common:errors.required"),
  password: yup
    .string()
    .trim()
    .min(MAX_PASSWORD_LENGTH_VALIDATION, "common:errors.min")
    .required("common:errors.required"),
});
