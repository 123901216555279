import styled from "styled-components";
import { IconButton } from "@mui/material";

export const StyledCell = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 20px;
  grid-column-gap: 8px;
  align-items: center;
`;

export const StyledCommentBody = styled.div`
  margin: 20px 0;
  display: grid;
  grid-row-gap: 20px;

  .MuiInputBase-multiline {
    padding: 0;
    border: 1px solid ${({ theme }) => `${theme.primary.textDark}1F`};
  }

  .MuiInputBase-inputMultiline {
    padding: 8px;

    &::placeholder {
      font-size: 14px;
      color: ${({ theme }) => `${theme.primary.textDark}61`};
      opacity: 1;
    }
  }

  .field-form-label {
    text-align: left;

    .MuiTypography-root {
      padding-left: 0;
      font-size: 14px;
      color: ${({ theme }) => theme.secondary.textAdditional};
    }
  }

  .field-form-label_required .MuiTypography-root {
    position: relative;
    width: max-content;

    &::after {
      content: "*";
      position: absolute;
      right: -9px;
      color: ${({ theme }) => theme.secondary.error};
    }
  }
`;

export const StyledCommentDescription = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`;

export const StyledDivisionName = styled.span`
  font-weight: 500;
`;

export const StyledButtonComment = styled(IconButton)`
  padding: 0;
  height: 20px;
  width: 20px;

  .icon {
    width: 16px;
    height: 16px;
    fill: ${({ theme }) => theme.primary.mainBlue};
  }

  &.Mui-disabled .icon {
    fill: ${({ theme }) => theme.background.gray};
  }
`;
