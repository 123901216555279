import { FC } from "react";
import { Stack, StackProps, Typography, useTheme } from "@mui/material";
import { UserCardsTitle } from "../../../../utils/constants";
import { useTranslation } from "react-i18next";

type UserCardCellProps = {
  title: UserCardsTitle;
  isAdmin?: boolean;
} & StackProps;

export const UserCardCell: FC<UserCardCellProps> = ({
  title,
  isAdmin,
  children,
  ...props
}) => {
  const { t } = useTranslation("user");
  const theme = useTheme();

  const isShowAdmin = isAdmin && title === UserCardsTitle.modules;
  return (
    <Stack flex={1} ml={8} {...props}>
      <Stack
        mb={1.5}
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Typography variant="caption" color="secondary">
          {title}
        </Typography>
        {isShowAdmin && (
          <Stack
            ml={2}
            justifyContent="center"
            px={1}
            py={0.4}
            sx={{
              background: "rgba(141, 212, 200, 0.15)",
              borderRadius: 16,
            }}
          >
            <Typography variant="body2" color={theme.palette.success.main}>
              {t("admin")}
            </Typography>
          </Stack>
        )}
      </Stack>
      {children}
    </Stack>
  );
};
