import styled, { css } from "styled-components";
import { Button, MenuItem, Select, Avatar } from "@mui/material";

const stickyWrapperStyles = css`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  padding: 14px 0;
  height: 58px;
  position: sticky;
`;

const commonStickyItemStyles = css`
  width: calc(100% - 32px);
  background: #fff;
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.primary.outlinedBorder};
  min-height: 30px;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
`;

export const StyledButtonWrapper = styled.li`
  ${stickyWrapperStyles};
  top: 58px;
  z-index: 5;
`;

export const StyledSearchWrapper = styled.li`
  ${stickyWrapperStyles};
  top: 0;
  z-index: 5;
`;

export const StyledSearchInput = styled.input`
  ${commonStickyItemStyles};
  padding: 0 8px;
  font-size: 14px;

  &:focus-visible {
    outline: none;
  }
`;

export const StyledButtonRedirect = styled(Button)`
  ${commonStickyItemStyles};
  color: ${({ theme }) => theme.primary.mainBlue};
  cursor: pointer;

  &:hover {
    background: #fff;
  }
`;

export const StyledSelect = styled(Select)`
  width: 100%;
  border-radius: 8px;
  max-height: 40px;
  background: ${({ theme }) => theme.primary.formBackground};
  border: none;

  .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  .MuiList-root {
    max-height: 280px;
    padding-top: 0;
  }
`;

export const StyledPlaceholder = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: #2b364861;
`;

export const StyledMenuItem = styled(MenuItem)`
  display: flex;
  align-items: center;
`;

export const StyledAvatar = styled(Avatar)`
  border-radius: 50%;
  margin-right: 16px;
`;
