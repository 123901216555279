import {
  StyledProjects,
  StyledHeader,
  StyledList,
  StyledButton,
} from "./projects.styles";
import { useTranslation } from "react-i18next";
import React, { useCallback, useMemo, useState } from "react";
import { useGlobalSearch } from "../../utils/useGlobalSearch";
import AddIcon from "@mui/icons-material/Add";
import {
  useSetFavouriteMutation,
  useGetFilteredProjectsQuery,
  useGetProjectFiltersQuery,
} from "../../api/companies";
import { useAppContext } from "../../layouts/app-layout/context";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ProjectCard } from "./components/ProjectCard";
import { useSnackbar } from "notistack";
import { FiltersState } from "./projects.types";
import { Filters } from "./components/Filters";
import { getFiltersParams } from "./projects.service";
import { NUMBER_OF_ROWS_PER_USERS_PAGE } from "../../utils/constants";
import { Pagination } from "./components/Pagination";
import { Progress } from "../../components/progress";

export const Projects = () => {
  const { t } = useTranslation("projects");
  const { t: commonT } = useTranslation("common");
  const [searchParam] = useGlobalSearch();
  const { currentCompanyId, isAdmin } = useAppContext();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [searchParams, setSearchParams] = useSearchParams();
  const limitParam = searchParams.get("limit");
  const pageParam = searchParams.get("page");

  const [filtersState, setFiltersState] = useState(new FiltersState(searchParams));

  const limitValue = useMemo(
    () => Number(limitParam) || NUMBER_OF_ROWS_PER_USERS_PAGE[0].value,
    [limitParam]
  );
  const page = useMemo(() => Number(pageParam) || 1, [pageParam]);
  const offset = useMemo(() => limitValue * (page - 1), [limitValue, page]);

  const { data: projects, isLoading } = useGetFilteredProjectsQuery(
    {
      companyID: currentCompanyId,
      limit: limitValue,
      offset: offset,
      search: searchParam,
      body: getFiltersParams(filtersState, [
        "enabledModules",
        "finTypes",
        "projectTypes",
        "regions",
        "statuses",
      ]),
    },
    { skip: !currentCompanyId }
  );

  const commonFiltersParams = {
    companyID: currentCompanyId,
    ...(searchParam && { search: searchParam }),
  };
  const enabledModules = useGetProjectFiltersQuery({
    ...commonFiltersParams,
    body: {
      ...getFiltersParams(filtersState, [
        "finTypes",
        "projectTypes",
        "regions",
        "statuses",
      ]),
    },
  });

  const finTypes = useGetProjectFiltersQuery({
    ...commonFiltersParams,
    body: {
      ...getFiltersParams(filtersState, [
        "enabledModules",
        "projectTypes",
        "regions",
        "statuses",
      ]),
    },
  });

  const projectTypes = useGetProjectFiltersQuery({
    ...commonFiltersParams,
    body: {
      ...getFiltersParams(filtersState, [
        "enabledModules",
        "finTypes",
        "regions",
        "statuses",
      ]),
    },
  });

  const regions = useGetProjectFiltersQuery({
    ...commonFiltersParams,
    body: {
      ...getFiltersParams(filtersState, [
        "enabledModules",
        "finTypes",
        "projectTypes",
        "statuses",
      ]),
    },
  });

  const statuses = useGetProjectFiltersQuery({
    ...commonFiltersParams,
    body: {
      ...getFiltersParams(filtersState, [
        "enabledModules",
        "finTypes",
        "projectTypes",
        "regions",
      ]),
    },
  });

  const [setFavourite] = useSetFavouriteMutation();

  const pages = useMemo(() => {
    if (!projects?.total) return 1;

    return Math.ceil(projects.total / Number(limitValue)) || 1;
  }, [projects, limitValue]);

  const handleButtonCreateClick = useCallback(() => {
    navigate(`/companies/${currentCompanyId}/projects/create`);
  }, [navigate, currentCompanyId]);

  const handleSetFavorite = useCallback(
    (projectID: number, favourite: boolean) => {
      try {
        setFavourite({
          companyID: currentCompanyId,
          projectID,
          favourite,
        });
      } catch {
        enqueueSnackbar(commonT("errors.error"), {
          variant: "error",
        });
      }
    },
    [currentCompanyId]
  );

  const handleChangePage = useCallback(
    (page: number) => {
      searchParams.set("page", page.toString());
      setSearchParams(searchParams);
    },
    [setSearchParams, searchParams]
  );

  const handleChangeLimit = useCallback(
    (limit) => {
      searchParams.set("limit", String(limit));
      searchParams.set("page", "1");
      setSearchParams(searchParams);
    },
    [setSearchParams, searchParams]
  );

  return (
    <StyledProjects>
      <StyledHeader>
        <Filters
          state={filtersState}
          onChange={setFiltersState}
          enabledModules={enabledModules.data?.enabledModules || []}
          projectTypes={projectTypes.data?.projectTypes || []}
          finTypes={finTypes.data?.finTypes || []}
          regions={regions.data?.regions || []}
          statuses={statuses.data?.statuses || []}
          searchParams={searchParams}
          setSearchParams={setSearchParams}
        />

        <StyledButton
          variant="contained"
          startIcon={<AddIcon />}
          onClick={handleButtonCreateClick}
          disabled={!isAdmin}
          $isVisible={isAdmin}
        >
          {t("buttons.add")}
        </StyledButton>
      </StyledHeader>

      <Pagination
        page={page}
        limit={limitValue}
        count={pages}
        onChangePage={handleChangePage}
        onChangeLimit={handleChangeLimit}
      />

      {isLoading ? (
        <Progress />
      ) : (
        <StyledList>
          {projects?.data.map((project) => (
            <ProjectCard
              key={project.id}
              projectInfo={project}
              onButtonFavoriteClick={() =>
                handleSetFavorite(project.id, !project.favourite)
              }
            />
          ))}
        </StyledList>
      )}
    </StyledProjects>
  );
};
