import styled from "styled-components";
import { Typography, Stack } from "@mui/material";

export const StyledWrapper = styled(Stack)`
  .MuiSelect-select {
    padding: 8px 16px;
  }

  .MuiFormControl-root {
    width: 220px;
  }
`;

export const StyledTile = styled(Typography)`
  font-size: 14px;
  font-weight: 500;
`;
