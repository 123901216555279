import styled, { css } from "styled-components";
import { Button, IconButton } from "@mui/material";

interface HeaderProps {
  $hasTabs?: boolean;
}

export const StyledHeader = styled.header<HeaderProps>`
  width: 100%;
  display: grid;
  grid-template-columns: ${({ $hasTabs }) =>
    $hasTabs ? "85px minmax(200px, max-content) 163px" : "85px 111px"};
  align-items: center;
  justify-content: space-between;
  min-height: 48px;
  column-gap: 20px;
  overflow-x: hidden;
`;

export const StyledButtonBack = styled(Button)`
  color: ${({ theme }) => theme.primary.blue};

  .MuiTypography-root {
    font-size: 13px;
  }
`;

const commonButtonStyles = css`
  font-size: 13px;
  line-height: 15px;
  font-weight: 500;
  min-height: 36px;
  color: #fff;
`;

export const StyledButtonSave = styled(Button)`
  background: ${({ theme }) => theme.legends.green};
  ${commonButtonStyles};

  &:hover {
    background: ${({ theme }) => theme.legends.green};
  }
`;

export const StyledButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 12px;
`;

export const StyledButtonDelete = styled(IconButton)`
  width: 40px;
  height: 40px;
  background: ${({ theme }) => theme.secondary.error};
  border-radius: 6px;

  .icon {
    fill: #fff;
  }

  &:hover {
    background: ${({ theme }) => theme.secondary.error};
  }
`;
