import styled, { css } from "styled-components";
import { Pagination, ToggleButton, ToggleButtonGroup } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

export const StyledPagination = styled(Pagination)`
  .MuiPaginationItem-root {
    &.Mui-selected {
      background: ${({ theme }) => theme.primary.blue20};
      color: ${({ theme }) => theme.secondary.blue};
      border: 1px solid blue;
    }
  }
`;

export const StyledSearchIcon = styled(SearchIcon)`
  width: 20px;
  fill: ${({ theme }) => "red"};
`;

interface LabelProps {
  $variant?: "profile" | "default";
}

export const StyledLabel = styled.label<LabelProps>`
  .MuiTypography-root {
    font-size: 14px;
    color: ${({ theme }) => theme.primary.textDark};
  }

  .MuiInputBase-root.MuiOutlinedInput-root {
    ${({ $variant, theme }) =>
      $variant === "profile" &&
      css`
        background: ${theme.primary.formBackground};
        border: none;
        border-radius: 8px;
      `};
  }
`;

export const StyledNoOptionsMessage = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`;

export const StyledRow = styled.div`
  display: grid;
  grid-row-gap: 4px;
`;

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
  background: #fff;
  padding: 2px;
  border: none;
  border-radius: 6px;
`;

interface ToggleButtonProps {
  $separatorPosition: "left" | "right" | "both";
}

export const StyledToggleButton = styled(ToggleButton)<ToggleButtonProps>`
  padding: 2px 8px;
  font-size: 14px;
  font-weight: 400;
  text-transform: unset;
  border: none;
  border-radius: 6px !important;
  position: relative;

  &.Mui-selected {
    background: ${({ theme }) => theme.primary.mainBlue};
    color: #fff;

    &:hover {
      background: ${({ theme }) => theme.primary.mainBlue};
    }

    &::before {
      position: absolute;
      content: "";
      width: 1px;
      height: 16px;
      background: ${({ theme }) => theme.primary.outlinedBorder};
      ${({ $separatorPosition }) =>
        css`
          ${$separatorPosition === "both" ? "left" : $separatorPosition}: ${[
            "left",
            "both",
          ].includes($separatorPosition)
            ? "-4px"
            : "-2px"};
        `}
    }

    ${({ $separatorPosition }) =>
      $separatorPosition === "both" &&
      css`
        &::after {
          position: absolute;
          content: "";
          width: 1px;
          height: 16px;
          background: ${({ theme }) => theme.primary.outlinedBorder};
          right: -2px;
        }
      `};
  }
`;
