import { useEffect } from "react";

type InitialOptions = {
  isSuccess?: boolean;
  data?: any;
  isError?: boolean;
  error?: any;
};

export const useMutationHandlers = <TOptions extends InitialOptions>(
  options: TOptions,
  onSuccess?: (data: NonNullable<TOptions["data"]>) => void,
  onFailure?: (error: NonNullable<TOptions["error"]>) => void
) => {
  const { isSuccess, data, isError, error } = options;

  useEffect(() => {
    if (isSuccess) {
      if ("success" in (data || {}) && data.success === false) {
        onFailure?.(data);
      } else {
        onSuccess?.(data);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, data]);

  useEffect(() => {
    if (isError) {
      onFailure?.(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError, error]);
};
