import { RouterState } from "./counerparty.types";
import { NavigateFunction, Location } from "react-router-dom";
import { CounterpartyProfileRes } from "../../api/counterparty/types";

interface GoBack {
  navigate: NavigateFunction;
  location: Location;
  newCounterparty?: CounterpartyProfileRes | null;
}

export function goBack({ navigate, location, newCounterparty }: GoBack) {
  const state = location.state as RouterState;
  const newCounterpartyName = newCounterparty?.name;
  if (state?.from) {
    navigate(state.from, {
      state: {
        userValues: {
          ...state.userValues,
          companyName: newCounterpartyName || state.userValues?.companyName,
        },
      },
    });
  } else {
    navigate(-1);
  }
}
