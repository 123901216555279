import { api } from "../api";
import {
  LoginByEmail,
  LoginByPhone,
  LoginResponse,
  SendSmsResponse,
} from "./types";

export type LoginByCode = {
  code?: string;
  email?: string;
};

export type SendCodeToEmailRequest = {
  email: string;
};
export type SendCodeToEmailResponse = SendSmsResponse;

export type AuthorizationSuccess = {
  token?: string;
};

export const authApi = api.injectEndpoints({
  endpoints: (build) => ({
    loginByEmail: build.mutation<LoginResponse, LoginByEmail>({
      query: ({ emailOrLogin, password }) => ({
        url: `/auth/login-by-email`,
        method: "POST",
        body: { emailOrLogin, password },
      }),
      invalidatesTags: () => ["Profile", "ProfileByCompany", "Companies", "Assignments"],
    }),
    sendSms: build.mutation<SendSmsResponse, LoginByPhone>({
      query: ({ phone }) => ({
        url: `/auth/login-by-phone`,
        method: "POST",
        body: { phone },
      }),
      invalidatesTags: () => ["Profile", "ProfileByCompany", "Assignments"],
    }),
    loginByPhone: build.mutation<LoginResponse, any>({
      query: ({ phone, code }) => ({
        url: `/auth/apply-phone-code`,
        method: "POST",
        body: { phone, code },
      }),
      invalidatesTags: () => ["Profile", "ProfileByCompany", "Companies", "Assignments"],
    }),
    sendCodeToEmail: build.mutation<
      SendCodeToEmailResponse,
      SendCodeToEmailRequest
    >({
      query: ({ email }) => ({
        url: `/auth/restore-by-email`,
        method: "POST",
        body: { email },
      }),
      invalidatesTags: () => ["Profile", "ProfileByCompany"],
    }),
    loginByEmailCode: build.mutation<AuthorizationSuccess, LoginByCode>({
      query: ({ email, code }) => ({
        url: `/auth/apply-restore-by-email-code`,
        method: "POST",
        body: { email, code },
      }),
      invalidatesTags: () => ["Profile", "ProfileByCompany", "Companies", "Assignments"],
    }),
    sendConfirmationEmail: build.mutation<AuthorizationSuccess, LoginByCode>({
      query: ({ email }) => ({
        url: `/auth/login-by-email-code`,
        method: "POST",
        body: { email },
      }),
    }),
    confirmEmailByCode: build.mutation<AuthorizationSuccess, LoginByCode>({
      query: ({ code, email }) => {
        return {
          method: "POST",
          url: `/auth/apply-email-code`,
          body: { code, email },
        };
      },
      invalidatesTags: () => ["Profile", "ProfileByCompany"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useLoginByEmailMutation,
  useSendSmsMutation,
  useLoginByPhoneMutation,
  useSendCodeToEmailMutation,
  useLoginByEmailCodeMutation,
  useSendConfirmationEmailMutation,
  useConfirmEmailByCodeMutation,
} = authApi;
