import React, { FC, useCallback, useContext } from "react";
import {
  StyledHeader,
  StyledButtonBack,
  StyledButtonSave,
  StyledButtonsContainer,
  StyledButtonDelete,
} from "./project-header.styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ProjectHeaderProps } from "./project-header.types";
import { projectContext } from "../../context";
import { ProjectTabs } from "../project-tabs";
import { useAppContext } from "../../../../layouts/app-layout/context";
import DeleteSharpIcon from "@mui/icons-material/DeleteSharp";

export const ProjectHeader: FC<ProjectHeaderProps> = ({
  tab,
  isEdit,
  onTabChange,
  onButtonDeleteClick,
}) => {
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const { formsState } = useContext(projectContext);
  const { isAdmin } = useAppContext();

  const handleButtonBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <StyledHeader $hasTabs={isEdit}>
      <StyledButtonBack
        onClick={handleButtonBack}
        startIcon={<ArrowBackIcon />}
      >
        <Typography variant="body2">{t("buttons.back")}</Typography>
      </StyledButtonBack>

      {isEdit && <ProjectTabs currentTab={tab} onTabChange={onTabChange} />}

      <StyledButtonsContainer>
        {isAdmin && (
          <StyledButtonSave
            variant="contained"
            type="submit"
            disabled={formsState[tab]?.isSubmitDisabled}
            form={tab}
          >
            {t("buttons.save")}
          </StyledButtonSave>
        )}

        {isEdit && isAdmin && (
          <StyledButtonDelete>
            <DeleteSharpIcon className="icon" onClick={onButtonDeleteClick} />
          </StyledButtonDelete>
        )}
      </StyledButtonsContainer>
    </StyledHeader>
  );
};
