import styled, { css } from "styled-components";
import { MenuItem } from "@mui/material";
import { AssignmentStatus } from "../../api/assignments/types";
import { colorByStatus } from "../../common/assignmentsCommonStyles";

export const StyledSelectWrapper = styled.div`
    .MuiSelect-select.MuiInputBase-input {
        padding: 4.5px 0;
    }

    .MuiFormControl-root {
        width: 100%;
    }

    .MuiInputBase-input {
        padding: 4.5px 14px;
    }

    .MuiInputBase-root {
        width: 100%;
        border-radius: 6px;
        border-color: ${({ theme }) => `${theme.primary.textDark}1F`};
        background: ${({ theme }) => theme.primary.blueShades};

        .MuiSvgIcon-root {
            fill: ${({ theme }) => theme.primary.disabled};
        }
    }
}

.MuiInputBase-root.Mui-focused{
    .MuiOutlinedInput-notchedOutline {
        border-color: ${({ theme }) => `${theme.primary.textDark}1F`};
    }
`;

interface MenuItemProps {
  $status: AssignmentStatus;
}

const getSelectItemStyles = (status: AssignmentStatus) => css`
  padding-left: 28px;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    left: 8px;
    top: 0;
    bottom: 0;
    margin: auto 0;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: ${colorByStatus[status]};
  }
`;

export const StyledMenuItem = styled(MenuItem)<MenuItemProps>`
  ${({ $status }) => getSelectItemStyles($status)};
`;

export const StyledSelectValue = styled.div<MenuItemProps>`
  ${({ $status }) => getSelectItemStyles($status)};
`;
