import { FC, useCallback, useMemo, useState, MouseEvent } from "react";
import {
  Avatar,
  Dialog,
  IconButton,
  ListItem,
  Menu,
  MenuItem,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { Icon } from "../icons";
import { useTranslation } from "react-i18next";
import {
  Company,
  useLeaveCompanyMutation,
  useSetCompanyAsMainMutation,
} from "../../api/companies";
import { useMutationHandlers } from "../../hooks/use-mutation-handlers";
import { useSnackbar } from "notistack";
import { ConfirmationDialog } from "../confirmation-dialogs";

type CompanyCardProps = {
  companyName?: Company["name"];
  companyId: Company["id"];
  description?: string;
  isMainCompany?: boolean;
  isPendingInvite?: boolean;
  onAcceptInvitation: (companyId: Company["id"]) => void;
  avatarSrc?: string;
};

export const CompanyCard: FC<CompanyCardProps> = ({
  companyName,
  companyId,
  description,
  isMainCompany,
  isPendingInvite,
  onAcceptInvitation,
  avatarSrc,
}) => {
  const { t } = useTranslation("company");
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();

  const [anchorMenu, setAnchorMenu] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorMenu);

  const [isOpenConfirmationDialogsMain, setIsOpenConfirmationDialogsMain] =
    useState(false);
  const [isOpenConfirmationDialogsLeave, setIsOpenConfirmationDialogsLeave] =
    useState(false);

  const [first = "", second = ""] = companyName?.split(" ") || [];
  const shortName = useMemo(
    () =>
      [first[0], second[0] || first[1]].filter(Boolean).join("").toUpperCase(),
    [first, second]
  );

  const [setCompanyAsMain, setCompanyAsMainResponse] =
    useSetCompanyAsMainMutation();
  const [leaveCompany, leaveCompanyResponse] = useLeaveCompanyMutation();

  const handleAcceptInvitation = useCallback(
    (e: MouseEvent<HTMLButtonElement | HTMLElement>) => {
      e.preventDefault();
      onAcceptInvitation(companyId);
      setAnchorMenu(null);
    },
    [onAcceptInvitation, companyId]
  );

  const handleSetCompanyAsMain = useCallback(
    (e: MouseEvent<HTMLButtonElement | HTMLElement>) => {
      e.preventDefault();
      setIsOpenConfirmationDialogsMain(true);
    },
    []
  );
  const handleSuccessDialogMain = useCallback(() => {
    setCompanyAsMain({ companyId });
  }, [setCompanyAsMain, companyId]);

  const handleCancelDialogMain = useCallback(() => {
    setIsOpenConfirmationDialogsMain(false);
  }, []);

  const handleLeaveCompany = useCallback(
    (e: MouseEvent<HTMLButtonElement | HTMLElement>) => {
      e.preventDefault();
      setIsOpenConfirmationDialogsLeave(true);
    },
    []
  );

  const handleSuccessDialogLeave = useCallback(() => {
    leaveCompany({ companyId });
  }, [leaveCompany, companyId]);

  const handleCancelDialogLeave = useCallback(() => {
    setIsOpenConfirmationDialogsLeave(false);
  }, []);

  const onClickIconPoints = useCallback(
    (e: MouseEvent<HTMLButtonElement | HTMLElement>) => {
      e.preventDefault();
      setAnchorMenu(e.currentTarget);
    },
    []
  );

  const handleCloseMenu = useCallback(
    (e: MouseEvent<HTMLButtonElement | HTMLElement>) => {
      e.preventDefault();
      setAnchorMenu(null);
    },
    []
  );

  useMutationHandlers(
    setCompanyAsMainResponse,
    () => {
      setIsOpenConfirmationDialogsMain(false);
      setAnchorMenu(null);
      enqueueSnackbar(t("success.changedMainCompany", { companyName }), {
        variant: "success",
      });
    },
    (error) => {
      enqueueSnackbar(t("common:errors.request_error"), { variant: "error" });
    }
  );
  useMutationHandlers(
    leaveCompanyResponse,
    () => {
      setIsOpenConfirmationDialogsLeave(false);
      setAnchorMenu(null);
      enqueueSnackbar(t("success.leaveCompany", { companyName }), {
        variant: "success",
      });
    },
    (error) => {
      enqueueSnackbar(t("common:errors.request_error"), { variant: "error" });
    }
  );

  const isShowSuccessIcon = isMainCompany && !isPendingInvite;

  return (
    <Stack flex={1}>
      <ListItem
        sx={{
          padding: "20px 16px",
          width: "100%",
        }}
      >
        <Stack
          flex={1}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack spacing={4} alignItems="center" direction="row">
            <Avatar
              sx={{
                width: 48,
                height: 48,
                background: theme.palette.primary.main,
                opacity: 0.5,
              }}
              src={avatarSrc}
            >
              {shortName}
            </Avatar>
            <Stack direction="column">
              <Typography variant="h6">{companyName}</Typography>
              <Typography variant="body2">{description}</Typography>
            </Stack>
          </Stack>
          <Stack>
            {isShowSuccessIcon ? (
              <Stack px={1.5}>
                <Icon name="success" />
              </Stack>
            ) : (
              <IconButton
                sx={{
                  maxHeight: 40,
                  "&:hover": { color: theme.palette.primary.main },
                }}
                onClick={onClickIconPoints}
              >
                <Icon name="points" />
              </IconButton>
            )}
            <Menu
              PaperProps={{
                sx: {
                  width: 360,
                },
              }}
              id="basic-menu"
              anchorEl={anchorMenu}
              open={openMenu}
              onClose={handleCloseMenu}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{
                vertical: 45,
                horizontal: 46,
              }}
            >
              {isPendingInvite && (
                <MenuItem
                  sx={{ "&:hover": { color: theme.palette.primary.main } }}
                  onClick={handleAcceptInvitation}
                >
                  {t("acceptInvitation")}
                </MenuItem>
              )}

              {!isMainCompany && (
                <MenuItem
                  sx={{ "&:hover": { color: theme.palette.primary.main } }}
                  onClick={handleSetCompanyAsMain}
                >
                  {t("setCompanyAsMain")}
                </MenuItem>
              )}
              <MenuItem
                sx={{ "&:hover": { color: theme.palette.primary.main } }}
                onClick={handleLeaveCompany}
              >
                {t("leaveCompany")}
              </MenuItem>
            </Menu>
          </Stack>
        </Stack>
      </ListItem>
      <Dialog
        open={isOpenConfirmationDialogsMain}
        onClose={handleCancelDialogMain}
      >
        <ConfirmationDialog
          onCancel={handleCancelDialogMain}
          onSuccess={handleSuccessDialogMain}
          title={t("dialogMain.title")}
          description={t("dialogMain.description", { companyName })}
          descriptionEnd={t("dialogMain.descriptionEnd")}
        />{" "}
      </Dialog>
      <Dialog
        open={isOpenConfirmationDialogsLeave}
        onClose={handleCancelDialogLeave}
      >
        <ConfirmationDialog
          onCancel={handleCancelDialogLeave}
          onSuccess={handleSuccessDialogLeave}
          title={t("dialogLeave.title")}
          description={t("dialogLeave.description", { companyName })}
          descriptionEnd={t("dialogLeave.descriptionEnd", { companyName })}
        />
      </Dialog>
    </Stack>
  );
};
