import { Access, ProfileByCompany } from "../profile";
import { Company, ModuleType } from "../companies";
import {CounterpartyStaffDivision} from "../counterparty/types";

export type Users = {
  data: ProfileByCompany[];
  total: number;
};

export enum UserRoles {
  user = "user",
  admin = "admin",
  companyAdmin = "company_admin",
}
export enum ProfileCompanyRoles {
  owner = "owner",
  admin = "admin",
  user = "user",
  none = "none",
}

export interface GetUserFiltersRequest {
  companyId?: number;
  search?: string;
  roles?: UserRoles[];
  modules?: ModuleType[];
  divisions?: string[];
  ownCompany?: boolean;
  counterpartyIDs?: number[];
}

export interface GetUserFiltersResponse {
  divisions: string[];
  modules: ModuleType[];
  roles: UserRoles[];
  counterparties: CounterpartyFilter[];
}

interface CounterpartyFilter extends CompanyProfileInfo {
  access: Access;
}

export interface CompanyProfileInfo {
  name: string;
  avatar?: string | null;
  avatarPreview?: string | null;
  id?: number | null;
  counterparty: boolean;
}

export type UsersRequest = {
  companyId: Company["id"];
  userId?: ProfileByCompany["coreID"];
  limit?: number;
  offset?: number;
  modules?: string[];
  roles?: string[];
  counterpartyIDs?: number[];
  ownCompany?: boolean;
  divisions?: string[];
  syncWithGlobal?: boolean;
  search?: string
};

export interface UpdateAccountProfileByCompanyRequest
  extends UsersRequest,
    Pick<
      ProfileByCompany,
      | "lastName"
      | "firstName"
      | "middleName"
      | "companyName"
      | 'division'
      | "position"
      | "all"
    > {
  company?: { counterpartyID: number | null };
  divisionHead?: boolean;
}

export type UserUpdateAvatarRequest = {
  companyId: Company["id"];
  userId?: ProfileByCompany["coreID"];
  file: Blob;
  all?: boolean;
};

export type CreateCompanyUserRequest = {
  companyId?: Company["id"];
  userId?: ProfileByCompany["coreID"];
  employment: {
    companyID: number;
    company?: { counterpartyID: number | null };
    division: string;
    divisionHead: boolean;
    firstName: string;
    lastName: string;
    middleName: string;
    position: string;
  };
  profile: {
    email: string;
    login: string;
    password: string;
    phone: string;
  };
  access?: Access;
  avatar?: UserUpdateAvatarRequest;
  syncWithGlobal?: boolean;
};

export interface InviteUserRequest
  extends Omit<CreateCompanyUserRequest, "employment"> {
  employment: {
    companyID: number;
    firstName: string;
    lastName: string;
    middleName: string;
    position: string;
  };
}

export interface ResetAccessRequest {
  companyID: number;
  userID: number;
}

export interface UpdateAccessRequest {
  companyID: number;
  body: Access;
}

export interface GetUsersListOwnResponse {
  divisions: CounterpartyStaffDivision[];
}
