import React, { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Avatar, Stack, Typography, useTheme } from '@mui/material'
import { formatMaskPhone } from '../../../../components/phone-field-form/utils'
import { ProfileCompanyRoles } from '../../../../api/company-admin'
import { Access, ProfileByCompany } from '../../../../api/profile'
import { UserCardCell } from '../user-card-cell'
import { RoleIndicator } from '../role-indicator'
import { ModuleType } from '../../../../api/companies'

type UserCardRowProps = {
  availableModules: ModuleType[];
} & Omit<
  ProfileByCompany,
  | 'login'
  | 'emailConfirmed'
  | 'phoneConfirmed'
  | 'coreID'
  | 'main'
  | 'invitationFields'
>;

export const UserCardRow: FC<UserCardRowProps> = ({
  firstName,
  lastName,
  middleName,
  position,
  companyName,
  avatarPreview,
  phone,
  email,
  access,
  availableModules,
}) => {
  const { t } = useTranslation('user')
  const theme = useTheme()

  const { admin: isAdmin, owner: isOwner } = access

  const formatPhone = useMemo(() => {
    if (phone) {
      return formatMaskPhone(phone)
    }
  }, [phone])

  const modulesAccessed = availableModules.filter(module => {
    const role = access[module as keyof Access]
    return role !== ProfileCompanyRoles.none
  })

  return (
    <Stack
      pl={2.5}
      pr={1.5}
      py={1.25}
      direction='row'
      height='138px'
      sx={{
        '&:hover': {
          background: theme.palette.warning.contrastText,
        },
      }}
    >
      <Stack justifyContent='center'>
        <Avatar sx={{ width: 80, height: 80 }} src={avatarPreview} />
      </Stack>
      <UserCardCell maxWidth={166} title={t('fullName')}>
        <Stack>
          <Typography textOverflow='ellipsis' overflow='hidden' whiteSpace='nowrap'>{lastName}</Typography>
          <Typography textOverflow='ellipsis' overflow='hidden' whiteSpace='nowrap'>{firstName}</Typography>
          <Typography textOverflow='ellipsis' overflow='hidden' whiteSpace='nowrap'>{middleName}</Typography>
        </Stack>
      </UserCardCell>
      <UserCardCell maxWidth={300} title={t('contacts')}>
        <Stack>
          <Typography>{formatPhone}</Typography>
          <Typography>{email}</Typography>
        </Stack>
      </UserCardCell>
      <Stack flex={1} direction='row' justifyContent='space-between'>
        <UserCardCell width={260} flex='none' title={t('company')}>
          <Stack>
            <Typography>{companyName}</Typography>
            <Typography>{position}</Typography>
          </Stack>
        </UserCardCell>
        {isOwner ? (
          <Stack
            ml={2}
            justifyContent='center'
            height='fit-content'
            whiteSpace='nowrap'
            m='auto'
            px={1}
            py={0.4}
            sx={{
              background: 'rgba(109, 154, 220, 0.2)',
              borderRadius: 16,
            }}
          >
            <Typography variant='body2' color='primary'>
              {t('superAdmin')}
            </Typography>
          </Stack>
        ) : (
          <UserCardCell
            ml={2}
            minWidth={300}
            maxWidth={420}
            flex={1}
            title={t('modules')}
            isAdmin={isAdmin}
          >
            <Stack
              maxHeight={100}
              flexWrap='wrap'
              columnGap={6}
              sx={modulesAccessed?.length > 3
                ? {
                  marginBottom: -13,
                  overflowX: 'auto',
                }
                : {}
              }
            >
              {availableModules.map((module) => {
                const role = access[module as keyof Access]
                const isAdmin = role === ProfileCompanyRoles.admin
                if (role === ProfileCompanyRoles.none) {
                  return null
                }

                return (
                  <Stack
                    key={module}
                    alignItems='center'
                    //mr={6}
                    mb={0.75}
                    direction='row'
                  >
                    <RoleIndicator isAdmin={isAdmin} />
                    <Typography ml={2} variant='body2'>
                      {t(`title.${module}`).toUpperCase()}
                    </Typography>
                  </Stack>
                )
              })}
            </Stack>
          </UserCardCell>
        )}
      </Stack>
    </Stack>
  )
}
