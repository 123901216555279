import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Button, Stack, Typography } from "@mui/material";

import {
  MAX_WIDTH_PROFILE_BLOCK,
  MIN_WIDTH_PROFILE_BLOCK,
} from "../../../../utils/constants";
import { CheckFieldForm } from "../../../../components/check-field-form";

type ProfileButtonsProps = {
  isSubmitDisabled: boolean;
  handleReset: (e: React.SyntheticEvent) => void;
};

export const ProfileButtons: FC<ProfileButtonsProps> = ({
  isSubmitDisabled,
  handleReset,
}) => {
  const { t } = useTranslation("profile");

  return (
    <Stack
      direction="row"
      flexWrap="wrap"
      justifyContent="center"
      px={7}
      pb={7}
    >
      <Stack
        px={7.5}
        maxWidth={MAX_WIDTH_PROFILE_BLOCK}
        minWidth={MIN_WIDTH_PROFILE_BLOCK}
        flex={1}
        mt={2}
      >
        <Stack my={3}>
          <CheckFieldForm
            name="all"
            label={
              <Typography ml={2} variant="body2" color="secondary">
                {t("changeDataAll")}
              </Typography>
            }
            color="secondary"
          />
        </Stack>
        <Stack flex={1} direction="row">
          <Button
            fullWidth
            variant="contained"
            type="submit"
            color="success"
            disabled={isSubmitDisabled}
          >
            {t("common:buttons.save")}
          </Button>
          <Button
            sx={{ ml: 4 }}
            fullWidth
            variant="contained"
            onClick={handleReset}
          >
            {t("common:buttons.cancel")}
          </Button>
        </Stack>
      </Stack>
      <Stack
        px={7.5}
        maxWidth={MAX_WIDTH_PROFILE_BLOCK}
        minWidth={MIN_WIDTH_PROFILE_BLOCK}
        flex={1}
      ></Stack>
    </Stack>
  );
};
