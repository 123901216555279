import { AssignmentCommentTooltipProps } from "./assignment-comment-tooltip.types";
import {
  StyledBody,
  StyledHeader,
  StyledMessage,
  StyledFooter,
} from "./assignment-comment-tooltip.styles";
import { FC } from "react";
import { CustomTooltip } from "../custom-tooltip";
import MessageIcon from "@mui/icons-material/Message";
import { useTranslation } from "react-i18next";
import { formatDate, getPersonInitials } from "../../utils/commonUtils";

export const AssignmentCommentTooltip: FC<AssignmentCommentTooltipProps> = ({
  open,
  onClose,
  children,
  comment,
  commentDate,
  commentAuthor,
}) => {
  const { t } = useTranslation("assignments");

  const author = commentAuthor
    ? `${commentAuthor.lastName} ${getPersonInitials({
        firstName: commentAuthor.firstName,
        middleName: commentAuthor.middleName,
        divider: ".",
      })}`
    : "";

  const date = commentDate ? formatDate(commentDate) : "";

  return (
    <CustomTooltip
      componentsProps={{
        popper: {
          sx: {
            "& .MuiTooltip-tooltip": {
              margin: "0 0 10px 0!important",
              width: "300px",
              background: "#F6F7FB",
              borderRadius: "8px",
            },

            "& .MuiTooltip-arrow": {
              color: "#F6F7FB",
            },
          },
        },
      }}
      placement="bottom-end"
      isOpen={open}
      onClose={onClose}
      title={
        <StyledBody>
          <StyledHeader>
            <MessageIcon className="icon" />
            {t("revisionReason")}
          </StyledHeader>

          <StyledMessage>{comment || ""}</StyledMessage>

          <StyledFooter>
            <div>{author}</div>
            <div>{date}</div>
          </StyledFooter>
        </StyledBody>
      }
    >
      {children}
    </CustomTooltip>
  );
};
