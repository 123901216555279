import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Button, IconButton, Paper, Stack, Typography } from "@mui/material";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import { Profile } from "../../api/profile";
import { Company } from "../../api/companies";

type CoincidenceDialogProps = {
  onCancel: () => void;
  onSuccess: () => void;
  currentCompanyName: Company["name"];
  invitationFields: Profile["mainProfile"]["invitationFields"] | null;
};

export const CoincidenceDialog: FC<CoincidenceDialogProps> = ({
  invitationFields,
  onCancel,
  onSuccess,
  currentCompanyName,
}) => {
  const { t } = useTranslation("coincidence");

  const invitationFieldsString = useMemo(() => {
    return invitationFields?.map((field) => t(`${field}`)).join(", ");
  }, [invitationFields, t]);

  return (
    <Paper sx={{ p: 4, width: 400 }}>
      <Stack alignItems="center">
        <Typography mb={2} color="primary" variant="h4" textAlign={"center"}>
          {t("title")}
        </Typography>
        <Typography maxWidth={340} variant="body2" px={1} textAlign="center">
          {t("description", { company: currentCompanyName })}
        </Typography>
        <Stack direction="row">
          <Typography px={2} variant="h4" textAlign="center">
            {invitationFieldsString}
          </Typography>
        </Stack>
        <Typography
          mt={2}
          maxWidth={340}
          px={2}
          variant="body2"
          textAlign="center"
        >
          {t("descriptionEnd")}
        </Typography>
      </Stack>
      <Stack mt={4} direction="row">
        <Button
          onClick={onSuccess}
          fullWidth
          variant="contained"
          color="success"
        >
          {t("common:buttons.yes")}
        </Button>

        <Button
          onClick={onCancel}
          sx={{ ml: 1, py: 1.5 }}
          fullWidth
          variant="contained"
        >
          {t("common:buttons.no")}
        </Button>
      </Stack>
      <IconButton
        onClick={onCancel}
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
        }}
      >
        <CloseSharpIcon fontSize="small" />
      </IconButton>
    </Paper>
  );
};
