import styled from "styled-components";

export const StyledPage = styled.div`
  padding: 10px 20px;
  width: 100%;
  height: 100%;
`;

export const StyledBody = styled.div`
  height: calc(100% - 70px);
`;

export const StyledCards = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(345px, 1fr));
  grid-gap: 20px;
  padding-bottom: 10px;
`;
