import styled from "styled-components";

export const StyledHeader = styled.div`
  min-height: 42px;
  display: grid;
  grid-template-columns: 320px 1fr;
  justify-items: start;
  align-items: center;
  margin-bottom: 20px;
`;
