import React, { FC, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { useMatch, Link, Outlet } from "react-router-dom";
import { Box, Paper, Stack, Tab, Tabs, Typography } from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import PersonIcon from "@mui/icons-material/Person";
import background from "./background.png";
import { LogoShow } from "../../components/logotype";

type AuthWrapperProps = {
  isShowTabs?: boolean;
  title?: ReactNode;
};

export const AuthLayout: FC<AuthWrapperProps> = ({ title, isShowTabs }) => {
  const { t } = useTranslation("auth");
  const match = useMatch("/auth/:tab");
  const tab = match?.params?.tab || "phone";

  return (
    <>
      <Box m="64px 5%" position="absolute">
        <LogoShow />
      </Box>
      <Stack
        sx={{
          backgroundImage: `url(${background})`,
          backgroundPosition: "8% 51%",
          backgroundRepeat: "no-repeat",
          backgroundSize: "52%",
        }}
        flex={1}
        justifyContent="center"
      >
        <Stack
          flex={1}
          flexDirection="row"
          justifyContent="flex-end"
          maxHeight={670}
          p="0 11%"
        >
          <Paper
            elevation={24}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              borderRadius: "120px 10px",
              p: 7,
              width: 537,
            }}
          >
            <Typography mb={3.5} sx={{ textAlign: "center" }} variant="h6">
              {title}
            </Typography>

            {isShowTabs && (
              <Tabs value={tab} textColor="primary" indicatorColor="primary">
                <Tab
                  value="phone"
                  icon={<PhoneIcon fontSize="small" />}
                  iconPosition="start"
                  label={
                    <Typography variant="caption">
                      {t("tabs.byPhoneNumber")}
                    </Typography>
                  }
                  component={Link}
                  to={"phone"}
                />
                <Tab
                  value="email"
                  sx={{ ml: 2 }}
                  icon={<PersonIcon fontSize="small" />}
                  iconPosition="start"
                  label={
                    <Typography variant="caption">
                      {t("tabs.byLoginAndPassword")}
                    </Typography>
                  }
                  component={Link}
                  to={"email"}
                />
              </Tabs>
            )}
            <Box mt={2.75} p={"0 16px"} component="div" sx={{ width: "100%" }}>
              <Outlet />
            </Box>
          </Paper>
        </Stack>
      </Stack>
    </>
  );
};
