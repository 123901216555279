import styled from "styled-components";

export const StyledProjectTabs = styled.div`
  display: flex;
  justify-content: center;

  .MuiTabs-flexContainer {
    column-gap: 23px;
  }

  .MuiTabs-scroller {
    //border-bottom: 1px solid ${({ theme }) => theme.legends.gray};
  }

  .MuiButtonBase-root {
    min-width: 20px;
    padding: 0 8px;
    border-bottom: 0;

    &.Mui-selected .MuiTypography-root {
      color: ${({ theme }) => theme.primary.mainBlue};
    }

    .MuiTypography-root {
      font-size: 13px;
    }
  }

  .MuiTabs-indicator {
    background: ${({ theme }) => theme.primary.mainBlue};
  }

  .icon {
    width: 20px;
    margin-right: 10px;
    fill: ${({ theme }) => theme.primary.disabled};

    &.icon-missed-data {
      fill: ${({ theme }) => theme.primary.warningLight};
    }
  }
`;
