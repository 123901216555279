import { StatusesChartProps } from "./statuses-chart.types";
import { FC, useCallback, useMemo } from "react";
import { Doughnut } from "react-chartjs-2";
import "chart.js/auto";
import { fromStatusToKey } from "./statuses-chart.service";
import {
  StyledStatusesChart,
  StyledDoughnutWrapper,
  StyledInfo,
  StyledTotalCount,
  StyledRow,
  StyledStatus,
  StyledAssignmentsCount,
  StyledFailedCount,
} from "./statuses-chart.styles";
import { ScriptableContext, TooltipItem } from "chart.js";
import { useTranslation } from "react-i18next";

export const StatusesChart: FC<StatusesChartProps> = ({ divisions, highPriority }) => {
  const { t } = useTranslation("assignments");

  const total = divisions.length;

  const labels = [
    [" Направлено", "В работе", "Доработка", "Приемка", "Исполнено"],
    ["", ""],
  ];

  const { sent, inProgress, finishing, acceptance, completed, failed } =
    useMemo(
      () =>
        divisions.reduce(
          (accumulator, current) => {
            const key = fromStatusToKey(current.status);

            return {
              ...accumulator,
              [key]: {
                count: accumulator[key].count + 1,
                failed: current.failed
                  ? accumulator[key].failed + 1
                  : accumulator[key].failed,
              },
              failed:
                current.failed && key !== "completed"
                  ? accumulator.failed + 1
                  : accumulator.failed,
            };
          },
          {
            sent: { count: 0, failed: 0 },
            inProgress: { count: 0, failed: 0 },
            finishing: { count: 0, failed: 0 },
            acceptance: { count: 0, failed: 0 },
            completed: { count: 0, failed: 0 },
            failed: 0,
          }
        ),
      [divisions]
    );

  const sentPercent = Math.round((sent.count / total) * 100);
  const inProgressPercent = Math.round((inProgress.count / total) * 100);
  const finishingPercent = Math.round((finishing.count / total) * 100);
  const acceptancePercent = Math.round((acceptance.count / total) * 100);
  const completedPercent = Math.round((completed.count / total) * 100);
  const failedPercent = Math.round((failed / total) * 100);

  const getSegmentBackground = useCallback(
    (ctx: ScriptableContext<"doughnut">) => {
      const index = ctx.dataIndex;

      return index === 0 ? "#FCA5A5" : "#F6F7FB";
    },
    []
  );

  const infoBlockData = [
    {
      label: t("statuses.sent"),
      color: "#C4B5FD",
      ...sent,
    },
    {
      label: t("statuses.inProgress"),
      color: "#93C5FD",
      ...inProgress,
    },
    {
      label: t("statuses.finishing"),
      color: "#F9A8D4",
      ...finishing,
    },
    {
      label: t("statuses.acceptance"),
      color: "#FDBA74",
      ...acceptance,
    },
    {
      label: t("statuses.completed"),
      color: "#14B8A6",
      ...completed,
    },
  ];

  return (
    <StyledStatusesChart>
      <StyledDoughnutWrapper>
        <Doughnut
          width="100%"
          height="100%"
          data={{
            datasets: [
              {
                data: [
                  sentPercent,
                  inProgressPercent,
                  finishingPercent,
                  acceptancePercent,
                  completedPercent,
                ],
                backgroundColor: [
                  "#C4B5FD",
                  "#93C5FD",
                  "#F9A8D4",
                  "#FDBA74",
                  "#14B8A6",
                ],
              },
              {
                data: [failedPercent, 100 - failedPercent],
                backgroundColor: ["#FCA5A5", "#F6F7FB"],
                borderColor: "#F6F7FB",
                hoverBackgroundColor: getSegmentBackground,
                hoverBorderColor: getSegmentBackground,
              },
            ],
          }}
          options={{
            maintainAspectRatio: true,
            responsive: true,
            cutout: "40%",
            animation: false,
            plugins: {
              tooltip: {
                filter: (item: TooltipItem<"doughnut">) => {
                  return labels[item.datasetIndex][item.dataIndex] !== "";
                },
                callbacks: {
                  label(item: TooltipItem<"doughnut">) {
                    return labels[item.datasetIndex][item.dataIndex];
                  },
                },
                displayColors: false,
              },
            },
          }}
        />

        <StyledTotalCount>{total}</StyledTotalCount>
      </StyledDoughnutWrapper>

      <StyledInfo>
        {infoBlockData.map((data, index) => (
          <StyledRow key={index}>
            <StyledStatus $color={data.color}>{data.label}</StyledStatus>
            <StyledAssignmentsCount>{data.count}</StyledAssignmentsCount>
            <StyledFailedCount $isVisible={data.label !== "Исполнено"} $highPriority={highPriority}>
              {data.failed || "–"}
            </StyledFailedCount>
          </StyledRow>
        ))}
      </StyledInfo>
    </StyledStatusesChart>
  );
};
