/*import EditIcon from "@mui/icons-material/Edit";*/
/*import DeleteIcon from "@mui/icons-material/Delete";*/
import VerifiedIcon from "@mui/icons-material/Verified";
import { ReactComponent as DirectorIcon } from "../../../icons/imported-icons/director.svg";

export const divisionListData = [
  /*{
    title: "Редактировать название",
    value: "editName",
    Icon: EditIcon,
  },*/
  /*{
    title: "Удалить подразделение",
    value: "delete",
    Icon: DeleteIcon,
  },*/
  {
    title: "Назначить головным подразделением",
    value: "head",
    Icon: VerifiedIcon,
  },
];

export const userListData = [
  /*{
    title: "Редактировать название",
    value: "editName",
    Icon: EditIcon,
  },*/
  /*{
    title: "Удалить подразделение",
    value: "delete",
    Icon: DeleteIcon,
  },*/
  {
    title: "Назначить руководителем",
    value: "head",
    Icon: DirectorIcon,
  },
];
