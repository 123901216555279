import React, { FC, ReactNode, useCallback } from "react";
import { useField } from "formik";
import { FieldHookConfig } from "formik/dist/Field";
import {Checkbox, FormControlLabel, FormControlLabelProps, TextFieldProps} from "@mui/material";

export type CheckFieldFormProps = {
  label: ReactNode;
  value?: boolean;
  name: string;
  color: string;
  labelPlacement?: FormControlLabelProps['labelPlacement']
  fieldProps?: Partial<FieldHookConfig<string>>;
} & Omit<TextFieldProps, "name">;

export const CheckFieldForm: FC<CheckFieldFormProps> = ({
  label,
  value,
  color,
  fieldProps,
  onBlur,
  labelPlacement,
  ...props
}) => {
  const [{ onBlur: onFieldBlur, ...field }] = useField({
    name: props.name,
    ...fieldProps,
  });

  const handleBlur = useCallback(
    (e) => {
      onFieldBlur?.(e);
      onBlur?.(e);
    },
    [onBlur, onFieldBlur]
  );

  return (
    <FormControlLabel
      {...field}
      onBlur={handleBlur}
      {...(props as any)}
      control={<Checkbox checked={!!field.value} color={color} />}
      label={label as string}
      labelPlacement={labelPlacement}
    />
  );
};
