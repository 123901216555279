import * as yup from "yup";
import { MAX_PASSWORD_LENGTH_VALIDATION } from "../../../utils/constants";

export const validationByEmail = yup.object({
  emailOrLogin: yup.string().trim().required("common:errors.required"),
  password: yup
    .string()
    .trim()
    .min(MAX_PASSWORD_LENGTH_VALIDATION, "common:errors.min")
    .required("common:errors.required"),
});
