import styled, {css} from "styled-components";
import { IconButton } from "@mui/material";
import { PendingDateStatus } from "../../../../api/assignments/types";
import { limitTextRows } from "../../../../common/styledMixins";

interface BlockProps {
  $hasComment: boolean;
}

export const StyledBlock = styled.div<BlockProps>`
  padding: 20px;
  border-radius: 16px;
  display: grid;
  grid-row-gap: 12px;
  ${({ $hasComment }) =>
    $hasComment &&
    css`
      grid-template-rows: 36px 24px 1fr 36px;
    `};

  grid-auto-rows: 36px;
  background: ${({ theme }) => theme.primary.blueShades};

  .MuiInputBase-root {
    min-width: 206px;
    min-height: 36px;
  }

  .date-picker {
    .MuiFormControl-root,
    .MuiInputBase-root {
      min-width: 118px;
    }

    .MuiInputBase-input {
      width: 92px;
      padding: 8px 16px;
      padding-left: 10px;
    }

    .MuiSvgIcon-root {
      width: 20px;
      fill: ${({ theme }) => theme.primary.light};
    }
  }
`;

export const StyledDateWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
`;

interface ButtonProposeDateProps {
  $pendingStatus?: PendingDateStatus;
}

export const StyledButtonProposeDate = styled(
  IconButton
)<ButtonProposeDateProps>`
  width: 36px;
  height: 36px;
  border-radius: 6px;
  background: ${({ theme }) => `${theme.background.blue}14`};

  .icon {
    width: 20px;
    height: 20px;
  }

  .icon path {
    fill: ${({ theme }) => theme.primary.mainBlue};
  }

  &:disabled {
    pointer-events: auto;
    background: ${({ theme, $pendingStatus }) => {
      switch ($pendingStatus) {
        case "awaiting":
          return `${theme.primary.warningMain}1A`;
        case "declined":
          return `${theme.charts.red}26`;
        case "approved":
          return `${theme.legends.lightGreen}26`;

        default:
          return theme.background.gray;
      }
    }};

    .icon path {
      fill: ${({ theme, $pendingStatus }) => {
        switch ($pendingStatus) {
          case "approved":
            return theme.legends.green;
          case "declined":
            return theme.secondary.error;
          case "awaiting":
            return theme.primary.warningLight;
          default:
            return theme.primary.disabled;
        }
      }}
`;

export const StyledComment = styled.p`
  margin: 0;
  font-size: 14px;
  line-height: 21px;
  ${limitTextRows(4)};
  max-width: 400px;
  word-wrap: break-word;
`;

export const StyledCommentFooter = styled.div`
  margin-top: 8px;
  padding-bottom: 12px;
  border-bottom: 1px solid ${({ theme }) => theme.secondary.backgroundDivider};
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme }) => theme.secondary.textAdditional};
`;
