import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../store";
import { api } from "../../../api/api";
import { AuthEndpoints, LoginResponse } from "../../../api/auth";
import { CompanyName, ModuleType } from '../../../api/companies'

let initialState: Partial<LoginResponse> & {
  sourceModule?: ModuleType,
  sourceCompany?: CompanyName,
};

try {
  const loginData: Partial<LoginResponse> = JSON.parse(localStorage.getItem("auth") || "{}")
  initialState = {
    ...loginData
  }
} catch (error) {
  initialState = {};
}

const updateState = (
  prevState: Partial<LoginResponse>,
  payload: Partial<LoginResponse>
) => {
  const { refreshToken, token, refreshDurationMs, tokenDurationMs } = payload;
  prevState.token = token;
  prevState.tokenDurationMs = tokenDurationMs;
  prevState.refreshToken = refreshToken;
  prevState.refreshDurationMs = refreshDurationMs;
  localStorage.setItem("auth", JSON.stringify(payload));
};

const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setCredentials: (state, { payload }) => {
      updateState(state, payload);
    },
    setSourceModule: (state, { payload }) => {
      state.sourceModule = payload
    },
    setSourceCompany: (state, { payload }) => {
      state.sourceCompany = payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (props) =>
          !!(api.endpoints as AuthEndpoints).loginByPhone?.matchFulfilled(
            props
          ) ||
          !!(api.endpoints as AuthEndpoints).loginByEmailCode?.matchFulfilled(
            props
          ),
        (state, { payload }) => {
          if (payload?.success && payload?.auth?.token) {
            updateState(state, payload?.auth);
          }
        }
      )
      .addMatcher(
        (props) =>
          !!(api.endpoints as AuthEndpoints).loginByEmail?.matchFulfilled(
            props
          ),
        (state, { payload }) => {
          if (payload?.token) {
            updateState(state, payload);
          }
        }
      );
  },
});

export default slice.reducer;

export const { setCredentials, setSourceModule, setSourceCompany } = slice.actions;
export const isAuthSelector = (state: RootState) => !!state.auth.token;
export const sourceModuleSelector = (state: RootState) => state.auth.sourceModule;
export const sourceCompanySelector = (state: RootState) => state.auth.sourceCompany;
