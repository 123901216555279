import { FC } from "react";
import { DeleteModalProps } from "./delete-modal.types";
import { Button, Modal } from "@mui/material";
import {
  StyledModal,
  StyledButtonClose,
  StyledTitle,
  StyledDescription,
  StyledButtons,
} from "./delete-modal.styles";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";

export const DeleteModal: FC<DeleteModalProps> = ({
  open,
  title,
  description,
  leftButtonColor = "error",
  rightButtonColor = "success",
  leftButtonText,
  rightButtonText,
  titleColor,
  disabledButtons = [],
  onClose,
  onConfirm,
  onButtonCloseClick,
}) => {
  const { t } = useTranslation("common");

  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{ display: "flex" }}
      onClick={(event) => event.stopPropagation()}
    >
      <StyledModal>
        <StyledTitle $titleColor={titleColor}>{title}</StyledTitle>
        <StyledDescription>{description}</StyledDescription>
        <StyledButtons>
          <Button
            variant="contained"
            color={leftButtonColor}
            onClick={onConfirm}
            disabled={disabledButtons.includes("left")}
          >
            {leftButtonText || t("buttons.yes")}
          </Button>
          <Button
            variant="contained"
            color={rightButtonColor}
            onClick={onClose}
            disabled={disabledButtons.includes("right")}
          >
            {rightButtonText || t("buttons.cancel")}
          </Button>
        </StyledButtons>

        <StyledButtonClose onClick={onButtonCloseClick || onClose}>
          <CloseIcon className="icon" />
        </StyledButtonClose>
      </StyledModal>
    </Modal>
  );
};
