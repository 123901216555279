import React, { FC, useState, MouseEvent, useCallback } from "react";
import { FilesBlockProps, Menu } from "./files-block.types";
import { StyledBlock, StyledStab } from "./files-block.styles";
import {
  StyledColumn,
  StyledLabel,
  StyledText,
} from "../../../../common/assignmentsCommonStyles";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { useTranslation } from "react-i18next";
import {
  StyledToggleButton,
  StyledToggleButtonGroup,
} from "../../../../common/commonStyles";
import { AssignmentFilesList } from "../../../../components/assignment-files-list";
import { AssignmentsFileAttachList } from "../../../../components/assignments-file-attach-list";
import { AssignmentsFile } from "../../../../api/assignments/types";

export const FilesBlock: FC<FilesBlockProps> = ({
  commonFiles,
  divisionFiles,
  isUserAllowEdit,
  serverDivisionFiles,
  onDivisionFilesChange,
}) => {
  const { t } = useTranslation("assignments");

  const [menu, setMenu] = useState<Menu>("common");

  const handleButtonGroupChange = useCallback((event: MouseEvent) => {
    const target = event.target as HTMLInputElement;
    setMenu(target.value as Menu);
  }, []);

  const handleFileDelete = useCallback(
    (_: AssignmentsFile | File, id: string | number) => {
      const newFiles = divisionFiles.filter((value) => value.id !== id);
      onDivisionFilesChange(newFiles);
    },
    [divisionFiles]
  );

  const divisionTab = isUserAllowEdit ? (
    <AssignmentsFileAttachList
      files={divisionFiles}
      onChange={onDivisionFilesChange}
      onDelete={handleFileDelete}
      hasFileExtension
    />
  ) : (
    <AssignmentFilesList files={serverDivisionFiles} />
  );

  return (
    <StyledBlock>
      <StyledColumn>
        <StyledLabel>
          <AttachFileIcon className="icon" />
          <StyledText $colorTheme="secondary">{`${t(
            "titles.files"
          )}:`}</StyledText>
        </StyledLabel>

        <StyledToggleButtonGroup
          value={menu}
          onChange={handleButtonGroupChange}
        >
          <StyledToggleButton $separatorPosition="right" value="common">
            {t("common")}
          </StyledToggleButton>
          <StyledToggleButton $separatorPosition="left" value="division">
            {t("division")}
          </StyledToggleButton>
        </StyledToggleButtonGroup>
      </StyledColumn>

      {menu === "common" && !commonFiles.length && (
        <StyledStab>{t("noCommonFiles")}</StyledStab>
      )}
      {menu === "division" && !divisionFiles.length && (
        <StyledStab>{t("noDivisionFiles")}</StyledStab>
      )}

      {menu === "common" ? (
        <AssignmentFilesList files={commonFiles} />
      ) : (
        divisionTab
      )}
    </StyledBlock>
  );
};
