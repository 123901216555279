import React, { FC, useCallback } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Stack, Typography } from "@mui/material";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { SendCodeToEmailRequest } from "../../../../api/auth";

type SuccessProps = {
  resend: (email: any) => void;
  email: SendCodeToEmailRequest["email"];
};

export const Success: FC<SuccessProps> = ({ email, resend }) => {
  const { t } = useTranslation("recovery");

  const handleClick = useCallback(() => {
    resend({ email });
  }, [resend, email]);

  return (
    <Stack>
      <Typography px={1} variant="body2" textAlign="center">
        {t("status.sendEmail", { email: email })}
      </Typography>
      <Typography mt={3} px={2} variant="body2" textAlign="center">
        {t("status.description")}
      </Typography>

      <Button
        sx={{ mt: 4, p: 1.5 }}
        startIcon={<AutorenewIcon />}
        onClick={handleClick}
      >
        {t("sendNewEmail")}
      </Button>

      <Button
        component={Link}
        to="/auth/email"
        sx={{ mt: 4, p: 1.5 }}
        variant="contained"
      >
        {t("common:buttons.good")}
      </Button>
    </Stack>
  );
};
