import {FinancingType, ProjectStatus, ProjectType} from "../../api/companies";

export const enProjectFinancingTypes: { [key in FinancingType]: string } = {
  investment: "Инвестиционный",
  contracting: "Подрядный",
  mixed: "Смешанный",
};

export const enProjectStatuses: { [key in ProjectStatus]: string } = {
  potential: "Потенциальный",
  active: "Активный",
  delayed: "Отложен",
  completed: "Завершен",
};

export const enProjectTypes: { [key in ProjectType]: string } = {
  industrial: "Производственного назначения",
  nonindustrial: "Непроизводственного назначения",
  linear: "Линейные объекты",
};