import {decode} from "js-base64";

export class Filters {
  authorID: number[] = [];
  executorID: number[] = [];
  group: string[] = [];
  supervisorID: number[] = [];
  status: string[] = [];

  constructor(searchParams: URLSearchParams) {
    const authorIDParam = searchParams.get("authorID");
    const executorIDParam = searchParams.get("executorID");
    const groupParam = searchParams.get("group");
    const supervisorIDParam = searchParams.get("supervisorID");
    const statusParam = searchParams.get("status");

    if (authorIDParam)
      this.authorID = decode(authorIDParam).split(",").map(Number);
    if (executorIDParam)
      this.executorID = decode(executorIDParam).split(",").map(Number);
    if (groupParam) this.group = decode(groupParam).split(",");
    if (supervisorIDParam)
      this.supervisorID = decode(supervisorIDParam).split(",").map(Number);
    if (statusParam) this.status = decode(statusParam).split(",");
    if (statusParam) this.status = decode(statusParam).split(",");
  }
}