import styled from "styled-components";
import { Select } from "@mui/material";

export const StyledLabel = styled.label`
  .MuiTypography-root {
    font-size: 14px;
    color: ${({ theme }) => theme.secondary.textAdditional};
  }
`;

export const StyledSelect = styled(Select)`
  width: 100%;

  .MuiFilledInput-input {
    padding: 8px 16px;

    &.Mui-disabled {
      border-radius: 8px;
    }
  }

  .dropdown-icon {
    fill: ${({ theme }) => theme.secondary.textAdditional};
    width: 20px;
    margin-right: 8px;
  }
`;

export const StyledPlaceholder = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #2b364861;
`;
