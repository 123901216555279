import { FC, useState } from "react";
import { ICellRendererParams } from "ag-grid-community";
import { FilesCellProps } from "./files-cell.types";
import { StyledCell, StyledIconButton } from "./files-cell.styles";
import { ReactComponent as DownloadIcon } from "../../../../../../components/icons/imported-icons/download.svg";
import { DownloadAssignmentFilesTooltip } from "../../../../../../components/download-assignment-files-tooltip";

export const FilesCell: FC<ICellRendererParams<unknown, FilesCellProps>> = ({
  value,
}) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  if (!value?.files?.length) return null;

  return (
    <DownloadAssignmentFilesTooltip
      files={value.files}
      isOpen={isTooltipOpen}
      onClose={() => setIsTooltipOpen(!isTooltipOpen)}
    >
      <StyledCell>
        <StyledIconButton onClick={() => setIsTooltipOpen(!isTooltipOpen)}>
          <DownloadIcon />
        </StyledIconButton>
      </StyledCell>
    </DownloadAssignmentFilesTooltip>
  );
};
