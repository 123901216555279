export const NUMBER_CELL_WIDTH = 50;

export const NAME_CELL_WIDTH = 248;

export const RESPONSIBLE_CELL_WIDTH = 200;

export const EXECUTORS_CELL_WIDTH = 286;

export const STATUS_CELL_WIDTH = 184;

export const PENDING_DATE_CELL_WIDTH = 174;

export const FILES_CELL_WIDTH = 44;

export const commonCellClasses =
  "ag-cell_flex ag-cell_align-items_center ag-cell_justify-content_center";