import styled from "styled-components";
import { Button } from "@mui/material";
import {
  limitTextRows,
  textOneRow,
} from "../../../../../../../../common/styledMixins";

interface CellProps {
  $isGroup?: boolean;
}

export const StyledCell = styled.div<CellProps>`
  width: 100%;
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: space-between;
  ${({ $isGroup }) => !$isGroup && limitTextRows(2)};
`;

export const StyledGroupWrapper = styled.div`
  display: grid;
  grid-template-columns: max-content max-content 1fr;
  align-items: center;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  color: ${({ theme }) => theme.primary.mainBlue};
  width: calc(100% - 110px);

  .group-icon {
    margin-right: 8px;
  }
`;

export const StyledGroupName = styled.div`
  padding-left: 4px;
  ${textOneRow};

  &:first-letter {
    text-transform: uppercase;
  }
`;

export const StyledButtonHide = styled(Button)`
  font-size: 13px;
  line-height: 15px;
  font-weight: 500;
  color: ${({ theme }) => theme.primary.mainBlue};
`;
