import i18n from "i18next";
import ru from "./ru.json";
import { formatMaskPhone } from "../components/phone-field-form/utils";

i18n.init({
  interpolation: { escapeValue: false },
  lng: "ru",
  resources: {
    ru,
  },
});

i18n.services.formatter?.add("phoneFormatter", (value) =>
  formatMaskPhone(value)
);

export { i18n };
