import * as yup from "yup";
import "yup-phone-lite";
import { useTranslation } from "react-i18next";
import { useCallback } from "react";
import { CODE_LENGTH } from "../../utils/constants";
import { formatValidationCode } from "./utils";

const validationCodeSchema = yup.string().min(CODE_LENGTH);

export const useValidateCode = () => {
  const { t } = useTranslation();

  return useCallback(
    (value) => {
      return validationCodeSchema.isValidSync(formatValidationCode(value))
        ? ""
        : t("common:errors.minCode");
    },
    [t]
  );
};
