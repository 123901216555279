import { Button, Stack } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { AccessButtonsProps } from "./access-buttons.types";
import { FC } from "react";

export const AccessButtons: FC<AccessButtonsProps> = ({ isDisabled, handleReset }) => {
  const { t } = useTranslation("user");

  return (
    <Stack mt={4} direction="row" spacing={4}>
      <Button
        fullWidth
        variant="contained"
        type="submit"
        color="success"
        disabled={isDisabled}
      >
        {t("common:buttons.save")}
      </Button>
      <Button fullWidth variant="contained" onClick={handleReset}>
        {t("common:buttons.cancel")}
      </Button>
    </Stack>
  );
};
