import {
  FC,
  useEffect,
  useRef,
  useState,
  MouseEvent,
  useCallback,
} from "react";
import { AssignmentsCardProps } from "./assignments-card.types";
import {
  StyledCard,
  StyledHeader,
  StyledButtonsWrapper,
  StyledIconButton,
  StyledSeparator,
  StyledTextWrapper,
} from "./assignments-card.styles";
import EditIcon from "@mui/icons-material/Edit";
import { useTranslation } from "react-i18next";
import DateRangeIcon from "@mui/icons-material/DateRange";
import {
  formatDate,
  getPersonInitials,
} from "../../../../../../utils/commonUtils";
import EventNoteIcon from "@mui/icons-material/EventNote";
import { ReactComponent as AuthorIcon } from "../../../../../../components/icons/imported-icons/author.svg";
import { ReactComponent as DirectorIcon } from "../../../../../../components/icons/imported-icons/director.svg";
import { useNavigate, useLocation } from "react-router-dom";
import { StatusesChart } from "../statuses-chart";
import {
  StyledLabel,
  StyledRow,
  StyledColumn,
  StyledText,
} from "../../../../../../common/assignmentsCommonStyles";
import { ReactComponent as DownloadIcon } from "../../../../../../components/icons/imported-icons/download.svg";
import { Tooltip } from "@mui/material";
import { AssignmentFailureDays } from "../../../../../../components/assignment-failure-days/assignment-failure-days";
import { DownloadAssignmentFilesTooltip } from "../../../../../../components/download-assignment-files-tooltip";

const TEXT_OFFSET = 120;

export const AssignmentsCard: FC<AssignmentsCardProps> = ({ data }) => {
  const { t } = useTranslation("assignments");
  const navigate = useNavigate();
  const location = useLocation();
  const cardRef = useRef<HTMLDivElement>(null);

  const [cardWidth, setCardWidth] = useState(0);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const author = data.author
    ? `${data.author.lastName} ${getPersonInitials({
        firstName: data.author.firstName,
        middleName: data.author.middleName,
        divider: ".",
      })}`
    : "–";
  const supervisor = data.supervisor
    ? `${data.supervisor.lastName} ${getPersonInitials({
        firstName: data.supervisor.firstName,
        middleName: data.supervisor.middleName,
        divider: ".",
      })}`
    : "–";

  useEffect(() => {
    if (cardRef.current) {
      setCardWidth(cardRef.current.getBoundingClientRect().width);
    }
  }, [cardRef.current]);

  const handleButtonEditClick = (event: MouseEvent) => {
    event.stopPropagation();

    navigate(`${location.pathname}/${data.id}`);
  };

  const handleCardClick = () => {
    if (isTooltipOpen) return;

    navigate(`${location.pathname}/${data.id}/co-view`);
  };

  const handleButtonDownloadClick = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation();

      setIsTooltipOpen(!isTooltipOpen);
    },
    [isTooltipOpen]
  );

  return (
    <StyledCard
      ref={cardRef}
      onClick={handleCardClick}
      $highPriority={data.highPriority}
    >
      <StyledHeader>
        <Tooltip title={data.text}>
          <StyledText
            $maxRows={2}
            $maxWidth={cardWidth ? cardWidth - TEXT_OFFSET : 0}
          >
            {data.text}
          </StyledText>
        </Tooltip>
        <StyledButtonsWrapper>
          {!!data.files.length && (
            <DownloadAssignmentFilesTooltip
              files={data.files}
              isOpen={isTooltipOpen}
              onClose={() => setIsTooltipOpen(!isTooltipOpen)}
            >
              <StyledIconButton
                onClick={handleButtonDownloadClick}
                $iconSize={20}
              >
                <DownloadIcon className="icon" />
              </StyledIconButton>
            </DownloadAssignmentFilesTooltip>
          )}

          {data.canEdit && (
            <StyledIconButton $hasBorder onClick={handleButtonEditClick}>
              <EditIcon className="icon" />
            </StyledIconButton>
          )}
        </StyledButtonsWrapper>
      </StyledHeader>

      <StyledRow>
        <StyledText $colorTheme="secondary">{`${t(
          "assignmentGroup"
        )}:`}</StyledText>
        <StyledText>{data.group ?? "–"}</StyledText>
      </StyledRow>

      <StyledSeparator />

      <StyledColumn>
        <StyledLabel>
          <DateRangeIcon className="icon" />
          <StyledText $colorTheme="secondary">{`${t(
            "departureDate"
          )}:`}</StyledText>
        </StyledLabel>
        <StyledText $isBold>
          {data.createdAt ? formatDate(data.createdAt) : "–"}
        </StyledText>
      </StyledColumn>

      <StyledColumn>
        <StyledLabel>
          <EventNoteIcon className="icon" />
          <StyledText $colorTheme="secondary">{`${t(
            "executionPeriod"
          )}:`}</StyledText>
        </StyledLabel>
        <StyledTextWrapper>
          <StyledText $isBold>
            {data.upToDate ? formatDate(data.upToDate) : "–"}
          </StyledText>
          <AssignmentFailureDays
            days={data.failureDays}
            colorTheme={data.highPriority ? "secondary" : "primary"}
          />
        </StyledTextWrapper>
      </StyledColumn>

      <StyledSeparator />

      <StyledColumn>
        <StyledLabel>
          <AuthorIcon className="icon" />
          <StyledText $colorTheme="secondary">{`${t("author")}:`}</StyledText>
        </StyledLabel>
        <StyledText $isBold>{author}</StyledText>
      </StyledColumn>

      <StyledColumn>
        <StyledLabel>
          <DirectorIcon className="icon" />
          <StyledText $colorTheme="secondary">{`${t(
            "supervisor.label"
          )}:`}</StyledText>
        </StyledLabel>
        <StyledText $isBold>{supervisor}</StyledText>
      </StyledColumn>

      <StyledSeparator />

      <StatusesChart
        divisions={data.divisions}
        highPriority={data.highPriority}
      />
    </StyledCard>
  );
};
