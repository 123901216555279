import styled, { css } from "styled-components";
import { Avatar, IconButton } from "@mui/material";
import { textOneRow } from "../../../../common/styledMixins";

interface UserProps {
  $isInteractive: boolean;
}

export const StyledUser = styled.div<UserProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 50px;
  padding: 8px;

  &:hover {
    ${({ $isInteractive }) =>
      $isInteractive &&
      css`
        cursor: pointer;
        background: #2b36480a;
        border-radius: 8px;
      `};
  }
`;

export const StyledUserInfo = styled.div`
  display: grid;
  grid-template-columns: 32px auto;
  grid-column-gap: 12px;
  align-items: center;
`;

export const StyledAvatar = styled(Avatar)`
  grid-row: span 2;
  width: 32px;
  height: 32px;
  background: ${({ theme }) => theme.primary.mainBlue};
  text-transform: uppercase;
`;

export const StyledNameWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
`;

export const StyledName = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.primary.textDark};
  max-width: 220px;
  ${textOneRow};
`;

export const StyledDivisionHead = styled.div`
  padding: 1px 4px;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  color: ${({ theme }) => theme.primary.light};
  border: 1px solid ${({ theme }) => theme.primary.light};
  border-radius: 4px;
`;

export const StyledPosition = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.secondary.textAdditional};
  ${textOneRow};
`;

export const StyledControls = styled.div``;

export const StyledButtonSync = styled(IconButton)`
  .icon {
    width: 20px;
    fill: #f46b6b;
  }

  &.Mui-disabled {
    .icon {
      fill: ${({ theme }) => theme.secondary.textAdditional};
    }
  }
`;
