import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Outlet } from "react-router-dom";
import { Companies } from "../../components/companies";
import { Box, Popper, Stack } from "@mui/material";
import { CoincidenceDialog } from "../../components/coincidence-dialog";
import { useAppContext } from "../app-layout/context";
import { useAcceptInvitationMutation } from "../../api/companies";
import { useMutationHandlers } from "../../hooks/use-mutation-handlers";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

export const CompaniesLayout: FC = () => {
  const { t } = useTranslation("company");
  const { enqueueSnackbar } = useSnackbar();

  const { currentCompanyId, companies, profileByCompany } = useAppContext();
  const { invitationFields } = profileByCompany;
  const container = useRef(null);

  const [isOpenPortal, setIsOpenPortal] = useState(false);

  const [acceptInvitation, acceptInvitationResponse] =
    useAcceptInvitationMutation();

  const handleAcceptInvitation = useCallback(
    (companyId) => {
      acceptInvitation({ companyId });
    },
    [acceptInvitation]
  );
  const handleSuccessDialog = useCallback(() => {
    handleAcceptInvitation(currentCompanyId);
  }, [handleAcceptInvitation, currentCompanyId]);

  const handleCloseDialog = useCallback(() => {
    setIsOpenPortal(false);
  }, []);

  const activeCompany = useMemo(
    () => companies?.find((company) => company.id === currentCompanyId),
    [companies, currentCompanyId]
  );
  const activeCompanyName = activeCompany?.name || "";

  const isPendingInvite = useMemo(
    () => activeCompany?.pendingInvite,
    [activeCompany]
  );

  useEffect(() => {
    if (isPendingInvite && !!invitationFields?.length) {
      setIsOpenPortal(true);
    } else {
      setIsOpenPortal(false);
    }
  }, [isPendingInvite, currentCompanyId, invitationFields]);

  useMutationHandlers(
    acceptInvitationResponse,
    () => {
      setIsOpenPortal(false);
      enqueueSnackbar(t("success.acceptInvitation"), {
        variant: "success",
      });
    },
    (error) => {
      enqueueSnackbar(t("common:errors.request_error"), { variant: "error" });
    }
  );

  return (
    <>
      <Box
        sx={{ position: "fixed", right: 0, bottom: 0, mr: 5, mb: 5 }}
        ref={container}
      />
      <Stack p={2.5} direction="row" flex={1} spacing={2.5}>
        <Companies onAcceptInvitation={handleAcceptInvitation} />
        <Outlet />
      </Stack>
      <Popper
        open={isOpenPortal}
        anchorEl={container.current}
        placement="top-start"
      >
        <CoincidenceDialog
          onCancel={handleCloseDialog}
          onSuccess={handleSuccessDialog}
          currentCompanyName={activeCompanyName}
          invitationFields={invitationFields}
        />
      </Popper>
    </>
  );
};
