import * as yup from "yup";
import "yup-phone-lite";
import { useTranslation } from "react-i18next";
import { useCallback } from "react";

export const validationPhoneSchema = yup.string().phone();

export const useValidatePhone = () => {
  const { t } = useTranslation();

  return useCallback(
    (value) =>
      validationPhoneSchema.isValidSync(value)
        ? ""
        : t("common:errors.auth.invalid_phone"),
    [t]
  );
};
