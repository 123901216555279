import { FC } from "react";
import { UpToDateCellProps } from "./upToDate-cell.types";
import {
  StyledCell,
  StyledColumn,
  StyledPendingDateWrapper,
} from "./upToDate-cell.styles";
import { ICellRendererParams } from "ag-grid-community";
import { AssignmentFailureDays } from "../../../../../../../../components/assignment-failure-days/assignment-failure-days";
import { AssignmentPendingDateTooltip } from "../../../../../../../../components/assignment-pending-date-tooltip";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CachedIcon from "@mui/icons-material/Cached";
import { formatDate } from "../../../../../../../../utils/commonUtils";

export const UpToDateCell: FC<ICellRendererParams<UpToDateCellProps>> = ({
  value,
}) => {
  const pendingDate = formatDate(value?.pendingDate);

  return (
    <StyledCell>
      <StyledColumn>
        {value?.upToDate}
        <AssignmentFailureDays
          days={value?.failureDays || 0}
          colorTheme={value?.failureDays > 0 ? "primary" : "gray"}
        />
      </StyledColumn>

      {value?.pendingDateStatus && (
        <AssignmentPendingDateTooltip
          status={value.pendingDateStatus}
          pendingDate={pendingDate || "–"}
        >
          <StyledPendingDateWrapper>
            {value?.pendingDateStatus === "declined" && (
              <CancelIcon className="icon-decline" />
            )}
            {value?.pendingDateStatus === "approved" && (
              <CheckCircleIcon className="icon-approved" />
            )}
            {value?.pendingDateStatus === "awaiting" && (
              <CachedIcon className="icon-awaiting" />
            )}
          </StyledPendingDateWrapper>
        </AssignmentPendingDateTooltip>
      )}
    </StyledCell>
  );
};
