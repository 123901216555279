import styled, { css } from "styled-components";
import { Tabs, Typography } from "@mui/material";

export const StyledTabText = styled(Typography)`
  font-weight: 500;
  line-height: 1.43;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.secondary.textAdditional};
`;

interface TabsProps {
  $hasPadding?: boolean;
}

export const StyledTabs = styled(Tabs)<TabsProps>`
  min-height: 44px;
  width: 100%;

  ${({ $hasPadding }) =>
  !$hasPadding &&
  css`
      padding: 0;
    `};

  .MuiTabs-indicator {
    background: ${({ theme }) => theme.secondary.textAdditional};
  }

  .MuiTabs-flexContainer {
    column-gap: 20px;
  }

  .MuiButtonBase-root {
    padding: 10px 0 10px 8px;
    border-bottom: 0;
  }

  .MuiTabs-scroller {
    border-bottom: 1px solid #0000001f;
  }

  .Mui-selected ${StyledTabText} {
    color: ${({ theme }) => theme.secondary.blue};
  }
`;
