import { FC } from "react";
import { Tooltip, ClickAwayListener, Button } from "@mui/material";
import { UnsyncModalProps } from "./un-sync-modal.types";
import { stopPropagation } from "../../../../utils/commonUtils";
import { StyledBody, StyledTitle, StyledButtons } from "./un-sync-modal.styles";
import { useTranslation } from "react-i18next";

export const UnSyncModal: FC<UnsyncModalProps> = ({
  open,
  children,
  onClose,
  onConfirm,
}) => {
  const { t } = useTranslation("counterparty");
  const { t: tCommon } = useTranslation("common");

  return (
    <Tooltip
      open={open}
      onClick={stopPropagation}
      componentsProps={{
        tooltip: {
          style: { margin: 0 },
          sx: {
            background: "transparent",
          },
        },
      }}
      PopperProps={{
        style: {
          background: "transparent",
        },
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [-40, -5],
            },
          },
        ],
      }}
      placement="bottom-end"
      title={
        <ClickAwayListener onClickAway={onClose}>
          <StyledBody onClick={stopPropagation}>
            <StyledTitle>{t("unSyncModal.title")}</StyledTitle>

            <StyledButtons>
              <Button variant="contained" color="success" onClick={onConfirm}>
                {tCommon("buttons.yes")}
              </Button>
              <Button variant="contained" onClick={onClose}>
                {tCommon("buttons.no")}
              </Button>
            </StyledButtons>
          </StyledBody>
        </ClickAwayListener>
      }
    >
      {children}
    </Tooltip>
  );
};
