import React, { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, FormikProvider } from "formik";
import {
  Button,
  Dialog,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import CheckIcon from "@mui/icons-material/Check";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import { ShowByTimer, Timer } from "../show-by-timer";
import { useForm } from "../../hooks/use-form";
import { useMutationHandlers } from "../../hooks/use-mutation-handlers";
import { useLoginByPhoneMutation } from "../../api/auth";
import { Profile } from "../../api/profile";
import { CodeFieldForm } from "../code-field-form";
import { CODE_LENGTH } from "../../utils/constants";
type ProfileConfirmPhoneProps = {
  handleGetCodeByPhone: () => void;
  getCodeByPhoneResponse: any;
  currentPhone: Profile["phone"];
};

export const PhoneConfirmDialog: FC<ProfileConfirmPhoneProps> = ({
  currentPhone,
  handleGetCodeByPhone,
  getCodeByPhoneResponse,
}) => {
  const { t } = useTranslation("profile");

  const [isOpenModalPhone, setIsOpenModalPhone] = useState(false);
  const [timer, setTimer] = useState<Timer>();

  const [confirmPhoneByCode, confirmPhoneResponse] = useLoginByPhoneMutation();

  const handleClose = useCallback(() => {
    setIsOpenModalPhone(false);
  }, []);

  const handleSubmit = useCallback(
    ({ code }) => {
      confirmPhoneByCode({ phone: currentPhone, code });
    },
    [confirmPhoneByCode, currentPhone]
  );

  const { formik } = useForm({
    initialValues: {
      code: "",
    },
    validateOnBlur: false,
    onSubmit: handleSubmit,
  });
  const { errors, values, setFieldError, setFieldValue, setFieldTouched } =
    formik;

  const code = values.code;

  const submitForm = formik.submitForm;
  useEffect(() => {
    const regExp = /_/g;
    if (code.length === CODE_LENGTH && !regExp.test(code)) {
      submitForm();
    }
  }, [code, submitForm]);

  useMutationHandlers(
    confirmPhoneResponse,
    () => {
      setIsOpenModalPhone(false);
    },
    (error) => {
      setFieldError(
        "code",
        "data" in error ? t(`common:errors.${error.data}`) : "Неверный код"
      );
    }
  );
  useMutationHandlers(
    getCodeByPhoneResponse,
    (data) => {
      setTimer({
        start: Math.floor(data?.canResendAfterMs / 1000) || 90,
      });
      setIsOpenModalPhone(true);
      setFieldValue("code", "");
      setFieldTouched("code", false);
    },
    (error) => {
      setFieldError(
        "code",
        "data" in error ? t(`common:errors.${error.data}`) : "Ошибка"
      );
    }
  );

  const isShowSuccessIcon =
    values?.code && !errors?.code && confirmPhoneResponse?.isSuccess;

  return (
    <Dialog maxWidth={"sm"} open={isOpenModalPhone} onClose={handleClose}>
      <Stack>
        <Paper sx={{ flex: 1, p: 4 }}>
          <FormikProvider value={formik}>
            <Stack component={Form}>
              <Typography
                width={336}
                mb={2}
                color="primary"
                variant="body1"
                textAlign={"center"}
              >
                {t("confirmPhone")}
              </Typography>
              <Typography px={2} variant="body2" textAlign="center">
                {t("status.toNumber", { phone: currentPhone })}
              </Typography>
              <Typography px={2} mb={1} variant="body2" textAlign="center">
                {t("status.sentCode")}
              </Typography>
              <>
                <CodeFieldForm
                  autoFocus
                  sx={{ marginTop: 3, marginBottom: 3 }}
                  name="code"
                  label={t("codeFromSMS")}
                  placeholder="- - - - - -"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PhoneAndroidIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        {isShowSuccessIcon && <CheckIcon color="success" />}
                      </InputAdornment>
                    ),
                  }}
                />
                <ShowByTimer
                  timer={timer}
                  waitingText={t("common:buttons.canGetNewCode")}
                >
                  <Button
                    sx={{ mt: 2 }}
                    startIcon={<AutorenewIcon />}
                    onClick={handleGetCodeByPhone}
                  >
                    {t("common:buttons.getNewCode")}
                  </Button>
                </ShowByTimer>
              </>
              <IconButton
                onClick={handleClose}
                sx={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                }}
              >
                <CloseSharpIcon />
              </IconButton>
            </Stack>
          </FormikProvider>
        </Paper>
      </Stack>
    </Dialog>
  );
};
