import React, { FC, ReactNode, useCallback, useMemo, useState } from "react";
import {
  Avatar,
  Button,
  Popover,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { LogoShow } from "../logotype";
import { useAppDispatch } from "../../store";
import { setCredentials } from "../../services/slices/auth/auth-slice";
import { Icon } from "../icons";
import { ProfileCard } from "../profile-card";
import { useAppContext } from "../../layouts/app-layout/context";
import { ColorDivider } from "../color-divider";

type HeaderProps = {
  title: ReactNode;
};

export const Header: FC<HeaderProps> = ({ title }) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const { profileByCompany, currentCompanyId, companies } = useAppContext();

  const currentCompany = useMemo(
    () => companies?.find((company) => company.id === currentCompanyId),
    [companies, currentCompanyId]
  );

  const {
    lastName,
    firstName,
    middleName,
    companyName,
    position,
    avatarPreview,
  } = profileByCompany;

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleLogout = useCallback(() => {
    dispatch(setCredentials({}));
    // dispatch(api.util.invalidateTags(["Profile", "ProfileByCompany", "Modules"]))
  }, [dispatch]);

  const handleIsShownProfileCard = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(anchorEl ? null : event.currentTarget);
    },
    [anchorEl]
  );
  const handleClose = () => {
    setAnchorEl(null);
  };

  const isOpenProfileCard = Boolean(anchorEl);

  return (
    <Stack
      px={2.5}
      position="sticky"
      top={0}
      zIndex={9}
      sx={{
        background: theme.palette.background.default,
      }}
    >
      <Stack
        direction="row"
        height={64}
        justifyContent="space-between"
        alignItems="center"
        py={1.5}
      >
        <Stack direction="row" alignItems="center" flex={1}>
          <LogoShow height="24px" width="196px" />
          <Typography ml={5.75} variant="h6" flex={1}>
            {title}
          </Typography>
        </Stack>
        <Stack direction="row" spacing={1.5} alignItems="center">
          <Stack
            component={"button"}
            onClick={handleIsShownProfileCard}
            direction="row"
            alignItems="center"
            border={0}
            sx={{ cursor: "pointer" }}
            maxHeight="40px"
          >
            <Avatar src={avatarPreview} />
            <Stack direction="row" ml={2} mr={0.5} spacing={0.5}>
              <Typography variant="h5">{firstName}</Typography>
              <Typography variant="h5">{lastName}</Typography>
            </Stack>

            <KeyboardArrowDownIcon />
          </Stack>
          <Popover
            anchorEl={anchorEl}
            open={isOpenProfileCard}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: 156,
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            sx={{ marginTop: 1.75 }}
          >
            <ProfileCard
              currentCompanyName={currentCompany?.name || ""}
              lastName={lastName}
              firstName={firstName}
              middleName={middleName}
              companyName={companyName}
              position={position}
              avatarPreview={avatarPreview}
            />
          </Popover>

          {/*<Button
            sx={{
              minWidth: "40px",
              minHeight: "40px",
              padding: 0,
              boxShadow: "none",
            }}
            variant="contained"
          >
            <HelpOutlineIcon />
          </Button>*/}
          <Button
            onClick={handleLogout}
            sx={{
              minWidth: "40px",
              minHeight: "40px",
              padding: 0,
              boxShadow: "none",
            }}
            variant="contained"
          >
            <Icon name="logout" />
          </Button>
        </Stack>
      </Stack>
      <ColorDivider size={2} />
    </Stack>
  );
};
