import React, { FC, useCallback, useState } from "react";
import { useField } from "formik";
import { FieldHookConfig } from "formik/dist/Field";
import { InputAdornment, TextField, TextFieldProps } from "@mui/material";
import { useTranslation } from "react-i18next";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import CheckIcon from "@mui/icons-material/Check";
import { MAX_PASSWORD_LENGTH_VALIDATION } from "../../utils/constants";

export type FormFieldProps = {
  name: string;
  fieldProps?: Partial<FieldHookConfig<string>>;
  label?: string;
} & Omit<TextFieldProps, "name">;

export const PasswordFieldForm: FC<FormFieldProps> = ({
  name,
  label,
  fieldProps,
  ...props
}) => {
  const { t } = useTranslation();

  const [isShowPassword, setIsShowPassword] = useState(false);

  const handleClickPasswordIcon = useCallback(() => {
    setIsShowPassword((state) => !state);
  }, []);

  const [field, { touched, error, value }] = useField({
    name: name,
    ...fieldProps,
  });

  const isError = touched && !!error;

  const isShowSuccessIcon = value && !error;

  return (
    <TextField
      {...field}
      {...props}
      helperText={
        isError
          ? t(error as string, { maxLength: MAX_PASSWORD_LENGTH_VALIDATION })
          : undefined
      }
      error={isError}
      name={name}
      label={label}
      type={isShowPassword ? "text" : "password"}
      placeholder="* * * * * * * * *"
      InputProps={{
        startAdornment: (
          <InputAdornment
            sx={{ cursor: "pointer" }}
            position="start"
            onClick={handleClickPasswordIcon}
          >
            {isShowPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            {isShowSuccessIcon && <CheckIcon color="success" />}
          </InputAdornment>
        ),
      }}
    />
  );
};
