import { FC } from "react";
import { Tabs } from "../../../../components/tabs";
import { GetIconProps, ProjectTabsProps } from "./project-tabs.types";
import { Tab } from "../../project.types";
import { useTranslation } from "react-i18next";
import { StyledProjectTabs } from "./project-tabs.styles";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { ModuleType } from "../../../../api/companies";
import { useAppContext } from "../../../../layouts/app-layout/context";
import { projectSelector } from "../../../../services/slices/project";
import { useSelector } from "react-redux";
import ErrorIcon from "@mui/icons-material/Error";

export const ProjectTabs: FC<ProjectTabsProps> = ({
  currentTab,
  onTabChange,
}) => {
  const { t } = useTranslation("project");
  const { availableModules } = useAppContext();
  const project = useSelector(projectSelector);

  const getTabIcon = ({ module, modules }: GetIconProps) => {
    if (modules?.[module] === "enabled_missed_data") {
      return <ErrorIcon className="icon icon-missed-data" />;
    }

    if (modules?.[module] === "disabled") {
      return <VisibilityOffIcon className="icon" />;
    }
  };

  return (
    <StyledProjectTabs>
      <Tabs
        currentValue={currentTab}
        values={[
          { value: "projectInfo", label: t("tabs.projectInfo") },
          {
            value: "personsAndCompanies",
            label: t("tabs.personsAndCompanies"),
            disabled: true,
          },
          {
            value: "dashboard",
            label: t("tabs.dashboard"),
            icon: getTabIcon({
              module: ModuleType.dashboard,
              modules: project.info?.modules,
            }),
            disabled: !availableModules.includes(ModuleType.dashboard),
          },
          {
            value: "design",
            label: t("tabs.design"),
            icon: getTabIcon({
              module: ModuleType.design,
              modules: project.info?.modules,
            }),
            disabled: !availableModules.includes(ModuleType.design),
          },
          {
            value: "rm",
            label: t("tabs.rm"),
            icon: getTabIcon({
              module: ModuleType.resource_managment,
              modules: project.info?.modules,
            }),
            disabled: !availableModules.includes(ModuleType.resource_managment),
          },
          {
            value: "qc",
            label: t("tabs.qc"),
            icon: getTabIcon({
              module: ModuleType.quality_control,
              modules: project.info?.modules,
            }),
            disabled: !availableModules.includes(ModuleType.quality_control),
          },
          {
            value: "estimation",
            label: t("tabs.estimation"),
            icon: getTabIcon({
              module: ModuleType.offer,
              modules: project.info?.modules,
            }),
            disabled: !availableModules.includes(ModuleType.offer),
          },
          {
            value: "finance",
            label: t("tabs.finance"),
            icon: getTabIcon({
              module: ModuleType.finance,
              modules: project.info?.modules,
            }),
            disabled: !availableModules.includes(ModuleType.finance),
          },
          {
            value: "procurement",
            label: t("tabs.procurement"),
            icon: getTabIcon({
              module: ModuleType.procurement,
              modules: project.info?.modules,
            }),
            disabled: !availableModules.includes(ModuleType.procurement),
          },
          {
            value: "asbuilt_doc",
            label: t("tabs.asbuilt_doc"),
            icon: getTabIcon({
              module: ModuleType.finance,
              modules: project.info?.modules,
            }),
            disabled: !availableModules.includes(ModuleType.asbuilt_doc),
          },
        ]}
        onChange={(value) => onTabChange(value as Tab)}
        scrollButtons
        variant="scrollable"
        hasPadding={false}
      />
    </StyledProjectTabs>
  );
};
