import { FC, useEffect, useMemo } from "react";
import { useMatch } from "react-router-dom";
import { AppContextType, AppProvider } from "./context";
import { Stack } from "@mui/material";
import { useGetProfileQuery } from "../../api/profile";
import {
  Company,
  ModulesResponse,
  ModuleStatuses,
  ModuleType,
  useGetCompaniesQuery,
  useGetModulesByCompanyIdQuery,
  useGetProfileByCompanyQuery,
  useGetUrlAuthInModuleMutation,
} from "../../api/companies";
import { Progress } from "../../components/progress";
import { useMutationHandlers } from "../../hooks/use-mutation-handlers";
import { useSelector } from "react-redux";
import { sourceModuleSelector } from "../../services/slices/auth/auth-slice";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

export const AppLayout: FC = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation("modules");
  const match = useMatch("/companies/:id/*");
  const currentCompanyId = Number(match?.params?.id);

  const {
    data: companies,
    isLoading: companiesIsLoading,
    error: companyError,
  } = useGetCompaniesQuery();

  const {
    data: profile,
    error: profileError,
    isLoading: profileIsLoading,
  } = useGetProfileQuery();

  const {
    data: profileByCompany,
    error: profileByCompanyError,
    isLoading: profileByCompanyIsLoading,
  } = useGetProfileByCompanyQuery(currentCompanyId, {
    skip: !Number(currentCompanyId),
  });
  const {
    data: modules,
    error: modulesError,
    isLoading: modulesIsLoading,
  } = useGetModulesByCompanyIdQuery(currentCompanyId, {
    skip: !Number(currentCompanyId),
  });

  const availableModules = useMemo(() => {
    const result = [] as ModuleType[];
    const statuses = modules?.statuses as ModulesResponse["statuses"];
    Object.entries(statuses || {})?.forEach(([module, status]) => {
      if (
        status === ModuleStatuses.available ||
        status === ModuleStatuses.online ||
        status === ModuleStatuses.offline
      ) {
        result.push(module as ModuleType);
      }
    });
    return result;
  }, [modules]);

  const value = useMemo<AppContextType>(() => {
    const sortedCompanies = [...(companies?.data || [])].sort((a) => {
      if (a.main) {
        return -1;
      } else {
        return 1;
      }
    });

    return {
      profile: profileError
        ? ({} as AppContextType["profile"])
        : profile || ({} as AppContextType["profile"]),
      profileByCompany: profileByCompanyError
        ? ({} as AppContextType["profileByCompany"])
        : profileByCompany || ({} as AppContextType["profileByCompany"]),
      companies: sortedCompanies,
      mainCompany: sortedCompanies?.find((company: Company) => company.id === currentCompanyId) || null,
      modules: modulesError
        ? ({} as ModulesResponse)
        : modules || ({} as ModulesResponse),
      availableModules: availableModules,
      currentCompanyId: currentCompanyId || 0,
      isAdmin: !!profileByCompany?.access?.admin,
    };
  }, [
    profile,
    profileError,
    companies,
    currentCompanyId,
    profileByCompany,
    profileByCompanyError,
    modulesError,
    modules,
    availableModules,
  ]);

  const isLoading =
    profileIsLoading ||
    companiesIsLoading ||
    profileByCompanyIsLoading ||
    modulesIsLoading;

  const isError =
    profileError || profileByCompanyError || companyError || modulesError;

  useEffect(() => {
    if (isError) {
      console.log("Ошибка");
    }
  }, [isError]);

  const sourceModule = useSelector(sourceModuleSelector);
  const [getUrlAuthInModule, getUrlAuthInModuleResponse] =
    useGetUrlAuthInModuleMutation();

  useEffect(() => {
    if (sourceModule && currentCompanyId) {
      getUrlAuthInModule({ companyId: currentCompanyId, module: sourceModule });
    }
  }, [sourceModule, currentCompanyId]);

  useMutationHandlers(
    getUrlAuthInModuleResponse,
    (data) => {
      window.location.replace(`${data?.url}`);
    },
    (error) => {
      enqueueSnackbar(t("common:errors.request_error"), { variant: "error" });
    }
  );

  return (
    <AppProvider value={value}>
      <Stack flex={1}>{isLoading ? <Progress /> : children}</Stack>
    </AppProvider>
  );
};
