import styled from "styled-components";
import { textOneRow } from "../../../../../../common/styledMixins";

export const StyledStatusesChart = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledDoughnutWrapper = styled.div`
  height: 110px;
  width: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  canvas {
    z-index: 1;
  }
`;

export const StyledTotalCount = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border: 2px solid ${({ theme }) => theme.background.gray};
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme }) => theme.secondary.text};
  position: absolute;
  top: 10px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.primary.blueShades};
`;

export const StyledInfo = styled.div`
  display: grid;
  grid-row-gap: 6px;
`;

export const StyledRow = styled.div`
  display: flex;
  column-gap: 8px;
  align-items: center;
`;

interface StatusProps {
  $color: string;
}

export const StyledStatus = styled.div<StatusProps>`
  padding-left: 24px;
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme.secondary.textAdditional};
  line-height: 14px;
  display: flex;
  align-items: center;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    width: 16px;
    height: 10px;
    border-radius: 12px;
    background: ${({ $color }) => $color};
  }
`;

export const StyledAssignmentsCount = styled.div`
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  width: 30px;
  ${textOneRow};
  margin-left: auto;
`;

interface FailedCountProps {
  $isVisible?: boolean;
  $highPriority?: boolean;
}

export const StyledFailedCount = styled.div<FailedCountProps>`
  width: 32px;
  border-radius: 6px;
  background: ${({ theme, $highPriority }) =>
    $highPriority ? theme.charts.red200 : `${theme.charts.red}26`};
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.secondary.error};
  text-align: center;
  ${textOneRow};
  padding: 2px;
  visibility: ${({ $isVisible }) => ($isVisible ? "visible" : "hidden")};
`;
