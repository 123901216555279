import { FC, HTMLAttributes } from "react";
import { ICONS } from "../../utils/constants";

export type IconProps = {
  name: keyof typeof ICONS;
} & HTMLAttributes<Element>;

export const Icon: FC<IconProps> = ({ name, ...props }) => {
  const Icon = (ICONS[name] as FC<HTMLAttributes<SVGElement>>) || null;

  return <Icon {...props} />;
};
