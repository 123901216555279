import styled from "styled-components";

export const StyledBlock = styled.div`
  padding: 20px;
  border-radius: 16px;
  display: grid;
  grid-row-gap: 10px;
  background: ${({ theme }) => theme.primary.blueShades};

  .files-list {
    grid-auto-rows: 36px;
  }

  .file {
    padding: 4px;
    border-color: ${({ theme }) => theme.background.gray};
    display: grid;
    grid-template-columns: max-content 1fr max-content;
  }

  .MuiToggleButtonGroup-root .MuiButtonBase-root {
    text-transform: capitalize;
  }
`;

export const StyledStab = styled.div`
  height: 169px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  text-align: center;
  color: ${({ theme }) => theme.secondary.text};
  white-space: pre-line;
`;
