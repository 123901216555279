import { FC, useState } from "react";
import { useAppContext } from "../../../../layouts/app-layout/context";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { FiltersState } from "./assignments-by-co.types";
import {
  useGetAssignmentsListFiltersQuery,
  useGetAssignmentsListQuery,
} from "../../../../api/assignments";
import { getFiltersParams } from "./assignments-by-co.service";
import {
  StyledBody,
  StyledCards,
  StyledPage,
} from "./assignments-by-co.styles";
import { StyledStub, StyledStubText } from "../../assignments.styles";
import { AssignmentsCard } from "./components/assignments-card";
import { Header } from "./components/header";
import { Filters } from "./components/filters";
import { Progress } from "../../../../components/progress";
import { ReactComponent as EmptyIcon } from "../../../../components/icons/imported-icons/empty-assignments.svg";

export const AssignmentsByCo: FC = () => {
  const { currentCompanyId } = useAppContext();
  const { t } = useTranslation("assignments");
  const [searchParams, setSearchParams] = useSearchParams();

  const [filtersState, setFiltersState] = useState(
    new FiltersState(searchParams)
  );

  const assignmentsList = useGetAssignmentsListQuery(
    {
      companyID: currentCompanyId,
      limit: Infinity,
      offset: 0,
      body: {
        ...getFiltersParams(filtersState, [
          "group",
          "authorID",
          "supervisorID",
          "upToDateFrom",
          "upToDateTo",
        ]),
      },
    },
    { skip: !currentCompanyId }
  );

  const groupList = useGetAssignmentsListFiltersQuery(
    {
      companyID: currentCompanyId,
      body: {
        ...getFiltersParams(filtersState, [
          "authorID",
          "supervisorID",
          "upToDateFrom",
          "upToDateTo",
        ]),
      },
    },
    { skip: !currentCompanyId }
  );

  const authorList = useGetAssignmentsListFiltersQuery(
    {
      companyID: currentCompanyId,
      body: {
        ...getFiltersParams(filtersState, [
          "group",
          "supervisorID",
          "upToDateFrom",
          "upToDateTo",
        ]),
      },
    },
    { skip: !currentCompanyId }
  );

  const supervisorIDList = useGetAssignmentsListFiltersQuery(
    {
      companyID: currentCompanyId,
      body: {
        ...getFiltersParams(filtersState, [
          "group",
          "authorID",
          "upToDateFrom",
          "upToDateTo",
        ]),
      },
    },
    { skip: !currentCompanyId }
  );

  const datesList = useGetAssignmentsListFiltersQuery(
    {
      companyID: currentCompanyId,
      body: {
        ...getFiltersParams(filtersState, [
          "group",
          "authorID",
          "supervisorID",
        ]),
      },
    },
    { skip: !currentCompanyId }
  );

  const body = (
    <StyledBody>
      {assignmentsList.data?.total ? (
        <StyledCards>
          {assignmentsList.data.data.map((value) => (
            <AssignmentsCard data={value} key={value.id} />
          ))}
        </StyledCards>
      ) : (
        <StyledStub>
          <EmptyIcon />
          <StyledStubText>{t("stubCoText")}</StyledStubText>
        </StyledStub>
      )}
    </StyledBody>
  );

  return (
    <StyledPage>
      <Header>
        <Filters
          state={filtersState}
          searchParams={searchParams}
          setSearchParams={setSearchParams}
          group={groupList.data?.group || []}
          author={authorList.data?.author || []}
          supervisor={supervisorIDList.data?.supervisor || []}
          upToDateFrom={datesList.data?.upToDateFrom || ""}
          upToDateTo={datesList.data?.upToDateTo || ""}
          onChange={setFiltersState}
        />
      </Header>
      {assignmentsList.isLoading ? <Progress /> : body}
    </StyledPage>
  );
};
