import { ProjectType } from "../../../../api/companies";

export const statusOptions = [
  { label: "Потенциальный", value: "potential" },
  { label: "Активный", value: "active" },
  { label: "Отложен", value: "delayed" },
  { label: "Завершен", value: "completed" },
];

export const projectTypeOptions = [
  {
    label: "Произв. назначения",
    value: "industrial",
  },
  {
    label: "Непроизв. назначения",
    value: "nonindustrial",
  },
  {
    label: "Линейные объекты",
    value: "linear",
  },
];

export const financingTypeOptions = [
  {
    label: "Инвестиционный",
    value: "investment",
  },
  {
    label: "Подрядный",
    value: "contracting",
  },
  {
    label: "Смешанный",
    value: "mixed",
  },
];

export const enProjectTypesShort: { [key in ProjectType]: string } = {
  industrial: "Произв. назначения",
  nonindustrial: "Непроизв. назначения",
  linear: "Линейные объекты",
};

export const projectCreationInitialValues = {
  shortName: "",
  fullName: "",
  status: "",
  projectType: "",
  financingType: "",
  price: "",
  region: "",
  timezone: "",
  address: "",
};
