import styled from "styled-components";

export const StyledCell = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 4px;
`;

export const StyledPendingDateWrapper = styled.div`
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
  margin: auto 0;

  .icon-decline {
    width: 20px;
    fill: ${({ theme }) => theme.secondary.error};
  }

  .icon-approved {
    width: 20px;
    fill: ${({ theme }) => theme.legends.green};
  }

  .icon-awaiting {
    width: 20px;
    fill: ${({ theme }) => theme.primary.warningLight};
  }
`;
