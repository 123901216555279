import styled, { css } from "styled-components";
import { textOneRow } from "../../common/styledMixins";
import { IconButton } from "@mui/material";

export const StyledFilesList = styled.ul`
  display: grid;
  grid-row-gap: 4px;
  grid-auto-rows: 32px;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
`;

export const StyledFile = styled.li`
  width: 100%;
  height: 100%;
  padding: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 8px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.background.gray};
  background: #fff;
  overflow: hidden;
`;

interface TextProps {
  $variant?: "primary" | "secondary";
}

export const StyledText = styled.div<TextProps>`
  ${textOneRow};

  ${({ $variant }) =>
    $variant === "primary" &&
    css`
      font-size: 14px;
      line-height: 16px;
      color: ${({ theme }) => theme.primary.mainBlue};
    `};
  ${({ $variant }) =>
    $variant === "secondary" &&
    css`
      font-size: 12px;
      line-height: 14px;
      font-weight: 500;
      color: #fff;
    `};
  width: 100%;
`;

export const StyledButtonDownload = styled(IconButton)`
  width: 24px;
  height: 24px;

  .icon {
    width: 16px;
    fill: ${({ theme }) => theme.primary.mainBlue};
  }
`;
