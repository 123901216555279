import React, { FC, useCallback } from "react";
import { MenuItem, SelectChangeEvent, Typography } from "@mui/material";
import { StyledMuiSelectProps } from "./styled-mui-select.types";
import {
  StyledPlaceholder,
  StyledSelect,
  StyledLabel,
} from "./styled-mui-select.styles";
import { useField } from "formik";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

export const StyledMuiSelect: FC<StyledMuiSelectProps> = ({
  labelText,
  options,
  labelClassName,
  placeholder,
  props,
  children,
}) => {
  const [, , { setValue }] = useField(props?.name || "");

  const handleChange = useCallback(
    (event: SelectChangeEvent) => {
      const option = options.find(({ value }) => value === event.target.value);
      if (!option) return;

      setValue(option.label);
    },
    [options, setValue]
  );

  return (
    <StyledLabel className={`field-form-label ${labelClassName}`}>
      <Typography pb={0.5} color="secondary" pl={2}>
        {labelText}
      </Typography>

      <StyledSelect
        {...props}
        IconComponent={(iconProps) => (
          <KeyboardArrowDownIcon className="dropdown-icon" {...iconProps} />
        )}
        onChange={handleChange}
        disableUnderline
        displayEmpty
        renderValue={(value: string | null) =>
          value || <StyledPlaceholder>{placeholder}</StyledPlaceholder>
        }
      >
        {children}
        {options.map(({ label, value }) => (
          <MenuItem key={label} value={value}>
            {label}
          </MenuItem>
        ))}
      </StyledSelect>
    </StyledLabel>
  );
};
