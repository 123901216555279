import { FiltersState } from "./projects.types";

export function getFiltersParams(
  filtersState: FiltersState,
  keys: string[]
) {
  return Object.entries(filtersState).reduce(
    (accumulator, [key, value]) => ({
      ...accumulator,
      ...(keys.includes(key) && value?.length && { [key]: value }),
    }),
    {}
  );
}
