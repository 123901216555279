import { Button, Popper } from "@mui/material";
import { FC, useState } from "react";
import { AddCounterpartyPopperProps } from "./add-counterparty-popper.types";
import {
  StyledArrow,
  StyledButtonsWrapper,
  StyledPopover,
  StyledTitle,
} from "./add-counterparty-popper.styles";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const AddCounterpartyPopper: FC<AddCounterpartyPopperProps> = ({
  open,
  anchorEl,
  onConnect,
}) => {
  const [arrowRef, setArrowRef] = useState(null);
  const { t } = useTranslation("counterparties");

  return (
    <Popper
      open={open}
      anchorEl={anchorEl}
      onClick={(event) => event.stopPropagation()}
      placement="bottom-end"
      modifiers={[
        {
          name: "arrow",
          enabled: true,
          options: {
            element: arrowRef,
          },
        },
      ]}
    >
      <StyledPopover>
        <StyledTitle>{t("addPopper.title")}</StyledTitle>
        <StyledButtonsWrapper>
          <Button variant="contained" onClick={onConnect}>{t("addPopper.connect")}</Button>
          <Button variant="contained" component={Link} to="create">
            {t("addPopper.create")}
          </Button>
        </StyledButtonsWrapper>
      </StyledPopover>
      <StyledArrow component="span" className="arrow" ref={setArrowRef} />
    </Popper>
  );
};
