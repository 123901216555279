import { FC, useMemo, useState } from "react";
import { StyledPage, StyledBody } from "./assignment-by-central-office.styles";
import { Header } from "./components/header";
import { useParams, useSearchParams } from "react-router-dom";
import { useGetAssignmentsViewByCentralOfficeQuery } from "../../api/assignments";
import { useAppContext } from "../../layouts/app-layout/context";
import { Progress } from "../../components/progress";
import { AssignmentPanel } from "./components/assignment-panel";
import { AssignmentTable } from "./components/assignment-table";
import { AssignmentStatus } from "../../api/assignments/types";
import { Filters } from "./assignment-by-central-office.types";

export const AssignmentByCentralOffice: FC = () => {
  const { currentCompanyId } = useAppContext();

  const [searchParams, setSearchParams] = useSearchParams();
  const params = useParams<{ id: string }>();
  const assignmentID = Number(params.id);

  const [filtersState, setFiltersState] = useState(new Filters(searchParams));

  const { data, isLoading } = useGetAssignmentsViewByCentralOfficeQuery(
    {
      companyID: currentCompanyId,
      assignmentID,
      ...(filtersState.status.length && { status: filtersState.status }),
    },
    { skip: !currentCompanyId || !assignmentID }
  );

  const { data: dataWithoutFilters } =
    useGetAssignmentsViewByCentralOfficeQuery(
      {
        companyID: currentCompanyId,
        assignmentID,
      },
      { skip: !currentCompanyId || !assignmentID }
    );

  const statuses = useMemo(() => {
    if (!dataWithoutFilters) return [];

    return dataWithoutFilters.divisions.reduce((accumulator, current) => {
      if (!accumulator.includes(current.status))
        return [...accumulator, current.status];

      return accumulator;
    }, [] as AssignmentStatus[]);
  }, [dataWithoutFilters]);

  return (
    <StyledPage>
      <Header
        statuses={statuses}
        filters={filtersState}
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        onFiltersChange={setFiltersState}
      />

      {isLoading ? (
        <Progress />
      ) : (
        <StyledBody>
          <AssignmentPanel data={data} />

          <AssignmentTable data={data} />
        </StyledBody>
      )}
    </StyledPage>
  );
};
