import { FC, useEffect, useMemo, useRef, useState } from "react";
import { ICellRendererParams } from "ag-grid-community";
import { ExecutorsCelProps } from "./executors-cell.types";
import { StyledCell, StyledNames, StyledCount } from "./executors-cell.styles";
import { useViewport } from "../../hooks/useViewport";
import { ReactComponent as WorkersIcon } from "../icons/imported-icons/workers.svg";
import { getPersonInitials } from "../../utils/commonUtils";

const DOTS_WIDTH = 20;

export const ExecutorsCell: FC<
  ICellRendererParams<unknown, ExecutorsCelProps>
> = ({ value }) => {
  const namesRef = useRef<HTMLDivElement>(null);
  const [namesWidth, setNamesWidth] = useState(0);

  const { width } = useViewport();

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      const rect = entries[0].contentRect;
      setNamesWidth(rect.width);
    });
    namesRef.current && observer.observe(namesRef.current);

    return () => {
      namesRef.current && observer.unobserve(namesRef.current);
    };
  }, [namesRef.current]);

  const names = useMemo(() => {
    return (
      value?.executors?.map(
        (item) =>
          `${item.lastName} ${getPersonInitials({
            firstName: item.firstName,
            middleName: item.middleName || "",
            divider: ".",
          })}`
      ) || []
    );
  }, [value]);

  const visibleCount = useMemo(() => {
    if (!namesRef.current) return 0;

    const items = namesRef.current.querySelectorAll(".name");
    const cellRect = namesRef.current.getBoundingClientRect();
    const cellRight = cellRect.right || 0;
    const cellBottom = cellRect.bottom || 0;
    return Array.from(items).reduce((accumulator, current) => {
      const itemRect = current.getBoundingClientRect();
      const itemLeft = itemRect.left || 0;
      const itemBottom = itemRect.bottom || 0;
      if (itemLeft < cellRight - DOTS_WIDTH && itemBottom < cellBottom) {
        return accumulator + 1;
      }
      return accumulator;
    }, 0);
  }, [names, namesRef.current, width, namesWidth]);
  const hiddenCount = names.length ? names.length - visibleCount : 0;

  return (
    <StyledCell $hasCountIndicator={hiddenCount > 0}>
      <StyledNames ref={namesRef}>
        {names.length
          ? names.map((item, index) => (
              <span className="name" key={index}>{`${item}${
                index < names.length - 1 ? ", " : ""
              }`}</span>
            ))
          : "–"}
      </StyledNames>

      {hiddenCount > 0 && (
        <StyledCount>
          {`+ ${hiddenCount}`}
          <WorkersIcon />
        </StyledCount>
      )}
    </StyledCell>
  );
};
