import styled from "styled-components";
import { MenuItem } from "@mui/material";

export const StyledMenuItem = styled(MenuItem)`
  min-height: 36px;
  min-width: 331px;
  font-size: 14px;
  font-weight: 400;
  color: #2b3648;

  .icon {
    width: 20px;
    margin-right: 16px;
    fill: #5c6e8c;
  }

  &:hover {
    background: #1976d214;
    color: ${({ theme }) => theme.primary.mainBlue};

    .icon {
      fill: ${({ theme }) => theme.primary.mainBlue};
    }
  }
`;
