import styled from "styled-components";

export const StyledCounterparties = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
`;

export const StyledNoRowsMessage = styled.div`
  position: absolute;
  margin: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 18px;
  font-weight: 400;
  color: ${() =>
    ({ theme }) =>
      theme.secondary.text};
`;

export const StyledHeaderWrapper = styled.div`
  padding: 0 20px;
`;

export const StyledList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(345px, 1fr));
  align-content: start;
  grid-gap: 20px;
  overflow-y: auto;
  width: 100%;
  height: calc(100% - 68px);
  padding: 15px 20px 20px;
`;
