import { FilesListProps } from "./files-list.types";
import { FC } from "react";
import {
  StylesList,
  StyledItem,
  StyledFileName,
  StyledButtonDelete,
} from "./files-list.styles";
import DeleteIcon from "@mui/icons-material/Delete";
import { StyledExtension } from "../../../../common/assignmentsCommonStyles";
import {AssignmentsFile} from "../../../../api/assignments/types";
import {getFileExtensionByUrl} from "../../../../utils/commonUtils";

export const FilesList: FC<FilesListProps> = ({
  files,
  disabled,
  hasFileExtension,
  onButtonDeleteClick,
}) => {
  if (!files.length) return null;
  const getFileExtension = (file: File | AssignmentsFile) => {
    if (file instanceof File) {
      return getFileExtensionByUrl(file.name);
    }
    if (file.url) {
      return getFileExtensionByUrl(file.url);
    }
  }

  return (
    <StylesList className="files-list">
      {files.map((attachFile, index) => (
        <StyledItem key={index.toString()} className="file">
          {hasFileExtension && (<StyledExtension>{getFileExtension(attachFile.file)}</StyledExtension>)}
          <StyledFileName>
            {attachFile.file instanceof File
              ? attachFile.file.name
              : attachFile.file.originName}
          </StyledFileName>
          <StyledButtonDelete
            onClick={() => onButtonDeleteClick(attachFile)}
          >
            <DeleteIcon className="icon" />
          </StyledButtonDelete>
        </StyledItem>
      ))}
    </StylesList>
  );
};
