import { AssignmentTableProps } from "./assignment-table.types";
import { FC, useMemo } from "react";
import { StyledAgGridWrapper } from "../../../../common/librariesStyles/agGrid";
import { AgGridReact } from "ag-grid-react";
import { AgGridNoRowsOverlay } from "../../../../components/ag-grid-no-rows-overlay";
import "ag-grid-community/styles/ag-grid.css";
import { ColDef, ColGroupDef } from "ag-grid-community";
import {
  NAME_CELL_WIDTH,
  RESPONSIBLE_CELL_WIDTH,
  EXECUTORS_CELL_WIDTH,
  STATUS_CELL_WIDTH,
  PENDING_DATE_CELL_WIDTH,
  FILES_CELL_WIDTH,
  commonCellClasses,
} from "./assignment-table.constants";
import { getPersonInitials } from "../../../../utils/commonUtils";
import { ExecutorsCell } from "../../../../components/executors-cell";
import { StatusCell } from "./components/status-cell";
import { StyledWrapper } from "./assignment-table.styles";
import { useAppContext } from "../../../../layouts/app-layout/context";
import { PendingDateCell } from "./components/pending-date-cell";
import { FilesCell } from "./components/files-cell";

export const AssignmentTable: FC<AssignmentTableProps> = ({ data }) => {
  const { currentCompanyId } = useAppContext();

  const columnDefs: (ColGroupDef | ColDef)[] = [
    {
      headerName: "Название подразделения",
      width: NAME_CELL_WIDTH,
      minWidth: NAME_CELL_WIDTH,
      field: "name",
      suppressSizeToFit: true,
    },
    {
      headerName: "Ответственный",
      width: RESPONSIBLE_CELL_WIDTH,
      minWidth: RESPONSIBLE_CELL_WIDTH,
      field: "responsible",
      suppressSizeToFit: true,
    },
    {
      headerName: "Исполнители",
      minWidth: EXECUTORS_CELL_WIDTH,
      flex: 1,
      field: "executors",
      cellRenderer: ExecutorsCell,
      cellClass: commonCellClasses,
    },
    {
      headerName: "Статус",
      width: STATUS_CELL_WIDTH,
      minWidth: STATUS_CELL_WIDTH,
      field: "status",
      cellRenderer: StatusCell,
      cellClass: `${commonCellClasses} ag-cell_padding_4`,
    },
    {
      headerName: "Согласованный срок",
      minWidth: PENDING_DATE_CELL_WIDTH,
      field: "pendingDate",
      cellRenderer: PendingDateCell,
      cellClass: 'ag-cell_flex ag-cell_align-items_center',
    },
    {
      headerName: "",
      width: FILES_CELL_WIDTH,
      minWidth: FILES_CELL_WIDTH,
      field: "files",
      cellRenderer: FilesCell,
      cellClass: commonCellClasses,
    },
  ];

  const rows = useMemo(() => {
    if (!data) return [];

    return data.divisions.map((value) => {
      return {
        name: value.name ? `«${value.name}»` : "–",
        responsible: value.responsible
          ? `${value.responsible.lastName} ${getPersonInitials({
              firstName: value.responsible.firstName,
              middleName: value.responsible.middleName || "",
              divider: ".",
            })}`
          : "–",
        executors: { executors: value.executors },
        status: {
          canChangeToStatus: value.canChangeToStatus,
          canEdit: data.canEdit,
          status: value.status,
          division: value.name,
          assignmentID: data.id,
          companyID: currentCompanyId,
          comment: value.comment,
          commentDate: value.commentDate,
          commentAuthor: value.commentAuthor,
        },
        pendingDate: {
          upToDate: value.upToDate,
          pendingDateStatus: value.pendingDateStatus,
          pendingDate: value.upToDatePending,
          upToDatePending: value.upToDatePending,
          division: value.name,
          assignmentID: data.id,
          companyID: currentCompanyId,
          failureDays: value.failureDays,
          canEdit: data.canEdit,
        },
        files: {
          files: value.files,
        },
      };
    });
  }, [data?.divisions]);

  return (
    <StyledWrapper>
      <StyledAgGridWrapper>
        <div className="ag-theme-alpine">
          <AgGridReact
            columnDefs={columnDefs}
            rowData={rows}
            noRowsOverlayComponent={AgGridNoRowsOverlay}
            headerHeight={49}
            rowHeight={42}
            defaultColDef={{
              editable: false,
              sortable: false,
              filter: false,
              resizable: true,
            }}
          />
        </div>
      </StyledAgGridWrapper>
    </StyledWrapper>
  );
};
