import React, { ChangeEvent, FC, useRef, useState } from "react";
import { Button, ClickAwayListener, Stack } from "@mui/material";
import { StyledPagination } from "../../../../common/commonStyles";
import { HeaderCounterpartiesProps } from "./header-counterparties.types";
import { FilterSelect } from "../../../../components/filter-select";
import { useTranslation } from "react-i18next";
import FilterAltSharpIcon from "@mui/icons-material/FilterAltSharp";
import { FilterSelectMultiple } from "../../../../components/filter-select-multiple";
import { fromValuesToOptions } from "../../../../utils/commonUtils";
import { AddCounterpartyPopper } from "./components/add-counterparty-popper";
import AddSharpIcon from "@mui/icons-material/AddSharp";
import { ConnectCounterpartyModal } from "../../../../components/connect-counterparty-modal/connect-conterparty-modal";
import { useAppContext } from "../../../../layouts/app-layout/context";

export const HeaderCounterparties: FC<HeaderCounterpartiesProps> = ({
  page,
  pages,
  limit,
  numberRows,
  onChangeLimit,
  onPaginationChange,
  allRoles,
  selectRoles,
  hasPagination,
  onRoleChange,
}) => {
  const { t } = useTranslation("counterparties");
  const { isAdmin } = useAppContext();
  const buttonAddRef = useRef<HTMLButtonElement>(null);

  const [buttonAddOrigin, setButtonAddOrigin] =
    useState<HTMLButtonElement | null>(null);
  const [isConnectModalOpen, setIsConnectModalOpen] = useState(false);

  const handlePaginationChange = (_: ChangeEvent<unknown>, value: number) => {
    onPaginationChange(value);
  };

  const handleButtonAddClick = () => {
    if (buttonAddOrigin) {
      setButtonAddOrigin(null);
      return;
    }

    if (buttonAddRef.current) {
      setButtonAddOrigin(buttonAddRef.current);
    }
  };

  return (
    <Stack
      justifyContent="space-between"
      direction="row"
      marginTop="15px"
      height={40}
    >
      {allRoles && (
        <FilterSelectMultiple
          startIcon={<FilterAltSharpIcon />}
          items={fromValuesToOptions(allRoles)}
          value={selectRoles}
          onChange={onRoleChange}
          defaultValue={t("roles.allRoles")}
          isDisabled={!hasPagination}
        />
      )}

      <Stack
        spacing={5}
        direction="row"
        justifyContent="center"
        alignItems="center"
        marginLeft="auto"
      >
        {hasPagination && (
          <>
            <FilterSelect
              items={numberRows}
              value={limit}
              onChange={onChangeLimit}
              startIcon={t("rows")}
            />
            <StyledPagination
              onChange={handlePaginationChange}
              count={pages}
              shape="rounded"
              page={page}
            />
          </>
        )}

        {isAdmin && (
          <ClickAwayListener onClickAway={() => setButtonAddOrigin(null)}>
            <Button
              sx={{
                minWidth: "40px",
                height: "40px",
                padding: 0,
                boxShadow: "none",
              }}
              variant="contained"
              onClick={handleButtonAddClick}
              ref={buttonAddRef}
            >
              <AddSharpIcon />
            </Button>
          </ClickAwayListener>
        )}
      </Stack>

      <AddCounterpartyPopper
        open={!!buttonAddOrigin}
        anchorEl={buttonAddOrigin}
        onClose={() => setButtonAddOrigin(null)}
        onConnect={() => setIsConnectModalOpen(true)}
      />

      {allRoles?.length && (
        <ConnectCounterpartyModal
          open={isConnectModalOpen}
          title={t("connectModal.title")}
          description={t("connectModal.description")}
          roles={allRoles}
          onClose={() => setIsConnectModalOpen(false)}
          onSuccess={() => setIsConnectModalOpen(false)}
        />
      )}
    </Stack>
  );
};
