import styled from "styled-components";
import { limitTextRows } from "../../common/styledMixins";

interface CellProps {
  $hasCountIndicator?: boolean;
}

export const StyledCell = styled.div<CellProps>`
  display: grid;
  align-items: center;
  justify-content: space-between;
  column-gap: 8px;
  grid-template-columns: ${({ $hasCountIndicator }) =>
    $hasCountIndicator ? "1fr minmax(52px, max-content)" : "100%"};
  width: 100%;
`;

export const StyledNames = styled.div`
  width: 100%;
  ${limitTextRows(2)};
`;

export const StyledCount = styled.div`
  padding: 4px 8px;
  border-radius: 6px;
  background: ${({ theme }) => `${theme.background.blue}14`};
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.primary.mainBlue};
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 2px;
`;
