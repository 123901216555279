import { ERRORS_COINCIDENCE, ERRORS_PHONE } from "./constants";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { SerializedError } from "@reduxjs/toolkit";

type ErrorCollected = {
  type: "phone" | "coincidence";
  field: "phone" | "email" | "login";
  text: string;
};

export const mapFieldErrorByError = (
  error: FetchBaseQueryError | SerializedError
): ErrorCollected | undefined => {
  if ("data" in error) {
    const data = error?.data;
    if (ERRORS_PHONE.includes(data as string)) {
      return {
        type: "phone",
        field: "phone",
        text: "common:errors.profile.invalid_phone",
      };
    }
    if (Object.keys(ERRORS_COINCIDENCE).includes(data as string)) {
      return {
        type: "coincidence",
        field: ERRORS_COINCIDENCE[data as keyof typeof ERRORS_COINCIDENCE],
        text: "common:errors.auth.invalid_coincidence",
      };
    }
  }
  return undefined;
};
