import styled from "styled-components";

export const StyledPaginationWrapper = styled.div`
  display: flex;
  align-items: center;
  min-height: 66px;
  padding: 0 20px;
  margin-left: auto;
  width: max-content;
`;
