import { FC, useCallback } from "react";
import { AssignmentFilesListProps } from "./assignment-files-list.types";
import {
  StyledFilesList,
  StyledFile,
  StyledText,
  StyledButtonDownload,
} from "./assignment-files-list.styles";
import { StyledExtension } from "../../common/assignmentsCommonStyles";
import {
  downloadFileByLink,
  getFileExtensionByUrl,
} from "../../utils/commonUtils";
import GetAppIcon from "@mui/icons-material/GetApp";
import { AssignmentsFile } from "../../api/assignments/types";

export const AssignmentFilesList: FC<AssignmentFilesListProps> = ({
  files,
}) => {
  const handleButtonDownloadClick = useCallback((file: AssignmentsFile) => {
    downloadFileByLink(file.url, file.originName);
  }, []);

  return (
    <StyledFilesList className="files-list">
      {files.map((value, index) => {
        const extension = getFileExtensionByUrl(value.url);

        return (
          <StyledFile key={index} className="file">
            <StyledExtension>
              <StyledText $variant="secondary">{extension}</StyledText>
            </StyledExtension>

            <StyledText $variant="primary">
              {value.originName || "–"}
            </StyledText>

            <StyledButtonDownload
              onClick={() => handleButtonDownloadClick(value)}
            >
              <GetAppIcon className="icon" />
            </StyledButtonDownload>
          </StyledFile>
        );
      })}
    </StyledFilesList>
  );
};
