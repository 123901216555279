import CircularProgress from "@mui/material/CircularProgress";
import { Stack } from "@mui/material";

export const Progress = () => {
  return (
    <Stack flex={1} alignItems="center" justifyContent="center" height="100%">
      <CircularProgress />
    </Stack>
  );
};
