import styled, { css } from "styled-components";
import { AssignmentStatus } from "../api/assignments/types";
import { styledComponentsTheme } from "../core/styledComponentsTheme";
import { limitTextRows } from "./styledMixins";

export const StyledColumn = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledLabel = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;

  .icon {
    width: 16px;
    fill: ${({ theme }) => theme.secondary.textAdditional};
  }
`;

export const StyledRow = styled.div`
  display: grid;
  grid-row-gap: 4px;
`;

export const colorByStatus: { [key in AssignmentStatus]: string } = {
  Направлено: styledComponentsTheme.legends.violet,
  "В работе": styledComponentsTheme.charts.blue,
  Доработка: styledComponentsTheme.charts.pink,
  Приёмка: styledComponentsTheme.charts.orange,
  Исполнено: styledComponentsTheme.legends.green,
};

interface TextProps {
  $colorTheme?: "primary" | "secondary" | "orange";
  $maxRows?: number;
  $isBold?: boolean;
  $maxWidth?: number;
}

export const StyledText = styled.div<TextProps>`
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: ${({ theme, $colorTheme = "primary" }) => {
    if ($colorTheme === "orange") {
      return theme.primary.warningMain;
    }

    return $colorTheme === "primary"
      ? theme.primary.textDark
      : theme.secondary.textAdditional;
  }};
  ${({ $maxRows = 1 }) => limitTextRows($maxRows)};
  ${({ $isBold }) =>
    $isBold &&
    css`
      font-weight: 500;
    `};
  ${({ $maxWidth }) =>
    $maxWidth &&
    css`
      max-width: ${$maxWidth}px;
    `};
`;

export const StyledExtension = styled.div`
  width: 40px;
  height: 24px;
  border-radius: 4px;
  background: ${({ theme }) => theme.primary.light};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px 4px;
  text-transform: uppercase;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
`;
