import { FC } from "react";
import { useTranslation } from "react-i18next";
import { List, Paper, Stack, Typography } from "@mui/material";
import { CompanyCard } from "../company-card";
import { CompaniesNavLink } from "./styled";
import { useAppContext } from "../../layouts/app-layout/context";
import { ColorDivider } from "../color-divider";
import { ScrollableContainer } from "../scrollable-container";
import { Company } from "../../api/companies";

type CompanyProps = {
  onAcceptInvitation: (companyId: Company["id"]) => void;
};

export const Companies: FC<CompanyProps> = ({ onAcceptInvitation }) => {
  const { t } = useTranslation("modules");

  const { companies } = useAppContext();

  return (
    <Stack
      sx={{
        maxWidth: 380,
        minWidth: 280,
        flex: 1,
        "*::-webkit-scrollbar": {
          width: "0px",
        },
      }}
    >
      <Paper
        elevation={0}
        sx={{
          borderRadius: 2,
          borderBottomRightRadius: 0,
          borderBottomLeftRadius: 0,
        }}
      >
        <Typography p="16px 32px 10px" variant="h6">
          {t("yourCompanies")}
        </Typography>
      </Paper>
      <ColorDivider />
      <ScrollableContainer>
        <Stack flex={1}>
          <Paper
            elevation={0}
            sx={{
              paddingBottom: 1,
              borderRadius: 2,
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
            }}
          >
            <List
              sx={{
                padding: 0,
                textDecoration: "none",
                flex: 1,
              }}
            >
              {companies?.map(
                ({ id, name, description, main, pendingInvite, avatarPreview }) => (
                  <Stack key={id}>
                    <CompaniesNavLink to={`/companies/${id}`}>
                      <CompanyCard
                        companyName={name}
                        companyId={id}
                        isMainCompany={main}
                        isPendingInvite={pendingInvite}
                        description={description || ""}
                        onAcceptInvitation={onAcceptInvitation}
                        avatarSrc={avatarPreview || ''}
                      />
                    </CompaniesNavLink>
                    <ColorDivider />
                  </Stack>
                )
              )}
            </List>
          </Paper>
        </Stack>
      </ScrollableContainer>
    </Stack>
  );
};
