import styled from "styled-components";

export const StyledPage = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;
`;

export const StyledBody = styled.div`
  width: 100%;
  height: calc(100% - 62px);
  display: grid;
  grid-template-columns: 300px 1fr;
  column-gap: 20px;
  justify-content: space-between;
  overflow-y: auto;
`;
