import styled from "styled-components";
import { IconButton } from "@mui/material";
import { Form } from "formik";

export const StyledModal = styled(Form)`
  padding: 30px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 24px 48px 0 #10182824, 0 -8px 24px 0 #10182814;
  width: 400px;
  margin: auto;
  display: grid;
  grid-row-gap: 12px;
  position: relative;
`;

export const StyledButtonClose = styled(IconButton)`
  position: absolute;
  right: 10px;
  top: 10px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.secondary.text};

  .icon {
    width: 16px;
  }
`;

export const StyledTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: ${({ theme }) => theme.primary.mainBlue};
  text-align: center;
`;

export const StyledDescription = styled.p`
  margin: 0;
  padding: 0;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 143%;
  white-space: pre-line;
`;

export const StyledPlaceholder = styled.div`
  font-size: 16px;
  opacity: 0.42;
  text-align: center;
`;

export const StyledButtons = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;
    margin-top: 16px;
`;
