import styled from "styled-components";
import { colorByStatus } from "../../../../../../../../common/assignmentsCommonStyles";
import { AssignmentStatus } from "../../../../../../../../api/assignments/types";

interface CellProps {
  $status?: AssignmentStatus;
}

export const StyledCell = styled.div<CellProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
  width: 100%;
  height: 100%;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto 0;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: ${({ $status }) =>
      $status ? colorByStatus[$status] : "transparent"};
  }
`;
