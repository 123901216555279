import { State } from "./assignment-by-division.types";
import {
  AssignmentPersonProfile,
  AssignmentsBranchResponse,
  AssignmentsFile,
} from "../../api/assignments/types";
import { AttachFile } from "../../components/assignments-file-attach-list/assignments-file-attach-list.types";
import { getAssignmentPersonName } from "../../utils/commonUtils";

function checkFiles(stateFiles: AttachFile[], serverFiles?: AssignmentsFile[]) {
  if (stateFiles.length !== serverFiles?.length) return true;
  if (!stateFiles.length) return false;

  const pureStateFiles = stateFiles.map(({ file }) => file);

  return JSON.stringify(pureStateFiles) !== JSON.stringify(serverFiles);
}

function checkExecutors(
  executors: State["executors"],
  serverExecutors?: AssignmentPersonProfile[]
) {
  if (!executors.length) return false;

  const pureExecutors = executors
    .map((item) => item.value)
    .filter((value) => value);

  const serverExecutorsNames = serverExecutors?.map((value) =>
    getAssignmentPersonName(value)
  );
  return !!pureExecutors.length && JSON.stringify(pureExecutors) !== JSON.stringify(serverExecutorsNames);
}

export function checkStateIsReady(
  state: State,
  assignmentData?: AssignmentsBranchResponse
) {
  const isStatusChanged = state.status !== assignmentData?.division.status;

  const isExecutorsChanged = checkExecutors(
    state.executors,
    assignmentData?.division.executors
  );
  const isFilesChanged = checkFiles(state.files, assignmentData?.division.files);

  return {
    isReady: isStatusChanged || isFilesChanged || isExecutorsChanged,
    isStatusChanged,
    isExecutorsChanged,
    isFilesChanged,
  };
}

export function prepareFilesBody(files: AttachFile[]) {
  const formData = new FormData();

  files.forEach((file) => {
    if (file.file instanceof Blob) formData.append("file", file.file);
    else {
      formData.append("existingFile", file.file.id.toString());
    }
  });
  formData.append('deleteOldFiles', "true");

  return formData;
}

export function prepareExecutorsBody(executors: State["executors"]) {
  return executors.map((value) => value.id).filter((value) => value) as number[];
}
