import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Stack, Typography } from "@mui/material";
import {
  MAX_WIDTH_PROFILE_BLOCK,
  MIN_WIDTH_PROFILE_BLOCK,
} from "../../../../utils/constants";
import { FieldForm } from "../../../../components/field-form";
import { AvatarFieldForm } from "../../../../components/avatar-field-form";
import { AccountFormProps } from "./account-form.types";
import { CompanySelect } from "../../../../components/company-select";
import { useAppContext } from "../../../../layouts/app-layout/context";
import { AutoComplete } from "../../../../components/auto-complete";

export const AccountForm: FC<AccountFormProps> = ({
  counterparties,
  userValues,
  divisions,
  mainCounterparty,
}) => {
  const { t } = useTranslation("profile");
  const { t: tUSer } = useTranslation("user");
  const { isAdmin } = useAppContext();

  const divisionOptions = useMemo(() => {
    if (!divisions?.length) return [];

    return divisions.map((division) => ({
      label: division,
      value: division,
    }));
  }, [divisions]);

  return (
    <Stack
      px={7.5}
      maxWidth={MAX_WIDTH_PROFILE_BLOCK}
      minWidth={MIN_WIDTH_PROFILE_BLOCK}
      flex={1}
      mb={2}
    >
      <Stack direction="row" spacing={2.5} mb={2.5}>
        <AvatarFieldForm
          name="avatar"
          needCrop
          placeholder={
            <Typography
              alignItems="center"
              textAlign="center"
              px={2}
              variant="body2"
            >
              {t("common:buttons.avatar")}
            </Typography>
          }
        />
        <Stack flex={1} justifyContent="space-between">
          <FieldForm
            version="profile"
            name="lastName"
            title={t("lastName")}
            placeholder={t("placeholders.lastName")}
          />
          <FieldForm
            version="profile"
            name="firstName"
            title={t("firstName")}
            placeholder={t("placeholders.firstName")}
          />
          <FieldForm
            version="profile"
            name="middleName"
            title={t("middleName")}
            placeholder={t("placeholders.notRequired")}
          />
        </Stack>
      </Stack>
      <Stack spacing={2}>
        <CompanySelect
          counterparties={counterparties}
          userValues={userValues}
          mainCounterparty={mainCounterparty}
          hasButtonAdd={isAdmin}
        />

        <label className="field-form-label">
          <Typography pb={0.5} color="secondary" pl={2}>
            {tUSer("divisionName")}
          </Typography>

          <AutoComplete
            options={divisionOptions}
            name="divisionName"
            placeholder={t("placeholders.divisionName")}
            isCreatable
            variant="profile"
            noOptionsMessage={tUSer("noOptions.division")}
          />
        </label>
        {/*<FieldForm
          version="profile"
          name="companyName"
          title={t("companyName")}
          placeholder={`${name} ${t("placeholders.notRequired")}`}
        />*/}
        <FieldForm
          version="profile"
          name="position"
          title={t("position")}
          placeholder={t("placeholders.position")}
        />
      </Stack>
    </Stack>
  );
};
