import { FC } from "react";
import { ICellRendererParams } from "ag-grid-community";
import { TextCellProps } from "./text-cell.types";
import {
  StyledCell,
  StyledGroupWrapper,
  StyledGroupName,
  StyledButtonHide,
} from "./text-cell.styles";
import { ReactComponent as GroupIcon } from "../../../../../../../../components/icons/imported-icons/group-docs.svg";
import { useTranslation } from "react-i18next";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {Tooltip} from "@mui/material";

export const TextCell: FC<ICellRendererParams<unknown, TextCellProps>> = ({
  value,
}) => {
  const { t } = useTranslation("assignments");

  if (!value?.isGroup) return <Tooltip title={value?.text} followCursor><StyledCell>{value?.text || ""}</StyledCell></Tooltip>;

  return (
    <StyledCell $isGroup>
      <StyledGroupWrapper>
        <GroupIcon className="group-icon" />
        <div>{`${t("assignmentsGroup")}:`}</div>
        <StyledGroupName>{value?.text || ""}</StyledGroupName>
      </StyledGroupWrapper>

      <StyledButtonHide
        endIcon={value?.isOpen ?  <ExpandMoreIcon /> : <ExpandLessIcon />}
        onClick={() => value?.onButtonHideClick()}
      >
        {value?.isOpen ? t("expand") : t("collapse") }
      </StyledButtonHide>
    </StyledCell>
  );
};
