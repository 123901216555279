import styled from "styled-components";
import { Box } from "@mui/material";

export const StyledPopover = styled.div`
  padding: 20px;
  position: relative;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 24px 48px 0 rgba(16, 24, 40, 0.14);
  width: 334px;
`;

export const StyledArrow = styled(Box)`
  position: absolute;
  width: 0;
  height: 0;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #fff;
  border-left: 10px solid transparent;
  top: -10px;
`;

export const StyledTitle = styled.div`
  text-align: center;
  font-size: 16px;
  line-height: 22px;
`;

export const StyledButtonsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 12px;
  margin-top: 12px;
`;
