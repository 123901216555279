import React, { FC, useCallback, useMemo } from "react";
import { StatusBlockProps } from "./status-block.types";
import {
  StyledBlock,
  StyledDateWrapper,
  StyledButtonProposeDate,
  StyledComment,
  StyledCommentFooter,
} from "./status-block.styles";
import {
  StyledColumn,
  StyledLabel,
  StyledText,
} from "../../../../common/assignmentsCommonStyles";
import DateRangeIcon from "@mui/icons-material/DateRange";
import { ReactComponent as StatusIcon } from "../../../../components/icons/imported-icons/agree.svg";
import { useTranslation } from "react-i18next";
import { AssignmentStatusSelect } from "../../../../components/assignment-status-select";
import dayjs from "dayjs";
import { DatePicker } from "../../../../components/date-picker";
import { ReactComponent as RequestIcon } from "../../../../components/icons/imported-icons/request.svg";
import { useField } from "formik";
import { useProposeDateMutation } from "../../../../api/assignments";
import { useAppContext } from "../../../../layouts/app-layout/context";
import { useSnackbar } from "notistack";
import CachedIcon from "@mui/icons-material/Cached";
import { getProposeDateErrorMessage } from "./status-block.service";
import { formatDate, getPersonInitials } from "../../../../utils/commonUtils";
import { AssignmentPendingDateTooltip } from "../../../../components/assignment-pending-date-tooltip";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import MessageIcon from "@mui/icons-material/Message";
import { Tooltip } from "@mui/material";

export const StatusBlock: FC<StatusBlockProps> = ({
  status,
  statuses,
  isDateChanged,
  assignmentID,
  isUserAllowEdit,
  pendingDateStatus,
  pendingDate,
  onChangeStatus,
  initialUpToDate,
  initialStatus,
  comment,
  commentDate,
  commentAuthor,
  onChangeUpToDate,
}) => {
  const { t } = useTranslation("assignments");
  const { t: tCommon } = useTranslation("common");
  const [, { value: upToDate }, { setValue: setUpToDate }] =
    useField("upToDate");
  const { enqueueSnackbar } = useSnackbar();
  const { currentCompanyId } = useAppContext();

  const [proposeDate, proposeDateData] = useProposeDateMutation();

  const isProposeDateDisabled =
    !isDateChanged ||
    !isUserAllowEdit ||
    pendingDateStatus === "awaiting" ||
    proposeDateData.isLoading;

  const isStatusDisabled =
    !isUserAllowEdit || (statuses.length === 0 && status === "Приёмка");

  const isProposeDateChanged = upToDate !== initialUpToDate;
  const showIconWithTooltip =
    (pendingDateStatus === "approved" || pendingDateStatus === "declined") &&
    !isProposeDateChanged;

  const minDate = useMemo(() => {
    const todayTimestamp = new Date().getTime();
    const upToDateTimestamp = new Date(initialUpToDate || 0).getTime();
    if (upToDateTimestamp < todayTimestamp) return dayjs(new Date());

    return dayjs(initialUpToDate);
  }, [initialUpToDate]);

  const formattedPendingDate = formatDate(pendingDate);

  const formattedCommentDate = formatDate(commentDate);
  const formattedCommentAuthor = commentAuthor
    ? `${commentAuthor.lastName} ${getPersonInitials({
        firstName: commentAuthor.firstName,
        middleName: commentAuthor.middleName,
        divider: ".",
      })}`
    : "";

  const showComment = !!comment && status !== 'Исполнено';

  const handleUpToDateChange = useCallback((date: dayjs.Dayjs | null) => {
    const newDate = date?.format("YYYY-MM-DD");
    if (date) {
      setUpToDate(newDate);
      onChangeUpToDate(newDate);
    }
  }, []);

  const handleButtonProposeDateClick = useCallback(async () => {
    try {
      if (!upToDate) return;

      const data = await proposeDate({
        companyID: currentCompanyId,
        assignmentID,
        body: { date: upToDate },
      });

      if ("error" in data && "data" in data.error) {
        enqueueSnackbar(
          getProposeDateErrorMessage(data.error.data as string, t),
          { variant: "error" }
        );
        return;
      }
      enqueueSnackbar(t("successMessages.proposeDate"), { variant: "success" });
    } catch {
      enqueueSnackbar(tCommon("errors.error"), { variant: "error" });
    }
  }, [assignmentID, currentCompanyId, upToDate]);

  const iconByStatus = {
    awaiting: <CachedIcon className="icon" />,
    declined: <CancelIcon className="icon" />,
    approved: <CheckCircleIcon className="icon" />,
  };

  const proposeValue =
    isUserAllowEdit && initialStatus !== "Приёмка" ? (
      <>
        <DatePicker
          name="upToDate"
          placeholder={t("upToDate.placeholder")}
          icon={DateRangeIcon}
          valueIntlOptions={{
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          }}
          onChange={handleUpToDateChange}
          minDate={minDate}
          disabled={pendingDateStatus === "awaiting"}
        />
        {pendingDateStatus === "awaiting" || showIconWithTooltip ? (
          <AssignmentPendingDateTooltip
            pendingDate={formattedPendingDate || "–"}
            status={pendingDateStatus}
            messageVariant="long"
          >
            <StyledButtonProposeDate
              disabled
              $pendingStatus={pendingDateStatus}
            >
              {iconByStatus[pendingDateStatus]}
            </StyledButtonProposeDate>
          </AssignmentPendingDateTooltip>
        ) : (
          <StyledButtonProposeDate
            disabled={isProposeDateDisabled}
            onClick={handleButtonProposeDateClick}
          >
            <RequestIcon className="icon" />
          </StyledButtonProposeDate>
        )}
      </>
    ) : (
      <div>{formatDate(upToDate)}</div>
    );

  return (
    <StyledBlock $hasComment={showComment}>
      <StyledColumn>
        <StyledLabel>
          <StatusIcon className="icon" />
          <StyledText $colorTheme="secondary">{`${t("status")}:`}</StyledText>
        </StyledLabel>

        <AssignmentStatusSelect
          options={statuses}
          value={status}
          disabled={isStatusDisabled}
          showPlainText={isStatusDisabled}
          onChange={onChangeStatus}
        />
      </StyledColumn>

      {showComment && (
        <>
          <StyledColumn>
            <StyledLabel>
              <MessageIcon className="icon" />
              <StyledText $colorTheme="secondary">{`${t(
                "revisionReason"
              )}:`}</StyledText>
            </StyledLabel>
          </StyledColumn>

          <div>
            <Tooltip title={comment || ""} followCursor>
              <StyledComment>{comment || ""}</StyledComment>
            </Tooltip>
            <StyledCommentFooter>
              <div>{formattedCommentAuthor}</div>
              <div>{formattedCommentDate}</div>
            </StyledCommentFooter>
          </div>
        </>
      )}

      <StyledColumn>
        <StyledLabel>
          <DateRangeIcon className="icon" />
          <StyledText $colorTheme="secondary">{`${t(
            "executionPeriod"
          )}:`}</StyledText>
        </StyledLabel>

        <StyledDateWrapper>{proposeValue}</StyledDateWrapper>
      </StyledColumn>
    </StyledBlock>
  );
};
