import React, { FC, useCallback, useContext, useEffect } from "react";
import {
  useCreateProjectMutation,
  useUpdateProjectMutation,
} from "../../../../api/companies";
import { useForm } from "../../../../hooks/use-form";
import { FormikProvider } from "formik";
import { useTranslation } from "react-i18next";
import { FormikValues } from "formik/dist/types";
import { validationProjectInfo } from "./validation";
import { StyledMain, StyledDivider, StyledForm } from "../../project.styles";
import { projectContext } from "../../context";
import { CommonInfo } from "./components/common-info";
import { ProjectGeography } from "./components/project-geography";
import { useAppContext } from "../../../../layouts/app-layout/context";
import {
  projectFinancingTypes,
  projectTypes,
  projectStatuses,
} from "../../../../common/commonConstants";
import {
  fromTimezoneOffsetToTimezone,
  fromTimezoneToTimezoneOffset,
} from "./project-info.service";
import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";
import {
  enProjectFinancingTypes,
  enProjectStatuses,
} from "../../../projects/projects.constants";
import {
  enProjectTypesShort,
  projectCreationInitialValues,
} from "./project-info.constants";
import { useSelector } from "react-redux";
import { projectSelector } from "../../../../services/slices/project";

export const ProjectInfo: FC = () => {
  const { t: commonT } = useTranslation("common");
  const { enqueueSnackbar } = useSnackbar();
  const { currentCompanyId } = useAppContext();
  const navigate = useNavigate();
  const params = useParams();
  const { info: project } = useSelector(projectSelector);

  const projectID = params.projectID ? Number(params.projectID) : null;

  const { updateFormState, formsState, dictionaries } =
    useContext(projectContext);

  const [createProject] = useCreateProjectMutation();
  const [updateProject] = useUpdateProjectMutation();

  const initialValues: FormikValues = projectID
    ? {
        shortName: project?.shortName || "",
        fullName: project?.fullName || "",
        status: project?.status ? enProjectStatuses[project.status] : "",
        projectType: project?.projectType
          ? enProjectTypesShort[project.projectType]
          : "",
        financingType: project?.financingType
          ? enProjectFinancingTypes[project.financingType]
          : "",
        price: project?.price || "",
        region: project?.region || "",
        timezone:
          fromTimezoneToTimezoneOffset(
            project?.timezone,
            dictionaries.timezones
          ) || "",
        address: project?.address || "",
      }
    : projectCreationInitialValues;

  const handleSubmit = useCallback(
    async (values: FormikValues) => {
      const price = values.price ? Number(values.price) : null;
      const timezone = fromTimezoneOffsetToTimezone(
        values.timezone,
        dictionaries.timezones
      );
      try {
        const body = {
          address: values.address,
          financingType: projectFinancingTypes[values.financingType],
          fullName: values.fullName,
          projectType: projectTypes[values.projectType],
          region: values.region,
          shortName: values.shortName,
          status: projectStatuses[values.status],
          timezone,
          ...(typeof price === "number" && { price }),
        };

        projectID
          ? await updateProject({
              projectID,
              companyID: currentCompanyId,
              body,
            })
          : await createProject({
              companyID: currentCompanyId,
              body,
            });
        navigate(`/companies/${currentCompanyId}/projects`);
      } catch {
        enqueueSnackbar(commonT("errors.error"), {
          variant: "error",
        });
      }
    },
    [dictionaries.timezones, projectID]
  );

  const { formik, isSubmitDisabled, validateForm } = useForm({
    validationSchema: validationProjectInfo,
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: true,
    initialValues,
    onSubmit: handleSubmit,
  });
  const { values, dirty } = formik;

  useEffect(() => {
    validateForm();
    updateFormState({
      ...formsState,
      projectInfo: {
        isChanged: dirty,
        isSubmitDisabled: projectID
          ? JSON.stringify(values) === JSON.stringify(initialValues)
          : isSubmitDisabled,
      },
    });
  }, [values, isSubmitDisabled, dirty]);

  return (
    <StyledMain>
      <FormikProvider value={formik}>
        <StyledForm id="projectInfo">
          <CommonInfo values={values} />

          <StyledDivider />

          <ProjectGeography values={values} />
        </StyledForm>
      </FormikProvider>
    </StyledMain>
  );
};
