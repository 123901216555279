import { api } from "../api";
import {
  GetAssignmentsListParams,
  GetAssignmentListResponse,
  GetCreationDataParams,
  AssignmentsCreationData,
  AssignmentPersonProfile,
  CreateAssignmentParams,
  UpdateAssignmentParams,
  AssignmentsResponse,
  AssignmentListFiltersResponse,
  GetAssignmentViewParams,
  GetAssignmentViewResponse,
  ChangeStatusParams,
  ApproveAssignmentDateParams,
  GetAssignmentsBranchListParams,
  GetAssignmentsBranchListResponse,
  GetAssignmentsBranchFiltersParams,
  GetAssignmentsBranchFiltersResponse,
  AssignmentsBranchResponse,
  ProposeDateParams,
  ChangeBranchStatusParams,
  UpdateBranchFilesParams,
  SetExecutorsParams,
} from "./types";

export const assignmentsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAssignmentsList: build.query<
      GetAssignmentListResponse,
      GetAssignmentsListParams
    >({
      query: ({ companyID, body, ...params }) => {
        return {
          url: `/companies/${companyID}/assignments/list`,
          body,
          params,
          method: "POST",
        };
      },
      providesTags: ["Assignments"],
    }),

    getAssignmentsCreationData: build.query<
      AssignmentsCreationData,
      GetCreationDataParams
    >({
      query: ({ companyID, ...params }) => {
        return {
          url: `/companies/${companyID}/assignments/get-creation-data`,
          params,
          method: "GET",
        };
      },
      providesTags: ["Assignments"],
    }),

    createAssignment: build.mutation<
      AssignmentPersonProfile,
      CreateAssignmentParams
    >({
      query: ({ companyID, body }) => {
        return {
          url: `/companies/${companyID}/assignments/create`,
          body,
          method: "POST",
        };
      },
      invalidatesTags: ["Assignments"],
    }),

    updateAssignment: build.mutation<
      AssignmentPersonProfile,
      UpdateAssignmentParams
    >({
      query: ({ companyID, assignmentID, body }) => {
        return {
          url: `/companies/${companyID}/assignments/${assignmentID}/update`,
          body,
          method: "PATCH",
        };
      },
      invalidatesTags: ["Assignments"],
    }),

    getAssignment: build.query<
      AssignmentsResponse,
      Pick<UpdateAssignmentParams, "companyID" | "assignmentID">
    >({
      query: ({ companyID, assignmentID }) => {
        return {
          url: `/companies/${companyID}/assignments/${assignmentID}/get`,
          method: "GET",
        };
      },
      providesTags: ["Assignments"],
    }),

    deleteAssignment: build.mutation<
      AssignmentPersonProfile,
      Pick<UpdateAssignmentParams, "companyID" | "assignmentID">
    >({
      query: ({ companyID, assignmentID }) => {
        return {
          url: `/companies/${companyID}/assignments/${assignmentID}/delete`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["Assignments"],
    }),

    getAssignmentsListFilters: build.query<
      AssignmentListFiltersResponse,
      Pick<GetAssignmentsListParams, "companyID" | "body">
    >({
      query: ({ companyID, body }) => {
        return {
          url: `/companies/${companyID}/assignments/list-filters`,
          method: "POST",
          body,
        };
      },
      providesTags: ["Assignments"],
    }),

    getAssignmentsViewByCentralOffice: build.query<
      GetAssignmentViewResponse,
      GetAssignmentViewParams
    >({
      query: ({ companyID, assignmentID, ...params }) => {
        return {
          url: `/companies/${companyID}/assignments/${assignmentID}/view`,
          method: "GET",
          params,
        };
      },
      providesTags: ["Assignments"],
    }),

    changeAssignmentStatus: build.mutation<
      GetAssignmentViewResponse,
      ChangeStatusParams
    >({
      query: ({ companyID, assignmentID, body }) => {
        return {
          url: `/companies/${companyID}/assignments/${assignmentID}/change-status`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["Assignments"],
    }),

    approveAssignment: build.mutation<
      GetAssignmentViewResponse,
      ApproveAssignmentDateParams
    >({
      query: ({ companyID, assignmentID, body }) => {
        return {
          url: `/companies/${companyID}/assignments/${assignmentID}/approve-date`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["Assignments"],
    }),

    declineAssignment: build.mutation<
      GetAssignmentViewResponse,
      ApproveAssignmentDateParams
    >({
      query: ({ companyID, assignmentID, body }) => {
        return {
          url: `/companies/${companyID}/assignments/${assignmentID}/decline-date`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["Assignments"],
    }),

    getAssignmentsBranchList: build.query<
      GetAssignmentsBranchListResponse,
      GetAssignmentsBranchListParams
    >({
      query: ({ companyID, body, ...params }) => {
        return {
          url: `/companies/${companyID}/assignments/branch/list`,
          method: "POST",
          body,
          params,
        };
      },
      providesTags: ["Assignments"],
    }),

    getAssignmentsBranchFilters: build.query<
      GetAssignmentsBranchFiltersResponse,
      GetAssignmentsBranchFiltersParams
    >({
      query: ({ companyID, body, ...params }) => {
        return {
          url: `/companies/${companyID}/assignments/branch/list-filters`,
          method: "POST",
          body,
          params,
        };
      },
      providesTags: ["Assignments"],
    }),

    getAssignmentBranchByDivision: build.query<
      AssignmentsBranchResponse,
      Omit<GetAssignmentViewParams, "status">
    >({
      query: ({ companyID, assignmentID }) => {
        return {
          url: `/companies/${companyID}/assignments/branch/${assignmentID}/view`,
          method: "GET",
        };
      },
      providesTags: ["Assignments"],
    }),

    proposeDate: build.mutation<AssignmentsBranchResponse, ProposeDateParams>({
      query: ({ companyID, assignmentID, body }) => {
        return {
          url: `/companies/${companyID}/assignments/branch/${assignmentID}/propose-date`,
          method: "POST",
          body,
        };
      },
      /*async onQueryStarted(
        { companyID, assignmentID },
        { dispatch, queryFulfilled }
      ) {
        const { data } = await queryFulfilled;
        dispatch(
          assignmentsApi.util.updateQueryData(
            "getAssignmentBranchByDivision",
            { companyID, assignmentID },
            (draft) => {
              Object.assign(draft, {
                division: {
                  ...draft.division,
                  pendingDateStatus: data.division.pendingDateStatus,
                },
              });
            }
          )
        );
      },*/
      invalidatesTags: ["Assignments"],
    }),

    changeBranchStatus: build.mutation<
      AssignmentsBranchResponse,
      ChangeBranchStatusParams
    >({
      query: ({ companyID, assignmentID, body }) => {
        return {
          url: `/companies/${companyID}/assignments/branch/${assignmentID}/change-status`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["Assignments"],
    }),

    updateBranchFiles: build.mutation<
      AssignmentsBranchResponse,
      UpdateBranchFilesParams
    >({
      query: ({ companyID, assignmentID, body }) => {
        return {
          url: `/companies/${companyID}/assignments/branch/${assignmentID}/update-files`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["Assignments"],
    }),

    setExecutors: build.mutation<AssignmentsBranchResponse, SetExecutorsParams>(
      {
        query: ({ companyID, assignmentID, body }) => {
          return {
            url: `/companies/${companyID}/assignments/branch/${assignmentID}/set-executors`,
            method: "POST",
            body,
          };
        },
        invalidatesTags: ["Assignments"],
      }
    ),
  }),
  overrideExisting: false,
});

export const {
  useGetAssignmentsListQuery,
  useGetAssignmentsCreationDataQuery,
  useCreateAssignmentMutation,
  useUpdateAssignmentMutation,
  useGetAssignmentQuery,
  useDeleteAssignmentMutation,
  useGetAssignmentsListFiltersQuery,
  useGetAssignmentsViewByCentralOfficeQuery,
  useChangeAssignmentStatusMutation,
  useApproveAssignmentMutation,
  useDeclineAssignmentMutation,
  useGetAssignmentsBranchListQuery,
  useLazyGetAssignmentsBranchListQuery,
  useGetAssignmentsBranchFiltersQuery,
  useLazyGetAssignmentsBranchFiltersQuery,
  useGetAssignmentBranchByDivisionQuery,
  useProposeDateMutation,
  useChangeBranchStatusMutation,
  useUpdateBranchFilesMutation,
  useSetExecutorsMutation,
} = assignmentsApi;
