import { FC, useMemo } from "react";
import { ReactComponent as DashboardIcon } from "../../../../../../components/icons/project/dashboard.svg";
import { ReactComponent as QcIcon } from "../../../../../../components/icons/project/qc.svg";
import { ReactComponent as DesignIcon } from "../../../../../../components/icons/project/desing.svg";
import { ReactComponent as RmIcon } from "../../../../../../components/icons/project/rm.svg";
import { ReactComponent as OfferIcon } from "../../../../../../components/icons/project/offer.svg";
import { ReactComponent as FinanceIcon } from "../../../../../../components/icons/project/finance.svg";
import { ReactComponent as ProcurementIcon } from "../../../../../../components/icons/project/procurement.svg";
import { ReactComponent as AsbuiltDocIcon } from "../../../../../../components/icons/imported-icons/asbuilt_doc-small.svg";
import { ModuleStatus, ModuleType } from "../../../../../../api/companies";
import { useTranslation } from "react-i18next";
import { useAppContext } from "../../../../../../layouts/app-layout/context";
import { StyledList, StyledModule } from "./modules-list.styles";
import { ModulesListProps } from "./modules-list.types";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import ErrorIcon from "@mui/icons-material/Error";

export const ModulesList: FC<ModulesListProps> = ({ modules }) => {
  const { t } = useTranslation("projects");
  const { availableModules } = useAppContext();

  const modulesData = useMemo(
    () =>
      [
        {
          key: ModuleType.design,
          title: t("card.modules.design"),
          icon: <DesignIcon className="icon" />,
        },
        {
          key: ModuleType.dashboard,
          title: t("card.modules.dashboard"),
          icon: <DashboardIcon className="icon" />,
        },
        {
          key: ModuleType.procurement,
          title: t("card.modules.procurement"),
          icon: <ProcurementIcon className="icon" />,
        },
        {
          key: ModuleType.finance,
          title: t("card.modules.finance"),
          icon: <FinanceIcon className="icon" />,
        },
        {
          key: ModuleType.offer,
          title: t("card.modules.offer"),
          icon: <OfferIcon className="icon" />,
        },
        {
          key: ModuleType.resource_managment,
          title: t("card.modules.resource_managment"),
          icon: <RmIcon className="icon" />,
        },
        /*{
          key: ModuleType.maintenance,
          title: t("card.modules.maintenance"),
          icon: <QcIcon className="icon" />,
        },*/
        /*{
          key: ModuleType.analytics,
          title: t("card.modules.analytics"),
          icon: <QcIcon className="icon" />,
        },*/
        {
          key: ModuleType.quality_control,
          title: t("card.modules.quality_control"),
          icon: <QcIcon className="icon" />,
        },
        {
          key: ModuleType.asbuilt_doc,
          title: t("card.modules.asbuilt_doc"),
          icon: <AsbuiltDocIcon className="icon" />,
        },
      ].filter(({ key }) => availableModules.includes(key)),
    [availableModules]
  );

  const getIconByModuleStatus = (status?: ModuleStatus) => {
    if (!status) return <VisibilityOffIcon className="icon-disabled" />;

    switch (status) {
      case "disabled":
        return <VisibilityOffIcon className="icon-disabled" />;
      case "enabled_missed_data":
        return <ErrorIcon className="icon-missed-data" />;
    }
  };

  return (
    <StyledList>
      {modulesData.map(({ key, title, icon }) => (
        <StyledModule
          key={key}
          $isDisabled={!modules[key] || modules[key] === "disabled"}
        >
          {icon} {title}
          {getIconByModuleStatus(modules[key])}
        </StyledModule>
      ))}
    </StyledList>
  );
};
