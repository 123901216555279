import {
  StyledHeader,
  StyledRightAside,
  StyledButtonSave,
  StyledButtonDelete,
} from "./header.styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Button } from "@mui/material";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../../../layouts/app-layout/context";
import DeleteIcon from "@mui/icons-material/Delete";
import { FC } from "react";
import { HeaderProps } from "./header.types";

export const Header: FC<HeaderProps> = ({
  isReady,
  onDeleteClick,
  isUserAllowEdit,
  onSave,
  canDelete,
}) => {
  const { t: tCommon } = useTranslation("common");
  const navigate = useNavigate();
  const { currentCompanyId } = useAppContext();

  const handleButtonBack = useCallback(() => {
    navigate(`/companies/${currentCompanyId}/assignments`);
  }, [currentCompanyId, navigate]);

  return (
    <StyledHeader>
      <Button startIcon={<ArrowBackIcon />} onClick={handleButtonBack}>
        {tCommon("buttons.back")}
      </Button>

      <StyledRightAside>
        {isUserAllowEdit && (
          <StyledButtonSave
            variant="contained"
            disabled={!isReady || !isUserAllowEdit}
            onClick={onSave}
          >
            {tCommon("buttons.save")}
          </StyledButtonSave>
        )}
        <StyledButtonDelete onClick={onDeleteClick} disabled={!canDelete}>
          <DeleteIcon className="icon" />
        </StyledButtonDelete>
      </StyledRightAside>
    </StyledHeader>
  );
};
