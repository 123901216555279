import styled from "styled-components";

export const StyledAgGridWrapper = styled.div`
  width: 100%;
  height: 100%;

  .ag-theme-alpine {
    width: 100%;
    height: 100%;
    --ag-selected-row-border-color: none;
    --ag-range-selection-border-color: transparent;
    --ag-border-color: #0000001a;
    --ag-header-cell-moving-background-color: #0000001a;
  }

  .ag-root-wrapper {
    border: 1px solid #0000001a;
    border-radius: 16px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: auto;
  }

  .ag-header {
    border-bottom: 1px solid #0000001a;
  }

  .ag-header-cell,
  .ag-header-group-cell {
    padding: 0;
    white-space: pre-line;
  }

  .ag-header-container,
  .ag-pinned-left-header,
  .ag-header-viewport {
    background: ${({ theme }) => theme.primary.blueShades};
  }

  .ag-pinned-left-header {
    border-right: 0;
  }

  .ag-header-cell {
    padding: 0 12px;

    .ag-header-cell-label,
    .ag-header-group-cell,
    .ag-header-cell-comp-wrapper {
      justify-content: start;
    }

    &:not(:last-child) {
      .ag-header-cell-label,
      .ag-header-group-cell,
      .ag-header-cell-comp-wrapper {
        &::after {
          position: absolute;
          content: "";
          width: 1px;
          height: 100%;
          background: #0000001a;
          right: 0;
          top: 0;
        }
      }
    }
  }

  .ag-header-group-cell-with-group {
    box-shadow: inset -1px 0 #00245f1a;
  }

  .ag-header-cell-comp-wrapper {
    &:hover {
      background: ${({ theme }) => theme.primary.blueShades};
    }
  }

  .ag-header-cell-text,
  .ag-header-group-text {
    color: ${({ theme }) => theme.primary.textDark};
    font-size: 14px;
    text-align: center;
    font-weight: 500;
  }

  .ag-header-cell_align-bottom {
    .ag-header-cell-label {
      align-self: end;
      padding-bottom: 6px;
    }
  }

  .ag-header-row:not(:first-child),
  .ag-header-cell:not(.ag-header-span-height.ag-header-span-total) {
    border-top: none;
  }

  .ag-row {
    white-space: unset;
  }

  .ag-row_interactive:hover {
    cursor: pointer;
  }

  .ag-cell {
    white-space: unset;
    padding: 11px 12px;
    color: ${({ theme }) => theme.primary.textDark};
    height: 100%;
    border-width: 0;

    &:not(:last-child) {
      box-shadow: inset -1px 0 #0000001a;
    }

    &.ag-cell-last-left-pinned:not(.ag-cell-range-right):not(.ag-cell-range-single-cell) {
      border-right: none !important;
    }

    &.ag-cell_padding_0 {
      padding: 0;
    }

    &.ag-cell_box-shadow_disabled {
      box-shadow: none;
    }
  }

  .ag-cell_horizontal-align_center {
    text-align: center;
  }

  .ag-cell_flex {
    display: flex;
  }

  .ag-cell_align-items_center {
    align-items: center;
  }

  .ag-cell_justify-content_center {
    justify-content: center;
  }

  .ag-cell_justify-content_end {
    justify-content: end;
  }

  .ag-cell_horizontal-align_end {
    text-align: end;
  }

  .ag-cell-focus {
    border: none !important;
  }

  .ag-cell_type_current-period {
    box-shadow: #e5e9ef 0 -1px 0 inset, #e5e9ef -1px 0 0 inset,
      #001a4d14 0 0 250px inset;
  }
`;
