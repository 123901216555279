import * as yup from "yup";
import { MAX_PASSWORD_LENGTH_VALIDATION } from "../../../utils/constants";

export const validationSetNewPassword = yup.object({
  password: yup
    .string()
    .trim()
    .min(MAX_PASSWORD_LENGTH_VALIDATION, "common:errors.min")
    .required("common:errors.required"),
  repeatPassword: yup
    .string()
    .trim()
    .oneOf([yup.ref("password"), null], "common:errors.passwordsNotMatch")
    .required("common:errors.required"),
});
