import styled from "styled-components";
import { Form } from "formik";
import { disableArrowFromInput } from "../../common/styledMixins";

export const StyledForm = styled(Form)`
  display: grid;
  grid-row-gap: 12px;
  align-content: start;

  .Mui-disabled {
    background: ${({ theme }) => theme.primary.blueShades};

    .MuiSvgIcon-root {
      display: none;
    }
  }

  .upload-user-file_disabled .MuiAvatar-root {
    background: ${({ theme }) => theme.primary.blueShades};
  }
`;

export const StyledRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .MuiFormControl-root {
    width: 236px;
  }
`;

export const StyledKey = styled.div`
  font-size: 14px;
  line-height: 130%;
  font-weight: 500;
`;

export const StyledMultiRows = styled.div`
  display: flex;
  margin-top: 12px;

  .MuiFilledInput-input {
    ${disableArrowFromInput};
  }
`;

export const StyledInputsWrapper = styled.div`
  display: grid;
  grid-row-gap: 4px;
  margin-left: 20px;
  align-content: start;

  .MuiFilledInput-root {
    width: 236px;
  }
`;

export const StyledPlaceholder = styled.div`
  font-size: 16px;
  opacity: 0.42;
`;

export const StyledTextAreaWrapper = styled.div`
  .MuiFilledInput-root {
    padding: 0;
  }
`;

export const StyledButtonsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
  margin-top: 8px;
`;
