import styled from "styled-components";
import { Button } from "@mui/material";

export const StyledDivisionsList = styled.ul`
  width: 100%;
  height: 100%;
  display: grid;
  grid-row-gap: 16px;
  align-items: start;
  margin: 0;
  padding: 0;
`;

export const StyledButtonAdd = styled(Button)`
  max-width: 250px;
  margin-left: auto;
  background: ${({ theme }) => theme.primary.blueShades};
  box-shadow: none;
  color: ${({ theme }) => theme.primary.mainBlue};
  font-size: 13px;

  &:hover {
    background: ${({ theme }) => theme.primary.blueShades};
    box-shadow: none;
  }
`;
