import { FiltersState } from "./assignments-by-co.types";

export function getFiltersParams(filtersState: FiltersState, keys: string[]) {
  const notArrayKeys = ['upToDateTo', 'upToDateFrom']

  return Object.entries(filtersState).reduce(
    (accumulator, [key, value]) => {
      if (notArrayKeys.includes(key)) return {
        ...accumulator,
        ...(keys.includes(key) && value && { [key]: value }),
      }

      return {
        ...accumulator,
        ...(keys.includes(key) && value?.length && { [key]: value }),
      }
    },
    {}
  );
}
