import { StatusKey } from "./statuses-chart.types";
import { AssignmentStatus } from "../../../../../../api/assignments/types";

export const fromStatusKeyToLabel = (value: StatusKey) => {
  switch (value) {
    case "sent":
      return "Направлено";
    case "inProgress":
      return "В работе";
    case "finishing":
      return "Доработка";
    case "acceptance":
      return "Приемка";
    case "completed":
      return "Исполнено";
    default:
      return "Направлено";
  }
};

export const fromStatusToKey = (value: AssignmentStatus) => {
  switch (value) {
    case "Направлено":
      return "sent";
    case "В работе":
      return "inProgress";
    case "Доработка":
      return "finishing";
    case "Приёмка":
      return "acceptance";
    case "Исполнено":
      return "completed";
    default:
      return "sent";
  }
};
