import { FC, useEffect } from "react";
import { DeleteModal } from "../../../../components/delete-modal";
import { DeleteModalProps } from "../../../../components/delete-modal/delete-modal.types";
import { useDeleteCounterpartyMutation } from "../../../../api/counterparty";
import { useAppContext } from "../../../../layouts/app-layout/context";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { CustomSnackbarContent } from "../../../../components/custom-snackbar-content/custom-snackbar-content";
import { useNavigate } from "react-router-dom";

export const DeleteCounterpartyModal: FC<DeleteModalProps> = (props) => {
  const { currentCompanyId } = useAppContext();
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams<{ id: string }>();
  const counterpartyID = Number(params.id);
  const { t } = useTranslation("counterparty");
  const navigate = useNavigate();

  const [deleteCounterparty, deleteCounterpartyResult] =
    useDeleteCounterpartyMutation();

  useEffect(() => {
    if (deleteCounterpartyResult.isError) {
      enqueueSnackbar(
        <CustomSnackbarContent
          title={t("deleteModal.errorSnackTitle")}
          message={t("deleteModal.errorSnackDescription")}
        />,
        {
          variant: "error",
        }
      );
    }
  }, [deleteCounterpartyResult.isError]);

  useEffect(() => {
    if (deleteCounterpartyResult.isSuccess) {
      enqueueSnackbar(
        <CustomSnackbarContent
          title={t("deleteModal.successSnackTitle")}
          message={t("deleteModal.successSnackDescription")}
        />,
        {
          variant: "success",
        }
      );
    }
  }, [deleteCounterpartyResult.isSuccess]);

  const handleConfirm = async () => {
    await deleteCounterparty({
      companyID: currentCompanyId,
      counterpartyID,
    });
    props.onClose();
    navigate(-1);
  };

  return (
    <>
      <DeleteModal {...props} onConfirm={handleConfirm} />
    </>
  );
};
