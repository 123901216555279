import styled from "styled-components";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
} from "@mui/material";
import { textOneRow } from "../../../../common/styledMixins";

export const StyledAccordion = styled(Accordion)`
  box-shadow: none;
  min-height: 38px;

  &.Mui-expanded {
    margin: 0;
  }

  &::before {
    display: none;
  }
`;

export const StyledAccordionSummary = styled(AccordionSummary)`
  min-height: 38px;
  border-bottom: 1px solid ${({ theme }) => theme.secondary.backgroundDivider};
  color: #7890b2;
  font-size: 14px;
  line-height: 20px;
  padding: 0 0 13px 0;

  &.Mui-expanded {
    min-height: 38px;
    border-bottom: 1px solid ${({ theme }) => theme.secondary.backgroundDivider};
  }

  .MuiAccordionSummary-expandIconWrapper {
    position: absolute;
    left: 0;
    width: 20px;
    height: 20px;

    .MuiSvgIcon-root {
      width: 20px;
    }
  }

  .MuiAccordionSummary-content {
    padding-left: 28px;
    margin: 0;
    align-items: center;
  }
`;

export const StyledNameWrapper = styled.div`
  display: flex;
  align-items: center;
  max-width: 350px;

  .icon {
    width: 20px;
    fill: #7890b2;
    margin: auto 0 auto 4px;
  }
`;

export const StyledName = styled.div`
  ${textOneRow};
`;

export const StyledAccordionDetails = styled(AccordionDetails)`
  padding: 10px 0 0;
`;

export const StyledButtonWrapper = styled.div`
  margin-left: auto;
  display: flex;
  column-gap: 8px;
`;

export const StyledHeadName = styled.span`
  font-weight: 600;
  font-size: 14px;
`;
