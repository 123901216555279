import styled, { css } from "styled-components";
import { TextField } from "@mui/material";
import { Button } from "@mui/material";

export const StyledProjects = styled.div`
  padding: 10px 20px 20px;
  width: 100%;
  height: 100%;
  max-width: 1400px;
  min-width: 1070px;
  margin: 0 auto;
`;

export const StyledHeader = styled.header`
  min-height: 66px;
  display: grid;
  grid-template-columns: /*255px */1fr 113px;
  justify-content: space-between;
  align-items: center;
  gap: 5px 32px;
  padding: 0 20px;
  width: 100%;
`;

export const StyledTitle = styled.h6`
  margin: 0;
  font-size: 20px;
  font-weight: 400;
  line-height: 23px;
  color: ${({ theme }) => theme.secondary.textAdditional};
`;

export const StyledSearch = styled(TextField)`
  padding: 0;
  width: 50%;

  .MuiOutlinedInput-root {
    height: 36px;
    border-radius: 6px;
    border: 1px solid ${({ theme }) => theme.secondary.grayLight};
  }

  .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  .MuiOutlinedInput-input {
    &::placeholder {
      font-size: 16px;
      color: ${({ theme }) => theme.primary.disabled};
      opacity: 1;
    }
  }
`;

export const StyledList = styled.div`
  display: grid;
  align-content: start;
  overflow-y: auto;
`;

interface ButtonProps {
  $isVisible: boolean;
}

export const StyledButton = styled(Button)<ButtonProps>`
  ${({ $isVisible }) =>
    !$isVisible &&
    css`
      visibility: hidden;
    `};
`;
