import { Access, ProfileByCompany } from "../profile";

export type Company = {
  admin: boolean;
  id: number;
  main: boolean;
  name: string;
  owner: boolean;
  pendingInvite: boolean;
  description?: string;
  avatar: string | null;
  avatarPreview: string | null;
};

export type CompaniesListResponse = {
  data: Company[];
};

export type Model = {
  auth: string;
};

export type Module = {
  model: Model;
  url: string;
};

export enum ModuleType {
  dashboard = "dashboard",
  finance = "finance",
  procurement = "procurement",
  design = "design",
  quality_control = "quality_control",
  offer = "offer",
  crm = "crm",
  resource_managment = "resource_managment",
  analytics = "analytics",
  tracker = "tracker",
  tender = "tender",
  equip = "equip",
  supply = "supply",
  workforce = "workforce",
  maintenance = "maintenance",
  asbuilt_doc = "asbuilt_doc",

}

export type CompanyName =
  | "step"
  | "nn"
  | "vls"
  | "gm"
  | "kfha"
  | "pyrus"
  | "nlmk"
  | "oes"
  | "most"
  | "eks"
  | "ap"
  | "ig"
  | "energomost"
  | "sti"
  | "rzd"
  | "ecolant"
  | "smoldor"
  | "zs"
  | "cg"
  | "fd"
  | "rgs"
  | "sp"
  | "geo";

export const companyNameByDomain: Record<CompanyName, string> = {
  step: "ООО «СТЭП»",
  nn: "ПАО ГМК «Норникель»",
  vls: 'ООО "Велесстрой"',
  gm: "ООО «ГенМастер»",
  kfha: "КФХ Абдулвагабов Магомедамир Ахмедович",
  pyrus: "Pyrus",
  nlmk: "Группа НЛМК",
  oes: "АО «Оргэнергострой»",
  most: "АО «СК «Мост»",
  eks: "АО «ГК «ЕКС»",
  ap: "ООО «Автоматизация Производств»",
  ig: "Incepta Group",
  energomost: "ООО «ЭСК «Энергомост»",
  sti: "ООО «СТИ»",
  rzd: "ОАО «РЖД»",
  ecolant: "ООО «Эколант»",
  smoldor: "ООО «Смол-ДорНИИ-Проект»",
  zs: "АО «Золото Селигдара»",
  cg: "Capital Group",
  fd: "ООО «Физир Девелопмент»",
  rgs: "ФГКУ Росгранстрой",
  sp: "ООО «СтройПроект»",
  geo: "ГЕО",
};

export enum ModuleStatuses {
  available = "available",
  online = "online",
  offline = "offline",
  unpaid = "unpaid",
  in_development = "in_development",
  planned = "planned",
}

export type ModuleStatusItem = {
  landingUrl: string;
  status: ModuleStatuses;
  type: ModuleType;
};

export type ModulesResponse = Partial<Record<ModuleType, Module>> & {
  services: ModuleStatusItem[];
  statuses: Partial<Record<ModuleType, ModuleStatuses>>;
};
export const SORTED_ACTIVE_STATUSES = [
  ModuleStatuses.available,
  ModuleStatuses.online,
  ModuleStatuses.offline,
];
export const SORTED_NOT_ACTIVE_STATUSES = [
  ModuleStatuses.in_development,
  ModuleStatuses.planned,
];

export interface CommonCompanyParams {
  companyID: number;
}

export interface CommonProjectParams extends CommonCompanyParams {
  projectID: number;
}

export interface CompanyAdminData {
  avatar: string | null;
  avatarPreview: string | null;
  clientID: string;
  fullName: string | null;
  id: number;
  inn: string | null;
  kpp: string | null;
  legalAddress: string | null;
  ogrn: string | null;
  name: string;
  access: Access;
}

export interface UpdateCompanyAdminInfoParams extends CommonCompanyParams {
  body: Omit<CompanyAdminData, "avatar" | "avatarPreview" | "clientID" | "id">;
}

export interface UpdateCompanyAdminInfoWithAvatarParams
  extends CommonCompanyParams {
  body: FormData;
}

export interface UploadAvatarParams extends CommonCompanyParams {
  body: FormData;
}

export interface UpdateProfileByCompanyRequest
  extends Pick<
    ProfileByCompany,
    "position" | "firstName" | "lastName" | "middleName" | "division" | "all"
  > {
  companyId: Company["id"];
  company?: {
    counterpartyID: number | null;
  };
}

export interface GetProjectsListRequest extends CommonCompanyParams {
  limit: number;
  offset: number;
  search?: string;
}

export interface GetFilteredProjectsListRequest extends GetProjectsListRequest {
  body: Partial<ProjectListFilter>;
}

export interface GetProjectsListResponse {
  data: ProjectInfo[];
  total: number;
}

export interface ProjectInfo {
  address: string;
  financingType: FinancingType;
  fullName: string;
  id: number;
  price: number;
  pricePretty?: PrettyPrice;
  projectType: ProjectType;
  region: string;
  shortName: string;
  status: ProjectStatus;
  timezone: Timezone;
  favourite: boolean;
  architector?: ProjectCompanyShort;
  contractor?: ProjectCompanyShort;
  counterpartyID?: number;
  curator?: ProjectResponsiblePerson;
  customer?: ProjectCompanyShort;
  director?: ProjectResponsiblePerson;
  modules: Modules;
}

interface ProjectCompanyShort {
  myOwnCompany: boolean;
  name?: string;
  recordID: number;
}

export interface Modules {
  [key: string]: ModuleStatus;
}

export type ModuleStatus =
  | "enabled_ok"
  | "enabled_missed_data"
  | "disabled"
  | "missing";

interface ProjectResponsiblePerson {
  recordID: number;
  userID?: number;
  name?: string;
}

export type FinancingType = "investment" | "contracting" | "mixed";

export interface PrettyPrice {
  unit: string;
  value: number;
}

export type ProjectType = "linear" | "industrial" | "nonindustrial";

export type ProjectStatus = "potential" | "active" | "delayed" | "completed";

export type Timezone =
  | "KALT"
  | "MSK"
  | "SAMT"
  | "YEKT"
  | "OMST"
  | "KRAT"
  | "IRKT"
  | "YAKT"
  | "VLAT"
  | "MAGT"
  | "PETT";

export interface GetProjectFiltersRequest extends CommonCompanyParams {
  search?: string;
  body: Partial<ProjectListFilter>;
}

export interface ProjectListFilter {
  enabledModules: ModuleType[];
  finTypes: FinancingType[];
  projectTypes: ProjectType[];
  regions: string[];
  statuses: ProjectStatus[];
}

export interface ProjectDictionary {
  regions: ProjectRegion[];
  timezones: ProjectTimezone[];
}

export interface ProjectTimezone {
  name: Timezone;
  mskDelta: number;
}

interface ProjectRegion {
  name: string;
  timezones: Timezone[];
}

export interface ProjectCreateBody
  extends Pick<
    ProjectInfo,
    | "address"
    | "financingType"
    | "fullName"
    | "projectType"
    | "region"
    | "shortName"
    | "status"
    | "timezone"
  > {
  price?: number;
}

export interface CreateProjectRequest extends CommonCompanyParams {
  body: ProjectCreateBody;
}

export interface SetFavouriteRequest extends CommonCompanyParams {
  projectID: number;
  favourite: boolean;
}

export interface DetailsProjectInfo extends ProjectInfo {
  qc: ExternalProjectInfo;
  rm: ExternalProjectInfo;
  design: DesignProjectInfo;
  dashboard: ExternalProjectInfo;
  finance: ExternalProjectInfo;
  offer: ExternalProjectInfo;
  procurement: ExternalProjectInfo;
  asbuilt_doc: ExternalProjectInfo;
}

export interface DesignProjectInfo extends ExternalProjectInfo {
  code?: string;
  phase?: DesignPhase;
  status?: DesignStatus;
}

export type DesignPhase =
  | "Сбор исходных данных"
  | "Инженерные изыскания"
  | "Проектная документация"
  | "Рабочая документация";

export type DesignStatus =
  | "Разработка документации"
  | "Авторский надзор"
  | "Документация согласована"
  | "Архив";

export interface ExternalProjectInfo {
  enabled?: boolean;
  endDate?: string;
  startDate?: string;
}

export interface UpdateProjectRequest extends CreateProjectRequest {
  projectID: number;
}

export interface UpdateProjectModuleRequest extends CommonProjectParams {
  projectID: number;
  body: ExternalProjectInfo;
}

export interface UpdateDesignRequest
  extends Omit<UpdateProjectModuleRequest, "body"> {
  projectID: number;
  body: DesignProjectInfo;
}
