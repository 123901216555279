import React, { FC, useCallback, useMemo } from "react";
import { FiltersProps } from "./filters.types";
import FilterAltSharpIcon from "@mui/icons-material/FilterAltSharp";
import { FilterSelectMultiple } from "../../../../../../components/filter-select-multiple";
import { encode } from "js-base64";
import { FiltersState } from "../../assignments-by-co.types";
import {
  fromValuesToOptions,
  getPersonInitials,
} from "../../../../../../utils/commonUtils";
import { useTranslation } from "react-i18next";
import { RangeFilter } from "../../../../../../components/range-filter";
import { StyledFilters } from "./filters.styles";

export const Filters: FC<FiltersProps> = ({
  state,
  searchParams,
  setSearchParams,
  onChange,
  group,
  author,
  supervisor,
  upToDateFrom,
  upToDateTo,
}) => {
  const { t } = useTranslation("assignments");

  const supervisorOptions = useMemo(
    () =>
      supervisor.map((value) => {
        const title = `${value.lastName} ${getPersonInitials({
          firstName: value.firstName,
          middleName: value.middleName,
          divider: ".",
        })}`;

        return { value: value.coreID, title };
      }),
    [supervisor]
  );

  const authorOptions = useMemo(
    () =>
      author.map((value) => {
        const title = `${value.lastName} ${getPersonInitials({
          firstName: value.firstName,
          middleName: value.middleName,
          divider: ".",
        })}`;

        return { value: value.coreID, title };
      }),
    [author]
  );

  const handleChange = useCallback(
    (value: string[], key: keyof FiltersState) => {
      searchParams.set(key, encode(value.join(",")));
      setSearchParams(searchParams);

      onChange({
        ...state,
        [key]: value,
      });
    },
    [state, searchParams]
  );

  const handleDateChange = useCallback(
    (upToDateFrom: string | null, upToDateTo: string | null) => {
      onChange({
        ...state,
        upToDateFrom,
        upToDateTo,
      });
    },
    [state, searchParams]
  );

  return (
    <StyledFilters>
      <FilterSelectMultiple
        startIcon={<FilterAltSharpIcon />}
        items={fromValuesToOptions(group)}
        value={state.group}
        onChange={(value) => handleChange(value, "group")}
        defaultValue={t("filtersPlaceholders.group")}
      />

      <FilterSelectMultiple
        items={authorOptions}
        value={state.authorID}
        onChange={(value) => handleChange(value, "authorID")}
        defaultValue={t("filtersPlaceholders.author")}
      />

      <FilterSelectMultiple
        items={supervisorOptions}
        value={state.supervisorID}
        onChange={(value) => handleChange(value, "supervisorID")}
        defaultValue={t("filtersPlaceholders.supervisor")}
      />

      <RangeFilter
        onChange={handleDateChange}
        maxDate={upToDateTo}
        minDate={upToDateFrom}
      />
    </StyledFilters>
  );
};
