import {
  AssignmentsBranchResponse,
  AssignmentStatus,
} from "../../api/assignments/types";
import { AttachFile } from "../../components/assignments-file-attach-list/assignments-file-attach-list.types";
import { getAssignmentPersonName } from "../../utils/commonUtils";

export class State {
  status: AssignmentStatus = "Направлено";
  upToDate?: string;
  files: AttachFile[] = [];
  executors: ExecutorItem[] = [];
  isEmpty = true;

  constructor(data?: AssignmentsBranchResponse) {
    if (!data || !this.isEmpty) return;

    this.status = data.division.status;
    this.upToDate = data.division.upToDate;
    this.files = data.division.files.map((file) => ({ file, id: file.id }));
    this.executors = data.division.executors.map((value) => ({
      value: getAssignmentPersonName(value),
      id: value.coreID,
    }));
    this.isEmpty = false;
  }
}

export interface ExecutorItem {
  value: string;
  id?: number;
}
