import {
  FC,
  useCallback,
  MouseEvent,
  useContext,
  useState,
  useMemo,
} from "react";
import { UsersByDivisionsProps } from "./users-by-divisions.types";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  StyledAccordion,
  StyledAccordionSummary,
  StyledAccordionDetails,
  StyledNameWrapper,
  StyledName,
  StyledHeadName,
  StyledButtonWrapper,
} from "./users-by-divisions.styles";
import { StyledDivisionButton } from "../../counterparty-staff.styles";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AddIcon from "@mui/icons-material/Add";
import { UserByDivision } from "../user-by-division";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { counterpartyStaffContext } from "../../context";
import { stopPropagation } from "../../../../utils/commonUtils";
import { useTranslation } from "react-i18next";
import { DivisionMenu } from "../division-menu";
import { MenuItemValue } from "../division-menu/division-menu.types";
import { DeleteModal } from "../../../delete-modal";
import {
  useSetMainDivisionMutation,
  useSetMainDivisionForMainCompanyMutation,
} from "../../../../api/counterparty";
import { useSnackbar } from "notistack";
import VerifiedIcon from "@mui/icons-material/Verified";
import { checkDivisionHead } from "./users-by-divisions.service";

export const UsersByDivisions: FC<UsersByDivisionsProps> = ({
  staff,
  access,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { companyID, counterpartyName } = useContext(counterpartyStaffContext);
  const { t } = useTranslation("counterparty");
  const { t: commonTranslation } = useTranslation("common");
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);

  const params = useParams<{ id: string }>();
  const counterpartyID = Number(params.id);
  const isAdminTab = location.pathname.includes("/administration");
  const { enqueueSnackbar } = useSnackbar();

  const [setMainDivision] = useSetMainDivisionMutation();
  const [setMainDivisionForMainCompany] =
    useSetMainDivisionForMainCompanyMutation();

  const headName = useMemo(() => {
    if (!staff.headUser) return "";

    return `${staff.headUser.lastName} ${staff.headUser.firstName} ${staff.headUser.middleName}`;
  }, [staff.headUser]);

  const handleButtonAddClick = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation();
      if ([!!companyID, !!counterpartyName].includes(false)) return;

      const divisionName = staff.users[0].division;
      navigate(`/companies/${companyID}/users/create`, {
        state: {
          userValues: {
            companyName: counterpartyName,
            ...(divisionName && { divisionName }),
            ...access,
          },
          from: location.pathname,
        },
      });
    },
    [companyID, staff.name, counterpartyName]
  );

  const handleButtonMoreClick = useCallback((event) => {
    event.stopPropagation();
    const target = event.target;

    setMenuAnchor(target);
  }, []);

  const setMainDivisionHandler = async () => {
    if (!staff.name || !counterpartyID) return;

    try {
      const commonParams = { companyID, body: { name: staff.name } };
      isAdminTab
        ? await setMainDivisionForMainCompany({ ...commonParams })
        : await setMainDivision({
            ...commonParams,
            counterpartyID,
          });
    } catch {
      enqueueSnackbar(commonTranslation("error"), {
        variant: "error",
      });
    }
  };

  const handleDivisionMenuClick = useCallback((value: MenuItemValue) => {
    setMenuAnchor(null);

    if (value === "head") {
      setMainDivisionHandler();
      return;
      //staff.headUser ? setDeleteModalOpen(true) : setMainDivisionHandler();
    }
  }, []);

  return (
    <StyledAccordion onClick={stopPropagation}>
      <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
        <StyledNameWrapper>
          <StyledName>{staff.name || t("notIndicated")} </StyledName>
          {staff.main && <VerifiedIcon className="icon" />}
        </StyledNameWrapper>

        <StyledButtonWrapper>
          <StyledDivisionButton onClick={handleButtonAddClick}>
            <AddIcon className="icon" />
          </StyledDivisionButton>

          {staff.name && (
            <StyledDivisionButton onClick={handleButtonMoreClick}>
              <MoreVertIcon className="icon" />
            </StyledDivisionButton>
          )}
        </StyledButtonWrapper>
      </StyledAccordionSummary>

      <StyledAccordionDetails>
        {staff.users.map((user) => {
          const hasHead = checkDivisionHead(staff.users);

          return (
            <UserByDivision
              key={user.coreID}
              user={user}
              hasHead={hasHead}
              hasDivision={!!staff.name}
            />
          );
        })}
      </StyledAccordionDetails>

      <DivisionMenu
        open={!!menuAnchor}
        onClose={() => setMenuAnchor(null)}
        anchor={menuAnchor}
        onMenuItemClick={handleDivisionMenuClick}
        variant="division"
      />

      <DeleteModal
        open={deleteModalOpen}
        title="Смена руководителя"
        description={
          <>
            Вы действительно хотите изменить руководителя подразделения? Новым
            руководителем будет назначен:{" "}
            <StyledHeadName>{`${headName}`}</StyledHeadName>
          </>
        }
        onClose={() => setDeleteModalOpen(false)}
        rightButtonColor="primary"
        onConfirm={setMainDivisionHandler}
      />
    </StyledAccordion>
  );
};
