import React, { FC, useCallback, useMemo, useState } from "react";
import { ICellRendererParams } from "ag-grid-community";
import { StatusCellProps } from "./status-cell.types";
import {
  StyledCell,
  StyledCommentBody,
  StyledCommentDescription,
  StyledDivisionName,
  StyledButtonComment,
} from "./status-cell.styles";
import { AssignmentStatus } from "../../../../../../api/assignments/types";
import { useChangeAssignmentStatusMutation } from "../../../../../../api/assignments";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { AssignmentStatusSelect } from "../../../../../../components/assignment-status-select";
import { DeleteModal } from "../../../../../../components/delete-modal";
import { FieldForm } from "../../../../../../components/field-form";
import { FormikProvider } from "formik";
import { useForm } from "../../../../../../hooks/use-form";
import { validationStatusCell } from "./validation";
import MessageIcon from "@mui/icons-material/Message";
import { AssignmentCommentTooltip } from "../../../../../../components/assignment-comment-tooltip";

export const StatusCell: FC<ICellRendererParams<unknown, StatusCellProps>> = ({
  value,
}) => {
  const { t: tCommon } = useTranslation("common");
  const { t } = useTranslation("assignments");
  const { enqueueSnackbar } = useSnackbar();

  const [status, setStatus] = useState(value?.status);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isCommentModalOpen, setIsCommentModalOpen] = useState(false);
  const [isCommentTooltipOpen, setIsCommentTooltipOpen] = useState(false);

  const [changeStatus] = useChangeAssignmentStatusMutation();

  const options = useMemo(() => {
    if (!value?.canChangeToStatus || !value?.status) return [];

    if (!value?.canChangeToStatus.includes(value?.status)) {
      return [value.status, ...value.canChangeToStatus];
    }

    return value?.canChangeToStatus;
  }, [value?.canChangeToStatus, value?.status]);

  const { formik, isSubmitDisabled } = useForm({
    validationSchema: validationStatusCell,
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: true,
    initialValues: { comment: "" },
    onSubmit: () => {},
  });
  const { values } = formik;

  const updateStatus = useCallback(
    async (newStatus: AssignmentStatus, comment?: string) => {
      try {
        if (!value?.companyID) return;

        await changeStatus({
          companyID: value?.companyID,
          assignmentID: value?.assignmentID,
          body: {
            division: value?.division,
            status: newStatus,
            ...(comment && { comment }),
          },
        });
      } catch {
        enqueueSnackbar(tCommon("errors.error"), { variant: "error" });
      }
    },
    []
  );

  const handleCommentSubmit = useCallback(async () => {
    setIsCommentModalOpen(false);
    setStatus("Доработка");
    await updateStatus("Доработка", values.comment);
  }, [values]);

  const handleChange = useCallback(async (newStatus: AssignmentStatus) => {
    if (newStatus === "Исполнено") {
      setIsConfirmModalOpen(true);
      return;
    }

    if (newStatus === "Доработка") {
      setIsCommentModalOpen(true);
      return;
    }

    setStatus(newStatus);
    updateStatus(newStatus);
  }, []);

  return (
    <StyledCell>
      <AssignmentStatusSelect
        options={options}
        value={status}
        onChange={handleChange}
        disabled={!value?.canEdit}
        showPlainText={!value?.canEdit}
      />

      {
        value?.status !== 'Исполнено' && <AssignmentCommentTooltip
          open={isCommentTooltipOpen}
          onClose={() => setIsCommentTooltipOpen(!isCommentTooltipOpen)}
          comment={value?.comment}
          commentDate={value?.commentDate}
          commentAuthor={value?.commentAuthor}
        >
          <StyledButtonComment
            onClick={() => setIsCommentTooltipOpen(!isCommentTooltipOpen)}
            disabled={!value?.comment}
          >
            <MessageIcon className="icon" />
          </StyledButtonComment>
        </AssignmentCommentTooltip>
      }

      <DeleteModal
        open={isConfirmModalOpen}
        title={t("statusConfirmModal.title")}
        titleColor="#0044B4"
        description={t("statusConfirmModal.description")}
        rightButtonColor="primary"
        leftButtonColor="success"
        onClose={() => setIsConfirmModalOpen(false)}
        onConfirm={() => updateStatus("Исполнено")}
      />

      <DeleteModal
        open={isCommentModalOpen}
        title={t("commentModal.title")}
        titleColor="#0044B4"
        description={
          <StyledCommentBody>
            <StyledCommentDescription>
              {t("commentModal.description")}
              <StyledDivisionName>{` «${value?.division}»`}</StyledDivisionName>
            </StyledCommentDescription>

            <FormikProvider value={formik}>
              <FieldForm
                version="profile"
                name="comment"
                title={t("commentModal.commentLabel")}
                labelClassName="field-form-label_required"
                placeholder={t("commentModal.commentPlaceholder")}
                InputProps={{
                  disableUnderline: true,
                }}
                multiline
                rows={3}
              />
            </FormikProvider>
          </StyledCommentBody>
        }
        onClose={() => setIsCommentModalOpen(false)}
        onConfirm={handleCommentSubmit}
        rightButtonColor="primary"
        leftButtonColor="success"
        leftButtonText={t("commentModal.leftButton")}
        disabledButtons={[...(isSubmitDisabled ? ["left"] : [])]}
      />
    </StyledCell>
  );
};
