import { ChangeEvent, FC } from "react";
import { FieldForm } from "../../../field-form";
import {
  StyledFieldWrapper,
  StyledClearButton,
} from "./cleared-client-id.styles";
import CloseIcon from "@mui/icons-material/Close";
import { ClearedClientIdProps } from "./cleared-client-id.types";
import { useTranslation } from "react-i18next";

export const ClearedClientId: FC<ClearedClientIdProps> = ({
  value,
  onChange,
  onClear,
}) => {
  const { t } = useTranslation("connectCounterpartyModal");

  const handleChange = (event: ChangeEvent) => {
    const target = event.target as HTMLInputElement;
    onChange(target.value);
  };

  return (
    <StyledFieldWrapper>
      <FieldForm
        name="clientID"
        version="profile"
        title={t("clientID.label")}
        placeholder={t("clientID.placeholder")}
        value={value?.toUpperCase()}
        onChange={handleChange}
      />

      <StyledClearButton onClick={onClear}>
        <CloseIcon className="icon" />
      </StyledClearButton>
    </StyledFieldWrapper>
  );
};
