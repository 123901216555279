import { createContext } from "react";
import { Context } from "./counterparty-staff.types";

export const counterpartyStaffContext = createContext<Context>({
  onUserUnSync: () => ({}),
  companyID: 0,
  modalUnSyncID: null,
  updateModalUnSyncID: () => {},
  counterpartyName: "",
});
