import { useAppContext } from "../../layouts/app-layout/context";
import { FC } from "react";
import { AssignmentsByCo } from "./components/assignments-by-co";
import { AssignmentsByDivision } from "./components/assignments-by-division";

export const Assignments: FC = () => {
  const { profileByCompany } = useAppContext();

  if (profileByCompany.divisionMain) return <AssignmentsByCo />;
  if (profileByCompany.division) return <AssignmentsByDivision />;

  return null;
};
