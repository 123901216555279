import styled from "styled-components";
import { IconButton } from "@mui/material";

export const StyledCounterpartiesBlock = styled.div`
  display: grid;
  grid-row-gap: 20px;
`;

export const StyledRow = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.primary.textDark};
  max-width: 400px;
`;

export const StyledKey = styled.div`
  font-size: 16px;
  line-height: 150%;
  font-weight: 600;
  margin-right: 16px;
`;

export const StyledKeyWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

export const StyledID = styled.div`
  font-size: 20px;
  font-weight: 400;
  border-radius: 8px;
  padding: 5px 16px;
  width: 245px;
  text-align: center;
  background: ${({ theme }) => theme.primary.formBackground};
`;

export const StyledCopyButton = styled(IconButton)`
  position: absolute;
  right: 10px;
  padding: 6px;

  .icon {
    width: 20px;
    height: 20px;
    fill: ${({ theme }) => theme.primary.mainBlue};
  }
`;

export const StyledDescription = styled.p`
  font-size: 14px;
  font-weight: 400;
  margin: 0;
  max-width: 400px;
  line-height: 143%;
`;

export const StyledButtonRefresh = styled(IconButton)`
  padding: 8px;
  border-radius: 6px;
  background: ${({ theme }) => theme.legends.orange};
  margin-left: auto;

  .icon {
    fill: ${({ theme }) => theme.primary.warningMain};
  }
`;
