import { FC } from "react";
import { ReactComponent as LogoText } from "./pragmacore-text.svg";
import { ReactComponent as Logo } from "./pragma-logo.svg";
import { Stack } from "@mui/material";
import { StyledLink } from "./logo-show.styles";

type LogoShowProps = {
  variant?: "text" | "image" | "all";
  height?: string;
  width?: string;
};

export const LogoShow: FC<LogoShowProps> = ({
  variant = "all",
  height = "50px",
  width,
}) => {
  const isShowImage = variant === "image" || variant === "all";
  const isShowText = variant === "text" || variant === "all";
  return (
    <Stack direction="row" height={height} width={width}>
      <StyledLink to="/">
        {isShowImage && <Logo />}
        {isShowText && <LogoText />}
      </StyledLink>
    </Stack>
  );
};
