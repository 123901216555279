import styled, { css } from "styled-components";
import { List, ListItem } from "@mui/material";

export const StyledSearchableSelect = styled.div`
  display: flex;
  align-items: center;
  min-height: 38px;
  position: relative;
`;

interface InputProps {
  $variant?: "profile" | "default";
  $hasPointer?: boolean;
}

export const StyledInput = styled.input<InputProps>`
  border: 1px solid ${({ theme }) => `${theme.secondary.textAdditional}4D`};
  color: ${({ theme }) => theme.primary.textDark};
  width: 100%;
  background: #fff;
  border-radius: 8px;
  padding: 10.5px 16px;
  ${({ $hasPointer }) =>
    $hasPointer &&
    css`
      cursor: pointer;
    `};

  ${({ $variant, theme }) =>
    $variant === "profile" &&
    css`
      background: ${theme.primary.formBackground};
      border: none;
      border-radius: 8px;
    `};

  &::placeholder {
    color: ${({ theme }) => theme.primary.disabled};
  }

  &:focus-visible {
    outline: none;
  }
`;

interface ListProps {
  $width?: number;
}

export const StyledList = styled(List)<ListProps>`
  max-height: 300px;
  margin: 5px 0;
  padding-top: 0;
  width: 100%;
  background: #fff;
  overflow-y: auto;
  box-shadow: 0 5px 5px -3px #00000033, 0 8px 10px 1px #00000024,
    0 3px 14px 2px #0000001f;
  border-radius: 8px;

  ${({ $width }) =>
    $width &&
    css`
      width: ${$width}px;
    `}
`;

interface ListItemProps {
  $isInteractive?: boolean;
  $isSearch?: boolean;
}

export const StyledListItem = styled(ListItem)<ListItemProps>`
  position: relative;
  display: flex;
  align-items: center;

  ${({ $isSearch }) =>
    $isSearch &&
    css`
      padding-right: 0;
      position: sticky;
      top: 0;
      z-index: 1;
      background: #fff;
      width: calc(100% - 16px);
    `}

  &:hover {
    ${({ $isInteractive }) =>
      $isInteractive &&
      css`
        background: #0000000a;
        cursor: pointer;
      `};
  }
`;

interface IconWrapperProps {
  $isRotated?: boolean;
}

export const StyledIconWrapper = styled.div<IconWrapperProps>`
  width: 24px;
  height: 24px;
  position: absolute;
  right: 7px;
  ${({ $isRotated }) =>
    $isRotated &&
    css`
      transform: rotate(180deg);
    `}

  .icon {
    fill: ${({ theme }) => theme.primary.black54};
  }
`;
