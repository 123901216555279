import { StyledForm, StyledDash, StyledTitle } from "./range-filter.styles";
import { DatePicker } from "../date-picker";
import { RangeFilterProps } from "./range-filter.types";
import { FC, useEffect } from "react";
import { useForm } from "../../hooks/use-form";
import { FormikProvider } from "formik";
import dayjs from "dayjs";
import {formatDateToISO} from "../../utils/commonUtils";

export const RangeFilter: FC<RangeFilterProps> = ({
  maxDate,
  minDate,
  onChange,
}) => {
  const { formik } = useForm({
    initialValues: {
      dateFrom: "",
      dateTo: "",
    },
    onSubmit: () => {},
  });
  const { dateFrom, dateTo } = formik.values;

  useEffect(() => {
    const formattedDateFrom = dateFrom ? formatDateToISO(new Date(dateFrom), true) : null
    const formattedDateTo = dateTo ? formatDateToISO(new Date(dateTo), true) : null

    onChange(formattedDateFrom, formattedDateTo);
  }, [dateFrom, dateTo]);

  const intlOptions: Intl.DateTimeFormatOptions = {
    day: "numeric",
    month: "numeric",
    year: "numeric",
  };

  return (
    <FormikProvider value={formik}>
      <StyledForm>
        <StyledTitle>Период:</StyledTitle>

        <DatePicker
          name="dateFrom"
          placeholder={"дд.мм.гггг"}
          valueIntlOptions={intlOptions}
          hasButtonClear
          minDate={dayjs(minDate)}
        />
        <StyledDash>–</StyledDash>
        <DatePicker
          name="dateTo"
          placeholder={"дд.мм.гггг"}
          hasButtonClear
          valueIntlOptions={intlOptions}
          maxDate={dayjs(maxDate)}
          minDate={dayjs(dateFrom)}
        />
      </StyledForm>
    </FormikProvider>
  );
};
