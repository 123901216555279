import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import { store } from "./store";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { I18nextProvider } from "react-i18next";
import { i18n } from "./i18n";
import { theme } from "./utils/theme";
import { App } from "./App";
import "./index.css";
import { SnackbarProvider, SnackbarOrigin } from "notistack";
import { SnackbarUtilsConfigurator } from "./utils/snackbarUtilsConfigurator";
import { ThemeProvider as StyledThemeProvider } from "styled-components";
import { styledComponentsTheme } from "./core/styledComponentsTheme";

const snackbarAnchorOrigin: SnackbarOrigin = {
  vertical: "bottom",
  horizontal: "right",
};

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <SnackbarProvider
        style={{ maxWidth: 320 }}
        anchorOrigin={snackbarAnchorOrigin}
        maxSnack={1}
      >
        <SnackbarUtilsConfigurator />
        <ThemeProvider theme={theme}>
          <StyledThemeProvider theme={styledComponentsTheme}>
            <I18nextProvider i18n={i18n}>
              <Provider store={store}>
                <CssBaseline />
                <App />
              </Provider>
            </I18nextProvider>
          </StyledThemeProvider>
        </ThemeProvider>
      </SnackbarProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
export { ICONS } from "./utils/constants";
