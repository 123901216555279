import { useSearchParams } from "react-router-dom";
import { useCallback } from "react";

export const useSearchParam = (
  name: string
): [string, (value: string) => void] => {
  const [searchParams, setSearchParams] = useSearchParams();

  const setSearchParam = useCallback(
    (value: string) => {
      const result: Record<string, string> = {};

      Array.from(searchParams.entries())?.forEach(
        ([key, val]) => (result[key] = val)
      );

      if (!value) {
        delete result[name];
      } else {
        result[name] = value;
      }
      setSearchParams(result);
    },
    [searchParams, name, setSearchParams]
  );
  const searchParam: string = searchParams.get(name) || "";
  return [searchParam, setSearchParam];
};
