import styled from "styled-components";
import { FormControlLabel } from "@mui/material";

export const StyledFormControlLabel = styled(FormControlLabel)`
  margin-left: auto;

  .MuiTypography-root {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: ${({ theme }) => theme.secondary.textAdditional};
  }
`;
