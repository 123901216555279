import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { StyledPage, StyledBody } from "./assignments-by-division.styles";
import {
  useGetAssignmentsBranchListQuery,
  useGetAssignmentsBranchFiltersQuery,
  useLazyGetAssignmentsBranchListQuery,
  useLazyGetAssignmentsBranchFiltersQuery,
} from "../../../../api/assignments";
import { Header } from "./components/header";
import { useAppContext } from "../../../../layouts/app-layout/context";
import { useSearchParams } from "react-router-dom";
import { NUMBER_OF_ROWS_PER_USERS_PAGE } from "../../../../utils/constants";
import { Progress } from "../../../../components/progress";
import { StyledStub, StyledStubText } from "../../assignments.styles";
import { ReactComponent as EmptyIcon } from "../../../../components/icons/imported-icons/empty-assignments.svg";
import { useTranslation } from "react-i18next";
import { AssignmentsTable } from "./components/assignments-table";
import { Filters } from "./assignments-by-division.types";
import { getFiltersParams } from "./assignments-by-division.service";

export const AssignmentsByDivision: FC = () => {
  const { currentCompanyId } = useAppContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation("assignments");
  const limitParam = searchParams.get("limit");
  const pageParam = searchParams.get("page");

  const [filtersState, setFiltersState] = useState(new Filters(searchParams));

  const limitValue = useMemo(
    () => Number(limitParam) || NUMBER_OF_ROWS_PER_USERS_PAGE[0].value,
    [limitParam]
  );

  const page = useMemo(() => Number(pageParam) || 1, [pageParam]);
  const offset = useMemo(() => limitValue * (page - 1), [limitValue, page]);

  const listWithoutFilters = useGetAssignmentsBranchListQuery(
    {
      companyID: currentCompanyId,
      limit: 1,
      offset: 0,
      body: {},
    },
    { skip: !currentCompanyId }
  );

  const listParams = {
    companyID: currentCompanyId,
    limit: limitValue,
    offset,
    body: {
      ...getFiltersParams(filtersState, [
        "status",
        "authorID",
        "executorID",
        "group",
        "supervisorID",
      ]),
    },
  };
  /*const { data, isLoading } = useGetAssignmentsBranchListQuery(
    {
      companyID: currentCompanyId,
      limit: limitValue,
      offset,
      body: {
        ...getFiltersParams(filtersState, [
          "status",
          "authorID",
          "executorID",
          "group",
          "supervisorID",
        ]),
      },
    },
    { skip: !currentCompanyId }
  );*/
  const [trigger, { data, isLoading }] = useLazyGetAssignmentsBranchListQuery();

  /*const { data: statusFiltersData } = useGetAssignmentsBranchFiltersQuery(
    {
      companyID: currentCompanyId,
      body: {
        ...getFiltersParams(filtersState, [
          "authorID",
          "executorID",
          "group",
          "supervisorID",
        ]),
      },
    },
    { skip: !currentCompanyId }
  );*/
  const [statusFiltersTrigger, { data: statusFiltersData }] =
    useLazyGetAssignmentsBranchFiltersQuery();

  const { data: authorFiltersData } = useGetAssignmentsBranchFiltersQuery(
    {
      companyID: currentCompanyId,
      body: {
        ...getFiltersParams(filtersState, [
          "status",
          "executorID",
          "group",
          "supervisorID",
        ]),
      },
    },
    { skip: !currentCompanyId }
  );

  const { data: executorFiltersData } = useGetAssignmentsBranchFiltersQuery(
    {
      companyID: currentCompanyId,
      body: {
        ...getFiltersParams(filtersState, [
          "status",
          "authorID",
          "group",
          "supervisorID",
        ]),
      },
    },
    { skip: !currentCompanyId }
  );

  const { data: groupFiltersData } = useGetAssignmentsBranchFiltersQuery(
    {
      companyID: currentCompanyId,
      body: {
        ...getFiltersParams(filtersState, [
          "status",
          "authorID",
          "executorID",
          "supervisorID",
        ]),
      },
    },
    { skip: !currentCompanyId }
  );

  const { data: supervisorFiltersData } = useGetAssignmentsBranchFiltersQuery(
    {
      companyID: currentCompanyId,
      body: {
        ...getFiltersParams(filtersState, [
          "status",
          "authorID",
          "executorID",
          "group",
        ]),
      },
    },
    { skip: !currentCompanyId }
  );

  /* disable caching for status filter and list req */
  useEffect(() => {
    trigger(listParams);
    statusFiltersTrigger({
      companyID: listParams.companyID,
      body: {
        ...getFiltersParams(filtersState, [
          "authorID",
          "executorID",
          "group",
          "supervisorID",
        ]),
      },
    });
  }, [limitValue, offset, filtersState]);

  const pages = useMemo(() => {
    if (!data?.total) return 1;

    return Math.ceil(data.total / Number(limitValue)) || 1;
  }, [data, limitValue]);

  const handleChangePage = useCallback(
    (page: number) => {
      searchParams.set("page", page.toString());
      setSearchParams(searchParams);
    },
    [setSearchParams, searchParams]
  );

  const handleChangeLimit = useCallback(
    (limit) => {
      searchParams.set("limit", String(limit));
      searchParams.set("page", "1");
      setSearchParams(searchParams);
    },
    [setSearchParams, searchParams]
  );

  const body = (
    <StyledBody>
      {listWithoutFilters.data?.total ? (
        <AssignmentsTable data={data?.data || []} />
      ) : (
        <StyledStub>
          <EmptyIcon />
          <StyledStubText>{t("stubDivisionText")}</StyledStubText>
        </StyledStub>
      )}
    </StyledBody>
  );

  return (
    <StyledPage>
      <Header
        limit={limitValue}
        page={page}
        pages={pages}
        authors={authorFiltersData?.author || []}
        supervisors={supervisorFiltersData?.supervisor || []}
        groups={groupFiltersData?.group || []}
        executors={executorFiltersData?.executor || []}
        statuses={statusFiltersData?.status || []}
        filters={filtersState}
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        onFiltersChange={setFiltersState}
        onPaginationChange={handleChangePage}
        onChangeLimit={handleChangeLimit}
      />

      {isLoading ? <Progress /> : body}
    </StyledPage>
  );
};
