import styled, { css } from "styled-components";
import { Form } from "formik";

export const StyledPage = styled.div`
  padding: 20px;
  width: 100%;
  height: 100%;
  display: grid;
  align-content: start;
  overflow-y: hidden;
  grid-template-rows: max-content 1fr;
`;

export const StyledMain = styled.div`
  width: 100%;
  height: calc(100% - 30px);
  margin-top: 30px;
  /*display: grid;
  justify-content: center;*/
  overflow-y: auto;
`;

export const StyledTitle = styled.h2`
  font-size: 20px;
  line-height: 23px;
  font-weight: 400;
  color: ${({ theme }) => theme.secondary.textAdditional};
  margin: 20px auto 30px auto;
  max-width: 1140px;
  display: flex;
  align-items: center;

  .icon {
    margin-right: 24px;
    width: 32px;
    height: 32px;

    .painted-area {
      fill: ${({ theme }) => theme.secondary.textAdditional};
    }
  }
`;

export const StyledFormLayout = styled.div`
  display: flex;
  justify-content: space-between;
  column-gap: 60px;
  width: max-content;
  margin: 0 auto;
  overflow: hidden;
`;

interface ColumnProps {
  $hasPaddingTop?: boolean;
}

export const StyledColumn = styled.div<ColumnProps>`
  width: 540px;
  padding: 0 20px;
  display: grid;
  grid-row-gap: 10px;
  grid-auto-rows: max-content;
  ${({ $hasPaddingTop }) =>
    $hasPaddingTop &&
    css`
      padding-top: 39px;
    `}

  .field-form-label .MuiTypography-root {
    width: max-content;
    font-size: 14px;
    color: ${({ theme }) => theme.secondary.textAdditional};
    position: relative;
  }

  .field-form-label_required .MuiTypography-root {
    &::before {
      position: absolute;
      content: "*";
      right: -15px;
      color: ${({ theme }) => theme.secondary.error};
    }
  }

  .MuiInputBase-root {
    font-size: 14px;
    color: ${({ theme }) => theme.primary.textDark};
    background: #fff;
    border-radius: 8px;
    border: 1px solid #5c6e8c4d;

    &.Mui-disabled {
      background: ${({ theme }) => theme.primary.blueShades};
      color: ${({ theme }) => theme.primary.disabled};
    }
  }

  .MuiFilledInput-input {
    padding: 8px 16px;

    &.Mui-disabled {
      background: ${({ theme }) => theme.primary.blueShades};
      color: ${({ theme }) => theme.primary.disabled};
    }
  }

  .MuiInputBase-inputMultiline {
    padding: 0;
  }

  .MuiInputBase-multiline {
    padding: 20px 16px;
  }

  .icon-map {
    fill: #5c6e8c;
  }
`;

export const StyledColumnTitle = styled.div`
  font-size: 18px;
  line-height: 29px;
  font-weight: 400;
  color: ${({ theme }) => theme.secondary.textAdditional};
`;

export const StyledDivider = styled.div`
  width: 100%;
  margin: 30px 0;
  height: 1px;
  background: ${({ theme }) => theme.secondary.backgroundDivider};
`;

export const StyledForm = styled(Form)`
  overflow: hidden;
`;

export const StyledLabel = styled.label`
  .MuiTypography-root {
    font-size: 14px;
    color: ${({ theme }) => theme.secondary.textAdditional};
  }
`;

export const StyledRow = styled.div`
  display: flex;
  align-items: center;
  column-gap: 20px;
`;
