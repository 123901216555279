import React, {
  BaseSyntheticEvent,
  ChangeEventHandler,
  FC,
  useCallback,
  useMemo,
  useState,
} from "react";
import { useField } from "formik";
import { Avatar, Stack } from "@mui/material";
import PersonSharpIcon from "@mui/icons-material/PersonSharp";
import { CropModal } from "../crop-modal";
import { AvatarFieldFormProps } from "./avatar-field-form.types";
import { ReactComponent as CounterpartyIcon } from "../icons/imported-icons/counterparty-avatar.svg";

export const AvatarFieldForm: FC<AvatarFieldFormProps> = ({
  placeholder,
  fieldProps,
  needCrop,
  variant = "user",
  disabled,
  ...props
}) => {
  const [{ value: formValue }, , { setValue }] = useField({
    name: props.name,
    ...fieldProps,
  });

  const [isOpenCroppedDialog, setIsOpenCroppedDialog] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const icon = useMemo(() => {
    switch (variant) {
      case "counterparty":
        return <CounterpartyIcon />;

      default:
        return <PersonSharpIcon sx={{ width: "100px", height: "100px" }} />;
    }
  }, [variant]);

  const handleSetValue = useCallback(
    (blob: Blob) => {
      if (disabled) return;

      const reader = new FileReader();
      reader.onload = ({ target }) => {
        setIsOpenCroppedDialog(false);
        setValue(new Blob([target!.result as ArrayBuffer]));
      };
      if (blob) {
        reader.readAsArrayBuffer(blob);
      }
    },
    [setValue, disabled]
  );

  const handleChangeAvatar: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      if (disabled) return;

      const file = e?.target?.files?.[0] || null;
      if (needCrop) {
        setSelectedFile(file);
        setIsOpenCroppedDialog(true);
      } else {
        if (file) {
          handleSetValue(file);
        }
      }
    },
    [needCrop, handleSetValue, disabled]
  );

  const handleCancelCrop = useCallback(() => {
    if (disabled) return;

    setIsOpenCroppedDialog(false);
    setSelectedFile(null);
  }, []);

  const src = useMemo(() => {
    return formValue instanceof Blob
      ? URL.createObjectURL(formValue)
      : formValue;
  }, [formValue, disabled]);

  return (
    <>
      <label
        htmlFor="upload-user-file"
        className={[
          "upload-user-file",
          disabled ? "upload-user-file_disabled" : "",
        ].join(" ")}
      >
        {!disabled && (
          <input
            style={{ display: "none" }}
            accept="image/*"
            id="upload-user-file"
            type="file"
            onChange={handleChangeAvatar}
            onClick={(e: BaseSyntheticEvent) => (e.target.value = "")}
          />
        )}
        <Avatar
          sx={{
            width: "220px",
            height: "220px",
            cursor: disabled ? "default" : "pointer",
          }}
          src={src}
          variant="rounded"
        >
          <Stack alignItems="center">
            {icon}

            {placeholder}
          </Stack>
        </Avatar>
      </label>
      <CropModal
        file={selectedFile}
        isOpen={isOpenCroppedDialog}
        onSave={handleSetValue}
        onClose={handleCancelCrop}
        onCancel={handleCancelCrop}
      />
    </>
  );
};
