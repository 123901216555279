import React, { FC } from "react";
import { Divider, DividerProps } from "@mui/material";

type ColorDividerProps = {
  size?: number;
} & DividerProps;

export const ColorDivider: FC<ColorDividerProps> = ({ size = 1, ...props }) => {
  return (
    <Divider
      sx={{ borderBottom: `${size}px solid rgba(122, 145, 247, 0.3019607843)` }}
      {...props}
    />
  );
};
