import { Filters } from "./assignments-by-division.types";

export function getFiltersParams(filtersState: Filters, keys: string[]) {
  return Object.entries(filtersState).reduce((accumulator, [key, value]) => {
    return {
      ...accumulator,
      ...(keys.includes(key) && value?.length && { [key]: value }),
    };
  }, {});
}
