import { ModuleType } from "../api/companies";
import { ReactComponent as Logout } from "../components/icons/imported-icons/logout.svg";
import { ReactComponent as Points } from "../components/icons/imported-icons/points.svg";
import { ReactComponent as Success } from "../components/icons/imported-icons/success.svg";

import { ReactComponent as Design } from "../components/icons/imported-icons/design.svg";
import { ReactComponent as QualityControl } from "../components/icons/imported-icons/quality_control.svg";
import { ReactComponent as Finance } from "../components/icons/imported-icons/finance.svg";
import { ReactComponent as Dashboard } from "../components/icons/imported-icons/dashboard.svg";
import { ReactComponent as Procurement } from "../components/icons/imported-icons/procurement.svg";
import { ReactComponent as Offer } from "../components/icons/imported-icons/offer.svg";
import { ReactComponent as Crm } from "../components/icons/imported-icons/crm.svg";
import { ReactComponent as Rm } from "../components/icons/imported-icons/rm.svg";
import { ReactComponent as Analytics } from "../components/icons/imported-icons/analytics.svg";
import { ReactComponent as Tracker } from "../components/icons/imported-icons/tracker.svg";
import { ReactComponent as Tender } from "../components/icons/imported-icons/tender.svg";
import { ReactComponent as Equip } from "../components/icons/imported-icons/equip.svg";
import { ReactComponent as Supply } from "../components/icons/imported-icons/supply.svg";
import { ReactComponent as Workforce } from "../components/icons/imported-icons/workforce.svg";
import { ReactComponent as Maintenance } from "../components/icons/imported-icons/maintenance.svg";
import { ReactComponent as Tangl } from "../components/icons/imported-icons/tangl.svg";
import { ReactComponent as Cynteka } from "../components/icons/imported-icons/cynteka.svg";
import { ReactComponent as Traceair } from "../components/icons/imported-icons/traceair.svg";
import { ReactComponent as Transbasa } from "../components/icons/imported-icons/transbasa.svg";
import { ReactComponent as Vampodryad } from "../components/icons/imported-icons/vampodryad.svg";
import { ReactComponent as BuildDocs } from "../components/icons/imported-icons/build-docs.svg";
import { ReactComponent as AsbuiltDoc } from "../components/icons/imported-icons/asbuilt_doc.svg";

export const CODE_LENGTH = 6;
export const MAX_PASSWORD_LENGTH_VALIDATION = 4;
export const DEFAULT_DISPLAY_PASSWORD_VALUE = "* * * * * *";

export const MAX_WIDTH_PROFILE_BLOCK = "596px";
export const MIN_WIDTH_PROFILE_BLOCK = "496px";

export const BACKGROUND_DISABLED = "#EDEFF3";

export const NUMBER_OF_ROWS_PER_USERS_PAGE = [
  { value: 100, title: "100" },
  { value: 50, title: "50" },
  { value: 25, title: "25" },
];

export const ACCESS_TO_ROLES = ["none", "user", "admin"];

export const TABS_NAME = {
  module: "module",
  projects: "projects",
  profile: "profile",
  users: "users",
  administration: "administration",
  counterparties: "counterparties",
  assignments: "assignments",
};
export const DIALOG_TYPE = {
  main: "main" as const,
  leave: "leave" as const,
};

export const ERRORS_PHONE = [
  "company.user_creation.invalid_phone",
  "profile.invalid_phone",
];

export const ERRORS_COINCIDENCE = {
  "company.user_creation.phone_taken": "phone" as const,
  "company.user_creation.email_taken": "email" as const,
  "company.user_creation.login_taken": "login" as const,
  "profile.phone_taken": "phone" as const,
  "profile.email_taken": "email" as const,
  "profile.login_taken": "login" as const,
};

export enum ModuleStatusesColors {
  available = "#00BFA5",
  online = "#7890B2",
  offline = "#ED6C02",
  unpaid = "#0044B4",
  in_development = "#0288D1",
  planned = "#5C6E8C",
}

export enum UserCardsTitle {
  fullName = "ФИО",
  contacts = "Контакты:",
  company = "Компания:",
  modules = "Модули:",
}

export const ICONS = {
  dashboard: Dashboard,
  design: Design,
  quality_control: QualityControl,
  finance: Finance,
  procurement: Procurement,
  offer: Offer,
  crm: Crm,
  resource_managment: Rm,
  analytics: Analytics,
  tracker: Tracker,
  tender: Tender,
  equip: Equip,
  supply: Supply,
  workforce: Workforce,
  maintenance: Maintenance,
  asbuilt_doc: AsbuiltDoc,

  logout: Logout,
  points: Points,
  success: Success,

  tangl: Tangl,
  cynteka: Cynteka,
  traceair: Traceair,
  transbasa: Transbasa,
  vampodryad: Vampodryad,
  buildDocs: BuildDocs,
};

export const MODULES: Record<
  ModuleType,
  {
    name: string;
    icon: typeof ICONS[keyof typeof ICONS];
    background: string;
    description?: string;
  }
> = {
  dashboard: {
    name: "Dashboard",
    icon: ICONS.dashboard,
    background: "linear-gradient(180deg, #F7F2FF 0%, #F0E7FF 100%)",
    description:
      "Позволяет составить общую картину проекта, раскрыть информацию до уровня первичной информации и разобрать возникшую проблему самым детальным образом.",
  },
  design: {
    name: "Design",
    icon: ICONS.design,
    background: "linear-gradient(180deg, #EAFAFF 0%, #FDECFB 100%)",
    description:
      "Управление проектированием и контроль статуса. Быстрое и максимально удобное согласование проектной документации, обработка замечаний.",
  },
  quality_control: {
    name: "Quality Control",
    icon: ICONS.quality_control,
    background: "linear-gradient(180deg, #FFF8DC 0%, #F9F2FF 100%)",
    description: "Решение по обеспечению контроля качества строительства",
  },
  finance: {
    name: "Finance",
    icon: ICONS.finance,
    background: "linear-gradient(180deg, #EAFFFC 0%, #D1FFF9 100%)",
    description:
      "Единое информационное пространство с другими модулями позволяет в режиме реального времени управлять экономикой строительства.",
  },
  procurement: {
    name: "Procurement",
    icon: ICONS.procurement,
    background: "linear-gradient(180deg, #FEF6E3 0%, #FFE2E0 100%)",
    description:
      "Отвечает за цепочку поставки материалов на строительную площадку из расчета потребности, контрактации до физического оприходования на складе и вовлечения в производство.",
  },
  offer: {
    name: "Estimation",
    icon: ICONS.offer,
    background: "linear-gradient(180deg, #FFF8DD 0%, #FEE8EF 100%)",
    description:
      "Позволяет формировать ТКП на стадии оферты и, в случае победы, экспортировать данные в последующие модули.",
  },
  crm: {
    name: "CRM",
    icon: ICONS.crm,
    background: "linear-gradient(180deg, #FFF8DC 0%, #F9F2FF 100%)",
    description:
      "CRM-система. Учет пула ответственных подрядчиков и поставщиков.",
  },
  resource_managment: {
    name: "Resource Management",
    icon: ICONS.resource_managment,
    background: "linear-gradient(180deg, #E3FAFF 0%, #E7FFFC 100%)",
    description:
      "Управление КСП, МСГ, привязка ресурсов, выдача СНЗ и сбор цифрового факта. Связка с «уберами» по людям, машины и механизмы, материально техническое обеспечение",
  },
  analytics: {
    name: "Analytics",
    icon: ICONS.analytics,
    background: "linear-gradient(180deg, #E7FFFC 0%, #FEEAED 100%)",
    description:
      "Предоставляет предиктивный анализ проекта и строит его цифровой двойник.",
  },
  tracker: {
    name: "Tracker",
    icon: ICONS.tracker,
    background: "linear-gradient(180deg, #EFF3FF 0%, #F3EFFF 44.27%)",
    description:
      "Позволяет составить общую картину проекта, раскрыть информацию до уровня первичной информации и разобрать возникшую проблему самым детальным образом.",
  },
  tender: {
    name: "Tender",
    icon: ICONS.tender,
    background: "linear-gradient(180deg, #E3FAFF 0%, #E5ECFC 100%)",
    description: "Сбор цифрового факта",
  },
  equip: {
    name: "Equip",
    icon: ICONS.equip,
    background: "linear-gradient(180deg, #FFF9DC 0%, #FFE9DC 48.96%)",
    description: "Убер на МиМ",
  },
  supply: {
    name: "Supply",
    icon: ICONS.supply,
    background: "linear-gradient(180deg, #F6F3FF 0%, #E1F5FF 100%)",
    description:
      "Организованный процесс комплектных поставок всех видов материально-технических ресурсов в строгом соответствии с технологической потребностью.",
  },
  workforce: {
    name: "Workforce",
    icon: ICONS.workforce,
    background: "linear-gradient(180deg, #E3FAFF 0%, #CAEFFF 59.37%)",
    description:
      "Лучшее решение для обеспечения стройки людскими ресурсами. Модуль постоянно развивается для создания экосистемы рабочего и экономии времени.",
  },
  maintenance: {
    name: "Maintenance",
    icon: ICONS.maintenance,
    background: "linear-gradient(180deg, #F4F0FF 0%, #FCEDFC 100%)",
    description:
      "Решение для управления эксплуатацией, текущим ремонтом и содержанием объектов",
  },
  asbuilt_doc: {
    name: "As-Built Documentation",
    icon: ICONS.maintenance,
    background: " linear-gradient(180deg, #D0F1FE 0%, #E6FFFC 100%)",
    description: "Работа с Исполнительной документацией на всех этапах",
  },
};

export const PARTNERS = [
  {
    name: "Tangl",
    description: "Контроль качества BIM-модели, коллизии, ВОРМ",
    key: "tangl",
    icon: ICONS.tangl,
    url: "https://tangl.cloud/",
  },
  {
    name: "CYNTEKA",
    description: "Маркетплейс по Комплектации",
    key: "cynteka",
    icon: ICONS.cynteka,
    url: "https://cynteka.ru/",
  },
  {
    name: "TRACEAIR",
    description: "Контроль строительства на основе данных с дронов",
    key: "traceair",
    icon: ICONS.traceair,
    url: "https://www.traceair.ru/",
  },
  {
    name: "TRANSBASA",
    description: "Аренда\n спецтехники",
    key: "transbasa",
    icon: ICONS.transbasa,
    url: "https://trans-baza.ru/pragma_partner/",
  },
  /*{
    name: "Вамподряд",
    description: "Поиск субподрядчиков",
    key: "vampodryad",
    icon: ICONS.vampodryad,
    url: "https://vampodryad.ru/?utm_source=referral&utm_medium=cpc&utm_campaign=ita|site|vpd|mp|users|pragma",
  },*/
  {
    name: "BuildDocs",
    description: "Исполнительная документация",
    key: "buildDocs",
    icon: ICONS.buildDocs,
    url: "https://builddocs.ru/",
  },
];
