import styled from "styled-components";
import { Button } from "@mui/material";

export const StyledHeader = styled.div`
  padding: 30px 40px;

  .MuiButtonBase-root {
    &:first-child {
      margin-right: 10px;
    }
  }

  .MuiTabs-scroller {
    border-bottom: none;
  }
`;

export const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  box-shadow: 0 -1px 0 0 ${({ theme }) => theme.secondary.backgroundDivider} inset;
  min-height: 48px;
`;

export const StyledButtonRemove = styled(Button)`
  min-width: 40px;
  min-height: 40px;
  width: 40px;
  height: 40px;
  padding: 0;
  box-shadow: none;
  margin-left: auto;
`;
