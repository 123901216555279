import styled from "styled-components";
import { IconButton } from "@mui/material";

interface CellProps {
  $colorTheme: "primary" | "secondary";
}

export const StyledCell = styled.div<CellProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 2px;
  width: 100%;
  color: ${({ theme, $colorTheme }) =>
    $colorTheme === "primary" ? theme.primary.textDark : theme.secondary.text};

  .icon-decline {
    width: 20px;
    fill: ${({ theme }) => theme.secondary.error};
  }

  .icon-approved {
    width: 20px;
    fill: ${({ theme }) => theme.legends.green};
  }
`;

export const StyledTextWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
`;

export const StyledIconButton = styled(IconButton)`
  width: 28px;
  height: 28px;
  border: 1px solid ${({ theme }) => theme.primary.mainBlue};
  border-radius: 4px;
  background: ${({ theme }) => `${theme.background.blue}14`};
  padding: 0;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    border-radius: 50%;
    background: ${({ theme }) => theme.secondary.error};
    width: 12px;
    height: 12px;
    top: -4px;
    right: -4px;
    border: 2px solid #fff;
  }

  .icon {
    width: 20px;
    fill: ${({ theme }) => theme.primary.mainBlue};
  }
`;

export const StyledModalDescription = styled.div`
  width: 100%;
`;

export const StyledModalDates = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 8px;

  .icon {
    width: 16px;
    fill: ${({ theme }) => theme.secondary.text};
  }
`;

interface ModalDateProps {
  $colorTheme: "primary" | "secondary";
}

export const StyledModalDate = styled.div<ModalDateProps>`
  padding: 4px 8px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: ${({ theme, $colorTheme }) =>
    $colorTheme === "primary" ? theme.secondary.text : theme.primary.mainBlue};
  background: ${({ theme, $colorTheme }) =>
    $colorTheme === "primary"
      ? theme.primary.blueShades
      : `${theme.background.blue}14`};
`;

export const StyledDivisionName = styled.span`
  font-weight: 500;
`;

export const StyledTooltipWrapper = styled.div`
  height: 20px;
`;
