import styled from "styled-components";
import { Button } from "@mui/material";

export const StyledFileAttachList = styled.div`
  width: 100%;
`;

export const StyledButtonAttach = styled(Button)`
  background: ${({ theme }) => `${theme.background.blue}14`};
  color: ${({ theme }) => theme.primary.mainBlue};
  font-size: 13px;
  line-height: 15px;
  min-height: 36px;
  box-shadow: none;
  width: 100%;

  &:hover {
    background: ${({ theme }) => `${theme.background.blue}14`};
    color: ${({ theme }) => theme.primary.mainBlue};
    box-shadow: none;
  }
`;

export const StyledInputFile = styled.input`
  display: none;
`;
