import { FC, useCallback, useMemo, useState } from "react";
import { useGetListQuery, useGetRolesQuery } from "../../api/counterparty";
import { useAppContext } from "../../layouts/app-layout/context";
import { useSearchParams } from "react-router-dom";
import { NUMBER_OF_ROWS_PER_USERS_PAGE } from "../../utils/constants";
import { HeaderCounterparties } from "./components/header-counterparties";
import {
  StyledCounterparties,
  StyledHeaderWrapper,
  StyledList,
  StyledNoRowsMessage,
} from "./counerparties.styles";
import { Progress } from "../../components/progress";
import { useTranslation } from "react-i18next";
import { CounterpartyCard } from "./components/counterparty-card";

export const Counterparties: FC = () => {
  const { t } = useTranslation("counterparties");
  const { currentCompanyId } = useAppContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const limitParam = searchParams.get("limit");
  const pageParam = searchParams.get("page");
  const search = searchParams.get("search");

  const [selectRoles, setSelectRoles] = useState<string[]>([]);

  const limitValue = useMemo(
    () => Number(limitParam) || NUMBER_OF_ROWS_PER_USERS_PAGE[0].value,
    [limitParam]
  );

  const page = useMemo(() => Number(pageParam) || 1, [pageParam]);
  const offset = useMemo(() => limitValue * (page - 1), [limitValue, page]);

  const list = useGetListQuery({
    companyID: currentCompanyId,
    limit: limitValue,
    offset,
    ...(search && { search }),
    ...(selectRoles.length && { roles: selectRoles }),
  });
  const roles = useGetRolesQuery({ companyID: currentCompanyId });

  const isLoading = list.isLoading || list.isFetching;

  const pages = useMemo(() => {
    if (!list.data?.total) return 1;

    return Math.ceil(list.data.total / Number(limitValue)) || 1;
  }, [list.data, limitValue]);

  const handleChangePage = useCallback(
    (page: number) => {
      searchParams.set("page", page.toString());
      setSearchParams(searchParams);
    },
    [setSearchParams, searchParams]
  );

  const handleChangeLimit = useCallback(
    (limit) => {
      searchParams.set("limit", String(limit));
      searchParams.set("page", "1");
      setSearchParams(searchParams);
    },
    [setSearchParams, searchParams]
  );

  const mainContent = (
    <>
      {list.data?.total ? (
        <StyledList>
          {list.data?.data.map((profile) => (
            <CounterpartyCard profile={profile} key={profile.id} />
          ))}
        </StyledList>
      ) : (
        <StyledNoRowsMessage>{t("noRows")}</StyledNoRowsMessage>
      )}
    </>
  );

  return (
    <StyledCounterparties>
      <StyledHeaderWrapper>
        <HeaderCounterparties
          page={page}
          pages={pages}
          numberRows={NUMBER_OF_ROWS_PER_USERS_PAGE}
          onPaginationChange={handleChangePage}
          onChangeLimit={handleChangeLimit}
          limit={limitValue}
          allRoles={roles.data?.roles}
          selectRoles={selectRoles}
          onRoleChange={setSelectRoles}
          hasPagination={!!list.data?.total}
        />
      </StyledHeaderWrapper>

      {isLoading ? <Progress /> : mainContent}
    </StyledCounterparties>
  );
};
