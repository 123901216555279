import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Form, FormikProvider } from "formik";
import { Button, Stack } from "@mui/material";
import { useMutationHandlers } from "../../../hooks/use-mutation-handlers";
import { useForm } from "../../../hooks/use-form";
import { Success } from "./components/success";
import { validationSetNewPassword } from "./validation";
import { PasswordFieldForm } from "../../../components/password-field-form";
import { useUpdateProfileMutation } from "../../../api/profile";

export const PasswordForm = () => {
  const { t } = useTranslation("recovery");
  const navigate = useNavigate();

  const [isChangedPassword, setIsChangedPassword] = useState(false);

  const [updateProfile, updateProfileResponse] = useUpdateProfileMutation();

  const handleSubmit = useCallback(
    (values) => {
      updateProfile({ ...values });
    },
    [updateProfile]
  );

  const { formik, isSubmitDisabled } = useForm({
    validationSchema: validationSetNewPassword,
    initialValues: {
      password: "",
      repeatPassword: "",
    },
    onSubmit: handleSubmit,
  });
  useMutationHandlers(
    updateProfileResponse,
    () => {
      setIsChangedPassword(true);
    },
    (error) => {
      navigate("/auth");
    }
  );

  return (
    <FormikProvider value={formik}>
      {isChangedPassword ? (
        <Success />
      ) : (
        <Stack component={Form} spacing={4}>
          <PasswordFieldForm
            name="password"
            label={t("confirm.labelNewPassword")}
          />
          <PasswordFieldForm
            name="repeatPassword"
            label={t("confirm.labelRepeatPassword")}
          />

          <Button
            type="submit"
            sx={{ mt: 3, p: 1.5 }}
            variant="contained"
            disabled={isSubmitDisabled}
          >
            {t("common:buttons.save")}
          </Button>
        </Stack>
      )}
    </FormikProvider>
  );
};
