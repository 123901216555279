import styled from "styled-components";
import {Button, IconButton} from "@mui/material";

export const StyledHeader = styled.div`
  height: 48px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledRightAside = styled.div`
  display: flex;
  align-items: center;
  column-gap: 12px;
`;

export const StyledButtonSave = styled(Button)`
  background: ${({ theme }) => theme.legends.green};
  border-radius: 8px;
`;

export const StyledButtonDelete = styled(IconButton)`
  width: 36px;
  height: 36px;
  background: ${({ theme }) => theme.secondary.error};
  border-radius: 6px;

  .icon {
    fill: #fff;
    width: 20px;
  }

  &:hover {
    background: ${({ theme }) => theme.secondary.error};
  }
`;
