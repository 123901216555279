import React, { ChangeEvent, FC, useCallback } from "react";
import {
  StyledColumn,
  StyledColumnTitle,
  StyledFormLayout,
  StyledTitle,
} from "../../../../project.styles";
import { FieldForm } from "../../../../../../components/field-form";
import { StyledMuiSelect } from "../../../../../../components/styled-mui-select";
import {
  financingTypeOptions,
  projectTypeOptions,
  statusOptions,
} from "../../project-info.constants";
import { NumericFormat } from "react-number-format";
import { useTranslation } from "react-i18next";
import { CommonInfoProps } from "./common-info.types";
import { useField } from "formik";
import { useAppContext } from "../../../../../../layouts/app-layout/context";
import { checkNumberIsSafe } from "../../../../../../utils/commonUtils";
import { StyledRuble } from "./common-info.styles";

export const CommonInfo: FC<CommonInfoProps> = ({ values }) => {
  const { t } = useTranslation("project");
  const [, , { setValue: setPriceValue }] = useField("price");
  const { isAdmin } = useAppContext();

  const handlePriceChange = useCallback((event: ChangeEvent) => {
    const target = event.target as HTMLInputElement;
    if (target.value.includes(",")) return setPriceValue(target.value);

    const price = Number(target.value.split(" ").join(""));
    checkNumberIsSafe(price) && setPriceValue(price);
  }, []);

  return (
    <>
      <StyledTitle>{t("titles.commonInfo")}</StyledTitle>
      <StyledFormLayout>
        <StyledColumn>
          <StyledColumnTitle>
            {t("columnTitles.projectNaming")}
          </StyledColumnTitle>

          <FieldForm
            version="profile"
            name="shortName"
            title={t("inputs.shortName.title")}
            labelClassName="field-form-label_required"
            placeholder={t("inputs.shortName.placeholder")}
            maxLength={30}
            disabled={!isAdmin}
            InputProps={{
              disableUnderline: true,
            }}
          />

          <FieldForm
            version="profile"
            name="fullName"
            title={t("inputs.fullName.title")}
            labelClassName="field-form-label_required"
            placeholder={t("inputs.fullName.placeholder")}
            InputProps={{
              disableUnderline: true,
            }}
            multiline
            rows={7}
            disabled={!isAdmin}
          />
        </StyledColumn>

        <StyledColumn>
          <StyledColumnTitle>{t("columnTitles.projectType")}</StyledColumnTitle>

          <StyledMuiSelect
            props={{
              name: "status",
              variant: "filled",
              value: values.status,
              disabled: !isAdmin,
            }}
            labelClassName="field-form-label_required"
            placeholder={t("inputs.status.placeholder")}
            labelText={t("inputs.status.title")}
            options={statusOptions}
          />

          <StyledMuiSelect
            props={{
              name: "projectType",
              variant: "filled",
              value: values.projectType,
              disabled: !isAdmin,
            }}
            labelClassName="field-form-label_required"
            placeholder={t("inputs.projectType.placeholder")}
            labelText={t("inputs.projectType.title")}
            options={projectTypeOptions}
          />

          <StyledMuiSelect
            props={{
              name: "financingType",
              variant: "filled",
              value: values.financingType,
              disabled: !isAdmin,
            }}
            labelClassName="field-form-label_required"
            placeholder={t("inputs.financingType.placeholder")}
            labelText={t("inputs.financingType.title")}
            options={financingTypeOptions}
          />

          <NumericFormat
            thousandSeparator={" "}
            decimalSeparator=","
            customInput={FieldForm}
            version="profile"
            name="price"
            value={values.price}
            title={t("inputs.price.title")}
            placeholder={t("inputs.price.placeholder")}
            onChange={handlePriceChange}
            InputProps={{
              disableUnderline: true,
              value: values.price,
              endAdornment: (
                <StyledRuble className="test">₽ - Рубли</StyledRuble>
              ),
            }}
            inputProps={{
              maxLength: 25,
            }}
            disabled={!isAdmin}
            maxLength={18}
          />
        </StyledColumn>
      </StyledFormLayout>
    </>
  );
};
