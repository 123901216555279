import styled from "styled-components";
import { IconButton } from "@mui/material";
import { textOneRow, limitTextRows } from "../../../../common/styledMixins";

export const StyledCard = styled.div`
  padding: 20px;
  display: grid;
  grid-row-gap: 20px;
  cursor: pointer;
`;

export const StyledHeader = styled.header`
  height: 56px;
  width: 100%;
  display: grid;
  grid-template-columns: 24px 1fr 86px;
  align-items: center;
  padding: 0 16px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.primary.outlinedBorder};
  margin-bottom: 20px;
`;

export const StyledButtonFavorite = styled(IconButton)`
  .MuiSvgIcon-root {
    fill: ${({ theme }) => theme.primary.mainBlue};
  }

  &:hover {
    background: none;
  }
`;

export const StyledShortName = styled.div`
  font-size: 20px;
  line-height: 24px;
  font-weight: 400;
  color: ${({ theme }) => theme.primary.mainBlue};
  margin-left: 10px;
  ${textOneRow};
`;

export const StyledId = styled.div`
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  text-transform: uppercase;
  color: ${({ theme }) => theme.primary.textDark};
  max-width: 86px;
  ${textOneRow};
  margin-left: auto;
`;

export const StyledBody = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 200px;
  justify-content: space-between;
  column-gap: 60px;
`;

export const StyledFullName = styled.p`
  height: 150px;
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  padding: 0 20px;
  color: ${({ theme }) => theme.primary.textDark};
  ${limitTextRows(7)};
  margin: 0 0 0;
`;

export const StyledField = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 14px 8px;
  border-bottom: 1px solid ${({ theme }) => theme.primary.blue20};
  max-height: 50px;
`;

interface ValueProps {
  $color?: 'textAdditional' | 'textCaption'
}

export const StyledValue = styled.div<ValueProps>`
  display: flex;
  align-items: center;
  column-gap: 8px;
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme, $color = 'textAdditional' }) => theme.secondary[$color]};

  .icon {
    width: 18px;
    fill: ${({ theme }) => theme.secondary.textAdditional};
  }
`;
