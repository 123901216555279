import styled from "styled-components";
import { TextField } from "@mui/material";

export const StyledWrapper = styled.div`
  .MuiInputBase-input {
    cursor: pointer;
  }
`;

export const StyledTextField = styled(TextField)`
  min-width: 240px;

  .MuiOutlinedInput-notchedOutline.MuiOutlinedInput-notchedOutline {
    border: 1px solid ${({ theme }) => `${theme.secondary.textAdditional}4D`};
    border-radius: 8px;
  }

  .MuiOutlinedInput-root {
    border: none;
  }
  .MuiInputBase-root.Mui-disabled {
    background: #fff;
  }

  .MuiInputBase-input {
    padding: 10px 16px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: ${({ theme }) => theme.primary.textDark};

    &.Mui-disabled {
      -webkit-text-fill-color: ${({ theme }) => theme.primary.textDark};
    }
  }
`;
