import { FC } from "react";
import { Stack, Typography } from "@mui/material";
import { ModuleStatuses } from "../../../../api/companies";
import { useTranslation } from "react-i18next";

type StatusIndicatorProps = {
  status?: ModuleStatuses;
  backgroundColor: string;
};

export const StatusIndicator: FC<StatusIndicatorProps> = ({
  status,
  backgroundColor,
}) => {
  const { t } = useTranslation("modules");

  return (
    <Stack
      max-width={140}
      borderRadius={100}
      px={1}
      py="1.2px"
      sx={{
        backgroundColor: backgroundColor,
      }}
    >
      <Typography sx={{ fontSize: 12 }} variant="body2" color="white">
        {t(`statuses.${status}`).toUpperCase()}
      </Typography>
    </Stack>
  );
};
