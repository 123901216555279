import * as yup from "yup";

export const validationProjectInfo = yup.object({
  shortName: yup.string().trim().required("common:errors.required"),
  fullName: yup.string().trim().required("common:errors.required"),
  status: yup.string().trim().required("common:errors.required"),
  projectType: yup.string().trim().required("common:errors.required"),
  financingType: yup.string().trim().required("common:errors.required"),
  region: yup.string().trim().required("common:errors.required"),
  timezone: yup.string().trim().required("common:errors.required"),
  address: yup.string().trim().required("common:errors.required"),
});
