import { api } from "../api";
import {
  GetUserFiltersRequest,
  GetUserFiltersResponse,
  Users,
  UsersRequest,
  UpdateAccountProfileByCompanyRequest,
  CreateCompanyUserRequest,
  UserUpdateAvatarRequest,
  InviteUserRequest,
  ResetAccessRequest,
  UpdateAccessRequest,
  GetUsersListOwnResponse,
} from "./types";
import { Access, ProfileByCompany } from "../profile";

export type Candidate = {
  alreadyInvited: boolean;
  bindFields: ["email" | "login" | "phone"] | null;
  profile: ProfileByCompany;
};
export type CreateCompanyUserResponse = {
  candidates?: Candidate[];
  newUser?: ProfileByCompany | null;
};

export type UpdateProfileResponse = ProfileByCompany;

export const companyAdminApi = api.injectEndpoints({
  endpoints: (build) => ({
    getUsers: build.query<Users, UsersRequest>({
      query: ({ companyId, ...params }) => {
        return {
          url: `/companies/${companyId}/admin/users/list`,
          params,
        };
      },
      providesTags: ["Users"],
    }),
    getProfileByCompanyAdmin: build.query<ProfileByCompany, UsersRequest>({
      query: ({ companyId, userId }) =>
        `/companies/${companyId}/admin/users/${userId}/profile`,
      providesTags: ["ProfileByCompany"],
    }),
    updateAccountProfileByCompany: build.mutation<
      UpdateProfileResponse,
      UpdateAccountProfileByCompanyRequest
    >({
      query: ({ companyId, userId, all, ...body }) => ({
        url: `companies/${companyId}/admin/users/${userId}/profile/update`,
        params: { all },
        method: "POST",
        body,
      }),
      invalidatesTags: () => ["ProfileByCompany", "Users", "Profile", "Counterparty"],
    }),
    updateContactsProfileByCompany: build.mutation<
      UpdateProfileResponse,
      Pick<ProfileByCompany, "phone" | "email" | "login"> & UsersRequest
    >({
      query: ({ companyId, userId, ...body }) => ({
        url: `/companies/${companyId}/admin/users/${userId}/profile/update-auth`,
        method: "POST",
        body,
      }),
      invalidatesTags: () => ["ProfileByCompany", "Users", "Profile"],
    }),
    updateAccessProfileByCompany: build.mutation<
      UpdateProfileResponse,
      Omit<Access, "owner"> & UsersRequest
    >({
      query: ({ companyId, userId, ...body }) => {
        return {
          url: `/companies/${companyId}/admin/users/${userId}/profile/update-access`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: () => ["ProfileByCompany", "Users", "Profile"],
    }),
    updateAccessAdminProfileByCompany: build.mutation<
      UpdateProfileResponse,
      Pick<Access, "admin" | "owner"> & UsersRequest
    >({
      query: ({ companyId, userId, ...body }) => {
        return {
          url: `/companies/${companyId}/admin/users/${userId}/profile/update-admin`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: () => ["ProfileByCompany", "Users", "Profile"],
    }),
    updateAvatarProfileByCompany: build.mutation<
      UpdateProfileResponse,
      UserUpdateAvatarRequest
    >({
      query: ({ companyId, userId, file }) => {
        const formData = new FormData();
        formData.append("file", file);
        return {
          url: `/companies/${companyId}/admin/users/${userId}/profile/upload-avatar`,
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: () => ["ProfileByCompany", "Users", "Profile"],
      async onQueryStarted(
        { companyId, userId },
        { dispatch, queryFulfilled }
      ) {
        try {
          const { data: updatedProfile } = await queryFulfilled;
          dispatch(
            companyAdminApi.util.updateQueryData(
              "getProfileByCompanyAdmin",
              { companyId, userId },
              (draft) => {
                const { avatar, avatarPreview } = updatedProfile;
                Object.assign(draft, {
                  avatar,
                  avatarPreview,
                });
              }
            )
          );
        } catch {}
      },
    }),
    createProfileByCompany: build.mutation<
      UpdateProfileResponse,
      CreateCompanyUserRequest
    >({
      query: (body) => ({
        url: `/companies/${body?.employment?.companyID}/admin/users/create`,
        method: "POST",
        body,
      }),
      invalidatesTags: () => ["ProfileByCompany", "Users", "Profile"],
    }),

    createFullProfileByCompany: build.mutation<any, CreateCompanyUserRequest>({
      queryFn: async (arg, queryApi, extraOptions, baseQuery) => {
        const { employment, profile, access, avatar, syncWithGlobal } =
          arg || {};
        const {
          admin,
          owner,
          design,
          quality_control,
          dashboard,
          finance,
          procurement,
          analytics,
          crm,
          equip,
          offer,
          supply,
          tender,
          tracker,
          workforce,
          resource_managment,
          maintenance,
          asbuilt_doc,
        } = access || {};

        const data = await baseQuery({
          url: `/companies/${employment?.companyID}/admin/users/create`,
          method: "POST",
          body: { employment, profile },
        });

        if (data?.error) {
          return { error: data.error };
        }

        const { newUser } = data.data as CreateCompanyUserResponse;

        if (!!newUser) {
          const { coreID: userId } = newUser || {};
          const { file, companyId } = avatar || {};

          if (file instanceof Blob) {
            const formData = new FormData();
            formData.append("file", file);
            await baseQuery({
              url: `/companies/${companyId}/admin/users/${userId}/profile/upload-avatar`,
              method: "POST",
              body: formData,
            });
          }

          if (Object.keys(access || {}).length) {
            /*await baseQuery({
              url: `/companies/${companyId}/admin/users/${userId}/profile/update-admin`,
              method: "POST",
              body: { owner },
            });*/
            await baseQuery({
              url: `/companies/${companyId}/admin/users/${userId}/profile/update-access`,
              method: "POST",
              body: {
                design,
                quality_control,
                dashboard,
                finance,
                procurement,
                analytics,
                crm,
                equip,
                offer,
                supply,
                tender,
                tracker,
                workforce,
                resource_managment,
                maintenance,
                admin,
                asbuilt_doc,
                ...(syncWithGlobal && { syncWithGlobal: true }),
              },
            });
          }
        }
        return { data: data.data };
      },
      invalidatesTags: () => ["ProfileByCompany", "Users", "Profile"],
    }),

    deleteProfileByCompany: build.mutation<unknown, UsersRequest>({
      query: ({ companyId, userId }) => ({
        url: `/companies/${companyId}/admin/users/${userId}/dismiss`,
        method: "DELETE",
      }),
      invalidatesTags: () => ["ProfileByCompany", "Users", "Profile"],
    }),
    inviteUser: build.mutation<UpdateProfileResponse, InviteUserRequest>({
      query: ({ companyId, userId, ...body }) => ({
        url: `/companies/${companyId}/admin/users/${userId}/invite`,
        method: "POST",
        body,
      }),
      invalidatesTags: () => ["ProfileByCompany", "Users", "Profile"],
    }),

    getUserFilters: build.query<GetUserFiltersResponse, GetUserFiltersRequest>({
      query: ({ companyId, ...params }) => ({
        url: `/companies/${companyId}/admin/users/list-filters`,
        method: "GET",
        params,
      }),
      providesTags: ["Users"],
    }),

    inviteFullUser: build.mutation<any, InviteUserRequest>({
      queryFn: async (arg, queryApi, extraOptions, baseQuery) => {
        const { companyId, userId, employment, profile, access, avatar } =
          arg || {};
        const {
          admin,
          owner,
          design,
          quality_control,
          dashboard,
          finance,
          procurement,
          analytics,
          crm,
          equip,
          offer,
          supply,
          tender,
          tracker,
          workforce,
          resource_managment,
        } = access || {};

        const data = await baseQuery({
          url: `/companies/${companyId}/admin/users/${userId}/invite`,
          method: "POST",
          body: { employment, profile },
        });

        if (data?.error) {
          return { error: data.error };
        }

        const { file } = avatar || {};

        if (file instanceof Blob) {
          const formData = new FormData();
          formData.append("file", file);
          await baseQuery({
            url: `/companies/${companyId}/admin/users/${userId}/profile/upload-avatar`,
            method: "POST",
            body: formData,
          });
        }

        if (Object.keys(access || {}).length) {
          await baseQuery({
            url: `/companies/${companyId}/admin/users/${userId}/profile/update-admin`,
            method: "POST",
            body: { admin, owner },
          });
          await baseQuery({
            url: `/companies/${companyId}/admin/users/${userId}/profile/update-access`,
            method: "POST",
            body: {
              design,
              quality_control,
              dashboard,
              finance,
              procurement,
              analytics,
              crm,
              equip,
              offer,
              supply,
              tender,
              tracker,
              workforce,
              resource_managment,
            },
          });
        }

        return { data: data.data };
      },
      invalidatesTags: () => ["ProfileByCompany", "Users", "Profile"],
    }),

    resetUserAccessByCounterparty: build.mutation<
      ProfileByCompany,
      ResetAccessRequest
    >({
      query: ({ companyID, userID }) => {
        return {
          url: `/companies/${companyID}/admin/users/${userID}/profile/reset-access`,
          method: "POST",
        };
      },
      invalidatesTags: ["Counterparty", "Users", "ProfileByCompany"],
    }),

    updateAccess: build.mutation<Access, UpdateAccessRequest>({
      query: ({ companyID, body }) => {
        return {
          url: `/companies/${companyID}/admin/update-access`,
          body,
          method: "POST",
        };
      },
      invalidatesTags: ["CompanyAdminInfo"],
    }),

    getUsersListOwn: build.query<
      GetUsersListOwnResponse,
      { companyID: number }
    >({
      query: ({ companyID }) => {
        return {
          url: `/companies/${companyID}/admin/users/list-own`,
          method: "GET",
        };
      },
      providesTags: ["Counterparty", "Profile", "Users"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetUsersQuery,
  useGetProfileByCompanyAdminQuery,
  useUpdateAccountProfileByCompanyMutation,
  useUpdateContactsProfileByCompanyMutation,
  useUpdateAccessProfileByCompanyMutation,
  useUpdateAccessAdminProfileByCompanyMutation,
  useUpdateAvatarProfileByCompanyMutation,
  useCreateFullProfileByCompanyMutation,
  useDeleteProfileByCompanyMutation,
  useInviteFullUserMutation,
  useGetUserFiltersQuery,
  useResetUserAccessByCounterpartyMutation,
  useUpdateAccessMutation,
  useGetUsersListOwnQuery,
} = companyAdminApi;
