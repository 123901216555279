import {
  FinancingType,
  ModuleType,
  ProjectStatus,
  ProjectType,
} from "../../api/companies";
import { decode } from "js-base64";

export class FiltersState {
  enabledModules: ModuleType[] = [];
  finTypes: FinancingType[] = [];
  projectTypes: ProjectType[] = [];
  regions: string[] = [];
  statuses: ProjectStatus[] = [];

  constructor(searchParams: URLSearchParams) {
    const enabledModulesParam = searchParams.get("enabledModules");
    const finTypesParam = searchParams.get("finTypes");
    const projectTypesParam = searchParams.get("projectTypes");
    const regionsParam = searchParams.get("regions");
    const statusesParam = searchParams.get("statuses");

    if (statusesParam)
      this.statuses = decode(statusesParam).split(",") as ProjectStatus[];
    if (regionsParam) this.regions = decode(regionsParam).split(",");
    if (projectTypesParam)
      this.projectTypes = decode(projectTypesParam).split(",") as ProjectType[];
    if (finTypesParam)
      this.finTypes = decode(finTypesParam).split(",") as FinancingType[];
    if (enabledModulesParam)
      this.enabledModules = decode(enabledModulesParam).split(
        ","
      ) as ModuleType[];
  }
}

export type FiltersKeys =
  | "enabledModules"
  | "finTypes"
  | "projectTypes"
  | "regions"
  | "statuses";
