import { ProjectTimezone, Timezone } from "../../../../api/companies";

export function fromTimezoneOffsetToTimezone(
  value: string,
  timezones: ProjectTimezone[]
) {
  const offset = value.match(/\d+/m)?.[0];
  return (
    timezones.find(({ mskDelta }) => mskDelta === Number(offset))?.name || "MSK"
  );
}

export function fromTimezoneToTimezoneOffset(timezone?: Timezone, timezones?: ProjectTimezone[]) {
  if (!timezone || !timezones) return null;

  const offset = timezones.find(({ name }) => name === timezone)?.mskDelta;
  if (typeof offset !== "number") return null;

  return `Москва (МСК +${offset})`;
}
