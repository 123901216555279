import styled from "styled-components";
import { Button, IconButton } from "@mui/material";

export const StyledPage = styled.div`
  padding: 20px;
  width: 100%;
  height: 100%;
`;

export const StyledHeader = styled.div`
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledButtons = styled.div`
  display: flex;
  column-gap: 12px;
`;

export const StyledButtonSave = styled(Button)`
  background: ${({ theme }) => theme.legends.green};
  border-radius: 8px;
`;

export const StyledButtonDelete = styled(IconButton)`
  width: 36px;
  height: 36px;
  background: ${({ theme }) => theme.secondary.error};
  border-radius: 6px;

  .icon {
    fill: #fff;
    width: 20px;
  }

  &:hover {
    background: ${({ theme }) => theme.secondary.error};
  }
`;

export const StyledBody = styled.div`
  width: 100%;
  height: calc(100% - 48px);
  padding: 40px 0 20px 0;
  display: flex;
  justify-content: center;
`;
