import {FinancingType, ProjectStatus, ProjectType, Timezone} from "../api/companies";

export const projectFinancingTypes: { [key: string]: FinancingType } = {
  Инвестиционный: "investment",
  Подрядный: "contracting",
  Смешанный: "mixed",
};

export const projectStatuses: { [key: string]: ProjectStatus } = {
  Потенциальный: "potential",
  Активный: "active",
  Отложен: "delayed",
  Завершен: "completed",
};

export const projectTypes: { [key: string]: ProjectType } = {
  "Произв. назначения": "industrial",
  "Непроизв. назначения": "nonindustrial",
  "Линейные объекты": "linear",
};

export const timezonesOffset: {[key in Timezone]: number} = {
  SAMT: -1,
  MSK: 0,
  KALT: 1,
  YEKT: 2,
  OMST: 3,
  KRAT: 4,
  IRKT: 5,
  YAKT: 6,
  VLAT: 7,
  MAGT: 8,
  PETT: 9,
}