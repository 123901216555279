import * as yup from "yup";
import { CODE_LENGTH } from "../../../utils/constants";

export const validationByPhone = yup.object({
  phone: yup.string().trim().required("common.errors.required"),
});

export const validationByPhoneAndCode = yup.object({
  phone: yup.string().trim().required("common:errors.required"),
  code: yup
    .string()
    .trim()
    .required("common:errors.required")
    .min(CODE_LENGTH, "common:errors.minCode"),
});
