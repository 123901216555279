import React, { FC, useState } from "react";
import { ICellRendererParams } from "ag-grid-community";
import { StatusCellProps } from "./status-cell.types";
import { StyledCell } from "./status-cell.styles";
import { StyledButtonComment } from "../../../../../../../assignment-by-central-office/components/assignment-table/components/status-cell/status-cell.styles";
import MessageIcon from "@mui/icons-material/Message";
import { AssignmentCommentTooltip } from "../../../../../../../../components/assignment-comment-tooltip";

export const StatusCell: FC<ICellRendererParams<unknown, StatusCellProps>> = ({
  value,
}) => {
  const [isCommentTooltipOpen, setIsCommentTooltipOpen] = useState(false);

  return (
    <StyledCell $status={value?.status}>
      {value?.status || "–"}

      <AssignmentCommentTooltip
        open={isCommentTooltipOpen}
        onClose={() => setIsCommentTooltipOpen(!isCommentTooltipOpen)}
        comment={value?.comment}
        commentDate={value?.commentDate}
        commentAuthor={value?.commentAuthor}
      >
        <StyledButtonComment
          onClick={() => setIsCommentTooltipOpen(!isCommentTooltipOpen)}
          disabled={!value?.comment}
          className="button-comment"
        >
          <MessageIcon className="icon" />
        </StyledButtonComment>
      </AssignmentCommentTooltip>
    </StyledCell>
  );
};
