import { FormikValues } from "formik/dist/types";
import { Access } from "../../api/profile";

export function mergeFormValuesAndAccess(values: FormikValues, access: Access) {
  const mergedValues = Object.entries(values).reduce(
    (accumulator, [key, value]) => {
      return { ...accumulator, [key]: access[key as keyof Access] || value };
    },
    {} as FormikValues
  );

  return {
    ...mergedValues,
    admin: "admin" in access ? access.admin : values.admin,
  };
}

/*dashboard: dashboard || "none",
  design: design || "none",
  quality_control: quality_control || "none",
  finance: finance || "none",
  procurement: procurement || "none",
  analytics: analytics || "none",
  crm: crm || "none",
  equip: equip || "none",
  offer: offer || "none",
  supply: supply || "none",
  tender: tender || "none",
  tracker: tracker || "none",
  workforce: workforce || "none",
  resource_managment: resource_managment || "none",
  maintenance: maintenance || "none",*/

export function compareFormValuesAndAccess(
  values: FormikValues,
  access?: Access
) {
  if (!access) return false;
  const keys: (keyof Access)[] = [
    "design",
    "quality_control",
    "finance",
    "procurement",
    "offer",
    "resource_managment",
    "maintenance",
    "dashboard",
    "admin",
    "asbuilt_doc",
  ];

  return keys.every((key) => {
    return access?.[key] === values[key];
  });
}

export function getCounterpartyIDByPath(pathName?: string) {
  const regex = /\/companies\/\d+\/counterparties\/(\d+)/;
  const match = pathName?.match(regex);
  return match ? Number(match[1]) : undefined;
}
