import styled from "styled-components";
import { limitTextRows } from "../../../../common/styledMixins";

export const StyledAssignmentPanel = styled.div`
  padding: 20px;
  width: 300px;
  height: 100%;
  border-radius: 16px;
  background: ${({ theme }) => theme.primary.blueShades};
  display: grid;
  grid-row-gap: 12px;
  align-content: start;
  overflow-y: auto;
`;

export const StyledTitle = styled.div`
  width: 100%;
  height: 40px;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  ${limitTextRows(2)};
`;

export const StyledDivider = styled.div`
  width: 100%;
  height: 1px;
  background: ${({ theme }) => theme.secondary.backgroundDivider};
`;
