import React, {
  FC,
  useCallback,
  useState,
  MouseEvent,
  ChangeEvent,
  useMemo, useEffect,
} from "react";
import { SearchableSelectProps } from "./searchable-select.types";
import { Typography } from "@mui/material";
import { StyledLabel } from "../../common/commonStyles";
import {
  StyledInput,
  StyledSearchableSelect,
  StyledList,
  StyledListItem,
  StyledIconWrapper,
} from "./searchable-select.styles";
import { Popper, ClickAwayListener } from "@mui/material";
import { useField } from "formik";
import { useTranslation } from "react-i18next";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SearchIcon from "@mui/icons-material/Search";
import { debounce } from "@mui/material";
import { StyledNoOptionsMessage } from "../../common/commonStyles";

export const SearchableSelect: FC<SearchableSelectProps> = ({
  options,
  noOptionsMessage,
  placeholder,
  labelClassName,
  variant,
  labelText,
  name,
}) => {
  const [{ value }, , { setValue }] = useField(name || "");
  const { t } = useTranslation("common");

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [search, setSearch] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useState("");

  const filteredOptions = useMemo(() => {
    if (debouncedSearch)
      return options.filter(({ label }) =>
        label?.toLowerCase()?.includes(debouncedSearch.toLowerCase())
      );

    return options;
  }, [options, debouncedSearch]);

  useEffect(() => {
    if (!anchorEl && search) setSearch("");
  }, [anchorEl]);

  const handleInputClick = useCallback(
    (event: MouseEvent) => {
      setAnchorEl(anchorEl ? null : (event.currentTarget as HTMLElement));
    },
    [anchorEl]
  );

  const handleListItemClick = useCallback(
    (option: { label: string; value: string | string[] }) => {
      setValue(option.label);
      setAnchorEl(null);
    },
    []
  );

  const setDebouncedSearchValue = useCallback(
    debounce((value: string) => setDebouncedSearch(value), 300),
    []
  );

  const handleSearchChange = useCallback((event: ChangeEvent) => {
    const target = event.target as HTMLInputElement;

    setSearch(target.value);
    setDebouncedSearchValue(target.value);
  }, []);

  return (
    <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
      <StyledLabel
        className={`field-form-label ${labelClassName}`}
        $variant={variant}
      >
        {labelText && (
          <Typography pb={0.5} color="secondary" pl={2}>
            {labelText}
          </Typography>
        )}

        <StyledSearchableSelect>
          <StyledInput
            aria-readonly
            readOnly
            onClick={handleInputClick}
            placeholder={placeholder}
            value={value}
            $variant={variant}
            $hasPointer
          />

          <StyledIconWrapper $isRotated={!!anchorEl}>
            {variant === "profile" ? (
              <ArrowDropDownIcon className="icon" />
            ) : (
              <KeyboardArrowDownIcon className="icon" />
            )}
          </StyledIconWrapper>

          <Popper open={!!anchorEl} anchorEl={anchorEl} placement="bottom">
            <StyledList $width={anchorEl?.getBoundingClientRect().width}>
              <StyledListItem $isSearch>
                <StyledInput
                  placeholder={t("buttons.search")}
                  value={search}
                  onChange={handleSearchChange}
                />
                <StyledIconWrapper>
                  <SearchIcon className="icon" />
                </StyledIconWrapper>
              </StyledListItem>

              {filteredOptions?.length ? (
                filteredOptions?.map((option) => (
                  <StyledListItem
                    key={option.label}
                    onClick={() => handleListItemClick(option)}
                    $isInteractive
                  >
                    <Typography>{option.label}</Typography>
                  </StyledListItem>
                ))
              ) : (
                <StyledListItem>
                  <StyledNoOptionsMessage>
                    {noOptionsMessage || t("noOptions")}
                  </StyledNoOptionsMessage>
                </StyledListItem>
              )}
            </StyledList>
          </Popper>
        </StyledSearchableSelect>
      </StyledLabel>
    </ClickAwayListener>
  );
};
