import { api } from "../api";
import {
  CompaniesListResponse,
  Company,
  ModulesResponse,
  ModuleType,
  CommonCompanyParams,
  CompanyAdminData,
  UpdateCompanyAdminInfoParams,
  UploadAvatarParams,
  UpdateCompanyAdminInfoWithAvatarParams,
  UpdateProfileByCompanyRequest,
  GetProjectsListResponse,
  GetProjectsListRequest,
  GetProjectFiltersRequest,
  ProjectListFilter,
  ProjectDictionary,
  CreateProjectRequest,
  ProjectCreateBody,
  GetFilteredProjectsListRequest,
  ProjectInfo,
  SetFavouriteRequest,
  DetailsProjectInfo,
  UpdateProjectRequest,
  UpdateProjectModuleRequest,
  UpdateDesignRequest, CommonProjectParams,
} from "./types";
import { ProfileByCompany } from "../profile";

type ProfileRequest = {
  companyId: Company["id"];
  file?: Blob;
  all?: boolean;
};

export const companyApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCompanies: build.query<CompaniesListResponse, void>({
      query: () => "/companies/list",
      providesTags: ["Companies"],
    }),
    getModulesByCompanyId: build.query<ModulesResponse, Company["id"]>({
      query: (companyId) => `/companies/${companyId}/modules`,
      providesTags: ["Modules"],
    }),
    getUrlAuthInModule: build.mutation<
      any,
      { companyId: Company["id"]; module: ModuleType }
    >({
      query: ({ companyId, module }) => ({
        url: `/companies/${companyId}/auth-in-module`,
        params: { module },
        method: "GET",
      }),
    }),

    getProfileByCompany: build.query<ProfileByCompany, Company["id"]>({
      query: (companyId) => `/companies/${companyId}/profile`,
      providesTags: ["ProfileByCompany"],
    }),
    updateProfileByCompany: build.mutation<
      ProfileByCompany,
      UpdateProfileByCompanyRequest
    >({
      query: ({ companyId, all, ...body }) => ({
        url: `/companies/${companyId}/profile/update`,
        params: { all },
        method: "POST",
        body,
      }),
      invalidatesTags: () => ["Profile", "ProfileByCompany"],
      async onQueryStarted({ companyId }, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedProfile } = await queryFulfilled;
          dispatch(
            companyApi.util.updateQueryData(
              "getProfileByCompany",
              companyId,
              (draft) => {
                const { avatar, avatarPreview, ...profile } = updatedProfile;
                Object.assign(draft, profile);
              }
            )
          );
        } catch {}
      },
    }),
    updateProfileAvatar: build.mutation<ProfileByCompany, ProfileRequest>({
      query: ({ companyId, file, all }) => {
        const formData = new FormData();
        formData.append("file", file || "");
        return {
          url: `/companies/${companyId}/profile/upload-avatar`,
          params: { all },
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: () => ["Profile", "Users", "ProfileByCompany"],
      async onQueryStarted({ companyId }, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedProfile } = await queryFulfilled;
          dispatch(
            companyApi.util.updateQueryData(
              "getProfileByCompany",
              companyId,
              (draft) => {
                const { avatar, avatarPreview } = updatedProfile;
                Object.assign(draft, {
                  avatar,
                  avatarPreview,
                });
              }
            )
          );
        } catch {}
      },
    }),
    acceptInvitation: build.mutation<ProfileByCompany, ProfileRequest>({
      query: ({ companyId }) => ({
        url: `/companies/${companyId}/accept-invitation`,
        method: "POST",
      }),
      invalidatesTags: () => [
        "Companies",
        "ProfileByCompany",
        "Profile",
        "Users",
      ],
    }),

    setCompanyAsMain: build.mutation<ProfileByCompany, ProfileRequest>({
      query: ({ companyId }) => ({
        url: `/companies/${companyId}/set-main`,
        method: "POST",
      }),
      invalidatesTags: () => ["Companies", "ProfileByCompany", "Profile"],
    }),

    leaveCompany: build.mutation<ProfileByCompany, ProfileRequest>({
      query: ({ companyId }) => ({
        url: `/companies/${companyId}/leave`,
        method: "POST",
      }),
      invalidatesTags: () => ["Companies", "ProfileByCompany", "Profile"],
    }),

    getCompanyAdminInfo: build.query<CompanyAdminData, CommonCompanyParams>({
      query: ({ companyID }) => ({
        url: `/companies/${companyID}/admin/get`,
        method: "GET",
      }),
      providesTags: ["CompanyAdminInfo"],
    }),

    updateCompanyAdminInfo: build.mutation<
      CompanyAdminData,
      UpdateCompanyAdminInfoParams
    >({
      query: ({ companyID, body }) => ({
        url: `/companies/${companyID}/admin/update`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["Companies"],
    }),

    updateCompanyAdminInfoWithAvatar: build.mutation<
      CompanyAdminData,
      UpdateCompanyAdminInfoWithAvatarParams
    >({
      query: ({ companyID, body }) => ({
        url: `/companies/${companyID}/admin/update-with-avatar`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["Companies", "CompanyAdminInfo"],
    }),

    uploadAvatar: build.mutation<CompanyAdminData, UploadAvatarParams>({
      query: ({ companyID, body }) => ({
        url: `/companies/${companyID}/admin/upload-avatar`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Companies"],
    }),

    resetClientId: build.mutation<CompanyAdminData, CommonCompanyParams>({
      query: ({ companyID }) => ({
        url: `/companies/${companyID}/admin/reset-client-id`,
        method: "POST",
      }),
      invalidatesTags: ["CompanyAdminInfo"],
    }),

    getProjects: build.query<GetProjectsListResponse, GetProjectsListRequest>({
      query: ({ companyID, ...params }) => ({
        url: `/companies/${companyID}/project/list`,
        method: "GET",
        params,
      }),
      providesTags: ["Projects"],
    }),

    getFilteredProjects: build.query<
      GetProjectsListResponse,
      GetFilteredProjectsListRequest
    >({
      query: ({ companyID, body, ...params }) => ({
        url: `/companies/${companyID}/project/list-filtered`,
        method: "POST",
        body,
        params,
      }),
      providesTags: ["Projects"],
    }),

    getProjectFilters: build.query<ProjectListFilter, GetProjectFiltersRequest>(
      {
        query: ({ companyID, body, ...params }) => ({
          url: `/companies/${companyID}/project/list-filters`,
          method: "POST",
          body,
          params,
        }),
        providesTags: ["Projects"],
      }
    ),

    getProjectDictionary: build.query<ProjectDictionary, CommonCompanyParams>({
      query: ({ companyID }) => ({
        url: `/companies/${companyID}/project/dictionary`,
        method: "GET",
      }),
      providesTags: ["Projects"],
    }),

    createProject: build.mutation<ProjectCreateBody, CreateProjectRequest>({
      query: ({ companyID, body }) => ({
        url: `/companies/${companyID}/project/create`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Projects"],
    }),

    updateProject: build.mutation<ProjectCreateBody, UpdateProjectRequest>({
      query: ({ companyID, projectID, body }) => ({
        url: `/companies/${companyID}/project/${projectID}/update`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["Projects"],
    }),

    setFavourite: build.mutation<ProjectInfo, SetFavouriteRequest>({
      query: ({ companyID, projectID, ...params }) => ({
        url: `/companies/${companyID}/project/${projectID}/set-favourite`,
        method: "POST",
        params,
      }),
      invalidatesTags: ["Projects"],
    }),

    getProject: build.query<
      DetailsProjectInfo,
      Omit<SetFavouriteRequest, "favourite">
    >({
      query: ({ companyID, projectID }) => ({
        url: `/companies/${companyID}/project/${projectID}/get`,
        method: "GET",
      }),
      providesTags: ["Project"],
    }),

    updateDesign: build.mutation<DetailsProjectInfo, UpdateDesignRequest>({
      query: ({ companyID, projectID, body }) => ({
        url: `/companies/${companyID}/project/${projectID}/design/update`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["Projects", "Project"],
    }),

    updateDashboard: build.mutation<
      DetailsProjectInfo,
      UpdateProjectModuleRequest
    >({
      query: ({ companyID, projectID, body }) => ({
        url: `/companies/${companyID}/project/${projectID}/dashboard/update`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["Projects", "Project"],
    }),

    updateRM: build.mutation<DetailsProjectInfo, UpdateProjectModuleRequest>({
      query: ({ companyID, projectID, body }) => ({
        url: `/companies/${companyID}/project/${projectID}/rm/update`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["Projects", "Project"],
    }),

    updateQC: build.mutation<DetailsProjectInfo, UpdateProjectModuleRequest>({
      query: ({ companyID, projectID, body }) => ({
        url: `/companies/${companyID}/project/${projectID}/qc/update`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["Projects", "Project"],
    }),

    updateOffer: build.mutation<DetailsProjectInfo, UpdateProjectModuleRequest>({
      query: ({ companyID, projectID, body }) => ({
        url: `/companies/${companyID}/project/${projectID}/offer/update`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["Projects", "Project"],
    }),

    updateFinance: build.mutation<DetailsProjectInfo, UpdateProjectModuleRequest>({
      query: ({ companyID, projectID, body }) => ({
        url: `/companies/${companyID}/project/${projectID}/finance/update`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["Projects", "Project"],
    }),

    updateAsbuiltDoc: build.mutation<DetailsProjectInfo, UpdateProjectModuleRequest>({
      query: ({ companyID, projectID, body }) => ({
        url: `/companies/${companyID}/project/${projectID}/asbuilt_doc/update`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["Projects", "Project"],
    }),

    updateProcurement: build.mutation<DetailsProjectInfo, UpdateProjectModuleRequest>({
      query: ({ companyID, projectID, body }) => ({
        url: `/companies/${companyID}/project/${projectID}/procurement/update`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["Projects", "Project"],
    }),

    deleteProject: build.mutation<string, CommonProjectParams>({
      query: ({ companyID, projectID }) => ({
        url: `/companies/${companyID}/project/${projectID}/delete`,
        method: "DELETE",
      }),
      invalidatesTags: ["Projects"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetCompaniesQuery,
  useGetModulesByCompanyIdQuery,
  useGetProfileByCompanyQuery,
  useUpdateProfileByCompanyMutation,
  useUpdateProfileAvatarMutation,
  useAcceptInvitationMutation,
  useSetCompanyAsMainMutation,
  useLeaveCompanyMutation,
  useGetUrlAuthInModuleMutation,
  useGetCompanyAdminInfoQuery,
  useUpdateCompanyAdminInfoMutation,
  useUploadAvatarMutation,
  useResetClientIdMutation,
  useUpdateCompanyAdminInfoWithAvatarMutation,
  useGetProjectsQuery,
  useGetProjectFiltersQuery,
  useGetProjectDictionaryQuery,
  useCreateProjectMutation,
  useGetFilteredProjectsQuery,
  useSetFavouriteMutation,
  useGetProjectQuery,
  useUpdateProjectMutation,
  useUpdateDashboardMutation,
  useUpdateDesignMutation,
  useUpdateRMMutation,
  useUpdateAsbuiltDocMutation,
  useUpdateQCMutation,
  useUpdateOfferMutation,
  useUpdateFinanceMutation,
  useUpdateProcurementMutation,
  useDeleteProjectMutation,
} = companyApi;
