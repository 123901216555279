import { AssignmentsBranchResponseShort } from "../../../../../../api/assignments/types";

export function getTableDataByGroup(data: AssignmentsBranchResponseShort[]) {
  const dataByGroup = data.reduce((accumulator, current) => {
    const key = current.group || "Без группы";

    if (accumulator[key]) {
      accumulator[key].push(current);
    } else {
      accumulator[key] = [current];
    }

    return accumulator;
  }, {} as { [key: string]: AssignmentsBranchResponseShort[] });

  return Object.entries(dataByGroup);
}
