import { FC, useCallback, useMemo, useState } from "react";
import { DivisionFormItem, DivisionsListProps } from "./DivisionsList.types";
import { DivisionItem } from "./components/DivisionItem";
import { StyledDivisionsList, StyledButtonAdd } from "./DivisionsList.styles";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import { v4 } from "uuid";
import { DeleteModal } from "../../../../components/delete-modal";

export const DivisionsList: FC<DivisionsListProps> = ({
  divisions,
  creationData,
  onChange,
}) => {
  const { t } = useTranslation("assignments");
  const [deletedDivision, setDeletedDivision] =
    useState<DivisionFormItem | null>(null);

  const creationDataWithHead = useMemo(
    () => creationData.filter((division) => division.headUser),
    [creationData]
  );
  const filteredCreationData = useMemo(() => {
    const divisionsNames = divisions.map((division) => division.name);

    return creationData.filter(
      (division) => !divisionsNames.includes(division.name || "")
    );
  }, [creationData, divisions]);
  const isAllDivisionSelected =
    creationDataWithHead.length === divisions.length;

  const handleButtonAddClick = useCallback(() => {
    onChange([...divisions, { id: v4(), name: "" }]);
  }, [divisions]);

  const handleConfirm = useCallback(() => {
    if (deletedDivision) {
      onChange(
        divisions.filter((division) => division.id !== deletedDivision.id)
      );
      setDeletedDivision(null);
    }
  }, [deletedDivision]);

  const handleItemChange = (id: string, name: string) => {
    if (name === t("allDivisions") && creationDataWithHead.length) {
      const updatedDivisions = creationDataWithHead.reduce(
        (accumulator, current) => {
          const division = divisions.find(
            (division) => division.name === current.name
          );

          return [
            ...accumulator,
            division || { id: v4(), name: current.name || "" },
          ];
        },
        [] as DivisionFormItem[]
      );

      onChange(updatedDivisions);
      return;
    }

    const updatedDivisions = divisions.map((division) => {
      if (division.id === id) {
        return { ...division, name };
      }
      return division;
    });
    onChange(updatedDivisions);
  };

  return (
    <StyledDivisionsList>
      {divisions.map((division, index) => {
        return (
          <DivisionItem
            key={division.id}
            division={division}
            creationData={filteredCreationData}
            onButtonRemoveClick={() => setDeletedDivision(division)}
            isDisabled={divisions.length === 1}
            onChange={(name) => handleItemChange(division.id, name)}
          />
        );
      })}

      {!isAllDivisionSelected && (
        <StyledButtonAdd
          variant="contained"
          startIcon={<AddIcon />}
          onClick={handleButtonAddClick}
        >
          {t("divisions.label")}
        </StyledButtonAdd>
      )}

      <DeleteModal
        open={!!deletedDivision}
        title={t("divisionsDeleteModal.title")}
        description={t("divisionsDeleteModal.description")}
        onClose={() => setDeletedDivision(null)}
        onConfirm={handleConfirm}
        rightButtonColor="primary"
      />
    </StyledDivisionsList>
  );
};
