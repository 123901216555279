import styled from "styled-components";
import { Form } from "formik";

export const StyledForm = styled(Form)`
  font-size: 13px;
  line-height: 22px;
  font-weight: 500;
  text-transform: uppercase;
  color: #2b3648;
  display: flex;
  align-items: center;

  .MuiFormControl-root {
    min-width: 190px;
    width: 190px;
  }

  .MuiInputBase-root {
    height: 33px;
  }
`;

export const StyledDash = styled.div`
  margin: 0 11px;
`;

export const StyledTitle = styled.div`
  font-size: 14px;
  text-transform: capitalize;
  margin-right: 16px;
`;
