import { css } from "styled-components";

export const disableArrowFromInput = css`
  -moz-appearance: textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;

export const limitTextRows = (count: number) => css`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: ${count};
  -webkit-box-orient: vertical;
`;

export const textOneRow = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const reactSelectCommonStyles = css`
  &__control {
    width: 100%;
    min-height: 32px;
    height: 40px;
    background: ${({ theme }) => theme.primary.formBackground};
    border: none;
    border-radius: 8px;
    font-size: 16px;
    padding-right: 4px;

    &--is-focused {
      box-shadow: none;
    }
  }

  &__value-container {
    padding: 2px 14px;
  }

  &__placeholder {
    font-size: 16px;
    color: ${({ theme }) => theme.primary.placeholder};
  }

  &__indicator {
    padding: 4px;
  }

  &__option--is-focused {
    background: ${({ theme }) => theme.background.focused};
    color: ${({ theme }) => theme.primary.textDark};
  }
`;

export const reactSelectCreateMessage = css`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: start;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.secondary.textAdditional};

  svg {
    margin-right: 8px;
    width: 20px;
    fill: ${({ theme }) => theme.primary.outlinedBorder};
  }
`;
