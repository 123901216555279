import { ModulesResponse, ModuleStatuses, ModuleType } from "../api/companies";

export const getGroupModulesByStatus = (
  statuses: ModulesResponse["statuses"]
) => {
  const result = {} as Record<ModuleStatuses, ModuleType[]>;

  Object.entries(statuses || {}).forEach(([module, status]) => {
    if (!result[status as ModuleStatuses]) {
      result[status as ModuleStatuses] = [];
    }
    result[status as ModuleStatuses]?.push(module as ModuleType);
  });
  return result;
};
