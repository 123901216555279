import React, { FC, SyntheticEvent } from "react";
import { Tab as MuiTab } from "@mui/material";
import { StyledTabs, StyledTabText } from "./tabs.styles";
import { TabsProps } from "./tabs.types";

export const Tabs: FC<TabsProps> = ({
  currentValue,
  values,
  hasPadding = true,
  onChange,
  ...props
}) => {
  return (
    <StyledTabs
      value={currentValue}
      textColor="primary"
      indicatorColor="primary"
      onChange={(_: SyntheticEvent, newValue: string) => onChange(newValue)}
      $hasPadding={hasPadding}
      {...props}
    >
      {values.map(({ label, value, icon, disabled }) => (
        <MuiTab
          value={value}
          label={<StyledTabText variant="body2">{icon}{label}</StyledTabText>}
          disabled={disabled}
          key={value}
        />
      ))}


    </StyledTabs>
  );
};
