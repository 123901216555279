import styled from "styled-components";

export const StyledBody = styled.div`
  width: 334px;
  min-height: 92px;
  border-radius: 8px;
  background: #fff;
  padding: 20px;
  box-shadow: 0 24px 48px 0 #10182824, 0 -8px 24px 0 #10182814;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #fff;
    top: -10px;
    right: 10px;
  }
`;

export const StyledTitle = styled.div`
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  color: ${({ theme }) => theme.primary.textDark};
  margin-bottom: 12px;
`;

export const StyledButtons = styled.div`
  display: flex;
  column-gap: 12px;
  justify-content: space-between;

  .MuiButton-root {
    width: 100%;
    font-size: 13px;
  }
`;
