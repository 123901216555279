import { FC } from "react";
import { Stack, StackProps } from "@mui/material";

export const ScrollableContainer: FC<StackProps> = ({ children, ...props }) => {
  return (
    <Stack flex={1} direction="row" position="relative" {...props}>
      <Stack
        direction="row"
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        overflow="auto"
        sx={{ overflowY: "overlay" }}
        flex={1}
      >
        {children}
      </Stack>
    </Stack>
  );
};
