import styled from "styled-components";
import { Button, IconButton } from "@mui/material";

export const StyledCell = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledIconButton = styled(IconButton)``;

export const StyledTooltipBody = styled.div`
  padding: 10px;
  color: ${({ theme }) => theme.primary.mainBlue};
  display: grid;
  grid-template-rows: 24px 1fr 36px;
  height: 100%;
  grid-row-gap: 8px;

  .file {
    min-height: 36px;
    grid-auto-rows: 36px;
  }

  .files-list {
    row-gap: 8px;
    max-height: 212px;
    height: 100%;
    grid-auto-rows: 36px;
    overflow-y: auto;
  }
`;

export const StyledTooltipTitle = styled.div`
  width: 100%;
  height: 24px;
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  column-gap: 4px;

  .icon {
    width: 16px;
  }
`;

export const StyledButtonDownload = styled(Button)`
  width: 100%;
  background: ${({ theme }) => `${theme.background.blue}14`};
  box-shadow: none;
  color: ${({ theme }) => theme.primary.mainBlue};
  font-size: 14px;
  font-weight: 500;

  &:hover {
    background: ${({ theme }) => `${theme.background.blue}14`};
    box-shadow: none;
  }
`;
