import styled, { DefaultTheme } from "styled-components";
import { limitTextRows } from "../../../../common/styledMixins";
import { Link } from "react-router-dom";

const getRoleBackground = (theme: DefaultTheme, role: string) => {
  switch (role) {
    case "Проектировщик":
      return theme.legends.lightGreen;
    case "Подрядчик":
      return theme.legends.violet;
    case "Поставщик":
      return theme.legends.green;
    case "Производитель":
      return theme.legends.red;
    case "Услуги":
      return theme.legends.blue;
    default:
      return theme.primary.warningLight;
  }
};

export const StyledCard = styled(Link)`
  min-width: 345px;
  width: 100%;
  padding: 20px;
  border-radius: 8px;
  background: ${({ theme }) => theme.primary.blueShades};
  display: grid;
  grid-row-gap: 16px;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    box-shadow: 0 6px 20px 2px #1018281a, 0 7px 8px -3px #10182814,
      0 -6px 12px 0 #10182814;
  }
`;

export const StyledHeader = styled.div`
  display: grid;
  grid-template-columns: repeat(3, max-content);
  grid-column-gap: 12px;
  align-items: center;
`;

interface RoleProps {
  $role: string;
}

export const StyledRole = styled.div<RoleProps>`
  min-width: 120px;
  width: 100%;
  padding: 2px 8px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: ${({ theme, $role }) => getRoleBackground(theme, $role)};
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
`;

export const StyledBinded = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: ${({ theme }) => theme.primary.textDark};
`;

export const StyledTopBlock = styled.div`
  display: flex;
  padding-bottom: 16px;
  border-bottom: 2px solid ${({ theme }) => theme.secondary.backgroundDivider};
`;

export const StyledAvatar = styled.div`
  width: 120px;
  height: 120px;
  border-radius: 4px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;

  .image {
    border-radius: 4px;
  }

  .icon path {
    fill: #7890b2;
  }
`;

export const StyledMainInfo = styled.div`
  display: grid;
  grid-row-gap: 12px;
  padding: 10px 0;
  margin-left: 16px;
  max-width: 169px;
`;

export const StyledName = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: ${({ theme }) => theme.primary.mainBlue};
  ${limitTextRows(2)};
`;

export const StyledField = styled.div`
  display: grid;
  grid-row-gap: 4px;
  align-content: start;
`;

export const StyledKey = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: ${({ theme }) => theme.secondary.text};
`;

export const StyledValue = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.primary.textDark};
  ${limitTextRows(2)};
`;

export const StyledAdditionalInfo = styled.div`
  display: grid;
  grid-row-gap: 12px;
  align-content: start;
`;
