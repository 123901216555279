import { FC } from "react";
import { CounterpartyCardProps } from "./counterparty-card.types";
import {
  StyledCard,
  StyledHeader,
  StyledRole,
  StyledBinded,
  StyledTopBlock,
  StyledAvatar,
  StyledMainInfo,
  StyledName,
  StyledField,
  StyledKey,
  StyledValue,
  StyledAdditionalInfo,
} from "./counterparty-card.styles";
import { useTranslation } from "react-i18next";
import { ReactComponent as AvatarIcon } from "../../../../components/icons/imported-icons/counterparty-avatar.svg";
import {useAppContext} from "../../../../layouts/app-layout/context";

export const CounterpartyCard: FC<CounterpartyCardProps> = ({ profile }) => {
  const { t } = useTranslation("counterparties");
  const { currentCompanyId } = useAppContext();

  return (
    <StyledCard to={`/companies/${currentCompanyId}/counterparties/${profile.id}`}>
      <StyledHeader>
        <StyledRole $role={profile.role}>
          {profile.role || t("roles.noRole")}
        </StyledRole>
        {profile.binded && <StyledBinded>{t("binded")}</StyledBinded>}
      </StyledHeader>

      <StyledTopBlock>
        <StyledAvatar>
          {profile.avatarPreview ? (
            <img
              width={120}
              height={120}
              src={profile.avatarPreview}
              alt={t("avatarAlt")}
              className="image"
            />
          ) : (
            <AvatarIcon className="icon" />
          )}
        </StyledAvatar>

        <StyledMainInfo>
          <StyledName>{profile.name}</StyledName>
          <StyledField>
            <StyledKey>{t("labels.inn")}</StyledKey>
            <StyledValue>{profile.inn ?? "–"}</StyledValue>
          </StyledField>
        </StyledMainInfo>
      </StyledTopBlock>

      <StyledAdditionalInfo>
        <StyledField>
          <StyledKey>{t("labels.fullName")}</StyledKey>
          <StyledValue>{profile.fullName ?? "–"}</StyledValue>
        </StyledField>

        <StyledField>
          <StyledKey>{t("labels.legalAddress")}</StyledKey>
          <StyledValue>{profile.legalAddress ?? "–"}</StyledValue>
        </StyledField>
      </StyledAdditionalInfo>
    </StyledCard>
  );
};
