export type Tab =
  | "projectInfo"
  | "personsAndCompanies"
  | "dashboard"
  | "design"
  | "rm"
  | "qc"
  | "estimation"
  | "finance"
  | "procurement"
  | "asbuilt_doc";

export class FormsState {
  projectInfo = { isSubmitDisabled: true, isChanged: false };
  personsAndCompanies = { isSubmitDisabled: true, isChanged: false };
  dashboard = { isSubmitDisabled: true, isChanged: false };
  design = { isSubmitDisabled: true, isChanged: false };
  rm = { isSubmitDisabled: true, isChanged: false };
  qc = { isSubmitDisabled: true, isChanged: false };
  estimation = { isSubmitDisabled: true, isChanged: false };
  finance = { isSubmitDisabled: true, isChanged: false };
  procurement = { isSubmitDisabled: true, isChanged: false };
  asbuilt_doc = { isSubmitDisabled: true, isChanged: false };
}
