import { FC, useCallback } from "react";
import { AssignmentStatusSelectProps } from "./assignment-status-select.types";
import {
  StyledSelectWrapper,
  StyledSelectValue,
  StyledMenuItem,
} from "./assignment-status-select.styles";
import { AssignmentStatus } from "../../api/assignments/types";
import { Select, SelectChangeEvent } from "@mui/material";

export const AssignmentStatusSelect: FC<AssignmentStatusSelectProps> = ({
  options,
  value,
  disabled,
  showPlainText,
  onChange,
}) => {
  const handleChange = useCallback((event: SelectChangeEvent) => {
    onChange(event.target.value as AssignmentStatus);
  }, []);

  const textValue = value ? (
    <StyledSelectValue $status={value || ""}>{value}</StyledSelectValue>
  ) : null;

  return (
    <StyledSelectWrapper>
      {showPlainText ? (
        textValue
      ) : (
        <Select
          name="status"
          value={value}
          disabled={disabled}
          renderValue={(value: AssignmentStatus) => (
            <StyledSelectValue $status={value}>{value}</StyledSelectValue>
          )}
          onChange={handleChange}
        >
          {options.map((status) => (
            <StyledMenuItem key={status} value={status} $status={status}>
              {status}
            </StyledMenuItem>
          ))}
        </Select>
      )}
    </StyledSelectWrapper>
  );
};
