import React, { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, FormikProvider } from "formik";
import {
  Button,
  Dialog,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import KeySharpIcon from "@mui/icons-material/KeySharp";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import CheckIcon from "@mui/icons-material/Check";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import { ShowByTimer, Timer } from "../show-by-timer";
import { useForm } from "../../hooks/use-form";
import { useMutationHandlers } from "../../hooks/use-mutation-handlers";
import { CodeFieldForm } from "../code-field-form";
import { useConfirmEmailByCodeMutation } from "../../api/auth";
import { CODE_LENGTH } from "../../utils/constants";
import { Profile } from "../../api/profile";

type ProfileConfirmEmailProps = {
  handleGetCodeByEmail: () => void;
  getCodeByEmailResponse: any;
  currentEmail: Profile["email"];
};

export const EmailConfirmDailog: FC<ProfileConfirmEmailProps> = ({
  currentEmail,
  getCodeByEmailResponse,
  handleGetCodeByEmail,
}) => {
  const { t } = useTranslation("profile");

  const [isOpenModalEmail, setIsOpenModalEmail] = useState(false);

  const [timer, setTimer] = useState<Timer>();

  const [confirmEmailByCode, confirmEmailByCodeResponse] =
    useConfirmEmailByCodeMutation();

  const handleClose = useCallback(() => {
    setIsOpenModalEmail(false);
  }, []);
  const handleSubmit = useCallback(
    ({ code }) => {
      confirmEmailByCode({ email: currentEmail, code });
    },
    [confirmEmailByCode, currentEmail]
  );

  const { formik } = useForm({
    initialValues: {
      code: "",
    },
    validateOnBlur: false,
    onSubmit: handleSubmit,
  });
  const {
    errors,
    values,
    setFieldError,
    setFieldValue,
    setFieldTouched,
    submitForm,
  } = formik;

  const code = values.code;
  useEffect(() => {
    const regExp = /_/g;
    if (code.length === CODE_LENGTH && !regExp.test(code)) {
      submitForm();
    }
  }, [code, submitForm]);

  useMutationHandlers(
    confirmEmailByCodeResponse,
    () => {
      setIsOpenModalEmail(false);
    },
    (error) => {
      setFieldError(
        "code",
        "data" in error ? t(`common:errors.${error.data}`) : "Неверный код"
      );
      setFieldTouched("code", true, false);
    }
  );
  useMutationHandlers(
    getCodeByEmailResponse,
    (data) => {
      setTimer({
        start: Math.floor(data?.canResendAfterMs / 1000) || 90,
      });
      setIsOpenModalEmail(true);
      setFieldValue("code", "");
      setFieldTouched("code", false);
    },
    (error) => {
      setFieldError(
        "code",
        "data" in error ? t(`common:errors.${error.data}`) : "Ошибка"
      );
    }
  );

  const isShowSuccessIcon =
    values.code && !errors.code && confirmEmailByCodeResponse?.isSuccess;

  return (
    <Dialog maxWidth={"sm"} open={isOpenModalEmail} onClose={handleClose}>
      <Stack>
        <Paper sx={{ p: 4 }}>
          <FormikProvider value={formik}>
            <Stack component={Form}>
              <Typography
                mb={2}
                color="primary"
                variant="body1"
                textAlign={"center"}
              >
                {t("confirmEmail")}
              </Typography>
              <Typography px={2} variant="body2" textAlign="center">
                {t("status.toEmail", { email: currentEmail })}
              </Typography>
              <Typography px={2} mb={1} variant="body2" textAlign="center">
                {t("status.sentCode")}
              </Typography>
              <>
                <CodeFieldForm
                  autoFocus
                  sx={{ marginTop: 3, marginBottom: 3 }}
                  name="code"
                  label={t("codeFromEmail")}
                  placeholder="- - - - - -"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <KeySharpIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        {isShowSuccessIcon && <CheckIcon color="success" />}
                      </InputAdornment>
                    ),
                  }}
                />
                <Typography
                  maxWidth={336}
                  px={2}
                  mt={2}
                  variant="body2"
                  textAlign="center"
                  color="secondary"
                >
                  {t("description")}
                </Typography>
                <ShowByTimer
                  timer={timer}
                  waitingText={t("common:buttons.canGetNewCode")}
                >
                  <Button
                    sx={{ mt: 2 }}
                    startIcon={<AutorenewIcon />}
                    onClick={handleGetCodeByEmail}
                  >
                    {t("common:buttons.sendMailAgain")}
                  </Button>
                </ShowByTimer>
              </>
              <IconButton
                onClick={handleClose}
                sx={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                }}
              >
                <CloseSharpIcon />
              </IconButton>
            </Stack>
          </FormikProvider>
        </Paper>
      </Stack>
    </Dialog>
  );
};
