import styled, { css } from "styled-components";
import {Button, IconButton} from "@mui/material";
import { Form } from "formik";

export const StyledCounterpartyStaff = styled.div`
  height: 100%;
  overflow-y: hidden;
  display: flex;
  justify-content: center;
  column-gap: 120px;
`;

export const StyledUsersBlock = styled.div`
  min-width: 596px;
  overflow-y: auto;
  width: 500px;
  padding: 0 60px;
`;

interface RowProps {
  $isSticky?: boolean;
}

export const StyledRow = styled.div<RowProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  ${({ $isSticky }) =>
    $isSticky &&
    css`
      position: sticky;
      top: 0;
      background: #fff;
      z-index: 5;
    `};
`;

export const StyledSubTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: ${({ theme }) => theme.secondary.textCaption};
`;

export const StyledRowButtonAdd = styled(Button)`
  background: ${({ theme }) => `${theme.background.blue}14`};
  color: ${({ theme }) => theme.secondary.infoMain};
  box-shadow: none;
  min-height: 30px;
  padding: 3px 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  text-transform: capitalize;

  &:hover {
    background: ${({ theme }) => `${theme.background.blue}14`};
  }
`;

export const StyledAccessBlock = styled.div`
  min-width: 596px;
  height: 100%;
  align-self: start;
  overflow: hidden;
  padding: 20px 30px;
  background: #f6f7fb;
  border-radius: 16px;
  max-width: 520px;

  .MuiInputBase-root {
    background: transparent;
    border: 1px solid ${({ theme }) => theme.secondary.backgroundDivider};
    color: #2b3648;
  }

  .label {
    color: ${({ theme }) => theme.primary.textDark};
  }

  .MuiCheckbox-root:not(.Mui-checked) .MuiSvgIcon-root {
    fill: #2b36481f;
  }
`;

export const StyledAccessDescription = styled.div`
  margin: 16px 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: ${({ theme }) => theme.primary.textDark};
  white-space: pre-line;

  .icon-unsync {
    position: absolute;
    width: 16px;
    height: 16px;
    top: 0;
    bottom: 0;
    margin: auto 0;
    left: 5px;
    fill: ${({ theme }) => theme.secondary.error};
  }
`;

export const StyledIconAccessWrapper = styled.span`
  position: relative;
`;

interface FormProps {
  $paddingBottom?: number;
}

export const StyledForm = styled(Form)<FormProps>`
  overflow-y: auto;
  height: 100%;
  padding-right: 20px;
  ${({ $paddingBottom }) =>
    $paddingBottom &&
    css`
      height: calc(100% - ${$paddingBottom}px);
    `};
`;

export const StyledButtonsWrapper = styled.div`
  position: sticky;
  bottom: 0;
  width: 100%;
`;

export const StyledDivisionButton = styled(IconButton)`
  width: 30px;
  height: 30px;
  border-radius: 6px;
  background: ${({ theme }) => `${theme.background.blue}14`};
  margin-left: auto;

  .icon {
    fill: ${({ theme }) => theme.secondary.infoMain};
    width: 20px;
  }
`;