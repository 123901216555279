import styled from "styled-components";

export const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 60px;
  width: 100%;
`;

export const StyledPaginationWrapper = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
`;

export const StyledFiltersWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 16px;
`;
