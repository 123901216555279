import React, { FC, useCallback, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useAppContext } from "../../../../layouts/app-layout/context";
import { useSelector } from "react-redux";
import { projectSelector } from "../../../../services/slices/project";
import { projectContext } from "../../context";
import { useUpdateDesignMutation } from "../../../../api/companies";
import { FormikValues } from "formik/dist/types";
import { formatDateToISO } from "../../../../utils/commonUtils";
import { useForm } from "../../../../hooks/use-form";
import {
  StyledColumn,
  StyledColumnTitle,
  StyledForm,
  StyledFormLayout,
  StyledLabel,
  StyledMain,
  StyledRow,
  StyledTitle,
} from "../../project.styles";
import { FormikProvider } from "formik";
import { EnabledSwitch } from "../enabled-switch";
import { Typography } from "@mui/material";
import { DatePicker } from "../../../../components/date-picker";
import dayjs from "dayjs";
import { ReactComponent as DesignIcon } from "../../../../components/icons/project/desing.svg";
import { StyledMuiSelect } from "../../../../components/styled-mui-select";
import { FieldForm } from "../../../../components/field-form";
import { designStatusesOptions, designPhasesOptions } from "./design.constants";

export const Design: FC = () => {
  const { t } = useTranslation("project");
  const { t: commonT } = useTranslation("common");
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { currentCompanyId, isAdmin } = useAppContext();
  const { info: project } = useSelector(projectSelector);

  const { updateFormState, formsState } = useContext(projectContext);
  const projectID = params.projectID ? Number(params.projectID) : null;

  const [updateDesign] = useUpdateDesignMutation();

  const initialValues = {
    enabled: project?.design?.enabled || false,
    startDate: project?.design?.startDate
      ? new Date(project.design.startDate)
      : null,
    endDate: project?.design?.endDate ? new Date(project.design.endDate) : null,
    code: project?.design?.code || "",
    status: project?.design?.status || "",
    phase: project?.design?.phase || "",
  };

  const handleSubmit = useCallback(async (values: FormikValues) => {
    try {
      const startDate = values.startDate ? formatDateToISO(new Date(values.startDate), true) : null;
      const endDate = values.endDate ? formatDateToISO(new Date(values.endDate), true) : null;

      await updateDesign({
        companyID: currentCompanyId,
        projectID: projectID || 0,
        body: {
          enabled: values.enabled,
          ...(startDate && { startDate }),
          ...(endDate && { endDate }),
          ...(values.code && { code: values.code }),
          ...(values.status && { status: values.status }),
          ...(values.phase && { phase: values.phase}),
        },
      });
    } catch {
      enqueueSnackbar(commonT("errors.error"), {
        variant: "error",
      });
    }
  }, []);

  const { formik, isSubmitDisabled } = useForm({
    enableReinitialize: true,
    initialValues,
    onSubmit: handleSubmit,
  });
  const { values, dirty } = formik;

  useEffect(() => {
    updateFormState({
      ...formsState,
      design: {
        isChanged: dirty,
        isSubmitDisabled: projectID
          ? JSON.stringify(values) === JSON.stringify(initialValues) ||
            isSubmitDisabled
          : true,
      },
    });
  }, [values, isSubmitDisabled, dirty]);

  return (
    <StyledMain>
      <FormikProvider value={formik}>
        <StyledForm id="design">
          <StyledTitle>
            <DesignIcon className="icon" />
            {t("titles.design")}{" "}
            <EnabledSwitch name="enabled" disabled={!isAdmin} />
          </StyledTitle>

          <StyledFormLayout>
            <StyledColumn>
              <StyledColumnTitle>
                {t("columnTitles.designMainInfo")}
              </StyledColumnTitle>

              <FieldForm
                version="profile"
                name="code"
                title={t("inputs.designCode.title")}
                labelClassName="field-form-label_required"
                placeholder={t("inputs.designCode.placeholder")}
                InputProps={{
                  disableUnderline: true,
                }}
                disabled={!isAdmin}
              />

              <StyledMuiSelect
                props={{
                  name: "status",
                  variant: "filled",
                  value: values.status,
                  disabled: !isAdmin,
                }}
                labelClassName="field-form-label_required"
                placeholder={t("inputs.designStatus.placeholder")}
                labelText={t("inputs.designStatus.title")}
                options={designStatusesOptions}
              />

              <StyledMuiSelect
                props={{
                  name: "phase",
                  variant: "filled",
                  value: values.phase,
                  disabled: !isAdmin,
                }}
                labelClassName="field-form-label_required"
                placeholder={t("inputs.designPhase.placeholder")}
                labelText={t("inputs.designPhase.title")}
                options={designPhasesOptions}
              />
            </StyledColumn>

            <StyledColumn>
              <StyledColumnTitle>
                {t("columnTitles.deadlines")}
              </StyledColumnTitle>

              <StyledRow>
                <StyledLabel className="field-form-label field-form-label_required">
                  <Typography pb={0.5} color="secondary" pl={2}>
                    {t("inputs.startDate.title")}
                  </Typography>
                  <DatePicker
                    name="startDate"
                    placeholder={t("inputs.startDate.placeholder")}
                    maxDate={dayjs(values.endDate)}
                    disabled={!isAdmin}
                  />
                </StyledLabel>

                <StyledLabel className="field-form-label field-form-label_required">
                  <Typography pb={0.5} color="secondary" pl={2}>
                    {t("inputs.endDate.title")}
                  </Typography>
                  <DatePicker
                    name="endDate"
                    placeholder={t("inputs.endDate.placeholder")}
                    minDate={dayjs(values.startDate)}
                    disabled={!isAdmin}
                  />
                </StyledLabel>
              </StyledRow>
            </StyledColumn>
          </StyledFormLayout>
        </StyledForm>
      </FormikProvider>
    </StyledMain>
  );
};
