import React, {FC, useCallback, useMemo, useRef, useState} from "react";
import { useTranslation } from "react-i18next";
import ReactCrop, { Crop, makeAspectCrop, centerCrop } from "react-image-crop";
import { Button, Dialog, Stack } from "@mui/material";
import "react-image-crop/dist/ReactCrop.css";
import { getCroppedImg } from "./utils";

export type CropModalProps = {
  isOpen?: boolean;
  file?: File | null;
  onClose?: () => void;
  aspect?: number;
  onSave?: (blob: Blob) => void;
  onCancel?: () => void;
}
export const CropModal: FC<CropModalProps> = ({isOpen, file, onClose, onSave, onCancel, aspect = 1}) => {
  const { t } = useTranslation();

  const imageRef = useRef<HTMLImageElement>(null);
  const src = useMemo(() => (file && URL.createObjectURL(file)) || '', [file]);

  const [crop, setCrop] = useState<Crop>({} as Crop);

  const handleImageLoad = useCallback((e) => {
    const width = e?.currentTarget?.width || 0
    const height = e?.currentTarget?.height || 0
    const isHorizontal = width > height

    setCrop(centerCrop(
        makeAspectCrop(
            {
              unit: 'px',
              width: isHorizontal ? width / 2 : undefined,
              height: !isHorizontal ? height / 2 : undefined,
            },
            aspect,
            width,
            height
        ),
        width,
        height
    ))
  }, [aspect])

  const handleSave = useCallback(async () => {
    if (imageRef.current) {
      const croppedImage = await getCroppedImg(imageRef?.current, crop);
      onSave?.(croppedImage)
    }
  }, [onSave, crop])

  return (
      <Dialog
          open={!!isOpen}
          onClose={onClose}
      >
        <Stack width={400}>
          <ReactCrop
              aspect={aspect}
              crop={crop}
              onChange={setCrop}
          >
            <img ref={imageRef} src={src} onLoad={handleImageLoad} alt="cropping" />
          </ReactCrop>
          <Stack p={1} flex={1} direction="row">
            <Button
                fullWidth
                variant="contained"
                type="submit"
                color="success"
                onClick={handleSave}
            >
              {t("common:buttons.save")}
            </Button>
            <Button
                sx={{ ml: 1 }}
                fullWidth
                variant="contained"
                onClick={onCancel}
            >
              {t("common:buttons.cancel")}
            </Button>
          </Stack>
        </Stack>
      </Dialog>
  )
}
