import { FC } from "react";
import { Avatar, Paper, Stack, Typography, useTheme } from "@mui/material";
import { MainCompany, ProfileByCompany } from "../../api/profile";

type ProfileCardProps = {
  currentCompanyName: MainCompany["name"];
} & Pick<
  ProfileByCompany,
  | "firstName"
  | "lastName"
  | "middleName"
  | "companyName"
  | "position"
  | "avatarPreview"
>;

export const ProfileCard: FC<ProfileCardProps> = ({
  currentCompanyName,
  firstName,
  lastName,
  middleName,
  companyName,
  position,
  avatarPreview,
}) => {
  const theme = useTheme();
  return (
    <Paper
      sx={{
        //width: 300,
        p: 2.5,
      }}
      elevation={24}
    >
      <Typography
        align="center"
        variant="h6"
        py={0.5}
        mb={2.5}
        sx={{ background: theme.palette.background.default, borderRadius: 0.5 }}
      >
        {currentCompanyName}
      </Typography>
      <Stack direction="row">
        <Avatar
          sx={{ width: 64, height: 64 }}
          src={avatarPreview}
          variant="rounded"
        />
        <Stack ml={3}>
          <Stack mb={1}>
            <Typography variant="h5">{lastName}</Typography>
            <Stack direction="row">
              <Typography variant="h5">{firstName}</Typography>
              <Typography ml={0.5} variant="h5">
                {middleName}
              </Typography>
            </Stack>
          </Stack>
          <Stack>
            <Typography variant="body1">{companyName}</Typography>
            <Typography variant="body1">{position}</Typography>
          </Stack>
        </Stack>
      </Stack>
    </Paper>
  );
};
