import styled from "styled-components";

export const StyledStub = styled.div`
  margin: auto;
  display: grid;
  grid-row-gap: 8px;
  justify-content: center;
  justify-items: center;
  align-items: center;
  align-content: center;
  width: 100%;
  height: 100%;
`;

export const StyledStubText = styled.p`
  font-size: 18px;
  line-height: 27px;
  font-weight: 400;
  color: ${({ theme }) => theme.secondary.text};
  white-space: pre-line;
  text-align: center;
  margin: 0;
`;
