import { decode } from "js-base64";

export class Filters {
  status: string[] = [];

  constructor(searchParams: URLSearchParams) {
    const statusParam = searchParams.get("status");

    if (statusParam)
      this.status = decode(statusParam).split(",");
  }
}
