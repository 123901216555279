import React, {
  ChangeEvent,
  FC,
  KeyboardEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { debounce, Select, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  StyledListItemReset,
  StyledMenuItem,
  StyledInput,
} from "./filter-select-multiple.styles";
import { FilterSelectProps, Item } from "./filter-select-multiple.types";
import { StyledNoOptionsMessage } from "../../common/commonStyles";

export const FilterSelectMultiple: FC<FilterSelectProps> = ({
  startIcon,
  items,
  value,
  defaultValue,
  onChange,
  isDisabled,
  isSearchable,
  noOptionsMessage,
  ...props
}) => {
  const { t } = useTranslation("common");

  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useState("");

  const itemsByValue = useMemo(() => {
    const result = {} as Record<Item["value"], Item>;
    items.forEach((item) => {
      result[item.value] = item;
    });
    return result;
  }, [items]);

  useEffect(() => {
    const filteredValue = value.filter((item) => itemsByValue[item]);

    if (value.length !== filteredValue.length) {
      //onChange(filteredValue);
    }
  }, [items, value]);

  useEffect(() => {
    if (!open) {
      setSearch("");
      setDebouncedSearch("");
    }
  }, [open]);

  const filteredItems = useMemo(
    () =>
      debouncedSearch
        ? items.filter(({ title }) =>
            title?.toLowerCase().includes(debouncedSearch.toLowerCase())
          )
        : items,
    [debouncedSearch, items]
  );

  const handleChange = useCallback(
    (event) => {
      const value = event?.target?.value;
      if (Array.isArray(value) && value.includes("reset")) {
        onChange([]);
        setOpen(false);
        return;
      }
      onChange(typeof value === "string" ? value.split(",") : value);
    },
    [onChange]
  );

  const setDebouncedSearchValue = useCallback(
    debounce((value: string) => setDebouncedSearch(value), 300),
    []
  );

  const handleSearchChange = useCallback((event: ChangeEvent) => {
    const target = event.target as HTMLInputElement;

    setSearch(target.value);
    setDebouncedSearchValue(target.value);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  return (
    <Stack justifyContent="center" {...props}>
      <Select
        disabled={isDisabled}
        MenuProps={{ sx: { maxHeight: "400px", maxWidth: "462px" } }}
        multiple
        sx={{
          "& .MuiSvgIcon-root": {
            color: "#2B3648",
          },
          "& div": {
            alignItems: "center",
            paddingRight: 0.2,
          },
        }}
        variant="standard"
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        value={value}
        onChange={handleChange}
        disableUnderline
        displayEmpty
        renderValue={(selected) => {
          return (
            <Stack direction="row">
              {!!startIcon ? startIcon : <Stack height={24} />}
              <Stack ml={1}>
                {selected.length === 0 ? (
                  <Typography variant="body2">{defaultValue}</Typography>
                ) : (
                  <Typography
                    textOverflow="ellipsis"
                    overflow="hidden"
                    variant="body2"
                    maxWidth={200}
                  >
                    {selected.length === 1
                      ? itemsByValue?.[selected[0]]?.title
                      : `${t("selected")} ${selected.length}`}
                  </Typography>
                )}
              </Stack>
            </Stack>
          );
        }}
      >
        <StyledListItemReset value="reset" tabIndex={-1}>
          {t("filters.reset")}
        </StyledListItemReset>

        {isSearchable && (
          <StyledInput
            placeholder={t("buttons.search")}
            onChange={handleSearchChange}
            value={search}
            onKeyDown={(event: KeyboardEvent) => event.stopPropagation()}
          />
        )}

        {filteredItems?.length ? (
          filteredItems?.map((item) => {
            const { value: name, title } = item;
            return (
              <StyledMenuItem value={name} key={name}>
                {title}
              </StyledMenuItem>
            );
          })
        ) : (
          <StyledMenuItem disabled>
            <StyledNoOptionsMessage>
              {noOptionsMessage || t("noOptions")}
            </StyledNoOptionsMessage>
          </StyledMenuItem>
        )}
      </Select>
    </Stack>
  );
};
