import { FC, useCallback, useState } from "react";
import { ICellRendererParams } from "ag-grid-community";
import { PendingDateCellProps } from "./pending-date-cell.types";
import {
  StyledCell,
  StyledIconButton,
  StyledTextWrapper,
  StyledModalDescription,
  StyledModalDates,
  StyledModalDate,
  StyledDivisionName, StyledTooltipWrapper,
} from "./pending-date-cell.styles";
import { formatDate } from "../../../../../../utils/commonUtils";
import {
  useApproveAssignmentMutation,
  useDeclineAssignmentMutation,
} from "../../../../../../api/assignments";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { AssignmentPendingDateTooltip } from "../../../../../../components/assignment-pending-date-tooltip";
import { AssignmentFailureDays } from "../../../../../../components/assignment-failure-days/assignment-failure-days";
import { ReactComponent as MarkUnreadIcon } from "../../../../../../components/icons/imported-icons/mark-unread.svg";
import { DeleteModal } from "../../../../../../components/delete-modal";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

export const PendingDateCell: FC<
  ICellRendererParams<unknown, PendingDateCellProps>
> = ({ value }) => {
  const { t: tCommon } = useTranslation("common");
  const { t } = useTranslation("assignments");
  const { enqueueSnackbar } = useSnackbar();

  const pendingDate = formatDate(value?.pendingDate);

  const [isButtonsDisabled, setIsButtonsDisabled] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [pendingDateStatus, setPendingDateStatus] = useState(
    value?.pendingDateStatus
  );

  const [approveAssignment] = useApproveAssignmentMutation();
  const [declineAssignment] = useDeclineAssignmentMutation();

  const handleDateConfirm = useCallback(
    async (variant: "approve" | "decline") => {
      if (!value?.companyID || !value?.assignmentID) return;
      setIsConfirmModalOpen(false);

      try {
        setIsButtonsDisabled(true);
        const params = {
          companyID: value?.companyID,
          assignmentID: value?.assignmentID,
          body: {
            division: value?.division,
            date: value?.upToDatePending,
          },
        };
        if (variant === "approve") {
          await approveAssignment(params);
          setPendingDateStatus("approved");
        } else {
          await declineAssignment(params);
          setPendingDateStatus("declined");
        }
      } catch {
        enqueueSnackbar(tCommon("errors.error"), { variant: "error" });
        setIsButtonsDisabled(false);
      }
    },
    []
  );

  return (
    <StyledCell
      $colorTheme={
        value?.pendingDateStatus === "awaiting" ? "secondary" : "primary"
      }
    >
      <StyledTextWrapper>
        {formatDate(value?.upToDate)}

        <AssignmentFailureDays days={value?.failureDays || 0} />
      </StyledTextWrapper>

      {pendingDateStatus === "awaiting" && value?.canEdit && (
        <>
          <StyledIconButton
            onClick={() => setIsConfirmModalOpen(true)}
            disabled={isButtonsDisabled}
          >
            <MarkUnreadIcon className="icon" />
          </StyledIconButton>
        </>
      )}

      {pendingDateStatus && pendingDateStatus !== "awaiting" && (
        <AssignmentPendingDateTooltip
          status={pendingDateStatus}
          pendingDate={pendingDate || "–"}
        >
          <StyledTooltipWrapper>
            {value?.pendingDateStatus === "declined" && (
              <CancelIcon className="icon-decline" />
            )}
            {value?.pendingDateStatus === "approved" && (
              <CheckCircleIcon className="icon-approved" />
            )}
          </StyledTooltipWrapper>
        </AssignmentPendingDateTooltip>
      )}

      <DeleteModal
        open={isConfirmModalOpen}
        title={t("pendingDateModal.title")}
        rightButtonText={tCommon("buttons.no")}
        description={
          <StyledModalDescription>
            Подразделение «
            <StyledDivisionName>
              {value?.division
                ? `${value.division[0].toUpperCase()}${value.division.slice(1)}`
                : "–"}
            </StyledDivisionName>
            » обратилось за разрешением изменить срок исполнения поручения:
            <StyledModalDates>
              <StyledModalDate $colorTheme="primary">
                {formatDate(value?.upToDate)}
              </StyledModalDate>
              <ArrowForwardIcon className="icon" />
              <StyledModalDate $colorTheme="secondary">
                {formatDate(value?.upToDatePending)}
              </StyledModalDate>
            </StyledModalDates>
          </StyledModalDescription>
        }
        leftButtonColor="success"
        rightButtonColor="primary"
        titleColor="#0044B4"
        onButtonCloseClick={() => setIsConfirmModalOpen(false)}
        onClose={() => handleDateConfirm("decline")}
        onConfirm={() => handleDateConfirm("approve")}
      />
    </StyledCell>
  );
};
