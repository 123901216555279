export const designStatusesOptions = [
  {
    label: 'Разработка документации',
    value: 'Разработка документации'
  },
  {
    label: 'Авторский надзор',
    value: 'Авторский надзор'
  },
  {
    label: 'Документация согласована',
    value: 'Документация согласована'
  },
  {
    label: 'Архив',
    value: 'Архив'
  },
]

export const designPhasesOptions = [
  {
    label: 'Сбор исходных данных',
    value: 'Сбор исходных данных'
  },
  {
    label: 'Инженерные изыскания',
    value: 'Инженерные изыскания'
  },
  {
    label: 'Проектная документация',
    value: 'Проектная документация'
  },
  {
    label: 'Рабочая документация',
    value: 'Рабочая документация'
  },
]