import React, { FC, useMemo } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Pagination, Stack, useTheme } from "@mui/material";
import FilterAltSharpIcon from "@mui/icons-material/FilterAltSharp";
import AddSharpIcon from "@mui/icons-material/AddSharp";
import { FilterSelect } from "../../../../components/filter-select";
import { FilterSelectMultiple } from "../../../../components/filter-select-multiple";
import { Limit } from "../../users";
import { useGetUserFiltersQuery } from "../../../../api/company-admin";
import { useAppContext } from "../../../../layouts/app-layout/context";
import { Filters } from "../../users.types";

type HeaderUsersProps = {
  limit: number;
  onChangeLimit: (limit: number) => void;
  numberRows: Limit[];

  page: number;
  onChangePage: (page: number) => void;
  filtersState: Filters;
  selectedCounterpartyIDs: number[];

  allRoles: Record<"value" | "title", string>[];
  onChangeRole: (role: string[]) => void;
  onChangeCounterparty: (role: string[]) => void;
  onChangeDivisions: (divisions: string[]) => void;
  allModules: Record<"value" | "title", string>[];
  onChangeModule: (module: string[]) => void;

  countPagination: number;
};

export const HeaderUsers: FC<HeaderUsersProps> = ({
  onChangeRole,
  filtersState,
  allRoles,
  onChangeModule,
  onChangeCounterparty,
  onChangeDivisions,
  allModules,
  countPagination,
  limit,
  onChangeLimit,
  onChangePage,
  page,
  numberRows,
  selectedCounterpartyIDs,
}) => {
  const { t } = useTranslation("user");
  const theme = useTheme();
  const { currentCompanyId } = useAppContext();

  const { data: filters } = useGetUserFiltersQuery(
    { companyId: currentCompanyId },
    { skip: !currentCompanyId }
  );

  const { data: filtersByCounterparty } = useGetUserFiltersQuery(
    { companyId: currentCompanyId, counterpartyIDs: selectedCounterpartyIDs },
    { skip: !currentCompanyId }
  );

  const counterpartyItems = useMemo(() => {
    if (!filters?.counterparties) return [];

    return filters.counterparties.map((counterparty) => ({
      value: counterparty.id?.toString() || "0",
      title: counterparty.name || "",
    }));
  }, [filters?.counterparties]);

  const divisionItems = useMemo(() => {
    if (!filtersByCounterparty?.divisions) return [];
    return filtersByCounterparty.divisions.map((division) => ({
      value: division,
      title: division,
    }));
  }, [filtersByCounterparty?.divisions]);

  return (
    <Stack justifyContent="space-between" direction="row" my={1.25} height={48}>
      <Stack direction="row" alignItems="center">
        <FilterSelectMultiple
          startIcon={<FilterAltSharpIcon />}
          items={allRoles}
          value={filtersState.roles}
          onChange={onChangeRole}
          defaultValue={t("roles.allRoles")}
        />

        <FilterSelectMultiple
          isDisabled={!allModules.length}
          items={allModules}
          value={filtersState.modules}
          onChange={onChangeModule}
          defaultValue={t("roles.allModules")}
        />

        <FilterSelectMultiple
          isDisabled={!counterpartyItems.length}
          items={counterpartyItems}
          value={filtersState.counterparties}
          onChange={onChangeCounterparty}
          defaultValue={"ВСЕ КОМПАНИИ"}
        />

        {filtersState?.counterparties?.length > 0 &&
          divisionItems.length > 0 && (
            <FilterSelectMultiple
              isDisabled={!filtersState?.counterparties?.length}
              items={divisionItems}
              value={filtersState.divisions}
              onChange={onChangeDivisions}
              defaultValue="ВСЕ ПОДРАЗДЕЛЕНИЯ"
            />
          )}
      </Stack>
      <Stack
        spacing={5}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <FilterSelect
          items={numberRows}
          value={limit}
          onChange={onChangeLimit}
          startIcon={t("header.rows")}
        />
        <Pagination
          onChange={(e, v) => onChangePage(v)}
          count={countPagination}
          shape="rounded"
          page={page}
          sx={{
            "& .MuiPaginationItem-root": {
              "&.Mui-selected": {
                background: "rgba(109, 154, 220, 0.2)",
                color: theme.palette.primary.main,
                border: `1px solid ${theme.palette.primary.main}`,
              },
            },
          }}
        />
        <Button
          component={Link}
          sx={{
            minWidth: "40px",
            height: "40px",
            padding: 0,
            boxShadow: "none",
          }}
          variant="contained"
          to={"create"}
        >
          <AddSharpIcon />
        </Button>
      </Stack>
    </Stack>
  );
};
