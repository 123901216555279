import { FC } from "react";
import { ICellRendererParams } from "ag-grid-community";
import { PersonsCellProps } from "./persons-cell.types";
import { StyledCell, StyledText } from "./persons-cell.styles";
import { getPersonInitials } from "../../../../../../../../utils/commonUtils";

export const PersonsCell: FC<
  ICellRendererParams<unknown, PersonsCellProps>
> = ({ value }) => {
  const author = value?.author
    ? `${value.author.lastName} ${getPersonInitials({
        firstName: value.author.firstName,
        middleName: value.author.middleName,
        divider: ".",
      })}`
    : "–";

  const supervisor = value?.supervisor
    ? `${value.supervisor.lastName} ${getPersonInitials({
        firstName: value.supervisor.firstName,
        middleName: value.supervisor.middleName,
        divider: ".",
      })}`
    : "–";

  return (
    <StyledCell>
      <StyledText>{author}</StyledText>
      <StyledText $colorTheme="secondary">{supervisor}</StyledText>
    </StyledCell>
  );
};
