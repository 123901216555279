import { FC } from "react";
import { StyledFailureDays, StyledText } from "./assignment-failure-days.styles";
import { AssignmentFailureDaysProps } from "./assignment-failure-days.types";

export const AssignmentFailureDays: FC<AssignmentFailureDaysProps> = ({
  days,
  colorTheme = 'primary',
  showDash,
}) => {
  if (days <= 0 && !showDash) return null;

  return <StyledFailureDays $colorTheme={colorTheme}>
    <StyledText>{days <= 0 ? '-' : `+${days}`}</StyledText>
  </StyledFailureDays>;
};
