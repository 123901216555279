import styled, { css } from "styled-components";

export const StyledAdministration = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  padding: 0 20px 25px 20px;
`;

export const StyledInfoBlock = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  overflow-y: auto;
  height: calc(100% - 48px);
`;

export const StyledUsersBlock = styled.div`
  width: 100%;
  height: 100%;
  padding: 30px 0;
`;

interface BlockProps {
  $orientation?: "right" | "left";
}

export const StyledBlock = styled.div<BlockProps>`
  width: 596px;
  padding: 32px 60px 32px 60px;
  height: 100%;
  ${({ $orientation }) =>
    $orientation === "right" &&
    css`
      justify-self: end;
    `}
`;

export const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  //box-shadow: 0 -1px 0 0 ${({ theme }) => theme.secondary.backgroundDivider} inset;
  min-height: 48px;
`;
