import React, { useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Form, FormikProvider } from "formik";
import { Button, InputAdornment, Stack, Typography } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import { useLoginByEmailMutation } from "../../../api/auth";
import { FieldForm } from "../../../components/field-form";
import { useMutationHandlers } from "../../../hooks/use-mutation-handlers";
import { useForm } from "../../../hooks/use-form";
import { validationByEmail } from "./validation";
import { PasswordFieldForm } from "../../../components/password-field-form";

export const EmailForm = () => {
  const { t } = useTranslation("auth");
  const navigate = useNavigate();

  const [loginByEmail, loginByEmailResponse] = useLoginByEmailMutation();

  const handleSubmit = useCallback(
    ({ emailOrLogin, password }) => {
      loginByEmail({ emailOrLogin, password });
    },
    [loginByEmail]
  );

  const { formik, isSubmitDisabled } = useForm({
    validationSchema: validationByEmail,
    initialValues: {
      emailOrLogin: "",
      password: "",
    },
    onSubmit: handleSubmit,
  });
  const { setFieldError } = formik;

  useMutationHandlers(
    loginByEmailResponse,
    (data) => {
      navigate("/companies");
    },
    (error) => {
      setFieldError(
        "password",
        "data" in error ? t(`common:errors.${error.data}`) : ""
      );
    }
  );

  return (
    <FormikProvider value={formik}>
      <Stack component={Form}>
        <Typography variant="body2" textAlign={"center"}>
          {t("emailForm.description")}
        </Typography>
        <FieldForm
          sx={{ marginTop: 3 }}
          name="emailOrLogin"
          label={t("emailForm.email.label")}
          placeholder={t("emailForm.email.placeholder")}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PersonIcon />
              </InputAdornment>
            ),
          }}
        />
        <PasswordFieldForm
          sx={{ marginTop: 3 }}
          name="password"
          label={t("emailForm.password")}
        />

        <Typography p="20px 80px 40px" variant="body2" textAlign="center">
          {t("emailForm.forgotPassword")}
          <Link to="/recovery/code">{t("emailForm.forgotPasswordLink")}</Link>
        </Typography>
        <Button
          sx={{ padding: 1.5 }}
          variant="contained"
          type="submit"
          disabled={isSubmitDisabled}
        >
          {t("common:buttons.login")}
        </Button>
      </Stack>
    </FormikProvider>
  );
};
