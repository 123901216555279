import { MouseEvent } from "react";
import {AssignmentPersonProfile} from "../api/assignments/types";

export function fromValuesToOptions(values: string[]) {
  return values.map((value) => ({
    value,
    title: value,
  }));
}

interface PersonInitials {
  firstName?: string;
  middleName?: string;
  divider?: string;
}

export function getPersonInitials({
  firstName,
  middleName,
  divider,
}: PersonInitials) {
  let initials = "";
  if (firstName) {
    initials += `${firstName[0]}${divider || ""}`;
  }
  if (middleName) {
    initials += `${middleName[0]}${divider || ""}`;
  }

  return initials;
}

export function stopPropagation(event: MouseEvent | KeyboardEvent) {
  event.stopPropagation();
}

export function formatDateToISO(rawDate: Date | null, hasTZOffset?: boolean) {
  if (rawDate == null) return null;
  const tzoffset = new Date().getTimezoneOffset() * 60000;
  const rowDateWithOffset = new Date(rawDate.getTime() - tzoffset);

  const [date] = (hasTZOffset ? rowDateWithOffset : rawDate)
    .toISOString()
    .split("T");

  return date;
}

export function parseISODate(date: string | null) {
  if (date == null) return null;

  const [d, t] = date.split(" ");
  const [year, month, day] = d.split("-").map(Number);
  const [hour = 0, minute = 0, second = 0] = (t ?? "").split(":").map(Number);

  if (!year || !month || !day) return null;
  const parsed = new Date(year, month - 1, day, hour, minute, second);

  if (isNaN(parsed.getTime())) return null;
  return parsed;
}

export function formatDate(rawDate: string | Date | null | undefined) {
  if (rawDate == null || rawDate === "") return null;
  let date: Date | null;

  if (rawDate instanceof Date) date = rawDate;
  else date = parseISODate(rawDate);

  if (date == null) return null;

  return date.toLocaleDateString("ru-RU");
}

export function checkNumberIsSafe(number: number) {
  return Number.isFinite(number) && Number.isSafeInteger(Math.round(number));
}

export async function downloadFileByLink(url: string, filename: string) {
  const file = await fetch(url);
  const blob = await file.blob();

  const blobUrl = URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.href = blobUrl;
  link.download = filename;
  link.click();

  URL.revokeObjectURL(blobUrl);
}

export function getFileExtensionByUrl(url?: string) {
  return url?.split(".").pop();
}

export function getAssignmentPersonName(person: AssignmentPersonProfile) {
  return `${person.lastName} ${getPersonInitials({
    firstName: person.firstName,
    middleName: person.middleName,
    divider: ".",
  })}`
}
