import { FormikProvider } from "formik";
import {
  StyledButtonsWrapper,
  StyledForm,
  StyledInputsWrapper,
  StyledKey,
  StyledMultiRows,
  StyledPlaceholder,
  StyledRow,
  StyledTextAreaWrapper,
} from "./counterparty-form.styles";
import { FieldForm } from "../field-form";
import { Button, MenuItem } from "@mui/material";
import { AvatarFieldForm } from "../avatar-field-form";
import React from "react";
import { CounterpartyFormProps } from "./counterparty-form.types";

export const CounterpartyForm = <T,>({
  t,
  formik,
  isFormDisabled,
  isButtonsDisabled,
  variant,
  onBlur,
  roles,
  hasRole,
  onReset,
}: CounterpartyFormProps<T>) => {
  return (
    <FormikProvider value={formik}>
      <StyledForm>
        <FieldForm
          version="profile"
          name="name"
          title={t("shortName")}
          placeholder={t("placeholders.shortName")}
          disabled={isFormDisabled}
        />

        {hasRole && (
          <StyledRow>
            <StyledKey>{t("counterpartyRole")}</StyledKey>
            <FieldForm
              version="profile"
              name="role"
              select
              variant="filled"
              sx={{
                "& div": {
                  borderRadius: 2,
                  "& .MuiFilledInput-input": {
                    padding: "10px 16px",
                  },
                },
              }}
              SelectProps={{
                displayEmpty: true,
                renderValue: (value) =>
                  value ? (
                    <>{value}</>
                  ) : (
                    <StyledPlaceholder>
                      {t("placeholders.role")}
                    </StyledPlaceholder>
                  ),
              }}
              disabled={isFormDisabled}
            >
              {roles ? (
                roles?.map((rol) => (
                  <MenuItem key={rol} value={rol}>
                    {rol}
                  </MenuItem>
                ))
              ) : (
                <div />
              )}
            </FieldForm>
          </StyledRow>
        )}

        <StyledMultiRows>
          <AvatarFieldForm name="avatar" needCrop variant="counterparty" disabled={isFormDisabled} />

          <StyledInputsWrapper>
            <FieldForm
              version="profile"
              name="inn"
              title={t("inn")}
              placeholder={t("placeholders.inn")}
              inputProps={{ type: "number" }}
              onBlur={onBlur}
              disabled={isFormDisabled}
            />
            <FieldForm
              version="profile"
              name="kpp"
              title={t("kpp")}
              placeholder={t("placeholders.kpp")}
              inputProps={{ type: "number" }}
              disabled={isFormDisabled}
            />
            <FieldForm
              version="profile"
              name="ogrn"
              title={t("ogrn")}
              placeholder={t("placeholders.ogrn")}
              inputProps={{ type: "number" }}
              disabled={isFormDisabled}
            />
          </StyledInputsWrapper>
        </StyledMultiRows>

        <StyledTextAreaWrapper>
          <FieldForm
            version="profile"
            name="fullName"
            title={t("fullName")}
            placeholder={t("placeholders.fullName")}
            rows={3}
            multiline
            disabled={isFormDisabled}
          />
        </StyledTextAreaWrapper>

        <StyledTextAreaWrapper>
          <FieldForm
            version="profile"
            name="legalAddress"
            title={t("legalAddress")}
            placeholder={t("placeholders.legalAddress")}
            rows={3}
            multiline
            disabled={isFormDisabled}
          />
        </StyledTextAreaWrapper>

        {!isButtonsDisabled && (
          <StyledButtonsWrapper>
            <Button type="submit" variant="contained" color="success">
              {t(variant === "add" ? "buttons.create" : "buttons.save")}
            </Button>
            <Button variant="contained" onClick={onReset}>
              {t("buttons.cancel")}
            </Button>
          </StyledButtonsWrapper>
        )}
      </StyledForm>
    </FormikProvider>
  );
};
