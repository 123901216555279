import { FC, useCallback, useMemo, useState } from "react";
import { AssignmentsTableProps } from "./assignments-table.types";
import { getTableDataByGroup } from "./assignments-table.service";
import { AgGridReact } from "ag-grid-react";
import { AgGridNoRowsOverlay } from "../../../../../../components/ag-grid-no-rows-overlay";
import { StyledAgGridWrapper } from "../../../../../../common/librariesStyles/agGrid";
import {
  ColDef,
  ColGroupDef,
  RowClassParams,
  RowClickedEvent,
  RowHeightParams,
} from "ag-grid-community";
import {
  TEXT_CELL_WIDTH,
  PERSONS_CELL_WIDTH,
  CREATED_AT_CELL_WIDTH,
  EXECUTORS_CELL_WIDTH,
  STATUS_CELL_WIDTH,
  UP_TO_DATE_CELL_WIDTH,
  commonCellClasses,
} from "./assignments-table.constants";
import { formatDate } from "../../../../../../utils/commonUtils";
import { TextCell } from "./components/text-cell";
import { PersonsCell } from "./components/persons-cell";
import { ExecutorsCell } from "../../../../../../components/executors-cell";
import { StatusCell } from "./components/status-cell";
import { StyledTableWrapper } from "./assignments-table.styles";
import { useNavigate, useLocation } from "react-router-dom";
import { UpToDateCell } from "./components/upToDate-cell";

export const AssignmentsTable: FC<AssignmentsTableProps> = ({ data }) => {
  const sortedTableData = useMemo(() => getTableDataByGroup(data), [data]);
  const navigate = useNavigate();
  const location = useLocation();

  const [hiddenRowsIds, setHiddenRowsIds] = useState<Set<string>>(new Set());

  const columnDefs: (ColGroupDef | ColDef)[] = [
    {
      headerName: "Текст поручения",
      width: TEXT_CELL_WIDTH,
      minWidth: TEXT_CELL_WIDTH,
      field: "text",
      colSpan: (params) => {
        return params.data?.isGroup ? 6 : 1;
      },
      cellRenderer: TextCell,
      cellClass: `${commonCellClasses} ag-cell_flex ag-cell_vertical-padding_9`,
    },
    {
      headerName: "Автор и контролер",
      width: PERSONS_CELL_WIDTH,
      minWidth: PERSONS_CELL_WIDTH,
      field: "persons",
      cellRenderer: PersonsCell,
    },
    {
      headerName: "Дата отправки",
      width: CREATED_AT_CELL_WIDTH,
      minWidth: CREATED_AT_CELL_WIDTH,
      field: "createdAt",
      cellClass: `${commonCellClasses} ag-cell_justify-content_center`,
    },
    {
      headerName: "Исполнитель",
      width: EXECUTORS_CELL_WIDTH,
      minWidth: EXECUTORS_CELL_WIDTH,
      flex: 1,
      field: "executors",
      cellRenderer: ExecutorsCell,
    },
    {
      headerName: "Статус",
      minWidth: STATUS_CELL_WIDTH,
      field: "status",
      cellRenderer: StatusCell,
    },
    {
      headerName: "Согласованный срок",
      minWidth: UP_TO_DATE_CELL_WIDTH,
      width: UP_TO_DATE_CELL_WIDTH,
      field: "upToDate",
      cellRenderer: UpToDateCell,
      cellClass: `${commonCellClasses} ag-cell_justify-content_center`,
    },
  ];

  const getRowHeight = useCallback((event: RowHeightParams) => {
    return event.data?.isGroup ? 40 : 62;
  }, []);

  const handleButtonHideClick = useCallback(
    (group: string) => {
      const newState = new Set(hiddenRowsIds);
      newState.has(group) ? newState.delete(group) : newState.add(group);
      setHiddenRowsIds(newState);
    },
    [hiddenRowsIds]
  );

  const getRowClass = useCallback((event: RowClassParams) => {
    let classes = "";
    if (!event.data?.isGroup) {
      classes = "ag-row_interactive";
    }
    if (event.data?.highPriority) classes += " ag-row_high-priority";

    return classes;
  }, []);

  const handleRowClicked = useCallback(
    (event: RowClickedEvent) => {
      const target = event.event?.target as HTMLElement;
      const isButtonComment =
        target.classList.contains("button-comment") ||
        target.closest(".button-comment");
      if (isButtonComment) return;

      const fullData = event.data?.fullData;
      if (event.data?.isGroup || !fullData) return;

      navigate(`${location.pathname}/${fullData.id}/division-view`);
    },
    [navigate, location]
  );

  const rows = useMemo(() => {
    return sortedTableData.reduce((accumulator, [group, value]) => {
      const groupData = {
        text: {
          text: group,
          isGroup: true,
          onButtonHideClick: () => handleButtonHideClick(group),
          isOpen: hiddenRowsIds.has(group),
        },
        isGroup: true,
      };
      if (hiddenRowsIds.has(group)) return [...accumulator, groupData];

      const items = value.map((value) => ({
        text: { text: value.text },
        persons: { author: value.author, supervisor: value.supervisor },
        createdAt: value.createdAt ? formatDate(value.createdAt) : "–",
        upToDate: {
          upToDate: value.division.upToDate
            ? formatDate(value.division.upToDate)
            : "–",
          failureDays: value.division.failureDays,
          pendingDateStatus: value.division.pendingDateStatus,
          pendingDate: value.division.upToDatePending,
        },
        executors: { executors: value.division.executors },
        status: {
          status: value.division.status,
          comment: value.comment,
          commentDate: value.commentDate,
          commentAuthor: value.commentAuthor,
        },
        fullData: value,
        highPriority: value.highPriority,
      }));

      return [...accumulator, groupData, ...items];
    }, [] as {}[]);
  }, [sortedTableData, hiddenRowsIds]);

  return (
    <StyledTableWrapper>
      <StyledAgGridWrapper>
        <div className="ag-theme-alpine">
          <AgGridReact
            columnDefs={columnDefs}
            rowData={rows}
            noRowsOverlayComponent={AgGridNoRowsOverlay}
            headerHeight={49}
            rowHeight={42}
            getRowHeight={getRowHeight}
            getRowClass={getRowClass}
            onRowClicked={handleRowClicked}
            defaultColDef={{
              editable: false,
              sortable: false,
              filter: false,
              resizable: true,
            }}
          />
        </div>
      </StyledAgGridWrapper>
    </StyledTableWrapper>
  );
};
