import { FC, useCallback, useMemo } from "react";
import ReactInputMask from "react-input-mask";
import { useField } from "formik";
import { FieldHookConfig } from "formik/dist/Field";
import { TextField, TextFieldProps } from "@mui/material";
import { formatMaskPhone, formatPhone, PHONE_MASK } from "./utils";
import { useValidatePhone } from "./validation";
import { useTranslation } from "react-i18next";

export type PhoneFieldFormProps = {
  name: string;
  disabled?: boolean;
  fieldProps?: Partial<FieldHookConfig<string>>;
} & Omit<TextFieldProps, "name">;

export const PhoneFieldForm: FC<PhoneFieldFormProps> = ({
  fieldProps,
  disabled,
  ...props
}) => {
  const { t } = useTranslation();
  const validate = useValidatePhone();
  const [{ onChange, value, ...field }, { touched, error }, { setValue }] =
    useField({
      name: props.name,
      validate,
      ...fieldProps,
    });

  const handleChange = useCallback(
    (e) => {
      setValue(formatPhone(e.target.value));
    },
    [setValue]
  );
  const valueFormatPhone = useMemo(() => {
    return formatMaskPhone(value);
  }, [value]);

  const isError = touched && !!error;

  return (
    <ReactInputMask
      disabled={disabled}
      {...field}
      value={valueFormatPhone}
      onChange={handleChange}
      mask={PHONE_MASK}
    >
      <TextField
        error={isError}
        helperText={isError ? t(error) : undefined}
        {...props}
      />
    </ReactInputMask>
  );
};
