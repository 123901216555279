import styled from "styled-components";

export const StyledAdminWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    .MuiFormControlLabel-root {
        margin: 0;
        border: 1px solid ${({ theme }) => theme.secondary.backgroundDivider};
        border-radius: 8px;
        min-width: 220px;
    }

    margin-bottom: 32px;
`;

export const StyledAdminTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
`;

export const StyledAdminLabel = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.primary.mainBlue};
  text-transform: lowercase;

  &:first-letter {
    text-transform: uppercase;
  }
`;
