import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { IconButton, Paper, Stack, Typography } from "@mui/material";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import {
  StyledCloseButton,
  StyledTitle,
  StyledButton,
  StyledDescription,
} from "./confirmation-dailog.styles";

type ConfirmationDialogProps = {
  onCancel: () => void;
  onSuccess: () => void;
  title: string;
  description?: string;
  descriptionEnd?: string;
  theme?: "default" | "error";
};

export const ConfirmationDialog: FC<ConfirmationDialogProps> = ({
  onCancel,
  onSuccess,
  title,
  description,
  descriptionEnd,
  theme = "default",
}) => {
  const { t } = useTranslation("company");

  return (
    <Paper sx={{ p: 4, width: 400 }}>
      <Stack alignItems="center">
        {theme === "default" ? (
          <Typography mb={2} color="primary" variant="h4" textAlign={"center"}>
            {title}
          </Typography>
        ) : (
          <StyledTitle>{title}</StyledTitle>
        )}
        {theme === "default" ? (
          <Typography maxWidth={340} variant="body2" px={5} textAlign="center">
            {description}
          </Typography>
        ) : (
          <StyledDescription>{description}</StyledDescription>
        )}

        <Typography
          mt={2}
          maxWidth={340}
          px={2}
          variant="body2"
          textAlign="center"
        >
          {descriptionEnd}
        </Typography>
      </Stack>
      <Stack mt={4} direction="row">
        <StyledButton
          onClick={onSuccess}
          fullWidth
          variant="contained"
          color={theme === "default" ? "success" : "error"}
          $theme={theme}
        >
          {t("common:buttons.yes")}
        </StyledButton>

        <StyledButton
          onClick={onCancel}
          sx={{ ml: 1, py: 1.5 }}
          fullWidth
          variant="contained"
          $theme={theme}
        >
          {t(
            theme === "default" ? "common:buttons.no" : "common:buttons.cancel"
          )}
        </StyledButton>
      </Stack>
      {theme === "default" ? (
        <IconButton
          onClick={onCancel}
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
          }}
        >
          <CloseSharpIcon fontSize="small" />
        </IconButton>
      ) : (
        <StyledCloseButton onClick={onCancel}>
          <CloseSharpIcon fontSize="small" />
        </StyledCloseButton>
      )}
    </Paper>
  );
};
