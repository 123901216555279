import { InitValues } from "./main-form.types";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { SerializedError } from "@reduxjs/toolkit";
import { TFunction } from "react-i18next";
import { OptionsObject, SnackbarKey, SnackbarMessage } from "notistack";

interface FromFormValueToRes {
  values: InitValues;
}

export function fromFormValueToRes({ values }: FromFormValueToRes) {
  const formData = new FormData();
  formData.append(
    "request",
    JSON.stringify({
      name: values.name,
      fullName: values.fullName,
      inn: values.inn,
      role: values.role,
      kpp: values.kpp,
      ogrn: values.ogrn,
      legalAddress: values.legalAddress,
    })
  );

  if (values.avatar instanceof Blob) {
    formData.append("file", values.avatar);
  }
  return formData;
}

interface GetErrorMessageKeyByRes {
  error?: FetchBaseQueryError | SerializedError;
  t: TFunction;
}

export function getErrorMessage({ error, t }: GetErrorMessageKeyByRes) {
  if (!error) return false;

  if ("data" in error && error?.data === "counterparty.duplicate_inn") {
    return { key: "inn", value: "errors.duplicateInn" };
  }

  if ("data" in error && error?.data === "counterparty.duplicate_name")
    return { key: "name", value: "errors.duplicateName" };

  return false;
}

interface GetFieldError {
  duplicateInn?: boolean;
  duplicateName?: boolean;
  existingInn?: boolean;
  callback: (field: string, value: string | undefined) => void;
  t: TFunction;
  commonTranslation: TFunction;
  enqueueSnackbar: (
    message: SnackbarMessage,
    options?: OptionsObject
  ) => SnackbarKey;
}

export function getFieldError({
  duplicateInn,
  duplicateName,
  existingInn,
  callback,
  t,
  commonTranslation,
  enqueueSnackbar,
}: GetFieldError) {
  if (duplicateInn) return callback("inn", t("errors.duplicateInn"));
  if (duplicateName) return callback("name", t("errors.duplicateName"));
  if (existingInn) return callback("inn", t("errors.duplicateInn"));

  enqueueSnackbar(commonTranslation("errors.error"), {
    variant: "error",
  });
}
