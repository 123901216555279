import { DownloadAssignmentFilesTootltipProps } from "./download-assignment-files-tooltip.types";
import { FC, useCallback } from "react";
import { CustomTooltip } from "../custom-tooltip";
import {
  StyledButtonDownload,
  StyledTooltipBody,
  StyledTooltipTitle,
} from "./download-assignment-files-tooltip.styles";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { AssignmentFilesList } from "../assignment-files-list";
import ButtonDownloadIcon from "@mui/icons-material/Download";
import { useTranslation } from "react-i18next";
import { downloadFileByLink } from "../../utils/commonUtils";

export const DownloadAssignmentFilesTooltip: FC<DownloadAssignmentFilesTootltipProps> = ({
  isOpen,
  onClose,
  files,
  children,
}) => {
  const { t } = useTranslation("assignments");

  const handleButtonDownloadClick = useCallback(() => {
    files.forEach((file) => {
      downloadFileByLink(file.url, file.originName);
    });
  }, [files]);

  return (
    <CustomTooltip
      isOpen={isOpen}
      onClose={onClose}
      placement="bottom-end"
      componentsProps={{
        popper: {
          sx: {
            "& .MuiTooltip-tooltip": {
              margin: "0!important",
              width: "300px",
              background: "#F6F7FB",
              borderRadius: "8px",
              boxShadow: '0px 2px 4px -2px #1018280F, 0px 4px 8px -2px #1018281A'
            },

            "& .MuiTooltip-arrow": {
              color: "#F6F7FB",
            },
          },
        },
      }}
      PopperProps={{
        sx: {
          width: "300px",
          maxHeight: "308px",
        },
      }}
      title={
        <StyledTooltipBody>
          <StyledTooltipTitle>
            <AttachFileIcon className="icon" />
            {t("files")}
          </StyledTooltipTitle>

          <AssignmentFilesList files={files} />
          <StyledButtonDownload
            variant="contained"
            startIcon={<ButtonDownloadIcon className="icon" />}
            onClick={handleButtonDownloadClick}
          >
            {t("downloadAll")}
          </StyledButtonDownload>
        </StyledTooltipBody>
      }
    >
      {children}
    </CustomTooltip>
  );
};
