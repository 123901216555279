import { api } from "../api";
import { Profile } from "./types";

export const profileApi = api.injectEndpoints({
  endpoints: (build) => ({
    getProfile: build.query<Profile, void>({
      query: () => "/profile/get",
      providesTags: ["Profile"],
    }),

    updateProfile: build.mutation<
      Profile,
      Pick<Profile, "login" | "email" | "phone" | "password">
    >({
      query: (values) => ({
        url: `/profile/update`,
        method: "POST",
        body: { ...values },
      }),
      invalidatesTags: () => ["Profile", "Users", "ProfileByCompany"],
    }),
  }),
  overrideExisting: false,
});

export const { useGetProfileQuery, useUpdateProfileMutation } = profileApi;
