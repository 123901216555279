import { useCallback, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { encodeURI, decode, isValid } from "js-base64";

type AcceptedValue = string | boolean | number | null;

export interface QueryHandler {
  get: <T = string>(key: string) => T | null;
  getBase64: <T = string>(key: string) => T | null;
  getBase64Object: <T = Record<string, any>>(key: string) => T | null;

  set: (key: string, value: AcceptedValue) => void;
  setBase64: (key: string, value: AcceptedValue) => void;
  setBase64Object: (key: string, value: any) => void;

  remove: (key: string) => void;
}

/**
 * Хук позволяющий взаимодействовать с query параметрами.
 */
export const useQuery = (
  callback?: (handler: QueryHandler) => any
): QueryHandler => {
  const location = useLocation();
  const navigate = useNavigate();
  const query = useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location.search]);

  const updateURL = useCallback(() => {
    navigate(
      { pathname: location.pathname, search: query.toString() },
      { replace: true }
    );
  }, [query, navigate, location.pathname]);

  const get = useCallback(
    (key: string) => {
      return query.get(key) as any;
    },
    [query]
  );

  const getBase64 = useCallback(
    (key: string) => {
      const string = query.get(key);
      if (string == null || !isValid(string)) return null;

      return decode(string) as any;
    },
    [query]
  );

  const getBase64Object = useCallback(
    (key: string) => {
      const string = query.get(key);
      if (string == null || !isValid(string)) return null;

      const parsed = JSON.parse(decode(string));
      if (typeof parsed != "object") return null;

      return parsed;
    },
    [query]
  );

  const set = useCallback(
    (key: string, value: AcceptedValue) => {
      query.set(key, String(value));
      updateURL();
    },
    [query]
  );

  const setBase64 = useCallback(
    (key: string, value: AcceptedValue) => {
      const encoded = encodeURI(String(value));

      query.set(key, encoded);
      updateURL();
    },
    [query]
  );

  const setBase64Object = useCallback(
    (key: string, value: any) => {
      const encoded = encodeURI(JSON.stringify(value));

      query.set(key, encoded);
      updateURL();
    },
    [query]
  );

  const remove = useCallback((key: string) => {
    query.delete(key);
    updateURL();
  }, []);

  const handler = {
    get,
    getBase64,
    getBase64Object,
    set,
    setBase64,
    setBase64Object,
    remove,
  };

  useEffect(() => {
    if (callback) callback(handler);
  }, []);

  return handler;
};
