import React, { ChangeEvent, FC, useCallback, useMemo } from "react";
import {
  StyledHeader,
  StyledPaginationWrapper,
  StyledFiltersWrapper,
} from "./header.styles";
import { FilterSelect } from "../../../../../../components/filter-select";
import { StyledPagination } from "../../../../../../common/commonStyles";
import { HeaderProps } from "./header.types";
import { NUMBER_OF_ROWS_PER_USERS_PAGE } from "../../../../../../utils/constants";
import { useTranslation } from "react-i18next";
import { Filters } from "../../assignments-by-division.types";
import { encode } from "js-base64";
import FilterAltSharpIcon from "@mui/icons-material/FilterAltSharp";
import {
  fromValuesToOptions,
  getPersonInitials,
} from "../../../../../../utils/commonUtils";
import { FilterSelectMultiple } from "../../../../../../components/filter-select-multiple";

export const Header: FC<HeaderProps> = ({
  limit,
  page,
  pages,
  filters,
  statuses,
  authors,
  supervisors,
  groups,
  executors,
  searchParams,
  setSearchParams,
  onChangeLimit,
  onPaginationChange,
  onFiltersChange,
}) => {
  const { t: tCommon } = useTranslation("common");
  const { t } = useTranslation("assignments");

  const authorsOptions = useMemo(
    () =>
      authors.map((value) => {
        const title = `${value.lastName} ${getPersonInitials({
          firstName: value.firstName,
          middleName: value.middleName,
          divider: ".",
        })}`;

        return { value: value.coreID, title };
      }),
    [authors]
  );

  const supervisorsOptions = useMemo(
    () =>
      supervisors.map((value) => {
        const title = `${value.lastName} ${getPersonInitials({
          firstName: value.firstName,
          middleName: value.middleName,
          divider: ".",
        })}`;

        return { value: value.coreID, title };
      }),
    [supervisors]
  );

  const executorsOptions = useMemo(
    () =>
      executors.map((value) => {
        const title = `${value.lastName} ${getPersonInitials({
          firstName: value.firstName,
          middleName: value.middleName,
          divider: ".",
        })}`;

        return { value: value.coreID, title };
      }),
    [executors]
  );

  const handlePaginationChange = (_: ChangeEvent<unknown>, value: number) => {
    onPaginationChange(value);
  };

  const handleChange = useCallback(
    (value: string[], key: keyof Filters) => {
      searchParams.set(key, encode(value.join(",")));
      setSearchParams(searchParams);

      onFiltersChange({
        ...filters,
        [key]: value,
      });
    },
    [filters, searchParams]
  );

  return (
    <StyledHeader>
      <StyledFiltersWrapper>
        <FilterSelectMultiple
          startIcon={<FilterAltSharpIcon />}
          items={fromValuesToOptions(statuses)}
          value={filters.status}
          onChange={(value) => handleChange(value, "status")}
          defaultValue={t("filtersPlaceholders.status")}
        />

        <FilterSelectMultiple
          items={fromValuesToOptions(groups)}
          value={filters.group}
          onChange={(value) => handleChange(value, "group")}
          defaultValue={t("filtersPlaceholders.group")}
        />

        <FilterSelectMultiple
          items={authorsOptions}
          value={filters.authorID}
          onChange={(value) => handleChange(value, "authorID")}
          defaultValue={t("filtersPlaceholders.author")}
        />

        <FilterSelectMultiple
          items={supervisorsOptions}
          value={filters.supervisorID}
          onChange={(value) => handleChange(value, "supervisorID")}
          defaultValue={t("filtersPlaceholders.supervisor")}
        />

        <FilterSelectMultiple
          items={executorsOptions}
          value={filters.executorID}
          onChange={(value) => handleChange(value, "executorID")}
          defaultValue={t("filtersPlaceholders.executor")}
        />
      </StyledFiltersWrapper>

      <StyledPaginationWrapper>
        <FilterSelect
          items={NUMBER_OF_ROWS_PER_USERS_PAGE}
          value={limit}
          onChange={onChangeLimit}
          startIcon={tCommon("rows")}
        />
        <StyledPagination
          onChange={handlePaginationChange}
          count={pages}
          shape="rounded"
          page={page}
        />
      </StyledPaginationWrapper>
    </StyledHeader>
  );
};
