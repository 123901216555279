import styled, { css } from "styled-components";
import { Form } from "formik";
import { styledComponentsTheme } from "../../../../core/styledComponentsTheme";
import { Switch } from "@mui/material";

const getLabelStyles = (isRequired?: boolean) => css`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 2px;
  color: ${({ theme }) => theme.secondary.textAdditional};

  &::before {
    ${isRequired &&
    css`
      content: "*";
      position: absolute;
      color: ${styledComponentsTheme.secondary.error};
      right: -9px;
    `}
  }
`;

export const StyledForm = styled(Form)`
  display: flex;
  justify-content: center;
  column-gap: 160px;
  overflow: auto;
  width: 100%;

  .MuiInputBase-root.MuiOutlinedInput-root,
  .MuiFilledInput-root {
    background: ${({ theme }) => theme.primary.blueShades};
    border: 1px solid ${({ theme }) => `${theme.primary.textDark}1F`};
    padding-left: 4px;

    .MuiSvgIcon-root {
      fill: ${({ theme }) => theme.secondary.text};
    }
  }

  .MuiInputBase-input {
    font-size: 14px;

    &::placeholder {
      font-size: 14px;
    }
  }

  .MuiInputBase-multiline {
    padding: 0;
  }

  .MuiInputBase-inputMultiline {
    padding: 8px;
  }

  .date-picker {
    .MuiFormControl-root {
      min-width: 200px;
    }

    .MuiInputBase-root {
      max-height: 36px;
      max-width: 200px;
      min-width: 200px;
      border: none;
    }

    .MuiInputBase-input {
      padding-left: 8px;
    }

    .MuiButtonBase-root {
      padding: 6px;
    }

    .MuiOutlinedInput-root .MuiSvgIcon-root {
      width: 20px;
      fill: ${({ theme }) => theme.primary.light};
    }
  }
`;

export const StyledColumn = styled.div`
  width: 480px;
  display: grid;
  align-content: start;
  grid-row-gap: 8px;
  height: 100%;
  overflow-y: auto;
`;

interface TitleProps {
  $hasMargin?: boolean;
}

export const StyledTitle = styled.div<TitleProps>`
  padding-bottom: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.secondary.backgroundDivider};
  display: flex;
  align-items: center;
  column-gap: 8px;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;

  ${({ $hasMargin }) =>
    $hasMargin &&
    css`
      margin-top: 32px;
      margin-bottom: 16px;
    `};

  .icon {
    fill: ${({ theme }) => theme.primary.light};
  }
`;

interface InputWrapperProps {
  $isRequired?: boolean;
  $hasMarginBottom?: boolean;
  $direction?: "row" | "column";
}

export const StyledInputWrapper = styled.div<InputWrapperProps>`
  ${({ $hasMarginBottom }) =>
    $hasMarginBottom &&
    css`
      margin-bottom: 8px;
    `};

  ${({ $direction }) =>
    $direction === "column" &&
    css`
      display: flex;
      align-items: center;
      justify-content: space-between;
    `};

  .field-form-label .MuiTypography-root {
    padding: 0;
    ${({ $isRequired }) => getLabelStyles($isRequired)};
    width: max-content;
    position: relative;
  }
`;

export const StyledLabel = styled.div<Pick<InputWrapperProps, "$isRequired">>`
  width: max-content;
  position: relative;

  ${({ $isRequired }) => getLabelStyles($isRequired)};
`;

export const StyledSwitch = styled(Switch)`
  .Mui-checked + .MuiSwitch-track {
    background: ${({ theme }) => theme.primary.light};
  }
`;
