import { FC, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useField } from "formik";
import { FieldHookConfig } from "formik/dist/Field";
import { TextFieldProps } from "@mui/material";
import ReactInputMask from "react-input-mask";
import { CODE_MASK, formatCode, formatMaskCode } from "./utils";
import { FieldForm } from "../field-form";
import { CODE_LENGTH } from "../../utils/constants";
import { useValidateCode } from "./validation";

export type CodeFieldFormProps = {
  name: string;
  fieldProps?: Partial<FieldHookConfig<string>>;
} & Omit<TextFieldProps, "name">;

export const CodeFieldForm: FC<CodeFieldFormProps> = ({
  fieldProps,
  ...props
}) => {
  const { t } = useTranslation();
  const validate = useValidateCode();
  const [{ onChange, value, ...field }, { touched, error }, { setValue }] =
    useField({
      name: props.name,
      validate,
      ...fieldProps,
    });

  const handleChangeValue = useCallback(
    (e) => {
      const newFormatCode = formatCode(e.target.value);
      if (value !== newFormatCode) {
        setValue(newFormatCode);
      }
    },
    [setValue, value]
  );

  const valueFormat = useMemo(() => {
    return formatMaskCode(value);
  }, [value]);

  const isError = touched && !!error;

  return (
    <ReactInputMask
      {...field}
      value={valueFormat}
      onChange={handleChangeValue}
      mask={CODE_MASK}
    >
      <FieldForm
        error={isError}
        helperText={
          isError ? t(error as string, { count: CODE_LENGTH }) : undefined
        }
        {...props}
      />
    </ReactInputMask>
  );
};
