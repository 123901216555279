export const TEXT_CELL_WIDTH = 388;

export const PERSONS_CELL_WIDTH = 256;

export const CREATED_AT_CELL_WIDTH = 172;

export const EXECUTORS_CELL_WIDTH = 300;

export const STATUS_CELL_WIDTH = 156;

export const UP_TO_DATE_CELL_WIDTH = 168;

export const commonCellClasses =
  "ag-cell_flex ag-cell_align-items_center";