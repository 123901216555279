import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "../store";
import i18n from "i18next";
import { setCredentials } from "../services/slices/auth/auth-slice";
import { LoginResponse } from "./auth";
import SnackbarUtils from "../utils/snackbarUtilsConfigurator";

const { REACT_APP_BASE_API_URL } = process.env;

const baseQuery = fetchBaseQuery({
  baseUrl: REACT_APP_BASE_API_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as any).auth.token;

    if (token && !headers.has("authorization")) {
      headers.set("authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

export const api = createApi({
  baseQuery: async (args, queryApi, extraOptions) => {
    const { dispatch, getState } = queryApi;
    let result = await baseQuery(args, queryApi, extraOptions);

    const statusCode = ((result?.error as any)?.originalStatus as number) || 0;

    if (result.error && result.error.status === 401) {
      const refreshToken = (getState() as RootState).auth.refreshToken;

      const refreshResult = await baseQuery(
        { url: "/auth/refresh_token", method: "POST", body: { refreshToken } },
        queryApi,
        extraOptions
      );
      if (refreshResult.data) {
        dispatch(setCredentials(refreshResult.data as LoginResponse));

        result = await baseQuery(args, queryApi, extraOptions);
      } else {
        dispatch(setCredentials({}));
      }
    }
    if (statusCode === 502 || statusCode === 503 || statusCode === 504) {
      SnackbarUtils.info(i18n.t("common:errors.server_is_not_available"));
    }
    if (statusCode === 500 || statusCode === 501 || statusCode === 505) {
      SnackbarUtils.info(i18n.t("common:errors.request_error"));
    }
    return result;
  },
  tagTypes: [
    "Profile",
    "Modules",
    "ProfileByCompany",
    "Users",
    "Companies",
    "CompanyAdminInfo",
    "Counterparty",
    "Projects",
    "Project",
    "Assignments",
  ],
  endpoints: () => ({}),
});
