import { FC, useMemo } from "react";
import { AssignmentPendingDateTooltipProps } from "./assignment-pending-date-tooltip.types";
import { Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { StyledTitle } from "./assignment-pending-date-tooltip.styles";

export const AssignmentPendingDateTooltip: FC<
  AssignmentPendingDateTooltipProps
> = ({ status, pendingDate, messageVariant = "short", children }) => {
  const { t } = useTranslation("assignments");

  const title = useMemo(() => {
    switch (status) {
      case "declined":
        return (
          <>
            <div>{t("tooltips.declined")}</div>
            <div>{t("tooltips.newDate")}</div>
          </>
        );
      case "approved":
        return (
          <>
            <div>{t("tooltips.approved")}</div>
            <div>{t("tooltips.newDate")}</div>
          </>
        );
      case "awaiting":
        return (
          <>
            <div>{t("tooltips.awaiting")}</div>
            <div>{`В зависимости от принятого решения установленный срок (${pendingDate}) будет изменён или останется в силе.`}</div>
            {messageVariant === "long" && (
              <div>{t("tooltips.awaitingLong")}</div>
            )}
          </>
        );
    }
  }, [status]);

  return (
    <Tooltip
      title={<StyledTitle>{title}</StyledTitle>}
      arrow
      placement={"bottom-end"}
      slotProps={{
        tooltip: { sx: { background: "#5C6E8C", maxWidth: "200px" } },
      }}
    >
      {children}
    </Tooltip>
  );
};
