import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useContext,
  ChangeEvent,
} from "react";
import {
  StyledForm,
  StyledColumn,
  StyledTitle,
  StyledInputWrapper,
  StyledLabel,
  StyledSwitch,
} from "./assignment-form.styles";
import { useTranslation } from "react-i18next";
import { useForm } from "../../../../hooks/use-form";
import { FormikProvider } from "formik";
import { ReactComponent as ChatIcon } from "../../../../components/icons/imported-icons/chat.svg";
import { ReactComponent as FileIcon } from "../../../../components/icons/imported-icons/file.svg";
import { ReactComponent as SendIcon } from "../../../../components/icons/imported-icons/send-file.svg";
import { AutoComplete } from "../../../../components/auto-complete";
import { Values, AssignmentFormProps } from "./assignment-form.types";
import { useAppContext } from "../../../../layouts/app-layout/context";
import { FieldForm } from "../../../../components/field-form";
import { DatePicker } from "../../../../components/date-picker";
import DateRangeIcon from "@mui/icons-material/DateRange";
import { AssignmentsFileAttachList } from "../../../../components/assignments-file-attach-list";
import { AssignmentsFile } from "../../../../api/assignments/types";
import { DivisionsList } from "../DivisionsList";
import { validationSchema } from "./validation";
import { editAssignmentsContext } from "../../context";
import dayjs from "dayjs";

export const AssignmentForm: FC<AssignmentFormProps> = ({
  creationData,
  isEdit,
}) => {
  const { t } = useTranslation("assignments");
  const { profileByCompany } = useAppContext();
  const { setIsFormReady, setIsAnyFieldChanged, setValues, currentAssignment } =
    useContext(editAssignmentsContext);

  const initialValues: Values = useMemo(() => {
    const files = currentAssignment?.files.map((file) => ({
      id: file.id,
      file,
    }));
    const divisions = currentAssignment?.divisions.map((division, index) => ({
      id: `${division.name}-${index}`,
      name: division.name || "",
    }));
    const supervisor = currentAssignment?.supervisor
      ? `${currentAssignment?.supervisor?.lastName || ""} ${
          currentAssignment?.supervisor?.firstName || ""
        } ${currentAssignment?.supervisor?.middleName || ""}`
      : "";

    return {
      group: currentAssignment?.group || "",
      text: currentAssignment?.text || "",
      supervisor,
      files: files || [],
      divisions: divisions || [{ id: "1", name: "" }],
      upToDate: currentAssignment?.upToDate
        ? new Date(currentAssignment?.upToDate)
        : null,
      highPriority: currentAssignment?.highPriority || false,
    };
  }, [currentAssignment]);

  const { formik } = useForm({
    validationSchema,
    enableReinitialize: true,
    initialValues,
    validateOnMount: true,
    onSubmit: () => {},
  });
  const { values, isValid, setFieldValue } = formik;

  const groupsOptions = useMemo(() => {
    if (!creationData?.groups) return [];
    return creationData?.groups.map((group) => ({
      value: group,
      label: group,
    }));
  }, [creationData?.groups]);

  const supervisorOptions = useMemo(() => {
    if (!creationData?.supervisors) return [];
    return creationData?.supervisors.map((supervisor) => {
      const value = `${supervisor.lastName || ""} ${
        supervisor.firstName || ""
      } ${supervisor.middleName || ""}`;

      return { value, label: value };
    });
  }, [creationData?.supervisors]);

  const profileFullName = `${profileByCompany?.lastName || ""} ${
    profileByCompany?.firstName || ""
  } ${profileByCompany?.middleName || ""}`;

  useEffect(() => {
    if (values.supervisor) return;

    if (
      !isEdit &&
      supervisorOptions?.find(
        (supervisor) => supervisor.value === profileFullName
      )
    ) {
      initialValues.supervisor = profileFullName;
      setFieldValue("supervisor", profileFullName);
    }
  }, [supervisorOptions, profileFullName]);

  useEffect(() => {
    setIsFormReady(
      isValid &&
        !!values.divisions.length &&
        values.divisions.every((division) => division.name)
    );
  }, [values.divisions, isValid]);

  useEffect(() => {
    setIsAnyFieldChanged(
      JSON.stringify(values) !== JSON.stringify(initialValues)
    );
    setValues(values);
  }, [values]);

  const handleFileDelete = useCallback(
    (file: File | AssignmentsFile, id: string | number) => {
      setFieldValue(
        "files",
        values.files.filter((f) => f.id !== id)
      );
    },
    [values]
  );

  return (
    <FormikProvider value={formik}>
      <StyledForm>
        <StyledColumn>
          <StyledTitle>
            <ChatIcon />
            {t("titles.info")}
          </StyledTitle>

          <StyledInputWrapper>
            <StyledLabel>{t("group.label")}</StyledLabel>
            <AutoComplete
              options={groupsOptions}
              name="group"
              variant="profile"
              placeholder={t("group.placeholder")}
              isCreatable
              noOptionsMessage={t("creatableNoOptionsMessage")}
            />
          </StyledInputWrapper>

          <StyledInputWrapper $isRequired>
            <FieldForm
              version="profile"
              name="text"
              title={t("text.label")}
              placeholder={t("text.placeholder")}
              multiline
              rows={4}
            />
          </StyledInputWrapper>

          <StyledInputWrapper $direction="column" $hasMarginBottom>
            <StyledLabel>{t("highPriority.label")}</StyledLabel>

            <StyledSwitch
              name="highPriority"
              checked={values.highPriority}
              onChange={(_: ChangeEvent, checked: boolean) =>
                setFieldValue("highPriority", checked)
              }
            />
          </StyledInputWrapper>

          <StyledInputWrapper>
            <StyledLabel>{t("supervisor.label")}</StyledLabel>
            <AutoComplete
              options={supervisorOptions}
              name="supervisor"
              variant="profile"
              placeholder={t("supervisor.placeholder")}
              isCreatable
            />
          </StyledInputWrapper>
          <StyledInputWrapper $direction="column">
            <StyledLabel $isRequired>{t("upToDate.label")}</StyledLabel>

            <DatePicker
              name="upToDate"
              placeholder={t("upToDate.placeholder")}
              icon={DateRangeIcon}
              valueIntlOptions={{
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              }}
              minDate={dayjs(new Date())}
            />
          </StyledInputWrapper>

          <StyledTitle $hasMargin>
            <FileIcon />
            {t("titles.files")}
          </StyledTitle>
          <AssignmentsFileAttachList
            files={values.files}
            onChange={(files) => setFieldValue("files", files)}
            onDelete={handleFileDelete}
          />
        </StyledColumn>

        <StyledColumn>
          <StyledTitle>
            <SendIcon />
            {t("titles.divisions")}
          </StyledTitle>

          <DivisionsList
            creationData={creationData?.divisions || []}
            divisions={values.divisions}
            onChange={(divisions) => setFieldValue("divisions", divisions)}
          />
        </StyledColumn>
      </StyledForm>
    </FormikProvider>
  );
};
