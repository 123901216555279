import { StyledHeader, StyledButtonAdd } from "./header.styles";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import { useLocation } from "react-router-dom";
import { FC } from "react";
import { HeaderProps } from "./header.types";

export const Header: FC<HeaderProps> = ({ children }) => {
  const { t } = useTranslation("assignments");
  const location = useLocation();

  return (
    <StyledHeader>
      {children}

      <StyledButtonAdd
        variant="contained"
        startIcon={<AddIcon />}
        href={`${location.pathname}/create`}
      >
        {t("assignment")}
      </StyledButtonAdd>

    </StyledHeader>
  );
};
