import { FC, useCallback, MouseEvent } from "react";
import { ProjectCardProps } from "./ProjectCard.types";
import {
  StyledCard,
  StyledHeader,
  StyledButtonFavorite,
  StyledShortName,
  StyledId,
  StyledBody,
  StyledFullName,
  StyledField,
  StyledValue,
} from "./ProjectCard.styles";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import { useTranslation } from "react-i18next";
import {
  enProjectFinancingTypes,
  enProjectStatuses,
  enProjectTypes,
} from "../../projects.constants";
import { ReactComponent as IconBagMoney } from "../../../../components/icons/imported-icons/bag-money.svg";
import { ReactComponent as IconGroup } from "../../../../components/icons/imported-icons/group.svg";
import { ReactComponent as IconCoins } from "../../../../components/icons/imported-icons/coins.svg";
import { ReactComponent as IconCustomer } from "../../../../components/icons/imported-icons/customer.svg";
import { ReactComponent as IconArchitector } from "../../../../components/icons/imported-icons/architector.svg";
import { ReactComponent as IconDirector } from "../../../../components/icons/imported-icons/director.svg";
import GroupIcon from "@mui/icons-material/Group";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import PersonIcon from "@mui/icons-material/Person";
import { getTimezoneLabel } from "./ProjectCard.service";
import { ModulesList } from "./components/modules-list";
import { useNavigate, useLocation } from "react-router-dom";
import { useAppContext } from "../../../../layouts/app-layout/context";

export const ProjectCard: FC<ProjectCardProps> = ({
  projectInfo,
  onButtonFavoriteClick,
}) => {
  const { t } = useTranslation("projects");
  const navigate = useNavigate();
  const location = useLocation();
  const { currentCompanyId } = useAppContext();

  const handleCardClick = useCallback(() => {
    navigate({
      pathname: `/companies/${currentCompanyId}/projects/${projectInfo.id}/`,
      search: location.search,
    });
  }, [projectInfo, navigate, location]);

  const handleButtonFavoriteClick = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation();

      onButtonFavoriteClick();
    },
    [onButtonFavoriteClick, projectInfo.id]
  );

  return (
    <StyledCard onClick={handleCardClick}>
      <StyledHeader>
        <StyledButtonFavorite onClick={handleButtonFavoriteClick}>
          {projectInfo.favourite ? <BookmarkIcon /> : <BookmarkBorderIcon />}
        </StyledButtonFavorite>
        <StyledShortName>{projectInfo.shortName}</StyledShortName>
        <StyledId>{`ID - ${projectInfo.id}`}</StyledId>
      </StyledHeader>

      <StyledBody>
        <div>
          <StyledFullName>{projectInfo.fullName}</StyledFullName>

          <StyledField>
            <StyledValue>
              <IconBagMoney className="icon" />
              {t("card.titles.financingType")}
            </StyledValue>
            <StyledValue $color="textCaption">
              {enProjectFinancingTypes[projectInfo.financingType]}
            </StyledValue>
          </StyledField>

          <StyledField>
            <StyledValue>
              <IconGroup className="icon" />
              {t("card.titles.status")}
            </StyledValue>
            <StyledValue $color="textCaption">
              {enProjectStatuses[projectInfo.status]}
            </StyledValue>
          </StyledField>

          <StyledField>
            <StyledValue>
              <HomeWorkIcon className="icon" />
              {t("card.titles.projectType")}
            </StyledValue>
            <StyledValue $color="textCaption">
              {enProjectTypes[projectInfo.projectType]}
            </StyledValue>
          </StyledField>

          <StyledField>
            <StyledValue>
              <IconCoins className="icon" />
              {t("card.titles.price")}
            </StyledValue>
            <StyledValue $color="textCaption">
              {projectInfo.pricePretty
                ? `${projectInfo.pricePretty.value} ${projectInfo.pricePretty.unit}`
                : "–"}
            </StyledValue>
          </StyledField>
        </div>

        <div>
          <StyledField>
            <StyledValue>
              <FmdGoodIcon className="icon" />
              {t("card.titles.region")}
            </StyledValue>
            <StyledValue $color="textCaption">{`${
              projectInfo.region
            } ${getTimezoneLabel(projectInfo.timezone)}`}</StyledValue>
          </StyledField>

          <StyledField>
            <StyledValue>
              <FmdGoodIcon className="icon" />
              {t("card.titles.address")}
            </StyledValue>
            <StyledValue $color="textCaption">
              {projectInfo.address}
            </StyledValue>
          </StyledField>

          <StyledField>
            <StyledValue>
              <IconCustomer className="icon" />
              {t("card.titles.customer")}
            </StyledValue>
            <StyledValue $color="textCaption">
              {projectInfo.customer?.name || "–"}
            </StyledValue>
          </StyledField>

          <StyledField>
            <StyledValue>
              <IconArchitector className="icon" />
              {t("card.titles.architector")}
            </StyledValue>
            <StyledValue $color="textCaption">
              {projectInfo.architector?.name || "–"}
            </StyledValue>
          </StyledField>

          <StyledField>
            <StyledValue>
              <GroupIcon className="icon" />
              {t("card.titles.contractor")}
            </StyledValue>
            <StyledValue $color="textCaption">
              {projectInfo.contractor?.name || "–"}
            </StyledValue>
          </StyledField>

          <StyledField>
            <StyledValue>
              <PersonIcon className="icon" />
              {t("card.titles.curator")}
            </StyledValue>
            <StyledValue $color="textCaption">
              {projectInfo.curator?.name || "–"}
            </StyledValue>
          </StyledField>

          <StyledField>
            <StyledValue>
              <IconDirector className="icon" />
              {t("card.titles.director")}
            </StyledValue>
            <StyledValue $color="textCaption">
              {projectInfo.director?.name || "–"}
            </StyledValue>
          </StyledField>
        </div>

        <div>
          <ModulesList modules={projectInfo.modules} />
        </div>
      </StyledBody>
    </StyledCard>
  );
};
