import { UsersListProps } from "./users-list.types";
import { FC } from "react";
import { StyledList } from "./users-list.styles";
import { UsersByDivisions } from "../users-by-divisions";

export const UsersList: FC<UsersListProps> = ({ users, access }) => {
  return (
    <StyledList>
      {users.map((user, index) => (
        <UsersByDivisions
          staff={user}
          access={access}
          key={`${user.name}-${index}`}
        />
      ))}
    </StyledList>
  );
};
