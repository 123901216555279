import { FC } from "react";
import { Stack, useTheme } from "@mui/material";

type RoleIndicatorProps = {
  isAdmin?: boolean;
};

export const RoleIndicator: FC<RoleIndicatorProps> = ({ isAdmin }) => {
  const theme = useTheme();

  return (
    <Stack
      width={22}
      height={12}
      borderRadius={10}
      sx={{
        backgroundColor: isAdmin
          ? theme.palette.warning.main
          : theme.palette.primary.contrastText,
        border: `1px solid ${!isAdmin ? theme.palette.warning.main : "none"}`,
      }}
    />
  );
};
