import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { PhoneForm, EmailForm } from "../pages/auth";
import { CodeForm, PasswordForm, Confirm } from "../pages/recovery";
import { AuthLayout } from "../layouts/auth-layout";
import { useTranslation } from "react-i18next";
import { Layout } from "../layouts/layout";
import { CompaniesLayout } from "../layouts/companies-layout";
import { CompanyLayout } from "../layouts/company-layout";
import { Modules } from "../pages/modules";
import { RecoveryTitle } from "../components/recovery-title";
import { Profile } from "../pages/profile";
import { Users } from "../pages/users";
import { PageTitle } from "../components/page-title";
import { NavigateToDefaultCompany } from "../components/navigate-to-default-company";
import { User } from "../pages/user";
import { Administration } from "../pages/administration";
import { Counterparties } from "../pages/counterparties";
import { Counterparty } from "../pages/counterparty";
import { Projects } from "../pages/projects";
import { Project } from "../pages/project";
import { Assignments } from "../pages/assignments";
import { EditAssignments } from "../pages/edit-assignments";
import { AssignmentByCentralOffice } from "../pages/assignment-by-central-office";
import { AssignmentByDivision } from "../pages/assignment-by-division";

export const Router = () => {
  const { t } = useTranslation();
  return (
    <Routes>
      <Route>
        <Route
          path="auth/*"
          element={<AuthLayout isShowTabs title={t("auth:title")} />}
        >
          <Route path="phone" element={<PhoneForm />} />
          <Route path="email" element={<EmailForm />} />
          <Route index element={<Navigate to="phone" />} />
          <Route path="*" element={<Navigate to="phone" />} />
        </Route>

        <Route
          path="recovery/*"
          element={<AuthLayout title={<RecoveryTitle />} />}
        >
          <Route path="code" element={<CodeForm />} />
          <Route path="confirm" element={<Confirm />} />
          <Route path="password" element={<PasswordForm />} />

          <Route index element={<Navigate to="code" />} />
          <Route path="*" element={<Navigate to="code" />} />
        </Route>

        <Route path="*" element={<Layout title={<PageTitle />} />}>
          <Route path="companies" element={<CompaniesLayout />}>
            <Route path=":id/*" element={<CompanyLayout />}>
              <Route path="module" element={<Modules />} />

              <Route path="projects/*">
                <Route path=":projectID" element={<Project />} />
                <Route path="create" element={<Project />} />
                <Route index element={<Projects />} />
              </Route>

              <Route path="assignments/*">
                <Route
                  path=":id/co-view"
                  element={<AssignmentByCentralOffice />}
                />
                <Route
                  path=":id/division-view"
                  element={<AssignmentByDivision />}
                />
                <Route path=":id" element={<EditAssignments isEdit />} />
                <Route path="create" element={<EditAssignments />} />
                <Route index element={<Assignments />} />
              </Route>

              <Route path="profile" element={<Profile />} />

              <Route path="users/*">
                <Route path=":user" element={<User />} />
                <Route index element={<Users />} />
              </Route>

              <Route path="administration" element={<Administration />} />

              <Route path="counterparties/*">
                <Route path=":id" element={<Counterparty variant="update" />} />
                <Route path="create" element={<Counterparty variant="add" />} />
                <Route index element={<Counterparties />} />
              </Route>

              <Route index element={<Navigate to="module" />} />
              <Route path="*" element={<Navigate to="module" />} />
            </Route>

            <Route index element={<NavigateToDefaultCompany />} />
          </Route>

          <Route path="*" element={<Navigate to="/companies" />} />
        </Route>
      </Route>
    </Routes>
  );
};
