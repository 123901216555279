import { useAppContext } from "../../layouts/app-layout/context";
import { Navigate } from "react-router-dom";
import { useMemo } from "react";
import { sourceCompanySelector } from '../../services/slices/auth/auth-slice'
import { useTypedSelector } from '../../store'
import { companyNameByDomain } from '../../api/companies'

export const NavigateToDefaultCompany = () => {
  const { companies } = useAppContext();
  const sourceCompany = useTypedSelector(sourceCompanySelector);

  const foundSourceCompany = useMemo(
    () => sourceCompany && companies?.find((company) => company.name === companyNameByDomain[sourceCompany]),
    [companies, sourceCompany]
  );

  const company = useMemo(
    () => companies?.find((company) => !!company.id),
    [companies]
  );

  if (foundSourceCompany?.id) {
    return <Navigate to={`/companies/${foundSourceCompany.id}`} />;
  }

  if (company?.id) {
    return <Navigate to={`/companies/${company.id}`} />;
  }

  return null;
};
