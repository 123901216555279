import React, { FC, useCallback, useEffect } from "react";
import { FieldForm } from "../field-form";
import { Button, MenuItem, Modal } from "@mui/material";
import {
  ConnectCounterpartyModalProps,
  FormValues,
} from "./connect-counterparty-modal.types";
import {
  StyledModal,
  StyledButtonClose,
  StyledTitle,
  StyledDescription,
  StyledPlaceholder,
  StyledButtons,
} from "./connect-counterparty-modal.styles";
import CloseIcon from "@mui/icons-material/Close";
import { ClearedClientId } from "./components/cleared-client-id";
import { useForm } from "../../hooks/use-form";
import { FormikProvider } from "formik";
import { validation } from "./validation";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useBindByClientIDMutation } from "../../api/counterparty";
import { useAppContext } from "../../layouts/app-layout/context";
import { isFetchBaseQueryErrorData } from "./connect-counterparty-modal.service";

export const ConnectCounterpartyModal: FC<ConnectCounterpartyModalProps> = ({
  open,
  title,
  description,
  roles,
  onClose,
  onSuccess,
}) => {
  const { t } = useTranslation("connectCounterpartyModal");
  const { t: commonTranslation } = useTranslation("common");
  const { t: counterpartyT } = useTranslation("counterparty");
  const { enqueueSnackbar } = useSnackbar();
  const { currentCompanyId } = useAppContext();

  const [bindByClientID, bindByClientIDResult] = useBindByClientIDMutation();

  const handleSubmit = async (values: FormValues) => {
    const res = await bindByClientID({
      body: {
        clientID: values.clientID,
        role: values.role,
      },
      companyID: currentCompanyId,
    });

    if ("data" in res && res.data.success) {
      enqueueSnackbar(t("successClientID"), {
        variant: "success",
      });
      onSuccess();
    }
  };

  useEffect(() => {
    if ("error" in bindByClientIDResult) {
      const message =
        isFetchBaseQueryErrorData(bindByClientIDResult.error) &&
        bindByClientIDResult.error.data === "Wrong client ID"
          ? t("wrongClientID")
          : commonTranslation("error");

      enqueueSnackbar(message, {
        variant: "error",
      });
    }
  }, [bindByClientIDResult]);

  useEffect(() => {
    if (!open) {
      setFieldValue("clientID", "");
      setFieldValue("role", "");
    }
  }, [open]);

  const initialValues: FormValues = {
    clientID: "",
    role: "",
  };

  const { formik, isSubmitDisabled, setFieldValue } = useForm({
    validationSchema: validation,
    enableReinitialize: true,
    initialValues,
    validateOnMount: true,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (bindByClientIDResult.isSuccess && !bindByClientIDResult.data.success) {
      const innMessage = bindByClientIDResult.data?.duplicateInn
        ? counterpartyT("errors.duplicateInn")
        : "";
      const nameMessage = bindByClientIDResult.data?.duplicateName
        ? counterpartyT("errors.duplicateName")
        : "";

      formik.setFieldError(
        "clientID",
        [
          ...(nameMessage ? [nameMessage] : []),
          ...(innMessage ? [innMessage] : []),
        ].join(", ")
      );
    }
  }, [bindByClientIDResult]);

  const handleClientIDChange = useCallback((value: string) => {
    setFieldValue("clientID", value);
  }, []);

  return (
    <Modal open={open} sx={{ display: "flex" }}>
      <FormikProvider value={formik}>
        <StyledModal>
          <StyledTitle>{title}</StyledTitle>
          <StyledDescription>{description}</StyledDescription>

          <ClearedClientId
            value={formik.values.clientID}
            onChange={handleClientIDChange}
            onClear={() => setFieldValue("clientID", "")}
          />

          <FieldForm
            version="profile"
            name="role"
            select
            variant="filled"
            sx={{
              "& div": {
                borderRadius: 2,
                "& .MuiFilledInput-input": {
                  padding: "10px 16px",
                },
              },
            }}
            SelectProps={{
              displayEmpty: true,
              renderValue: (value) =>
                value ? (
                  <>{value}</>
                ) : (
                  <StyledPlaceholder>
                    {t("select.placeholder")}
                  </StyledPlaceholder>
                ),
            }}
          >
            {roles ? (
              roles?.map((rol) => (
                <MenuItem key={rol} value={rol}>
                  {rol}
                </MenuItem>
              ))
            ) : (
              <div />
            )}
          </FieldForm>

          <StyledButtons>
            <Button
              variant="contained"
              color="success"
              type="submit"
              disabled={isSubmitDisabled}
            >
              {t("buttons.connect")}
            </Button>
            <Button variant="contained" onClick={onClose}>
              {t("buttons.cancel")}
            </Button>
          </StyledButtons>

          <StyledButtonClose onClick={onClose}>
            <CloseIcon className="icon" />
          </StyledButtonClose>
        </StyledModal>
      </FormikProvider>
    </Modal>
  );
};
