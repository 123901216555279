import { decode } from "js-base64";

export class Filters {
  roles: string[] = [];
  modules: string[] = [];
  counterparties: string[] = [];
  divisions: string[] = [];

  constructor(searchParams: URLSearchParams) {
    const roleParam = searchParams.get("roles");
    const moduleParam = searchParams.get("modules");
    const counterpartiesParam = searchParams.get("counterparties");
    const divisionsParam = searchParams.get("divisions");

    if (divisionsParam) this.divisions = decode(divisionsParam).split(",");
    if (roleParam) this.roles = decode(roleParam).split(",");
    if (moduleParam) this.modules = decode(moduleParam).split(",");
    if (counterpartiesParam)
      this.counterparties = decode(counterpartiesParam).split(",");
  }
}
