import styled from "styled-components";
import { IconButton } from "@mui/material";

export const StyledFieldWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  .field-form-label {
    width: 100%;

    .MuiTypography-root {
      font-size: 12px;
      text-align: center;
      color: ${({ theme }) => theme.secondary.textAdditional};
    }
  }

  .MuiFilledInput-input {
    text-align: center;
    font-size: 20px;
    line-height: 20px;
    padding: 7px 16px;
  }
`;

export const StyledClearButton = styled(IconButton)`
  position: absolute;
  top: 32px;
  right: 8px;
  width: 24px;
  height: 24px;

  .icon {
    width: 16px;
    fill: ${({ theme }) => theme.primary.mainBlue};
  }
`;
