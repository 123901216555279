import React, { FC } from "react";
import { ListItem, Stack, Typography } from "@mui/material";
import { ModuleStatuses, ModuleType } from "../../api/companies";
import { Icon } from "../icons";
import {
  BACKGROUND_DISABLED,
  ICONS,
  ModuleStatusesColors,
} from "../../utils/constants";
import { StatusIndicator } from "../../pages/modules/components/status-indicator";

type ModuleCardHorizontalProps = {
  name: string;
  title: string;
  description?: string;
  background?: string;
  moduleType: ModuleType;
  status?: ModuleStatuses;
};

export const ModuleCardHorizontal: FC<ModuleCardHorizontalProps> = ({
  name,
  title,
  background,
  moduleType,
  description,
  status,
}) => {
  return (
    <ListItem
      sx={{
        padding: 0,
        borderRadius: 5,
        background: background,
        position: "relative",
        minWidth: 588,
      }}
    >
      <Stack position="absolute" right={30} top={-9}>
        <StatusIndicator
          status={status}
          backgroundColor={
            ModuleStatusesColors[status as keyof typeof ModuleStatusesColors] ||
            BACKGROUND_DISABLED
          }
        />
      </Stack>
      <Stack
        textAlign="center"
        alignItems="center"
        justifyContent="space-around"
        width="100%"
        height="240px"
        p="28px"
        direction="row"
      >
        <Icon
          style={{ width: 200, height: 200 }}
          name={moduleType as keyof typeof ICONS}
        />
        <Stack flex={1} alignItems="start">
          <Typography
            sx={{
              fontWeight: 800,
            }}
            color="primary"
            variant="h3"
            mb="6px"
            mt="6px"
          >
            {name}
          </Typography>
          <Typography variant="body2">{title}</Typography>
          <Typography
            color="secondary"
            textAlign="start"
            sx={{
              fontWeight: 300,
            }}
            variant="body2"
          >
            {description}
          </Typography>
          {/*<Stack mt={1} alignItems="end" width="100%">*/}
          {/*  <Button>{t("common:buttons.access")}</Button>*/}
          {/*</Stack>*/}
        </Stack>
      </Stack>
    </ListItem>
  );
};
